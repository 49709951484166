import {
  Box,
  Collapse,
  Stack,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { useRandomIllustration } from "~/images/illustrations";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { useGetIdentity } from "react-admin";

export const Error = ({ resetErrorBoundary, error, errorInfo }) => {
  const [open, setOpen] = useState(false);
  const Illustration = useRandomIllustration();
  const { identity } = useGetIdentity();
  const { pathname } = useLocation();
  const originalPathname = useRef(pathname);

  // Effect that resets the error state whenever the location changes
  useEffect(() => {
    if (pathname !== originalPathname.current) {
      resetErrorBoundary();
    }
  }, [pathname, resetErrorBoundary]);

  return (
    <Box
      width="100%"
      height="100%"
      justifyContent="center"
      textAlign="center"
      m={1}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: "white",
          borderRadius: 3,
          padding: 4,
        }}
      >
        <Illustration width="15%" height="10%" />
        <Typography variant="h4" paragraph mt={5}>
          Oops! This page isn't loading
        </Typography>
        <Typography variant="body1" marginBottom={4}>
          Try refreshing the page to see if we can get this thing booted up
        </Typography>
        {identity.role === "super_admin" && (
          <>
            <Button
              onClick={() => {
                setOpen((open) => !open);
              }}
              variant="outlined"
            >
              <Typography variant="body">{`${
                open ? "Hide" : "View"
              } Error`}</Typography>
            </Button>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Typography variant="h6">{error.message}</Typography>
              <Divider />
              <Typography variant="body1" width="80%">
                {errorInfo}
              </Typography>
            </Collapse>
          </>
        )}
      </Stack>
    </Box>
  );
};
