import { Box, Fade, Stack, Typography } from "@mui/material";
import { get } from "lodash";
import { useRef, useState } from "react";
import {
  Button,
  LinearProgress,
  useDataProvider,
  useRecordContext,
} from "react-admin";
import { useQueryClient } from "react-query";
import { OptionsInputs } from "~/components/inputs";
import { useSubscription } from "~/lib";

export const WpExtension = () => {
  const record = useRecordContext();
  const { wp_api_root_url, encrypted_wp_password, wp_username } = get(
    record,
    "options",
    {}
  );
  const [disabled, setDisabled] = useState(false);
  const [importStatus, setImportStatus] = useState();
  const dataProvider = useDataProvider();
  const queryClient = useQueryClient();
  const windowRef = useRef();

  const handleClick = () => {
    setDisabled(true);
    dataProvider.putCustom(`platform_extensions/${record?.id}/start_process`);
  };
  useSubscription("PlatformExtensionChannel", record?.id, {
    received: (res) => {
      setImportStatus(res);
      if (res.progress === 100) {
        queryClient.invalidateQueries([
          "platform_extensions",
          "getOne",
          { id: record?.id.toString() },
        ]);
        windowRef.current?.close();
      }
    },
  });
  return (
    <>
      {!!record?.id && (
        <Stack gap={2} py={2}>
          <Stack gap={2}>
            {importStatus?.message && (
              <>
                <Typography variant="h4">{importStatus?.message}</Typography>
                <LinearProgress
                  sx={{ width: "100%" }}
                  variant={
                    importStatus?.progress ? "determinate" : "indeterminate"
                  }
                  value={importStatus?.progress}
                />
              </>
            )}
          </Stack>
          {wp_api_root_url && (
            <Box>
              <Button
                disabled={
                  disabled ||
                  (!!importStatus?.progress && importStatus.progress !== 100)
                }
                onClick={handleClick}
                label="Import Now"
              />
            </Box>
          )}
        </Stack>
      )}
      <OptionsInputs
        record={record}
        filter={([field, _options]) => {
          if (field === "wp_api_root_url") return true;
          if (!wp_api_root_url) return false;
          if (["wp_username", "wp_password"].includes(field)) return false;
          if (!encrypted_wp_password || !wp_username) return false;
          return true;
        }}
        source={"options"}
        type_source={"type"}
        container
        gridItemProps={(field) => ({
          item: true,
          xs: 12,
          md: ["json", "object"].includes(field.type) ? 12 : 4,
        })}
      />
      {!!wp_api_root_url && !encrypted_wp_password && (
        <Button
          label="Log into Wordpress"
          onClick={async () => {
            const auth_params = new URLSearchParams({
              rest_route: "/",
              _fields: "authentication",
            });
            const auth_path = await fetch(
              `${wp_api_root_url}?${auth_params.toString()}`
            )
              .then((r) => r.json())
              .then((r) =>
                get(
                  r,
                  "authentication.application-passwords.endpoints.authorization"
                )
              );
            const params = new URLSearchParams({
              app_name: "Itinerator",
              success_url: `${window.location.origin}/hooks/wordpress/v1/platform_extensions/${record.id}/add_credentials`,
            });
            windowRef.current = window.open(
              `${auth_path}?${params.toString()}`
            );
          }}
        />
      )}
    </>
  );
};
