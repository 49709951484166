import { useState } from "react";
import { ResizeHandlesWrapper } from "../generic";
import { type ImageBlock } from "./types";

export const ImagePreview = ({ block, editor }: ImageBlock) => {
  const [width, setWidth] = useState<number>(
    Math.min(
      block.props.previewWidth!,
      editor.domElement.firstElementChild!.clientWidth
    )
  );
  const { url, fullWidth } = block.props;
  return (
    <ResizeHandlesWrapper
      block={block}
      editor={editor}
      width={width}
      setWidth={setWidth}
    >
      <img
        src={url}
        width={fullWidth ? "100%" : width}
        contentEditable={false}
        draggable={false}
      />
    </ResizeHandlesWrapper>
  );
};
