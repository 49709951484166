import { Box, Stack, Typography, styled } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import {
  LinearProgress,
  RecordContextProvider,
  TextField,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { useQueryClient } from "react-query";
import { useGetElementSize } from "~/hooks";
import { useChannel } from "~/lib";
import { LoaderOverlay } from "../LoaderOverlay";
import { DrawerFormProvider } from "../drawer/DrawerForm";
import { ImageError } from "./ImageError";
import { ImageForm } from "./ImageForm";
import { ImagePreviewButtons } from "./ImagePreviewButtons";
import { useDeleteImage } from "./hooks";
import { AudioThumbnail } from "./AudioThumbnail";

export const ImagePreview = ({ source, show_caption = true, ...props }) => {
  const { file } = props;
  const [loading, setLoading] = useState(true);
  const [renderingStatus, setRenderingStatus] = useState({
    message: "Rendering...",
    progress: false,
  });

  const { message: rendering_message, progress } = renderingStatus;

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const resource = useResourceContext();
  const record = useRecordContext();
  const { id: parentId } = record || {};
  const queryClient = useQueryClient();
  const deleteImage = useDeleteImage(source);
  const { ref, size } = useGetElementSize([loading]);
  const { id, content_type, url, rendering } = file || {};

  const invalidateRecord = useCallback(() => {
    if (source.includes("@@ra-many")) {
      const cache_key = [resource, "getManyReference"];
      queryClient.invalidateQueries(cache_key);
    } else if (!!parentId) {
      const cache_key = [resource, "getOne", { id: parentId.toString() }];
      queryClient.invalidateQueries(cache_key);
    }
  }, [parentId, queryClient, resource, source]);

  const { subscribe, unsubscribe } = useChannel();

  useEffect(() => {
    if (id && rendering) {
      subscribe(
        { channel: "MediaConversionChannel", id },
        {
          received: (res) => {
            setRenderingStatus(res);
            if (res?.progress === 100) {
              invalidateRecord();
            }
          },
        }
      );
    }
    return () => unsubscribe();
  }, [id, rendering, subscribe, unsubscribe, invalidateRecord]);

  const handleDelete = () => {
    setError(false);
    setLoading(true);
    deleteImage(file);
  };

  const onClose = () => {
    setTimeout(() => {
      invalidateRecord();
      setOpen(false);
    }, 100);
  };

  const canEdit = show_caption && !loading && !error;
  const handleEdit = canEdit ? () => setOpen(true) : undefined;

  if (!file) return null;
  if (rendering)
    return (
      <Stack
        justifyContent="safe center"
        alignItems="stretch"
        borderRadius={1.5}
        border="1px dashed"
        borderColor="grey.900"
        textAlign="center"
        width={1}
        boxSizing="border-box"
        height="100%"
        padding={5}
        {...props}
      >
        <Typography variant="h5" mb={2}>
          {rendering_message}
        </Typography>

        <LinearProgress
          variant={
            typeof progress === "number" ? "determinate" : "indeterminate"
          }
          value={typeof progress === "number" ? progress : 0}
          sx={{ width: 1 }}
        />
      </Stack>
    );
  return (
    <>
      <RecordContextProvider value={file}>
        <StyledImagePreview
          onClick={handleEdit}
          loaded={(!loading).toString()}
          size={size}
          error={error.toString()}
          {...props}
        >
          <Box className={ImagePreviewClasses.imageContainer} ref={ref}>
            <Box className={ImagePreviewClasses.imageWrapper}>
              <LoaderOverlay show={loading} />
              <ImagePreviewButtons
                onDelete={handleDelete}
                onEdit={handleEdit}
                disabled={loading}
                className={ImagePreviewClasses.buttons}
              />
              {content_type.startsWith("image") && (
                <img
                  alt=""
                  className={ImagePreviewClasses.image}
                  src={url}
                  onLoad={() => {
                    setLoading(false);
                  }}
                  onError={() => {
                    setLoading(false);
                    setError(true);
                  }}
                />
              )}
              {content_type.startsWith("video") && (
                <video
                  className={ImagePreviewClasses.image}
                  onLoadStart={() => setLoading(false)}
                  height={"100%"}
                  width={"100%"}
                  controls
                  autoPlay
                  muted
                >
                  <source src={url} />
                </video>
              )}
              {content_type.startsWith("audio") && (
                <Box className={[ImagePreviewClasses.audio]}>
                  <AudioThumbnail src={url} onLoad={() => setLoading(false)} />
                </Box>
              )}
              {error && <ImageError onDelete={handleDelete} file={file} />}
            </Box>
          </Box>
          {show_caption && (
            <TextField
              source="caption"
              emptyText="No Caption"
              sx={{ display: "block" }}
            />
          )}
        </StyledImagePreview>
      </RecordContextProvider>
      <DrawerFormProvider value={{ open, openId: file.id, onClose }}>
        <ImageForm />
      </DrawerFormProvider>
    </>
  );
};

const PREFIX = "ItinImagePreview";
const ImagePreviewClasses = {
  imageContainer: `${PREFIX}-imageContainer`,
  imageWrapper: `${PREFIX}-imageWrapper`,
  image: `${PREFIX}-image`,
  buttons: `${PREFIX}-buttons`,
  audio: `${PREFIX}-audio`,
};

const StyledImagePreview = styled(Box, {
  name: PREFIX,
  slot: "Root",
})(({ theme, loaded, size, error, file }) => {
  const hasError = error === "true";
  const isLoaded = loaded === "true";
  const { height, width } = size;
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    [`&:hover .${ImagePreviewClasses.buttons}`]: {
      opacity: 1,
    },
    [`& .${ImagePreviewClasses.imageContainer}`]: {
      flexGrow: 1,
      display: "flex",
      overflow: "hidden",
      justifyContent: "center",
      alignItems: "center",
      width: 300,
      height: 300,
      borderRadius: 12,
    },
    [`& .${ImagePreviewClasses.imageWrapper}`]: {
      position: "relative",
      height:
        !isLoaded || hasError || file.content_type.startsWith("video")
          ? "100%"
          : undefined,
      width: !isLoaded || hasError ? "100%" : undefined,
    },
    [`& .${ImagePreviewClasses.image}`]: {
      display: hasError ? "none" : undefined,
      opacity: isLoaded ? 1 : 0,
      borderRadius: 12,
      width: "100%",
      maxHeight: height ? `${height}px` : "100%",
      maxWidth: width ? `${width}px` : "100%",
    },
    [`& .${ImagePreviewClasses.audio}`]: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
    },
    [`& .${ImagePreviewClasses.buttons}`]: {
      position: "absolute",
      right: 10,
      top: 10,
      opacity: 0,
      transition: theme.transitions.create(["opacity"], {
        duration: theme.transitions.duration.short,
      }),
    },
  };
});
