import { EditContextProvider, useEditController, useNotify } from "react-admin";
import { useQueryClient } from "react-query";
import { useDrawerForm } from "./DrawerForm";
import { DrawerFormHeader } from "./DrawerFormHeader";
export const EditContext = ({
  children,
  onUpdate,
  mutationOptions = {},
  closeOnSave = true,
  closeOnUpdate = true,
  showCloseButton,
  ...props
}) => {
  const { onClose } = useDrawerForm();
  const { onSuccess, ...newMutationOptions } = mutationOptions;
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { resource } = props;
  const controllerProps = useEditController({
    mutationOptions: {
      ...newMutationOptions,
      onSuccess: async (data) => {
        notify("ra.notification.updated", {
          type: "info",
          messageArgs: { smart_count: 1 },
        });
        if (onUpdate) await onUpdate(data);
        if (onSuccess) await onSuccess(data);
        queryClient.invalidateQueries([resource, "getManyReference"]);
        if (closeOnSave && closeOnUpdate) {
          onClose();
        }
      },
    },
    redirect: "",
    ...props,
  });

  return (
    <EditContextProvider value={controllerProps}>
      <DrawerFormHeader
        title={controllerProps.defaultTitle}
        showCloseButton={showCloseButton}
      />
      {children}
    </EditContextProvider>
  );
};
