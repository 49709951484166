import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import {
  Datagrid,
  ImageField,
  List,
  ReferenceField,
  ReferenceManyField,
  useGetList,
  useRecordContext,
} from "react-admin";
import { ItinLoading } from "~/components";
import { useCurrentPlatform } from "~/context";
import { black } from "~/theme/colors";
const Empty = () => {
  const { getTerm } = useCurrentPlatform();
  const eventsTerm = getTerm("event", { plural: true, capitalize: false });

  return (
    <Typography sx={{ padding: 2, marginLeft: "-40px" }}>
      {`No associated ${eventsTerm}.`}
    </Typography>
  );
};

export const RegionEvents = (props) => {
  const record = props.record;
  const { getTerm } = useCurrentPlatform();
  const eventTerm = getTerm("event", { plural: false, capitalize: false });
  const { data, isLoading } = useGetList("events", {
    filter: { target_id: record.id },
  });

  if (isLoading) {
    return <ItinLoading />;
  }
  const hasEvents = data.length > 0;
  return (
    <Box maxWidth="100%">
      {!hasEvents && <Empty />}
      {hasEvents && (
        <List
          data={data}
          exporter={false}
          actions={false}
          sx={{
            "& .MuiTablePagination-root": { display: "none" },
          }}
        >
          <Datagrid
            buttonLabel={eventTerm}
            data={data}
            bulkActionButtons={false}
            sx={{
              border: "none",
              marginTop: "28px",
              "& .RaDatagrid-thead ": {
                display: "none",
              },
              "& .RaDatagrid-headerRow": { display: "none" },
            }}
          >
            <ImageField
              source="hero.url"
              label=""
              sx={{
                "& img": {
                  maxWidth: 25,
                  maxHeight: 25,
                  objectFit: "contain",
                  borderRadius: 10,
                },
              }}
            />
            <ReferenceField
              source="id"
              reference="events"
              sx={{ "& .RaReferenceField-link": { backgroundColor: black[0] } }}
            />
            <ReferenceManyField
              label="Times"
              reference="event_times"
              target="event_id"
            >
              <EventTimes />
            </ReferenceManyField>
          </Datagrid>
        </List>
      )}
    </Box>
  );
};
const EventTimes = () => {
  const record = useRecordContext();
  const start = dayjs(record.start);
  const end = dayjs(record.end);
  if (!record.start || !record.end) {
    return <>No Time Set</>;
  }
  const singleDay = record.start.slice(0, 10) === record.end.slice(0, 10);

  const date_range = singleDay
    ? [start.format("MMM D")]
    : [start.format("MMM D"), end.format("MMM D")];
  const end_year = end.format("YYYY");
  const time_range = [start.format("H:mm a"), end.format("H:mm a")];

  return (
    <>
      <Typography display="flex" alignItems="center" color="grey.600">
        {/* <Calendar size={12} style={{ marginRight: 2 }} weight="fill" /> */}
        {`${date_range.join(" - ")} ${end_year}`}
      </Typography>

      {/* <Typography display="flex" alignItems="center" color="grey.600"> */}

      {/* {time_range.join(" - ")} */}
      {/* </Typography> */}
    </>
  );
};
