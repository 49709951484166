import { Grid } from "@mui/material";
import { chain, isFunction, isNil, startCase } from "lodash";
import { createContext, useContext, useEffect, useState } from "react";
import {
  AutocompleteArrayInput,
  BooleanInput,
  DateInput,
  DateTimeInput,
  Labeled,
  Loading,
  NumberInput,
  SelectInput,
  TextInput,
  regex,
  required,
  useResourceContext,
} from "react-admin";
import { JsonInput } from "react-admin-json-view";
import { useFormContext } from "react-hook-form";
import { useCustomQuery } from "~/hooks";
import { ColorInput } from "./ColorInput";

const PREFIX = "ItinOptionsInputs";

const OptionsSchemaContext = createContext({});
export const useOptionsSchema = () => useContext(OptionsSchemaContext);
export const OptionsInputs = ({
  source,
  type_source,
  fieldProps,
  gridItemProps,
  record,
  filter = () => true,
  label = () => null,
  schemaRef,
  ...props
}) => {
  const { watch } = useFormContext();
  const type = type_source ? watch(type_source) : null;
  const resource = useResourceContext();
  const field = source.split(".").pop();
  const { data = {}, isLoading } = useCustomQuery(
    `${resource}/schema_by_field`,
    { field, type, record_id: record?.id },
    {
      key: [resource, `schema_by_field`, source, type],
      options: { enabled: !!source && (!type_source || !!type) },
    }
  );
  useEffect(() => {
    if (schemaRef) schemaRef.current = data;
  }, [data]);
  if (isLoading) return <Loading />;
  return (
    <OptionsSchemaContext.Provider value={data}>
      <Grid className={`${PREFIX}-root`} container columnSpacing={2} {...props}>
        {Object.entries(data)
          .filter((field) => filter(field, source))
          .map(([field, config]) => {
            return (
              <FieldSection
                key={field}
                source={source}
                field={field}
                config={config}
                gridItemProps={gridItemProps}
                fieldProps={fieldProps}
                filter={filter}
                label={label}
              />
            );
          })}
      </Grid>
    </OptionsSchemaContext.Provider>
  );
};

const FieldSection = ({
  source,
  field,
  config,
  gridItemProps,
  fieldProps,
  filter,
  label: getLabel = () => null,
}) => {
  const nested_source = `${source}.${field}`;
  const grid_item_props = isFunction(gridItemProps)
    ? gridItemProps(config, nested_source)
    : gridItemProps;
  const customLabel = getLabel(field);
  const label = isNil(customLabel) ? startCase(field) : customLabel;

  return (
    <Grid
      item
      xs={["array", "json", "object"].includes(config.type) ? 12 : 6}
      key={field}
      {...grid_item_props}
      className={`${PREFIX}-grid-item`}
    >
      {config.type !== "object" ? (
        <Field source={source} field={field} config={config} {...fieldProps} />
      ) : (
        <Labeled label={label} fullWidth isRequired={config.required}>
          <Grid container item xs={12} columnSpacing={2} sx={{ px: 3 }}>
            {Object.entries(config.properties)
              .filter((properties) => filter(properties, nested_source))
              .map(([nested_field, nested_config]) => {
                return (
                  <FieldSection
                    key={`${nested_source}.${nested_field}`}
                    source={nested_source}
                    field={nested_field}
                    config={nested_config}
                    gridItemProps={gridItemProps}
                    fieldProps={fieldProps}
                    filter={filter}
                  />
                );
              })}
          </Grid>
        </Labeled>
      )}
    </Grid>
  );
};

const Field = ({
  source: root_source,
  field,
  config,
  CustomField,
  ...props
}) => {
  const { getValues } = useFormContext();
  const source = `${root_source}.${field}`;
  const currentValue = getValues(source);
  const [choices, setChoices] = useState(
    chain(config.choices)
      .concat(currentValue)
      .compact()
      .uniq()
      .map((c) => ({ id: c, name: c }))
      .value()
  );

  const validations = [
    config.required && required(),
    config.pattern && regex(new RegExp(config.pattern), config.message),
  ].filter(Boolean);
  const base_props = {
    source,
    label: startCase(field),
    helperText: config.description,
    fullWidth: true,
    validate: validations,
    defaultValue: config.default,
    className: `${PREFIX}-input ${PREFIX}-${config.type}`,
    ...props,
  };

  let component;
  switch (config.type) {
    case "color":
      component = <ColorInput variant="outlined" {...base_props} />;
      break;
    case "number":
      component = <NumberInput {...base_props} />;
      break;
    case "string":
      if (config.choices?.length) {
        component = <SelectInput {...base_props} choices={choices} />;
      } else {
        component = <TextInput {...base_props} />;
      }
      break;
    case "date_time":
      component = <DateTimeInput {...base_props} InputLabelProps={null} />;
      break;
    case "date":
      component = <DateInput {...base_props} InputLabelProps={null} />;
      break;
    case "boolean":
      component = <BooleanInput {...base_props} />;
      break;
    case "array":
      component = (
        <AutocompleteArrayInput
          {...base_props}
          choices={choices}
          onCreate={
            config.creatable
              ? (val) => {
                  const value = { id: val, name: val };
                  setChoices((c) => [...c, value]);
                  return value;
                }
              : undefined
          }
        />
      );
      break;

    case "json":
      component = (
        <JsonInput
          {...base_props}
          jsonString
          reactJsonOptions={{
            enableClipboard: false,
            displayDataTypes: false,
            theme: "summerfruit:inverted",
          }}
        />
      );
      break;
    default:
      component = <TextInput {...base_props} />;
      break;
  }
  if (CustomField) {
    return (
      <CustomField
        source={source}
        root_source={root_source}
        field={field}
        config={config}
      >
        {component}
      </CustomField>
    );
  }
  return component;
};
