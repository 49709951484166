import { Datagrid, TextField, useCreatePath } from "react-admin";
import { ToggleList } from "~/components/lists";
import { ImageCard } from "~/components/lists/ImageCard";
import { areaCardData } from "./areaCardData";
import { SimpleMapList } from "~/components";

export const AreaList = () => {
  return <ToggleList modes={MODES} resource="areas" />;
};

const ListData = () => {
  const createPath = useCreatePath();

  return (
    <Datagrid
      rowClick={(id) => createPath({ resource: "areas", id, type: "edit" })}
      bulkActionButtons={false}
    >
      <TextField source="name" />
      <TextField source="subtitle" />
    </Datagrid>
  );
};

const MODES = [
  {
    name: "Grid",
    view: <ImageCard cardData={areaCardData} resource="areas" />,
  },
  {
    name: "Map",
    view: (
      <SimpleMapList
        navigateOnClick
        cardData={areaCardData}
        showPins={false}
        showPolygons={true}
      />
    ),
  },
  { name: "List", view: <ListData />, resource: "areas" },
];
