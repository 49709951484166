import { CustomColorChipField } from "./CustomColorChipField";

const COLOR_OPTIONS = {
  draft: "primary",
  active: "success",
  hidden: "error",
  complete: "default",
  fallback: "primary",
};
export const EnumField = (props) => {
  return (
    <CustomColorChipField
      color_options={COLOR_OPTIONS}
      variant={"outlined"}
      {...props}
    />
  );
};
