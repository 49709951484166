import { IconButton, styled, iconButtonClasses } from "@mui/material";

export const SolidIconButton = styled(IconButton, {
  name: "MuiSolidIconButton",
  slot: "Root",
})(({ theme, color = "primary" }) => {
  const colorPalette =
    color !== "inherit" && color !== "default"
      ? theme.palette[color]
      : theme.palette.primary;
  return {
    transition: theme.transitions.create(
      ["background-color", "box-shadow", "color"],
      {
        duration: theme.transitions.duration.short,
      }
    ),
    backgroundColor: colorPalette.main,
    color: colorPalette.contrastText,
    boxShadow: theme.shadows[6],
    "&:hover": {
      boxShadow: theme.shadows[8],
      backgroundColor: colorPalette.dark,
    },
    [`&.${iconButtonClasses.disabled}`]: {
      backgroundColor: theme.palette.action.disabledBackground,
      boxShadow: theme.shadows[0],
    },
  };
});
