import { RecordContextProvider, useListContext } from "react-admin";
import { ImageCard } from "./ImageCard";
import { Box } from "@mui/material";
import { ItinLoading } from "~/components/ItinLoading";

export const ImageGrid = ({ cardData, children, ...props }) => {
  const { data, isLoading } = useListContext();
  if (isLoading || !data) return <ItinLoading />;
  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(12,1fr)"
      gap={2}
      padding={"20px"}
      {...props}
    >
      {data.map((item) => (
        <RecordContextProvider key={item.id} value={item}>
          {children || <ImageCard cardData={cardData} />}
        </RecordContextProvider>
      ))}
    </Box>
  );
};
