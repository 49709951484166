import { Delete, Edit } from "@mui/icons-material";
import { Card, ClickAwayListener, MenuItem, MenuList } from "@mui/material";
import {
  OverlayView,
  OverlayViewF,
  PolygonF,
  useGoogleMap,
} from "@react-google-maps/api";
import { wktToGeoJSON } from "@terraformer/wkt";
import { useCallback, useRef, useState } from "react";
import {
  useDataProvider,
  useInput,
  useSimpleFormIteratorItem,
  ValidationError,
} from "react-admin";
import { useDebounceCallback } from "usehooks-ts";
import { googlePolygonToWkt } from "./helpers";
import { useMapInput } from "./MapInput";

type PolygonInputProps = {
  source: string;
  editable?: boolean;
  deletable?: boolean;
  onEditClick: (polygon?: google.maps.Polygon) => void;
};

export const PolygonInput = ({
  onEditClick,
  editable,
  deletable,
  ...props
}: PolygonInputProps) => {
  const map = useGoogleMap();
  const { mapBounds } = useMapInput();
  const dataProvider = useDataProvider();
  const { remove } = useSimpleFormIteratorItem();
  const validateBounds = useCallback(async (value: any) => {
    const { data } = await dataProvider.getCustom("polygons/validate_bounds", {
      bounds: value,
    });
    if (!data.valid) return "Boundary is invalid";
    return undefined;
  }, []);

  const ref = useRef<google.maps.Polygon>();
  const [clickEvent, setClickEvent] =
    useState<google.maps.MapMouseEvent | null>(null);

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useInput({
    ...props,
    validate: validateBounds,
  });

  const onEdit = useCallback((polygon?: google.maps.Polygon) => {
    if (!polygon) return;

    const wkt = googlePolygonToWkt(polygon);
    onChange(wkt);
  }, []);
  const onDebounceEdit = useDebounceCallback(onEdit, 400);

  const poly = wktToGeoJSON(value);
  const [paths] = useState(
    () =>
      poly.type === "Polygon" &&
      poly.coordinates.map((p) =>
        p.map((coords) => {
          return { lat: coords[1]!, lng: coords[0]! };
        })
      )
  );

  if (!paths) return null;

  const getCenter = () => {
    const gbounds = new google.maps.LatLngBounds();
    ref.current
      ?.getPath()
      .getArray()
      .forEach((path) => {
        gbounds.extend(path);
      });
    return gbounds.getCenter();
  };
  return (
    <>
      <PolygonF
        onLoad={(polygon) => {
          ref.current = polygon;
          polygon.getPath().forEach((latLng) => mapBounds.extend(latLng));
          map?.fitBounds(mapBounds);
        }}
        options={{
          fillColor: error?.message ? "red" : editable ? "blue" : "#283593",
          strokeColor: error?.message ? "red" : "black",
        }}
        paths={paths}
        draggable={editable}
        onClick={(e) => setClickEvent(e)}
        onEdit={onDebounceEdit}
        editable={editable}
      />
      {error?.message && (
        <OverlayViewF
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          position={getCenter()}
          getPixelPositionOffset={(width, height) => ({
            x: -(width / 2),
            y: -(height / 2),
          })}
        >
          <Card variant="outlined">
            <ValidationError error={error.message} />
          </Card>
        </OverlayViewF>
      )}

      {clickEvent && (
        <ClickAwayListener onClickAway={() => setClickEvent(null)}>
          <OverlayView
            mapPaneName="overlayMouseTarget"
            position={clickEvent.latLng || getCenter()}
          >
            <Card variant="outlined" sx={{ width: "fit-content", padding: 0 }}>
              <MenuList>
                <MenuItem
                  sx={{ color: "primary.main" }}
                  onClick={() => onEditClick?.(ref.current)}
                >
                  <Edit sx={{ mr: 1 }} />
                  {`${editable ? "Finish " : ""}Edit`}
                </MenuItem>
                {deletable && (
                  <MenuItem
                    sx={{ color: "error.main" }}
                    onClick={() => remove()}
                  >
                    <Delete sx={{ mr: 1 }} />
                    Remove
                  </MenuItem>
                )}
              </MenuList>
            </Card>
          </OverlayView>
        </ClickAwayListener>
      )}
    </>
  );
};
