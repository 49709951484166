import { ReactNode } from "react";
import { useFormContext } from "react-hook-form";
type Props = {
  children: (value: any) => ReactNode;
  watch: string[];
};
export const WithWatch = ({ children, watch: watch_name }: Props) => {
  const { watch, getValues } = useFormContext();
  if (!watch_name) {
    return children([]);
  }
  watch(watch_name);
  const current_val = getValues(watch_name);
  return children(current_val);
};
