import {
  useBlockNoteEditor,
  useComponentsContext,
  useDictionary,
  useSelectedBlocks,
} from "@blocknote/react";
import { useEffect, useState } from "react";
import { block_note_schema } from "../BlockInput";
import { titleize } from "~/helpers";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";

export const SocialsToolbarButtons = () => {
  const dict = useDictionary();
  const Components = useComponentsContext()!;

  const editor = useBlockNoteEditor(block_note_schema);

  const selectedBlocks = useSelectedBlocks(editor);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(false);
  }, [selectedBlocks]);

  const block = selectedBlocks.length === 1 ? selectedBlocks[0] : undefined;
  if (block === undefined || block?.type !== "socials" || !editor.isEditable) {
    return null;
  }
  const { showTitle } = block.props;
  const items = ["small" as const, "medium" as const, "large" as const].map(
    (i) => ({
      text: titleize(i),
      isSelected: block.props.size === i,
      onClick: () => {
        editor.updateBlock(block, { props: { size: i } });
      },
      icon: null,
    })
  );
  const label = `${showTitle ? "Hide" : "Show"} Title`;
  return (
    <>
      <Components.FormattingToolbar.Select
        className={"bn-select"}
        items={items}
      />

      <Components.FormattingToolbar.Button
        icon={showTitle ? <RiEyeLine /> : <RiEyeOffLine />}
        label={label}
        mainTooltip={label}
        isSelected={block.props.showTitle}
        onClick={() =>
          editor.updateBlock(block, {
            props: { showTitle: !block.props.showTitle },
          })
        }
      />
    </>
  );
};
