import { Grid, Typography } from "@mui/material";
import { Plus } from "@phosphor-icons/react";
import {
  Button,
  CreateButton,
  Link,
  RecordContextProvider,
  ResourceContextProvider,
  useGetList,
} from "react-admin";
import { Empty, ItinLoading } from "~/components";
import { CreateButtonWithDefaults } from "~/components/buttons/CreateButtonWithDefaults";
import { ImageCard } from "~/components/lists/ImageCard";
import { useCurrentPlatform } from "~/context";
import { listingCardData } from "~/resources/listings/ListingList";

export const RegionListings = (props) => {
  const record = props.record;
  const { getTerm } = useCurrentPlatform();

  const { data, isLoading, total } = useGetList("listings", {
    filter: { region_id: record.id },
    pagination: { page: 1, perPage: 10 },
    sort: { field: "updated_at", order: "DESC" },
  });
  if (isLoading) {
    return <ItinLoading />;
  }
  const hasListings = data.length > 0;

  return hasListings ? (
    <>
      <Typography variant="h5">
        {total > 10 && `10 recent of `}
        {`${total} ${getTerm("listing", {
          plural: true,
          capitalize: true,
        })}`}
        {total > 0 && (
          <CreateButtonWithDefaults
            defaultValues={{ region_id: record.id }}
            resource="listings"
            sx={{ marginLeft: "10px" }}
          />
        )}
      </Typography>
      <Grid container>
        {data.map((listing) => {
          return (
            <RecordContextProvider value={listing} key={listing.id}>
              <ResourceContextProvider value="listings">
                <ImageCard
                  cardData={listingCardData}
                  gridColumn="span 3"
                  padding={1}
                />
              </ResourceContextProvider>
            </RecordContextProvider>
          );
        })}
      </Grid>
    </>
  ) : (
    <Empty
      resource="listings"
      title={`Who's in ${record.name}?`}
      subtitle="Let's create our first listing in this region..."
      buttonLabel="Create Listing"
      showImage={false}
      defaultValues={{ region_id: record.id }}
    />
  );
};
