import { Box, Typography } from "@mui/material";
import { MarkdownInput } from "@react-admin/ra-markdown";
import { required } from "react-admin";

const ItinMarkdownInput = ({
  title,
  titleVariant = "h5",
  description,
  textVariant = "body",
  isRequired = true,
  validate,
  ...props
}) => {
  return (
    <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
      <Typography variant={titleVariant} sx={{ mb: 2 }}>
        {title + (isRequired ? "*" : "")}
      </Typography>
      {description && (
        <Typography variant={textVariant} sx={{ mb: 2 }}>
          {description}
        </Typography>
      )}
      <MarkdownInput
        label={false}
        width="100%"
        validate={[isRequired && required(), ...[].concat(validate)].filter(
          Boolean
        )}
        {...props}
      />
    </Box>
  );
};

export default ItinMarkdownInput;
