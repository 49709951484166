import { get, startCase } from "lodash";
import pluralize from "pluralize";

export type Terms = {
  available: string;
  badge: string;
  challenge: string;
  coin: string;
  earn: string;
  event: string;
  form: string;
  form_entry: string;
  itinerary: string;
  leg: string;
  listing: string;
  platform_level: string;
  point: string;
  redeem: string;
  reel: string;
  region: string;
  reward: string;
  stop: string;
  tag: string;
  user: string;
  website: string;
};

// class Term extends String {
//   get plural() {
//     return pluralizeLib(this.value);
//   }
//   get singular() {
//     return pluralizeLib.singular(this.value);
//   }
//   singularize() {
//     return new Term(pluralizeLib.singular(this.value));
//   }
//   pluralize(count?: number, inclusive?: boolean) {
//     return new Term(pluralizeLib(this.value, count, inclusive));
//   }
//   capitalize() {
//     return new Term(startCase(this.value));
//   }
//   get value() {
//     return this.toString();
//   }
// }

type GetTermOptions = {
  plural?: boolean | number;
  capitalize?: boolean;
  inclusive?: boolean;
};

export const getTermFunction =
  (terms: Terms) =>
  (
    resource: string,
    { plural, capitalize, inclusive }: GetTermOptions = {}
  ) => {
    const name = pluralize.singular(resource).toLowerCase();
    let term = get(terms, name, name);
    if (typeof plural === "boolean") {
      term = pluralize(term, plural ? 2 : 1);
    } else if (typeof plural === "number") {
      term = pluralize(term, plural, inclusive);
    }
    return capitalize ? startCase(term) : term;
  };
