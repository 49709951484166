import { Card, Grid, Typography } from "@mui/material";
import { SparkLineChart } from "@mui/x-charts";
import { ArrowDown, ArrowUp, ChartLine } from "@phosphor-icons/react";
import { get, map } from "lodash";
import { abbreviateNumber } from "~/helpers/abbreviateNumber";
import { showDate } from "~/helpers/showDate";
import { StatSummaryCard } from "~/resources/types";
import { DateLineChart } from "./DateLineChart";
import { DEFAULT_STATS_ICONS } from "../stats_constants";
import { HeatMapCard } from "./HeatMapCard";
import { ToggleTablePie } from "./ToggleTablePie";

export const SummaryCard = ({ card }: { card: StatSummaryCard }) => {
  const {
    name,
    value,
    sparkline,
    table,
    linechart,
    xs,
    comparison_data,
    comparison_value,
    map_points,
    description,
    stat_type,
  } = card;
  const percentage_of_change = comparison_value
    ? Math.round((value / comparison_value - 1) * 100)
    : null;
  const Icon = get(DEFAULT_STATS_ICONS, stat_type, ChartLine);
  // This view attempts to intelligently render a chart card right from a basic number count, all the way to a full featured chart
  return (
    <Grid item xs={xs || "auto"}>
      {/* @ts-expect-error */}
      <Grid container as={Card} variant={"outlined"}>
        <Grid item xs={12}>
          <Typography variant={linechart || table ? "h4" : "h3"}>
            <Icon
              size={28}
              style={{ verticalAlign: "middle", marginTop: "-2px" }}
            />
            {value && abbreviateNumber(value)} {(linechart || table) && name}
          </Typography>
          <Typography>{!(linechart || table) && name}</Typography>
          {description && (
            <Typography
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: 4,
                maxHeight: `calc(1.2em * 4)`,
                lineHeight: "1.2em",
              }}
            >
              {description}
            </Typography>
          )}

          {percentage_of_change && comparison_value && (
            <Typography
              color={percentage_of_change > 0 ? "#00aa00" : "#aa0000"}
            >
              {percentage_of_change > 0 ? <ArrowUp /> : <ArrowDown />}
              {percentage_of_change}% from {abbreviateNumber(comparison_value)}
            </Typography>
          )}
          {linechart && (
            <DateLineChart data={linechart} comparisonData={comparison_data} />
          )}

          {sparkline && (
            <SparkLineChart
              area
              data={map(sparkline, "count")}
              colors={["#6d88f4"]}
              curve="natural"
              // @ts-expect-error valueFormatter has incorrect typing for the function args
              valueFormatter={(val, { dataIndex }) => {
                const date_string = sparkline[dataIndex]?.date;
                const date = date_string && showDate(date_string);
                return [date, val].filter(Boolean).join(" - ");
              }}
              height={100}
              showTooltip
              showHighlight
              sx={{ position: "absolute", left: 0, right: 0 }}
            />
          )}

          {map_points && <HeatMapCard map_points={map_points} />}

          {table?.length && <ToggleTablePie data={table} name={name} />}
        </Grid>
      </Grid>
    </Grid>
  );
};
