import { ButtonColumn } from "./ButtonColumn";
import { useLogin } from "react-admin";
import { Email } from "@mui/icons-material";
import { Google } from "~/components/icons";
import { LoginButton } from "./LoginButton";
import { useNotifySignInError } from "./useNotifySignInError";
import { useLoginContext } from "./Login";

export const LoginSelect = () => {
  const { sign_up, setEmailAuth } = useLoginContext();
  const login = useLogin();
  const notify = useNotifySignInError();
  const handleSignin = (provider) => {
    login({ provider, sign_up }).catch((error) => {
      notify(error);
    });
  };

  return (
    <ButtonColumn>
      <LoginButton
        onClick={() => handleSignin("google")}
        startIcon={<Google />}
      >
        {sign_up ? "Sign up" : "Log in"} With Google
      </LoginButton>
      <LoginButton onClick={() => setEmailAuth(true)} startIcon={<Email />}>
        {sign_up ? "Sign up" : "Log in"} With Email
      </LoginButton>
    </ButtonColumn>
  );
};
