import { useCanAccess } from "@react-admin/ra-rbac";
import {
  useCreatePath,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { useNavigate } from "react-router-dom";

export const useNavigateToRecord = ({
  type = false,
  record: providedRecord,
  resource: providedResource,
  path: providedPath,
} = {}) => {
  // Fall back to context if record and resource are not provided
  const resource = providedResource || useResourceContext();
  const record = providedRecord || useRecordContext();

  const createPath = useCreatePath();
  const { canAccess: canEditResource } = useCanAccess({
    resource: resource,
    record: record,
    action: "edit",
  });

  const { canAccess: canShowResource } = useCanAccess({
    resource: resource,
    record: record,
    action: "show",
  });

  // Default action to 'edit' if user has edit permissions, otherwise 'show'
  const default_action =
    type || (canEditResource ? "edit" : canShowResource ? "show" : "list");

  const path = createPath({ resource, id: record.id, type: default_action });
  const navigate = useNavigate();

  return () => {
    navigate(providedPath || path);
  };
};
