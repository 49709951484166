import { geojsonToWKT } from "@terraformer/wkt";

export const googlePolygonToWkt = (polygon: google.maps.Polygon): string => {
  return geojsonToWKT({
    type: "Polygon",
    coordinates: polygon
      .getPaths()
      .getArray()
      .map((path) =>
        path.getArray().map((coords) => [coords.lng(), coords.lat()])
      ),
  });
};
