import { Marker, useGoogleMap } from "@react-google-maps/api";
import { get } from "lodash";
import { useFormContext } from "react-hook-form";
import { useMapInput } from "~/components";
import { useFallbackPoint } from "~/resources/points/useFallbackPoint";

export const NewMarker = () => {
  const { setValue, watch } = useFormContext();
  const [record_lat, record_lng] = watch([
    "point_attributes.latitude",
    "point_attributes.longitude",
  ]);
  const all = watch();
  const { data: fallbackPoint } = useFallbackPoint({
    fallback_reference: "areas",
  });
  const fallback_lat = get(fallbackPoint, "latitude");
  const fallback_lng = get(fallbackPoint, "longitude");
  const lat = record_lat || fallback_lat;
  const lng = record_lng || fallback_lng;
  const { mapBounds } = useMapInput();
  const map = useGoogleMap();

  if (!lat || !lng) return null;
  return (
    <Marker
      draggable
      onDragEnd={(event) => {
        const position = event.latLng;
        setValue(
          "point_attributes",
          { latitude: position?.lat(), longitude: position?.lng() },
          { shouldDirty: true, shouldTouch: true }
        );
      }}
      position={{ lat, lng }}
      onLoad={(marker) => {
        const position = marker.getPosition();
        if (!position) return;
        mapBounds.extend(position);
        map?.fitBounds(mapBounds);
      }}
    />
  );
};
