import { Box, Tooltip } from "@mui/material";
import { useTheme } from "@mui/styles";
import { capitalize, get, lowerCase } from "lodash";
import pluralize from "pluralize";
import { useRecordContext } from "react-admin";

export const SummaryIcon = ({
  Icon,
  source,
  name: defaultName,
  value: defaultValue,
  noInfo,
  info,
  showValue = true,
  ...props
}) => {
  const { palette } = useTheme();
  const record = useRecordContext(props);
  let value = defaultValue || get(record, source);
  if (Array.isArray(value)) {
    value = value.length;
  }
  const name = defaultName || lowerCase(source);

  let infoText = info;
  if (!infoText) {
    if (isFinite(value)) {
      infoText = `${pluralize(name, value, true)} set.`;
    } else if (!showValue) {
      infoText = `${capitalize(name)} set.`;
    } else {
      infoText = value;
    }
  }

  const noInfoText = noInfo || `No ${name} set.`;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Tooltip title={value ? infoText : noInfoText}>
        <Icon
          color={value ? palette.green[700] : palette.grey[400]}
          size={18}
        />
      </Tooltip>
    </Box>
  );
};
