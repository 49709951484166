import { Grid, Typography } from "@mui/material";
import { MarkdownInput } from "@react-admin/ra-markdown";
import {
  DateInput,
  Labeled,
  NumberInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import { SingleImageInput } from "~/components";
import { EnumChoiceInput } from "~/components/inputs";
import { useCurrentPlatform } from "~/context";

export const RewardForm = ({ challenge, ...props }) => {
  const { currentPlatform, platformLoading, getTerm } = useCurrentPlatform();
  if (platformLoading) return null;
  const rewardCapTerm = getTerm("reward", {
    plural: false,
    capitalize: true,
  });
  const rewardTerm = getTerm("reward", {
    plural: false,
    capitalize: false,
  });
  return (
    <SimpleForm
      label={rewardCapTerm}
      defaultValues={{
        challenge_id: challenge.id,
        platform_id: currentPlatform.id,
      }}
      {...props}
    >
      <Grid container width="50vw" columnSpacing={3}>
        <Grid item sm={6}>
          <TextInput
            source="title"
            label="Name"
            validate={required()}
            fullWidth
          />
        </Grid>
        <Grid item sm={6}>
          <NumberInput
            source="completion_count"
            min={0}
            validate={required()}
            fullWidth
          />
        </Grid>
        <Grid item sm={6}>
          <EnumChoiceInput source="type" isRequired fullWidth />
        </Grid>
        <Grid item sm={6}>
          <TextInput label="URL" source="url" fullWidth />
        </Grid>
        <Grid item sm={6}>
          <NumberInput
            source="weight"
            defaultValue={300}
            min={0}
            label="Completion Points"
            validate={required()}
            fullWidth
          />
        </Grid>
        <Grid item sm={12}>
          <Labeled sx={{ mb: 4, width: "100%" }}>
            <SingleImageInput source="image" />
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" pb="16px">
            Description
          </Typography>
          <Typography variant="body2" pb="16px">
            {`Give a short description of this ${rewardTerm}.`}
          </Typography>
          <MarkdownInput
            source="description"
            validate={required()}
            height="150px"
            label="Description"
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
