import { Grid } from "@mui/material";
import { ListBase, useRecordContext } from "react-admin";
import { SimpleListView } from "~/components/lists";
import { ImageGrid } from "~/components/lists/ImageGrid";
import { useCurrentPlatform } from "~/context";
import { TagSidebar } from "~/resources/taggings";
import { regionCardData } from "../RegionCardData";
import { RegionChallenges } from "./RegionChallenges";
import { RegionEvents } from "./RegionEvents";
import { RegionItineraries } from "./RegionItineraries";
import { RegionListings } from "./RegionListings";
import { GridCard } from "~/components/GridCard";
import HasPlatformExtension from "~/components/HasPlatformExtension";
import { RegionStats } from "../RegionStats";

export const RegionOverview = () => {
  const record = useRecordContext();
  const boxStyling = {
    backgroundColor: "primary.contrastText",
    borderRadius: "12px",
    cursor: "pointer",
    border: "1px solid",
    borderColor: `grey.200`,
    padding: "16px",
    display: "flex",
    flexDirection: "column",
  };
  const { getTerm } = useCurrentPlatform();
  return (
    <Grid container spacing={1}>
      <Grid item xs={9}>
        <RegionStats />

        {/* <ListBase resource="regions" filter={{ parent_region_id: record.id }}>
          <SimpleListView title="sub-regions" empty={<></>}>
            <ImageGrid
              cardData={regionCardData}
              gridTemplateColumns="repeat(6,1fr)"
              padding={0}
            />
          </SimpleListView>
        </ListBase> */}
        <RegionItineraries record={record} />

        <RegionListings record={record} />
      </Grid>
      <Grid item xs={3}>
        <HasPlatformExtension platform_extension="Contesting">
          <GridCard
            title={`${getTerm("challenge", {
              plural: true,
              capitalize: true,
            })}
            `}
          >
            <RegionChallenges />
          </GridCard>
        </HasPlatformExtension>
        <HasPlatformExtension platform_extension="Events">
          <GridCard
            title={`${getTerm("event", {
              plural: true,
              capitalize: true,
            })}`}
          >
            <RegionEvents record={record} />
          </GridCard>
        </HasPlatformExtension>

        <GridCard
          title={`${getTerm("tag", {
            plural: true,
            capitalize: true,
          })}`}
        >
          <TagSidebar />
        </GridCard>
      </Grid>
    </Grid>
  );
};
