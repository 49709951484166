import { useAdminUsers } from "~/hooks";
import { AutocompleteInput } from "react-admin";

export const AdminUserInput = (props) => {
  const { data, isLoading } = useAdminUsers();

  return (
    <AutocompleteInput
      label="Owner"
      source="owner_id"
      optionValue="id"
      optionText="name"
      choices={data}
      loading={isLoading}
      {...props}
    />
  );
};
