import { Accordion } from "~/components";
import { UserCheckIns } from "./UserCheckIns";
import { UserRewards } from "./UserRewards";
import { useState } from "react";
import { useCurrentPlatform } from "~/context";

export const UserInteractionsUserProfile = () => {
  const [currentId, setCurrentId] = useState();
  const { getTerm } = useCurrentPlatform();
  const rewardTerm = getTerm("reward", { plural: true, capitalize: true });
  return (
    <>
      <Accordion
        id={"user1"}
        current_id={currentId}
        onChange={setCurrentId}
        title="Check Ins"
      >
        <UserCheckIns />
      </Accordion>
      <Accordion
        id={"user2"}
        current_id={currentId}
        onChange={setCurrentId}
        title={rewardTerm}
      >
        <UserRewards />
      </Accordion>
    </>
  );
};
