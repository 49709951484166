import { useCallback } from "react";
import { useRecordContext, useRefresh, useResourceContext } from "react-admin";
import { UseMutationOptions, useQueryClient } from "react-query";
import {
  CustomMutationVariables,
  useCustomMutation,
} from "./useCustomMutation";
import { useMagicNotify } from "./useMagicNotify";

type RecordAndResource = {
  record?: any;
  resource?: string;
};

type UseDoMagicOptions<T extends RecordAndResource = RecordAndResource> = Omit<
  UseMutationOptions<any, Error, CustomMutationVariables>,
  "mutationFn"
> &
  T;

export const useDoMagic = (options: UseDoMagicOptions = {}) => {
  const {
    record: custom_record,
    resource: custom_resource,
    ...mutationOptions
  } = options;
  const resource = useResourceContext(options);
  const record = useRecordContext(options);
  const notify = useMagicNotify();
  const queryClient = useQueryClient();
  const refresh = useRefresh();
  const { mutate, ...rest } = useCustomMutation();

  const doMagic = useCallback(
    (action: "ai" | "" = "") =>
      mutate(
        {
          resource,
          id: record.id,
          custom_url: `do_magic/${action}`,
        },
        {
          ...mutationOptions,
          onSuccess: (res, variables, context) => {
            mutationOptions.onSuccess?.(res, variables, context);
            const { data } = res;
            // the invalidate queries doesn't work, so going nuclear for now;
            refresh();
            // queryClient.invalidateQueries([magicResource,"useGetOne", magicRecord.id]); // Invalidate the cache
            if (data?.notice) notify(data.notice, { type: data.type });
          },
        }
      ),
    [mutate, record, resource, mutationOptions, notify, refresh]
  );
  return [doMagic, rest];
};
