import { useNotify } from "react-admin";

export const useNotifySignInError = () => {
  const raNotify = useNotify();
  return (error) => {
    raNotify(
      typeof error === "string"
        ? error
        : typeof error === "undefined" || !error.message
        ? "ra.auth.sign_in_error"
        : error.message,
      {
        type: "warning",
        messageArgs: {
          _:
            typeof error === "string"
              ? error
              : error && error.message
              ? error.message
              : undefined,
        },
      }
    );
  };
};
