import { Card, Typography, useTheme } from "@mui/material";
import { Plus } from "@phosphor-icons/react";
import { useMemo, useState } from "react";
import {
  InputProps,
  Labeled,
  LinearProgress,
  useInput,
  useReference,
  useTranslateLabel,
} from "react-admin";
import { MediaCard } from "~/components/media/MediaCard";
import { MediaDialog } from "~/components/media/MediaDialog";
import {
  useFallbackResourceValue,
  UseFallbackResourceValueProps,
} from "~/hooks";
import { Media } from "~/resources/media/types";

type MediaInputProps = InputProps & {
  label?: string;
  fallbackProps?: Partial<UseFallbackResourceValueProps>;
};
export const SingleMediaInput = ({
  label: customLabel,
  fallbackProps,
  ...props
}: MediaInputProps) => {
  const {
    field: { value, onChange },
  } = useInput(props);
  const { referenceRecord, isLoading } = useReference<Media>({
    reference: "media",
    id: value,
  });
  const { data: fallbackRecord, isLoading: fallbackLoading } = useFallbackMedia(
    {
      source: props.source,
      ...fallbackProps,
    }
  );
  const displayRecord = referenceRecord || fallbackRecord;
  const [open, setOpen] = useState(false);
  const translateLabel = useTranslateLabel();
  const label = translateLabel({
    label: customLabel,
    resource: "media",
    source: props.source,
  });

  return (
    <Labeled label={label}>
      <>
        <SingleMediaPreview
          media={displayRecord}
          onOpen={() => setOpen(true)}
          onRemove={() => onChange(null)}
          isLoading={isLoading || (!referenceRecord && fallbackLoading)}
          label={label}
        />
        <MediaDialog
          open={open}
          onClose={() => setOpen(false)}
          toggleSelect={(id) => {
            console.log("❗️ - id:", id);
            if (value !== id) {
              onChange(id);
              setOpen(false);
            } else {
              onChange(null);
            }
          }}
          selected_ids={[value]}
        />
      </>
    </Labeled>
  );
};

const SingleMediaPreview = ({
  media,
  onOpen,
  onRemove,
  label,
  isLoading,
}: {
  media?: Media;
  onOpen: () => void;
  onRemove: () => void;
  isLoading: boolean;
  label: ReturnType<ReturnType<typeof useTranslateLabel>>;
}) => {
  const theme = useTheme();
  if (isLoading) return <LinearProgress />;
  if (!media)
    return (
      <Card
        sx={{
          height: 200,
          width: 200,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderColor: "success.main",
          gap: 2,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "green.100",
          },
        }}
        onClick={onOpen}
        variant="outlined"
      >
        <Plus size={40} color={theme.palette.success.main} />
        <Typography variant="h5" textTransform="capitalize">
          Add {label}
        </Typography>
      </Card>
    );
  return <MediaCard media={media} onClick={onOpen} onRemove={onRemove} />;
};

export const useFallbackMedia = (props: UseFallbackResourceValueProps) => {
  const [id, isFallbackLoading] = useFallbackResourceValue(props);
  const { referenceRecord, isLoading } = useReference<Media>({
    reference: "media",
    id,
    options: { enabled: !!id },
  });
  return useMemo(
    () => ({
      data: referenceRecord,
      isLoading: isLoading || isFallbackLoading,
    }),
    [referenceRecord, isLoading]
  );
};
