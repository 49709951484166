import {
  BooleanField,
  BooleanInput,
  Button,
  DateField,
  FunctionField,
  ImageField,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  TextField,
  useRecordContext,
} from "react-admin";
import { DrawerDatagrid } from "~/components/drawer";
import { EnumField, EnumSelectField } from "~/components/fields";
import { PlatformUserForm } from "./PlatformUserForm";
import { useCanAccess } from "@react-admin/ra-rbac";
import { get } from "lodash";
import {
  AndroidLogo,
  AppleLogo,
  Notification,
  Phone,
} from "@phosphor-icons/react";
import { useRecordCommunicate } from "~/hooks";
import AvatarField from "~/components/AvatarField";

const PLATFORM_USER_ROLE_COLORS = {
  user: "primary",
  collaborator: "success",
  viewer: "warning",
  admin: "error",
};

export const InvitePlatformUserButton = () => {
  const record = useRecordContext();
  const { mutate: invite_collaborator } = useRecordCommunicate(
    "invite_collaborator",
    {
      resource: "platform_users",
      id: record.id,
    }
  );
  return (
    <Button variant="outlined" onClick={invite_collaborator}>
      Invite User
    </Button>
  );
};
export const PlatformUserList = () => {
  const record = useRecordContext();
  const { canAccess: canUpdatePlatformUsers } = useCanAccess({
    action: "update",
    resource: "platform_users",
    record,
  });

  return (
    <ReferenceManyField
      reference="platform_users"
      target="user_id"
      label={false}
      perPage={10}
      pagination={
        <Pagination sx={{ width: "calc(100% + 64px)", marginX: "-32px" }} />
      }
    >
      <DrawerDatagrid
        Form={<PlatformUserForm user={record} />}
        canEdit={canUpdatePlatformUsers}
        rowClick={true}
      >
        <ReferenceField reference="platforms" source="platform_id" link="false">
          <ImageField
            source="logo.url"
            width="32"
            sx={{
              "& .RaImageField-image": {
                height: "48px",
                width: "48px",
                display: "inline",
              },
            }}
          />
          <TextField source="name" />
        </ReferenceField>

        <AvatarField label={false} source="avatar.url" />
        {canUpdatePlatformUsers ? (
          <EnumSelectField
            source="role"
            color_options={PLATFORM_USER_ROLE_COLORS}
          />
        ) : (
          <EnumField source="role" color_options={PLATFORM_USER_ROLE_COLORS} />
        )}
        <FunctionField
          label="Points"
          render={(record) => `${record.points_remaining}/${record.points}`}
        />
        <DateField source="last_log_in" showTime />
        <DateField source="invited_at" showTime />
        {/* <DateField source="created_at" showTime /> */}
        {/* <FunctionField
          label="Platform"
          render={(record) => {
            return (
              record.expo_push_token && (
                <Button
                  label="Send Notification"
                  startIcon={
                    Math.random() > 0.4 ? <AppleLogo /> : <AndroidLogo />
                  }
                />
              )
            );
          }}
        /> */}
        <BooleanField source="allow_emails" />
        {/* <BooleanField source="allow_push" />
        <BooleanField source="allow_sms" /> */}
        <InvitePlatformUserButton />
      </DrawerDatagrid>
    </ReferenceManyField>
  );
};
