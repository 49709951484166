import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { formatData, formatLabels, selectedRowsDateFiller } from "../helpers";
import { StackedAreaChart } from "../graphs/StackedAreaChart";
import { ChartData, ChartDataset } from "../shared_components";
import dayjs, { Dayjs, OpUnitType } from "dayjs";
import { green, red, blue, yellow, grey } from "~/theme/colors";
import { X } from "@phosphor-icons/react";

type SelectedRowChartProps = {
  data: any[];
  setting: string;
  period: string;
  activeRows: string[];
  setActiveRows: Dispatch<SetStateAction<string[]>>;
  startDate: Dayjs;
  endDate: Dayjs;
  metrics: string[];
  labels: string[];
};

const plotColors = [blue[300], green[300], red[300], grey[300], yellow[300]];
const hoverColors = [blue[400], green[400], red[400], grey[400], yellow[400]];

function hexToRGB(hex: string, alpha: number) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

const switchFilter = (setting: string, row: any) => {
  if (setting == "mediumSource") {
    return row.medium + " " + row.source;
  }
  return row.pagePath;
};

export const SelectedRowChart = ({
  data,
  setting,
  period,
  activeRows,
  setActiveRows,
  startDate,
  endDate,
  metrics,
  labels,
}: SelectedRowChartProps) => {
  const [summaryStatistics, setSummaryStatistics] = useState<number[]>([0, 0]);
  const [chartData, setChartData] = useState<ChartData>({
    labels: [],
    datasets: [],
  });

  const handleDelete = (row_id: string) => {
    const validRows = activeRows.filter((r) => r != row_id);
    setActiveRows([...validRows]);
  };

  useEffect(() => {
    const filteredData = data?.filter(
      (e: any) =>
        dayjs(e.date).isSameOrAfter(startDate, period as OpUnitType) &&
        dayjs(e.date).isSameOrBefore(endDate, period as OpUnitType)
    );

    const formattedData = filteredData && formatData(filteredData, [], period);
    const keys = activeRows;
    let datasets: ChartDataset[] = [];
    const metricKeys = metrics.flatMap((d) => keys.map((v) => d + "::" + v));

    metricKeys.forEach((metricKey: string, ii: number, _: string[]) => {
      const metric = metricKey.split("::")[0];
      const key = metricKey.split("::")[1];

      const thisDataset = {
        label: key,
        data:
          selectedRowsDateFiller(
            filteredData.filter(
              (row: any) => switchFilter(setting, row) == key
            ),
            metric,
            startDate.format("YYYY-MM-DD"),
            endDate.format("YYYY-MM-DD"),
            period
          ).map((e) => e.metric) || [],
        borderColor: hexToRGB(plotColors[ii % activeRows.length], 1),
        backgroundColor: undefined,
        fill: false,
        tension: 0.3,
        borderWidth: 2,
        radius: 2,
        pointBorderColor: "rgba(0, 0, 0, 0)",
        pointBackgroundColor: "rgba(0, 0, 0, 0)",
        pointHoverBackgroundColor: "rgba(242, 202, 81, 1)",
        pointHoverBorderColor: "rgba(242, 202, 81, 1)",
      };
      datasets.push(thisDataset);
    });

    formattedData &&
      setChartData({
        labels: formatLabels(Array.from(new Set(formattedData.labels)), period),
        datasets: datasets,
      });
  }, [period, startDate, endDate, data, activeRows]);


  return (
    <Grid container spacing={1} sx={{ marginTop: "32px" }}>
      {metrics.map((metric, jj) => {
        const N = activeRows.length;
        const chartDataByMetric = {
          labels: chartData["labels"],
          datasets: chartData["datasets"].slice(jj * N, jj * N + N),
        } as ChartData;
        return (
          <Grid item xs={6} key={metric + jj}>
            <Card
              variant={"outlined"}
              sx={{
                borderRadius: "12px",
                backgroundColor: "primary.contrastText",
                cursor: "pointer",
                height: "400px",
                width: "100%",
                ":hover": {
                  backgroundColor: "blue.50",
                },
              }}
            >
              <CardContent
                sx={{ width: "100%", display: "flex", flexDirection: "column" }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography
                    variant={"h5"}
                    align="inherit"
                    textTransform="capitalize"
                    noWrap
                    sx={{ marginBottom: "12px" }}
                  >
                    {labels[jj]}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant={"body1"}
                    align="inherit"
                    sx={{ width: "100%" }}
                  >
                    Displays page views for rows selected in table above.
                  </Typography>
                </Box>
                <StackedAreaChart
                  formattedData={chartDataByMetric}
                  displayLegend={false}
                  stackDisabled
                  isAreaChart={false}
                  reverseToolTip={false}
                />
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  flexWrap="wrap"
                >
                  {activeRows.map((row_id, ii) => {
                    const label = row_id;
                    const displayLabel =
                      label.length <= 20 ? label : label.slice(0, 20) + "...";
                    return (
                      <Tooltip title={label} key={row_id}>
                        <Chip
                          size="small"
                          label={displayLabel}
                          onClick={undefined}
                          onDelete={() => handleDelete(row_id)}
                          deleteIcon={<X size={12} color={grey[600]} />}
                          sx={{
                            marginLeft: "5px",
                            marginBottom: "12px",
                            backgroundColor: plotColors[ii],
                            "&:hover": { backgroundColor: hoverColors[ii] },
                          }}
                        />
                      </Tooltip>
                    );
                  })}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};
