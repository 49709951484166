import { useDefineAppLocation } from "@react-admin/ra-navigation";
import { Show, TabbedShowLayout } from "react-admin";
import { Stats } from "~/components";
import { TourismoDateRangePicker } from "~/components/TourismoDateRangePicker";
import { useCurrentPlatform } from "~/context";
import { ReportFilterProvider } from "~/context/ReportFilterContext";

export const CommunicationReport = () => {
  useDefineAppLocation("reports.communication");
  const { currentPlatform, isLoading } = useCurrentPlatform();
  if (isLoading) return "";

  return (
    <ReportFilterProvider>
      <TourismoDateRangePicker />
      <Show resource="platforms" id={currentPlatform.id} actions={false}>
        <TabbedShowLayout>
          <TabbedShowLayout.Tab label="E-mail Activity">
            <Stats category="email" />
          </TabbedShowLayout.Tab>{" "}
          <TabbedShowLayout.Tab label="Push Activity">
            <Stats category="push" />
          </TabbedShowLayout.Tab>{" "}
          <TabbedShowLayout.Tab label="SMS Activity">
            <Stats category="sms" />
          </TabbedShowLayout.Tab>
        </TabbedShowLayout>
      </Show>
    </ReportFilterProvider>
  );
};
