import { useRecordContext } from "react-admin";
import { Box, Typography, Stack } from "@mui/material";
import { HELP_TOPIC_ICONS } from "./HelpTopicIcons";
import { Question } from "@phosphor-icons/react";

export const HelpTopicTitle = () => {
  const { name, fa_icon } = useRecordContext();
  const { Icon, props } = HELP_TOPIC_ICONS[fa_icon] || { Icon: Question };

  return (
    <Stack display="flex" direction="row" alignItems="center">
      <Box
        backgroundColor={"blue.50"}
        display="flex"
        padding={1}
        borderRadius={1}
        mr={2}
        color="blue.500"
        fontSize="22px"
        width="36px"
        height="36px"
      >
        <Icon {...props} />
      </Box>
      <Typography
        variant="h5"
        fontWeight={500}
        fontStyle="normal"
        lineHeight="27px"
        noWrap
      >
        {name}
      </Typography>
    </Stack>
  );
};
