import { Box } from "@mui/material";
import { get, isArray } from "lodash";
import { useState } from "react";
import { useRecordContext } from "react-admin";
import { MediaModal } from "./MediaModal";
import { MediaThumbnail } from "./MediaThumbnail";

export const ImageField = ({ source, title, ...props }) => {
  const [open, setOpen] = useState(false);
  const record = useRecordContext(props);
  const sourceValue = source ? get(record, source) : record;
  let value = sourceValue;
  let additionalCount = 0;
  if (isArray(sourceValue)) {
    value = get(sourceValue, [0]);
    additionalCount = sourceValue.length - 1;
  }
  const contentType = get(value, "content_type", "");

  const url = typeof value === "string" ? value : get(value, "url");

  if (!url) return null;
  const text = get(record, title, title);

  return (
    <>
      <MediaThumbnail
        url={url}
        text={text}
        contentType={contentType}
        openModal={() => setOpen(true)}
        {...props}
      />
      {!!additionalCount && <Box>{`+ ${additionalCount} more`}</Box>}
      <MediaModal
        src={url}
        contentType={contentType}
        open={open}
        onClose={(e) => {
          e.stopPropagation();
          setOpen(false);
        }}
      />
    </>
  );
};
