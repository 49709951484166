import { ArrowBack, Email } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { sendEmailVerification } from "firebase/auth";
import { useAuthProvider, useSafeSetState } from "react-admin";
import { ButtonColumn } from "./ButtonColumn";
import { useLoginContext } from "./Login";
import { LoginButton } from "./LoginButton";

export const EmailVerification = () => {
  const { setSignUp, setAwaitingVerification } = useLoginContext();
  const { getAuthUser } = useAuthProvider();
  const [sending, setSending] = useSafeSetState(false);
  const [sent, setSent] = useSafeSetState(false);
  const handleEmailVerification = async () => {
    setSending(true);
    const user = await getAuthUser();
    await sendEmailVerification(user);
    setSending(false);
    setSent(true);
  };

  const text = sent
    ? "Please check your email and follow the instructions provided. The return here and log back in."
    : "Before you can log in we need to verify your email.";
  return (
    <>
      <Box p={1}>
        {sent && (
          <Typography variant="h4" align="center">
            Sent!
          </Typography>
        )}
        <Typography>{text}</Typography>
      </Box>
      <ButtonColumn>
        <LoginButton
          onClick={handleEmailVerification}
          startIcon={<Email />}
          disabled={sending}
        >
          {sent && "Re-"}Send Verification Email
        </LoginButton>
        <LoginButton
          onClick={() => {
            setAwaitingVerification(false);
            setSignUp(false);
          }}
          startIcon={<ArrowBack />}
          variant="outlined"
        >
          Back To Login
        </LoginButton>
      </ButtonColumn>
    </>
  );
};
