import { Box } from "@mui/material";

export const ProgressBar = ({ value }: { value: number }) => {
  const valueInPercent = value * 100;
  let color = "#088208a3";
  if (valueInPercent < 30) {
    color = "#f44336";
  } else if (valueInPercent <= 70) {
    color = "#efbb5aa3";
  }
  return (
    <Box
      sx={{
        border: `1px solid `,
        borderColor: "palette.divider",
        position: "relative",
        overflow: "hidden",
        width: "100%",
        height: "26px",
        borderRadius: 1,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          lineHeight: "24px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >{`${valueInPercent.toLocaleString()} %`}</Box>
      <Box
        sx={{
          height: "100%",
          backgroundColor: color,
          maxWidth: `${valueInPercent}%`,
        }}
      />
    </Box>
  );
};
