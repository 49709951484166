import { get } from "lodash";
import {
  ListBase,
  ReferenceField,
  SimpleList,
  useCreatePath,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import AvatarField from "~/components/AvatarField";
import { TargetReferenceField } from "~/components/fields";
import { SimpleListView } from "~/components/lists";
import { classifyResource, declassifyResource } from "~/helpers";

export const RecentCheckIns = ({ filter, limit }) => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const createPath = useCreatePath();
  const createTargetPath = (r) => {
    const id = get(r, "target_id");
    if (!id) return false;
    const resource = declassifyResource(get(r, "target_type", ""));
    const path = createPath({
      resource,
      id: r.target_id,
      type: "edit",
    });
    return path;
  };
  // HACK this should be refactored to use platform_user_tasks, and possibly compmletely rewritten as more of a stats_style summary.
  return (
    <ListBase
      resource="platform_user_check_ins"
      filter={{
        record_type: classifyResource(resource || ""),
        record_id: record?.id,
        ...filter,
      }}
      perPage={limit || 5}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <SimpleListView title={"Recently Completed"}>
        <SimpleList
          linkType={false}
          primaryText={<TargetReferenceField link={createTargetPath} />}
          tertiaryText={(record) => {
            const date = get(record, "updated_at");
            return !!date && new Date(date).toLocaleDateString();
          }}
          secondaryText={
            <ReferenceField
              reference="platform_users"
              source="platform_user_id"
            >
              <ReferenceField reference="users" source="user_id" />
            </ReferenceField>
          }
          rowSx={() => ({
            "& .MuiListItemText-primary": {
              display: "flex",
              alignItems: "center",
              "& .MuiListItemText-primary": {
                display: "block",
              },
            },
          })}
          leftAvatar={() => (
            <ReferenceField
              label={false}
              source="platform_user_id"
              reference="platform_users"
            >
              <AvatarField
                sx={{ width: 32, height: 32 }}
                source="avatar"
                link
              />
            </ReferenceField>
          )}
        />
      </SimpleListView>
    </ListBase>
  );
};
