import { NewspaperClipping } from "@phosphor-icons/react";
import { ItinResourceProps } from "../types";
import { FormCreate } from "./FormCreate";
import { FormEdit } from "./FormEdit";
import { FormList } from "./FormList";

export const FormResource: ItinResourceProps = {
  name: "forms",
  list: FormList,
  edit: FormEdit,
  create: FormCreate,
  icon: <NewspaperClipping />,
  recordRepresentation: (r) => r.name,
  options: {menu_group: "contesting"}

};
