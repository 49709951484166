export const RESULT_LENGTH = 150;

const searchRegex = (search) =>
  new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), "gi");

export const getTextMatches = (search, text) => {
  if (!search) return [];
  return [...text.matchAll(searchRegex(search))].map(({ index }) => {
    const startLength = index - RESULT_LENGTH;
    const startIndex = Math.max(0, startLength);
    const startIndexLength = Math.min(index, RESULT_LENGTH);
    const startString = text.substr(startIndex, startIndexLength);
    const resultString = text.substr(index, search.length);
    const endIndex = index + search.length;
    const endString = text.substr(endIndex, RESULT_LENGTH);
    return [startString, resultString, endString];
  });
};
