import {
  Button,
  Card,
  FormHelperText,
  Grid,
  TextField as MuiTextField,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Plus } from "@phosphor-icons/react";
import { isNumber } from "lodash";
import { ChangeEventHandler, useCallback, useRef, useState } from "react";
import {
  email as emailValidator,
  useCreate,
  useNotify,
  useRecordContext,
} from "react-admin";
import { useQueryClient } from "react-query";
import { Confirm, UploadButton } from "~/components";
import { useCustomMutation } from "~/hooks";

export const AddSubscribers = () => {
  const queryClient = useQueryClient();
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const { mutate: uploadCSV, isLoading: isUploading } = useCustomMutation();
  const [create, { isLoading: isCreating }] = useCreate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const record = useRecordContext();
  const notify = useNotify();
  const [error, onError] = useState<{ message: string } | null>(null);
  const [promiseWithResolvers, setPromise] = useState<PromiseWithResolvers<
    1 | 0
  > | null>(null);

  const statusFromConfirm = useCallback(async () => {
    const promiseWR = Promise.withResolvers<0 | 1>();
    setPromise(promiseWR);
    return promiseWR?.promise;
  }, []);

  const handleSendCSV = useCallback<ChangeEventHandler<HTMLInputElement>>(
    async ({ target }) => {
      const file = target.files?.[0];
      if (!file) return notify("No file selected", { type: "error" });
      const status = await statusFromConfirm();
      if (!isNumber(status)) return;

      const data = new FormData();
      data.append("file", file);
      data.append("status", status.toString());

      uploadCSV(
        {
          resource: "newsletters",
          custom_url: "csv",
          id: record.id,
          data,
        },
        {
          onSuccess: () => {
            notify("Subscribers added");
            queryClient.invalidateQueries([
              "newsletter_users",
              "getManyReference",
            ]);
          },
          onError: (e) => notify(e.message, { type: "error" }),
        }
      );
    },
    [uploadCSV, notify, queryClient, record.id, statusFromConfirm]
  );

  const handleCreate = useCallback(async () => {
    if (!name) return onError({ message: "Name is required." });
    const invalidEmail = emailValidator("Email not valid.")(email);
    if (invalidEmail)
      return onError(
        typeof invalidEmail === "string"
          ? { message: invalidEmail }
          : invalidEmail
      );
    const status = await statusFromConfirm();
    create(
      "newsletter_users",
      {
        data: {
          subscribe_name: name,
          subscribe_email: email,
          newsletter_id: record.id,
          subscribe_type: "manual",
          status,
        },
      },
      {
        onError: (error) => {
          if (error instanceof Error) onError(error);
        },
        onSuccess: (d) => {
          setEmail("");
          setName("");
          notify(`${email} added to newsletter`, { type: "success" });
        },
      }
    );
  }, [create, email, name, record, statusFromConfirm]);
  return (
    <Card variant="outlined" sx={{ flexDirection: "column", mb: 4 }}>
      <Typography variant="h5">Add Subscribers</Typography>
      <Grid container gap={2} alignItems={"flex-end"}>
        <Grid item>
          <MuiTextField
            label="Name"
            autoComplete="off"
            value={name}
            error={!!error}
            onChange={({ target }) => {
              setName(target.value);
              onError(null);
            }}
            required
          />
        </Grid>
        <Grid item>
          <MuiTextField
            label="Email"
            autoComplete="off"
            error={!!error}
            value={email}
            onChange={({ target }) => {
              setEmail(target.value);
              onError(null);
            }}
            onKeyDown={(e) => e.key === "Enter" && handleCreate()}
            required
          />
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2} mb={0.5} alignItems={"center"}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<Plus />}
              onClick={handleCreate}
            >
              Add
            </Button>
            <Typography variant="button">or</Typography>
            <Tooltip title="csv requires 'name' and 'email' columns">
              <UploadButton
                ref={uploadInputRef}
                loading={isUploading}
                inputProps={{ accept: ".csv" }}
                onChange={handleSendCSV}
              >
                Upload CSV
              </UploadButton>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      <FormHelperText error={!!error}>
        {error?.message ||
          "Enter a Name and Email to manually add a subscriber."}
      </FormHelperText>
      <Confirm
        loading={isUploading || isCreating}
        isOpen={!!promiseWithResolvers}
        title="Send confirmation emails?"
        content="Do you want to automatically confirm the subscribers or send an confirmation email?"
        confirmColor="primary"
        cancelColor="error"
        cancelVariant="contained"
        onClose={(e, reason) => {
          if (reason === "buttonPress") {
            promiseWithResolvers?.resolve(0);
          }
          uploadInputRef.current?.value && (uploadInputRef.current.value = "");
          setPromise(null);
        }}
        onConfirm={() => {
          promiseWithResolvers?.resolve(1);
          setPromise(null);
        }}
        cancel="Send Confirmation Email"
        confirm="Automatically Confirm"
      />
    </Card>
  );
};
