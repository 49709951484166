import { useCanAccess } from "@react-admin/ra-rbac";
import { useState } from "react";
import {
  Confirm,
  useDelete,
  useListContext,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { ChipField } from "./ChipField";

export const ChipWithConfirmDelete = (props) => {
  const record = useRecordContext();
  const [value, setValue] = useState();
  const resource = useResourceContext();
  const { refetch } = useListContext();
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleDialogClose = () => setOpenConfirm(false);
  const [deleteOne] = useDelete();
  const { canAccess: canDelete } = useCanAccess({
    record,
    resource,
    action: "destroy",
  });
  const handleDeleteTag = () => {
    deleteOne(resource, { id: record.id }, { onSuccess: () => refetch() });
    setOpenConfirm(false);
  };
  return (
    <>
      <ChipField
        onValue={setValue}
        onDelete={canDelete ? () => setOpenConfirm(true) : undefined}
        {...props}
      />
      <Confirm
        isOpen={openConfirm}
        title={`Delete ${value}`}
        content={`Are you sure you want to delete ${value}?`}
        onConfirm={handleDeleteTag}
        onClose={handleDialogClose}
      />
    </>
  );
};
