import { wktToGeoJSON } from "@terraformer/wkt";
import { get } from "lodash";
import { useMemo } from "react";
import { useInput, useRecordContext } from "react-admin";
import { getPosition } from "./helpers";

export const useWktPointValues = (source) => {
  const {
    field: { value },
  } = useInput({ source });
  const record = useRecordContext();
  const val = value || get(record, source);
  return val ? wktToGeoJSON(val).coordinates : [];
};

export const usePosition = (source) => {
  const [lng, lat] = useWktPointValues(source);
  return useMemo(() => getPosition({ lat, lng }), [lng, lat]);
};

export const extractLatLngs = (source) => {
  return source
    .replaceAll("MULTIPOLYGON (((", "")
    .replaceAll(")))", "")
    .split(", ")
    .map((x) => {
      return {
        lng: Number(x.split(" ").slice(0, 1)),
        lat: Number(x.split(" ").slice(1, 2)),
      };
    });
};
