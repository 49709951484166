import { PlatformLevelCreate, PlatformLevelEdit } from "./platform_levels";
import { PlatformLevelList } from "./PlatformLevelList";
import { Star } from "@phosphor-icons/react";
import { ItinResourceProps } from "../types";

export const PlatformLevelResource: ItinResourceProps = {
  name: "platform_levels",
  list: PlatformLevelList,
  edit: PlatformLevelEdit,
  create: PlatformLevelCreate,
  icon: <Star />,
  recordRepresentation: ({ name }) => name,
  options: { menu_group: "contesting" },
};
