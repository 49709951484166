import { SimpleForm, TextInput } from "react-admin";
import { SingleImageInput } from "~/components";
import ItinMarkdownInput from "~/components/ItinMarkdownInput";
import { validateUrl } from "~/hooks";
export const DmoForm = ({ defaultName, ...props }) => {
  return (
    <SimpleForm {...props}>
      <SingleImageInput source="logo" />
      <TextInput source="secret_key" disabled={true} label="API Key" />
      <TextInput source="name" defaultValue={defaultName} />
      <TextInput source="website" validate={validateUrl} />
      <ItinMarkdownInput
        source="description"
        isRequired={false}
        titleVariant=""
        title="Description"
      />
    </SimpleForm>
  );
};
