import { useCallback } from "react";
import { TextInput, required } from "react-admin";
import { GooglePlaceAutocomplete } from "~/components/inputs";
import { Grid, Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { MapField } from "~/components/maps/MapField";
import { LonLatFields } from "~/components/maps/LonLatFields";
import { MapDataProvider } from "~/components/maps/MapDataProvider";
import { validateUrl } from "~/hooks";

const VALUE_KEYS = {
  city: ["locality"],
  postal_code: ["postal_code"],
  street_address: ["street_number", "route"],
  province: ["administrative_area_level_1"],
  country: ["country"],
};

const getAddressValue = (key, place) => {
  const value = place.address_components?.find((component) =>
    component.types.includes(key)
  );
  return value?.short_name ?? "";
};

export const LocationForm = ({ defaultValue = "" }) => {
  const { setValue } = useFormContext();

  const handleSelectPlace = useCallback(
    (place) => {
      Object.entries(VALUE_KEYS).forEach(([key, place_keys]) => {
        const value = place_keys
          .map((pk) => getAddressValue(pk, place))
          .join(" ");
        setValue(key, value);
      });
      setValue("name", place.name);
      setValue("latitude", place.geometry.location.lat());
      setValue("longitude", place.geometry.location.lng());
      setValue("website", place.website);
      setValue("phone_number", place.international_phone_number);
    },
    [setValue]
  );
  return (
    <>
      <GooglePlaceAutocomplete
        defaultValue={defaultValue}
        handleSelectPlace={handleSelectPlace}
      />
      <Grid container columnSpacing={3}>
        <Grid item xs={12}>
          <TextInput
            source="name"
            defaultValue={defaultValue}
            fullWidth
            validate={required()}
          />
        </Grid>
        <Grid item xs={7}>
          <TextInput source="street_address" fullWidth />
        </Grid>
        <Grid item xs={5}>
          <TextInput source="city" fullWidth />
        </Grid>
        <Grid item xs={4}>
          <TextInput source="province" fullWidth />
        </Grid>
        <Grid item xs={4}>
          <TextInput source="country" fullWidth />
        </Grid>
        <Grid item xs={4}>
          <TextInput source="postal_code" fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextInput source="email" fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextInput source="phone_number" fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextInput source="website" fullWidth validate={validateUrl} />
        </Grid>
        <Grid item xs={6}>
          <TextInput source="website2" fullWidth validate={validateUrl} />
        </Grid>
        <Grid item xs={12}>
          <MapDataProvider>
            <LonLatFields />
            <Box height={"350px"}>
              <MapField getSiblings={false} />
            </Box>
          </MapDataProvider>
        </Grid>
      </Grid>
    </>
  );
};
