import { groupBy, mean, sum, max, map } from "lodash";
import { aggregateWeekYear } from "../helpers";
import { DataType, GenericTableData, TableMetric } from "./types";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

const aggregate = (values: number[], type: string) => {
  let value;
  switch (type) {
    case "sum":
      value = sum(values);
      break;
    case "mean":
      value = mean(values);
      break;
    case "max":
      value = max(values);
      break;
    case "none":
      value = values[0];
      break;
    default:
      value = values[0];
  }
  return value || 0;
};

const aggregateTableData = (
  data: GenericTableData[],
  period: string,
  aggregationTypes: string[]
) => {
  type DataKey = keyof (typeof data)[0];
  let keys = Object.keys(data[0]).map((k) => k as DataKey);
  keys = keys.slice(1, keys.length);
  let grouped;
  switch (period) {
    case "month":
      const monthsAndKey = groupBy(data, function (item: DataType) {
        const pagePath = item.pagePath;
        const mediumSource = `${item.medium}<>${item.source}`;
        return `${item.date.substring(0, 7)}<>${pagePath || mediumSource}`;
      });
      grouped = Object.entries(monthsAndKey).map(([key, value]) => {
        const date = key.split("<>")[0];
        const year = parseInt(date.split("-")[0]);
        const month = parseInt(date.split("-")[1]);
        const aggregatedDate = new Date(year, month - 1);
        let aggregatedValues = {} as any;
        keys.forEach((this_key: DataKey, ii: number) => {
          aggregatedValues[this_key] = aggregate(
            map(value as TableMetric, this_key) as number[],
            aggregationTypes[ii]
          );
        });
        return { date: aggregatedDate.toString(), ...aggregatedValues };
      });
      break;
    case "week":
      const weekYearKey = groupBy(data, function (item) {
        const pagePath = item.pagePath;
        const mediumSource = `${item.medium}<>${item.source}`;
        return `${aggregateWeekYear(item.date)}<>${pagePath || mediumSource}`;
      });
      grouped = Object.entries(weekYearKey).map(([key, value]) => {
        const dateKey = key.split("<>")[0];
        const year = dateKey.split("-")[0];
        const weekNum = parseInt(dateKey.split("-")[1]);
        // const date = new Date(
        //   dayjs(`${year}-01-01`)
        //     // .tz("America/Vancouver")
        //     .add(7 * weekNum, "day")
        //     .format("YYYY-MM-DD")
        // ).toISOString();
        let aggregatedValues = {} as any;
        keys.forEach((this_key: DataKey, ii: number) => {
          aggregatedValues[this_key] = aggregate(
            map(value as TableMetric, this_key) as number[],
            aggregationTypes[ii]
          );
        });
        return {
          date: dayjs(value[0].date).day(0).toISOString(),
          ...aggregatedValues,
        };
      });
      break;
    default:
      grouped = data;
  }
  return grouped;
};

export default aggregateTableData;
