import { ScriptableContext } from "chart.js";
import _ from "lodash";
import dayjs, { Dayjs, OpUnitType } from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { AppInstallsType, CheckInsGraphType } from "./types";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const formatData = (
  data: any,
  ignore?: string[],
  period?: string,
  startDate?: Dayjs | null,
  endDate?: Dayjs | null
) => {
  let keys = Object.keys(data[0]);
  keys.shift(); // remove the Date field
  keys =
    ignore && ignore?.length > 0
      ? keys.filter((k) => !ignore?.includes(k))
      : keys;

  let filteredData = data;
  if (startDate && endDate) {
    filteredData = filteredData.filter(
      (e: any) =>
        dayjs(e.date).isSameOrAfter(startDate, period as OpUnitType) &&
        dayjs(e.date).isSameOrBefore(endDate, period as OpUnitType)
    );
  }

  //filter out unwanted keys from `ignore`
  const mappedData = keys.map((k) => _.map(filteredData, k)); // array of arrays, subarrays are values for a single field like `Install_events`

  // This loop makes an array of objects formatted for ChartJS
  var datasets: {
    label: any;
    data: any;
    borderColor: string;
    backgroundColor: any;
    fill: boolean;
    tension: number;
    borderWidth: number;
    radius: number;
  }[] = [];
  var len = keys.length;
  for (var i = 0; i < len; i++) {
    datasets.push({
      label: keys[i],
      data: mappedData[i],
      borderColor: "rgba(101, 72, 143,0.3)",
      backgroundColor: (context: ScriptableContext<"line">) => {
        const ctx = context.chart.ctx;
        const gradient = ctx.createLinearGradient(0, 255, 0, 0);
        gradient.addColorStop(1, "rgba(101, 72, 143, 0.3)");
        gradient.addColorStop(0, "rgba(101, 72, 143, 0");
        return gradient;
      },
      fill: true,
      tension: 0.3,
      borderWidth: 2,
      radius: 0,
    });
  }

  const labels = (filteredData as AppInstallsType[] | CheckInsGraphType[])?.map(
    (r: any) => r["date"]
  );

  const formattedDataset = {
    labels,
    datasets,
  };

  return formattedDataset;
};
