import {
  ReferenceArrayField,
  ReferenceManyField,
  SimpleList,
} from "react-admin";

export const ListingChallenges = () => {
  return (
    <ReferenceArrayField source="challenge_ids" reference="challenges">
      <SimpleList
        tertiaryText={(record) => {
          const completed = record.user_completion_counts.filter(
            (c) => c.completed
          ).length;
          return `${completed} completed`;
        }}
        secondaryText={
          <ReferenceManyField reference="rewards" target="challenge_id">
            <SimpleList
              empty={null}
              linkType={false}
              leftAvatar={(r) => r.url}
              tertiaryText={(r) => `${r.earned_count} earned`}
              sx={{ padding: 1 }}
              rowSx={() => ({
                "& .MuiListItemText-primary": {
                  display: "flex",
                  alignItems: "center",
                  "& .MuiListItemText-primary": {
                    display: "block",
                  },
                },
              })}
            />
          </ReferenceManyField>
        }
      />
    </ReferenceArrayField>
  );
};
