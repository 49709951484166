import { geojsonToWKT, wktToGeoJSON } from "@terraformer/wkt";
import { Feature, FeatureCollection, MultiPolygon } from "terraformer";

export const getPosition = ({ lat, lng }) => {
  if (!lat || !lng) return false;
  return {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  };
};

export const lonLatToPosition = (lonlat) => {
  if (!lonlat) return null;
  const [lng, lat] = wktToGeoJSON(lonlat).coordinates;
  return getPosition({ lat, lng });
};

export const dataJsonToMultiPolyString = (json) => {
  const collection = new FeatureCollection(json);
  const allCoords = [];
  collection.forEach(({ geometry: { type, coordinates } }) => {
    allCoords.push(...(type === "MultiPolygon" ? coordinates : [coordinates]));
  });
  if (!allCoords.length) return null;
  const multiPolygon = new MultiPolygon(allCoords);
  return geojsonToWKT(multiPolygon);
};

export const convertMultiPolyToPolyFeature = (wkt) => {
  const multiPoly = wktToGeoJSON(wkt);
  const features = multiPoly.coordinates.map(
    (coordinates) => new Feature({ type: "Polygon", coordinates })
  );
  return new FeatureCollection(features);
};
