import { CustomBlockConfig, defaultProps } from "@blocknote/core";

export const EMBED_CONFIG = {
  type: "embed" as const,
  propSchema: {
    textAlignment: defaultProps.textAlignment,
    url: { default: "" as const },
    previewWidth: { default: 512 as const },
    fullWidth: { default: true as const },
  },
  content: "none",
} satisfies CustomBlockConfig;
