import { Block } from "@blocknote/core";
import {
  useBlockNoteEditor,
  useComponentsContext,
  useSelectedBlocks,
} from "@blocknote/react";
import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { ChromePicker } from "react-color";
import { block_note_schema, BlockSchema } from "../BlockInput";

const colorInBlock = <Color extends "text" | "background">(
  color: Color,
  block?: Block<BlockSchema>
): block is Block<BlockSchema> &
  (Color extends "text"
    ? { props: { textColour: string } }
    : { props: { backgroundColour: string } }) => {
  return !!block?.props && `${color}Colour` in block.props;
};

export const CustomColorStyleButton = () => {
  const editor = useBlockNoteEditor(block_note_schema);
  const selectedBlocks = useSelectedBlocks(editor);
  const block = selectedBlocks.length === 1 ? selectedBlocks[0] : undefined;
  const hasBgColor = colorInBlock("background", block);
  const hasTextColor = colorInBlock("text", block);
  const currentTextColor = hasTextColor ? block.props.textColour : "#000";
  const currentBackgroundColor = hasBgColor
    ? block.props.backgroundColour
    : "#fff";

  const setTextColor = useCallback(
    (color: string) => {
      if (!hasTextColor) return;
      editor.updateBlock(block, { props: { textColour: color } });
    },
    [editor, hasTextColor, block]
  );

  const setBackgroundColor = useCallback(
    (color: string) => {
      if (!hasBgColor) return;
      editor.updateBlock(block, { props: { backgroundColour: color } });
    },
    [editor, hasBgColor, block]
  );

  const show = useMemo(() => {
    if (!hasTextColor && !hasBgColor) {
      return false;
    }
    return block?.content === undefined;
  }, [hasTextColor, hasBgColor, block]);

  if (!block || !show || !editor.isEditable) {
    return null;
  }
  return (
    <>
      {hasTextColor && (
        <ColorPickerButton
          label="Text Color"
          currentColor={currentTextColor}
          setColor={setTextColor}
          icon={<ColorIcon textColor={currentTextColor}>A</ColorIcon>}
        />
      )}
      {hasBgColor && (
        <ColorPickerButton
          label="Background Color"
          currentColor={currentBackgroundColor}
          setColor={setBackgroundColor}
          icon={<ColorIcon backgroundColor={currentBackgroundColor} />}
        />
      )}
    </>
  );
};

const ColorPickerButton = ({
  currentColor,
  setColor,
  icon,
  label,
}: {
  currentColor: string;
  setColor: (color: string) => void;
  label: string;
  icon: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColorState] = useState(currentColor);
  const Components = useComponentsContext()!;

  return (
    <Components.Generic.Popover.Root opened={isOpen} position="bottom">
      <Components.Generic.Popover.Trigger>
        <Components.FormattingToolbar.Button
          isSelected={isOpen}
          onClick={() => setIsOpen(!isOpen)}
          className={"bn-button"}
          data-test="colors"
          label={label}
          mainTooltip={label}
          icon={icon}
        />
      </Components.Generic.Popover.Trigger>
      <Components.Generic.Popover.Content
        className={"bn-popover-content bn-panel-popover"}
        variant={"panel-popover"}
      >
        <div style={{ padding: "10px" }}>
          <ChromePicker
            color={color}
            onChange={({ hex }) => setColorState(hex)}
            onChangeComplete={({ hex }) => setColor(hex)}
            disableAlpha
          />{" "}
        </div>
      </Components.Generic.Popover.Content>
    </Components.Generic.Popover.Root>
  );
};

const ColorIcon = ({
  textColor,
  backgroundColor,
  children,
}: PropsWithChildren<{ textColor?: string; backgroundColor?: string }>) => {
  const size = 20;

  const style = useMemo(
    () =>
      ({
        pointerEvents: "none",
        fontSize: (size * 0.75).toString() + "px",
        height: size.toString() + "px",
        lineHeight: size.toString() + "px",
        textAlign: "center",
        width: size.toString() + "px",
        color: textColor,
        backgroundColor: backgroundColor,
      } as const),
    [size, textColor, backgroundColor]
  );

  return (
    <div className={"bn-color-icon"} style={style}>
      {children}
    </div>
  );
};
