import { Box, CircularProgress } from "@mui/material";

export const LoaderOverlay = ({ show, sx, props }) => {
  if (!show) return null;
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "12px",
        backgroundColor: "action.disabledBackground",
        ...sx,
      }}
      {...props}
    >
      <CircularProgress />
    </Box>
  );
};
