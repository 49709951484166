import { Grid, GridProps } from "@mui/material";
import { StatSummaryCard } from "~/resources/types";
import { SummaryCard } from "./SummaryCard";

type Props = GridProps & {
  cards?: StatSummaryCard[];
};

export const SummaryCards = ({ cards, ...props }: Props) => {
  if (!cards?.length) return null;

  return (
    <Grid container spacing={2} {...props}>
      {cards.map((card) => {
        if (card.value == 0) return null;
        return (
          <SummaryCard key={`${card.name}-${card.stat_type}`} card={card} />
        );
      })}
    </Grid>
  );
};
