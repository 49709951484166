import { Grid } from "@mui/material";
import { chain } from "lodash";
import { Children, cloneElement } from "react";
import { BooleanInput, required, SimpleForm, TextInput } from "react-admin";
import { useFormContext } from "react-hook-form";
import { WithWatch } from "~/components";
import { EnumChoiceInput, OptionsInputs } from "~/components/inputs";
import ItinMarkdownInput from "~/components/ItinMarkdownInput";

export const FormFieldForm = ({ form_id }) => {
  return (
    <SimpleForm defaultValues={{ form_id }} sx={{ maxWidth: "50vw" }}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={6}>
          <TextInput source="label" fullWidth validate={required()} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <EnumChoiceInput source="type" fullWidth />
        </Grid>
      </Grid>
      <ItinMarkdownInput
        source="description"
        title="Description"
        isRequired={false}
        titleVariant="body"
        height="200px"
      />
      <BooleanInput source="required" />
      <BooleanInput
        source="include_in_report"
        helperText="Turn off if you don't want to see this data in your charts, often for email address, names, or anything that's unique to each submission"
      />
      <TextInput source="helper_text" fullWidth />
      <WithWatch watch={"options.multiple"}>
        {(multiple) => (
          <OptionsInputs
            source="options"
            type_source="type"
            fieldProps={{ CustomField }}
            gridItemProps={(config, source) =>
              source === "options.multiple" ? { xs: 12 } : null
            }
            filter={([field]) =>
              !!multiple || !["max_count", "min_count"].includes(field)
            }
          />
        )}
      </WithWatch>
    </SimpleForm>
  );
};

const CustomField = ({ field, source, root_source, children }) => {
  const { setValue, getValues, watch } = useFormContext();
  const currentValue = getValues(source);
  if (field === "correct_answers") {
    const hasCorrectAnswer = watch(
      `${root_source}.hasCorrectAnswer`,
      currentValue !== undefined && currentValue?.length !== 0
    );
    const choices = watch(`${root_source}.choices`);
    return (
      <>
        <BooleanInput
          source={`${root_source}.hasCorrectAnswer`}
          label="Has correct answer"
          defaultValue={!!hasCorrectAnswer}
          onChange={({ target }) => {
            if (!target.checked) setValue(source, null);
          }}
        />
        {hasCorrectAnswer &&
          Children.map(children, (child) => {
            if (child.props.choices) {
              return cloneElement(child, {
                choices: chain(child.props.choices)
                  .map("id")
                  .merge(choices)
                  .uniq()
                  .map((c) => ({ id: c, name: c }))
                  .value(),
              });
            }
            return child;
          })}
      </>
    );
  }
  return children;
};
