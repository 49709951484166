import { fetchUtils } from "react-admin";
import firebase from "firebase/compat/app";
import { GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { FirebaseAuthProvider } from "react-admin-firebase";

const apiUrl = "/api/admin";
const httpClient = fetchUtils.fetchJson;
const firebaseConfig = {
  apiKey: "AIzaSyCjZWCe7FNpAegj204pAMh9xVaMaegRr-g",
  authDomain: "itinerator-app.firebaseapp.com",
  projectId: "itinerator-app",
  storageBucket: "itinerator-app.appspot.com",
  messagingSenderId: "107846325976",
  appId: "1:107846325976:web:e0df9858e1eb3eccbf69ad",
};
firebase.initializeApp(firebaseConfig);
const fbAuthProvider = FirebaseAuthProvider(firebaseConfig);
const providers = {
  google: new GoogleAuthProvider(),
  facebook: new FacebookAuthProvider(),
};

export const authProvider = {
  ...fbAuthProvider,
  login: async (params) => {
    const { email, password, provider, sign_up } = params;
    const isEmail = email && password;
    let authUser;
    if (isEmail) {
      if (sign_up) {
        authUser = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password);
      } else {
        authUser = await firebase
          .auth()
          .signInWithEmailAndPassword(email, password);
      }
    } else {
      authUser = await firebase.auth().signInWithPopup(providers[provider]);
    }
    if (isEmail && authUser.user && !authUser.user.emailVerified) {
      return Promise.reject({
        user: authUser.user,
        message: "emailNotVerfied",
      });
    }
    const token = await authUser.user.getIdToken(true);
    const user = await httpClient(`${apiUrl}/login`, {
      method: "POST",
      withCredentials: true,
      body: JSON.stringify({ user: { token } }),
    }).then(({ json }) => json);
    if (user.auth.status === 200) {
      localStorage.setItem("auth", JSON.stringify(user.auth));
      localStorage.setItem("userProfile", JSON.stringify(user.user));

      return Promise.resolve();
    }
    await fbAuthProvider.logout();
    return Promise.reject({ error: true, message: user.auth.message });
  },
  logout: async () => {
    localStorage.clear();
    return await httpClient(`${apiUrl}/logout`, {
      method: "POST",
      withCredentials: true,
    }).then(() => firebase.auth().signOut());
  },
  checkError: async (error) => {
    const { status } = error;
    if ([401, 403].includes(status)) {
      localStorage.removeItem("auth");
      localStorage.removeItem("userProfile");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    const user = await fbAuthProvider.checkAuth();
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (!auth?.logged_in) {
      return Promise.reject();
    }
    return Promise.resolve(user);
  },
  getPermissions: async (user) => {
    const userProfile = JSON.parse(localStorage.getItem("userProfile"));

    const permissions = await httpClient(
      `${apiUrl}/users/${userProfile.id}/permissions`,
      {
        withCredentials: true,
      }
    ).then(({ json }) => json);
    return Promise.resolve(permissions);

    //localStorage.setItem("permissions", permissions);
  },
  getIdentity: async () => {
    const profile = JSON.parse(localStorage.getItem("userProfile"));
    if (profile.avatar) {
      profile.avatar = profile.avatar.url;
    }
    if (profile) return Promise.resolve(profile);
    return await fbAuthProvider.getIdentity();
  },
};
