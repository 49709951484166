import { Box, Button, Typography } from "@mui/material";
import { IfCanAccess } from "@react-admin/ra-rbac";
import { useRecordContext, useResourceContext } from "react-admin";
import { useCurrentPlatform } from "~/context";
import { useRecordCommunicate } from "~/hooks";
import { GridCard } from "../GridCard";
import { Sparkles } from "../Sparkles";
import { InviteCollaborator } from "./InviteCollaborator";

export const ClaimRecord = (props) => {
  const resource = useResourceContext(props);
  const record = useRecordContext(props);
  const { getTerm } = useCurrentPlatform();
  const resourceTerm = getTerm(resource);
  const { mutate } = useRecordCommunicate("claim", props);

  if (!record || !!record?.owner_id) return null;

  return (
    <IfCanAccess action="claim" fallback={<InviteCollaborator />}>
      <GridCard variant="outlined">
        <Typography variant="h5">
          {`It looks like this ${resourceTerm} might belong to you!`}
        </Typography>
        <p>
          {`In order to claim ownership of this ${resourceTerm}, please click on the claim ${resourceTerm} button, and you will receive an email confirming you have access to this ${resourceTerm}'s e-mail.`}
        </p>
        <Box>
          <Sparkles show={true}>
            <Button variant="contained" onClick={mutate}>
              {`Claim this ${resourceTerm}!`}
            </Button>
          </Sparkles>
        </Box>
      </GridCard>
    </IfCanAccess>
  );
};
