import { Box } from "@mui/material";
import {
  Clock,
  Envelope,
  Globe,
  House,
  Image,
  MapPin,
  Phone,
  FacebookLogo,
  InstagramLogo,
  YoutubeLogo,
  TwitterLogo,
  TiktokLogo,
} from "@phosphor-icons/react";
import { useGetManyReference, useRecordContext } from "react-admin";
import { ReferenceManySummaryIcon, SummaryIcon } from "~/components";
import { useTargetType } from "~/hooks";

const SOCIAL_CATEGORIES = {
  facebook: FacebookLogo,
  instagram: InstagramLogo,
  youtube: YoutubeLogo,
  twitter: TwitterLogo,
  tiktok: TiktokLogo,
};

export const ListingIcons = (props) => {
  const record = useRecordContext(props);
  const target_type = useTargetType();
  const { data: socials = record.socials_attributes || [] } =
    useGetManyReference(
      "socials",
      {
        target: "target_id",
        id: record.id,
        pagination: { page: 1, perPage: null },
        filter: { target_type },
      },
      { enabled: !!record.id }
    );

  return (
    <Box
      sx={{
        display: "grid",
        gap: 2,
        gridTemplateColumns: "repeat(6, 1fr)",
      }}
    >
      <SummaryIcon record={record} Icon={Globe} source="website" />
      {Object.entries(SOCIAL_CATEGORIES).map(([name, Icon]) => {
        const social = socials.find((s) => s.key.toLowerCase() === name);
        return (
          <SummaryIcon
            record={record}
            key={name}
            Icon={Icon}
            source={`${name}_link`}
            value={social?.value}
          />
        );
      })}
      <SummaryIcon
        record={record}
        source="lonlat"
        info="Map location set."
        noInfo="No location set."
        Icon={MapPin}
      />
      <SummaryIcon record={record} Icon={Envelope} source="email" />
      <SummaryIcon record={record} Icon={Phone} source="phone_number" />
      <SummaryIcon record={record} Icon={Image} source="carousel_images" />
      <ReferenceManySummaryIcon
        reference="operating_hours"
        target="target_id"
        filter={{ target_type }}
        Icon={Clock}
      />
      <SummaryIcon record={record} Icon={House} source="street_address" />
    </Box>
  );
};
