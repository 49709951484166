import { ReferenceField, useRecordContext } from "react-admin";
import { declassifyResource } from "~/helpers";

export const TargetReferenceField = ({
  source_reference = "target_type",
  ...props
}) => {
  const record = useRecordContext();
  return (
    <ReferenceField
      source="target_id"
      reference={declassifyResource(record[source_reference])}
      {...props}
    />
  );
};
