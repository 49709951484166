import { TableHead } from "@mui/material";
import { DatagridHeader, DatagridHeaderCell } from "react-admin";
import { ArrowsInLineVertical } from "@phosphor-icons/react";
export const SortableHeader = ({ children, ...props }) => {
  return (
    <DatagridHeader
      // icon={<ArrowsInLineVertical size={32} weight="fill" />}
      {...props}
    >
      <DatagridHeaderCell sx={{ color: "red" }} />
      {children}
    </DatagridHeader>
  );
};
