import { useGetList, useRecordContext } from "react-admin";
import { useTargetType } from "~/hooks";
import { SubscribeButton } from "./SubscribeButton";
import { useCurrentPlatform } from "~/context";
import { Alert } from "@mui/material";

export const SubscriptionOptions = ({
  extension_type = "PremiumContentExtension",
  target_type: custom_type,
  cta = "",
  ...props
}) => {
  const record = useRecordContext(props);
  const target_type = useTargetType(custom_type);
  const { currentPlatform, loading } = useCurrentPlatform();

  const { data: platform_extensions = [], isLoading } = useGetList(
    "platform_extensions",
    {
      filter: {
        type: extension_type,
        options: { target_type },
        platform_id: currentPlatform?.id,
      },
      pagination: { page: 1, perPage: 1 },
    },
    { enabled: !!currentPlatform?.id }
  );
  const platform_extension = platform_extensions[0];

  if (loading || isLoading || !platform_extension) return null;
  if (
    (record && record.subscriptions.includes(extension_type)) ||
    (!record &&
      target_type === "Platform" &&
      currentPlatform.subscriptions.includes(extension_type))
  ) {
    return (
      <Alert severity="success">{`This is a Premium ${target_type}`}</Alert>
    );
  }

  return (
    <Alert
      severity="warning"
      action={
        <SubscribeButton
          target_type={target_type}
          target_id={
            target_type === "Platform" ? currentPlatform.id : record.id
          }
          platform_extension_id={platform_extension.id}
        />
      }
    >
      {cta}
    </Alert>
  );
};
