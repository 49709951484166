import { useJsApiLoader } from "@react-google-maps/api";
import { createContext, useContext } from "react";
import { ItinLoading } from "~/components";
const GoogleMapsLoaderContext = createContext();
const SCRIPT_OPTIONS = {
  id: "google-map-script",
  googleMapsApiKey: import.meta.env.GOOGLE_API_KEY,
  libraries: ["drawing", "places", "visualization"],
};

export const GoogleMapsLoaderProvider = (props) => {
  const { isLoaded } = useJsApiLoader(SCRIPT_OPTIONS);
  if (!isLoaded) return <ItinLoading />;
  return (
    <GoogleMapsLoaderContext.Provider value={{ loaded: isLoaded }} {...props} />
  );
};

export const useGoogleMapsLoader = () => useContext(GoogleMapsLoaderContext);
