import {
  DeleteWithConfirmButton,
  EditButton,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { Stack } from "@mui/material";
import { PencilSimple, Trash } from "@phosphor-icons/react";
import { mergeSx } from "~/helpers";
import { isFunction } from "lodash";
import { IfCanAccess, useCanAccess } from "@react-admin/ra-rbac";

const ButtonStyle = {
  color: "grey.500",
  backgroundColor: "transparent",
  borderColor: "transparent",
  padding: 1,
  "& .MuiButton-startIcon": { margin: 0 },
  "&:hover": {
    color: "white",
  },
  "&:focus": {
    backgroundColor: "transparent",
  },
};

export const EditDeleteButtons = ({
  canDelete,
  canEdit = true,
  buttonProps,
}) => {
  const record = useRecordContext();
  const resource = useResourceContext();

  const handleProps = (type) => {
    if (typeof buttonProps === "function") {
      return buttonProps(record, resource, type);
    }
    return buttonProps;
  };
  const showEdit = isFunction(canEdit)
    ? canEdit(record.id, resource, record)
    : canEdit;
  const { canAccess: canDestroy } = useCanAccess({
    action: "destroy",
    resource,
    record,
  });
  const showDelete =
    canDestroy &&
    (isFunction(canDelete)
      ? canDelete(record.id, resource, record)
      : canDelete);
  return (
    <Stack direction="row" justifyContent="flex-end">
      {showEdit && (
        <IfCanAccess action="update">
          <EditButton
            icon={<PencilSimple />}
            label=""
            onClick={(e) => {
              e.preventDefault();
            }}
            sx={mergeSx(ButtonStyle, {
              "&:hover": {
                backgroundColor: "grey.600",
              },
            })}
            {...handleProps("edit")}
          />
        </IfCanAccess>
      )}
      {showDelete && (
        <IfCanAccess action="destroy">
          <DeleteWithConfirmButton
            redirect={false}
            icon={<Trash />}
            label=""
            sx={mergeSx(ButtonStyle)}
            {...handleProps("delete")}
          />
        </IfCanAccess>
      )}
    </Stack>
  );
};
