import { BulkUpdateButton, BulkUpdateButtonProps } from "react-admin";
import { Palette, useTheme, alpha } from "@mui/material";
import { mergeSx } from "~/helpers";

type Props = BulkUpdateButtonProps & {
  color: keyof Palette;
};

export const ColoredBulkUpdateButton = ({ color, sx, ...props }: Props) => {
  const theme = useTheme();
  const colorVal = theme.palette[color].main;
  return (
    <BulkUpdateButton
      // @ts-expect-error null is a valid value for icon
      icon={null}
      {...props}
      sx={mergeSx(sx, {
        color: colorVal,
        borderColor: colorVal,
        "&:hover": {
          backgroundColor: alpha(colorVal, 0.12),
        },
      })}
    />
  );
};
