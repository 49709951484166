/*global google*/

import { Box } from "@mui/material";
import { GoogleMap, Data as MapsData } from "@react-google-maps/api";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecordContext } from "react-admin";
import { useFormContext } from "react-hook-form";
import { useUpdateEffect } from "usehooks-ts";
import { Data } from "./Data";
import { useMapData } from "./MapDataProvider";
import { Marker } from "./Marker";
import { ToolButtons } from "./ToolButtons";
import {
  convertMultiPolyToPolyFeature,
  dataJsonToMultiPolyString,
  lonLatToPosition,
} from "./helpers";
import { extractLatLngs, usePosition } from "./hooks";
import { brightMap } from "./mapStyle";

export const MapField = (props) => {
  const { dataRef, drawingMode, setDrawingMode } = useMapData();
  const {
    toolButtons,
    targetLonLat,
    targetBounds,
    maximumZoom = 17,
    fallbackCenterLonLat,
  } = props;
  const [mouseOver, setMouseOver] = useState(false);
  const record = useRecordContext();
  const { setValue } = useFormContext();
  const [map, setMap] = useState(null);

  const position = usePosition("lonlat");
  const targetPosition = useMemo(
    () => lonLatToPosition(targetLonLat),
    [targetLonLat]
  );

  const handleMarker = ({ latLng }) => {
    const options = { shouldDirty: true, shouldTouch: true };
    setValue("latitude", latLng.lat(), options);
    setValue("longitude", latLng.lng(), options);
  };

  const onDraw = () => {
    setDrawingMode("Grab");
    dataRef.current.setDrawingMode(null);
  };
  const handleMapClick = (map) => {
    if (drawingMode === "Marker") {
      handleMarker(map);
      setDrawingMode("Grab");
    }
    dataRef.current?.revertStyle();
  };

  const onLoad = useCallback(
    (map) => {
      setMap(map);
      const mapBounds = new window.google.maps.LatLngBounds();
      if (record?.bounds) {
        extractLatLngs(record.bounds).forEach((latlng) => {
          mapBounds.extend(latlng);
        });
      }
      if (position) mapBounds.extend(position);
      if (targetPosition) mapBounds.extend(targetPosition);
      if (mapBounds.isEmpty()) {
        const fallback = fallbackCenterLonLat
          ? lonLatToPosition(fallbackCenterLonLat)
          : {
              lat: 48.43838879995998,
              lng: -123.36993255463291,
            };
        map.setCenter(fallback);
      } else {
        map.fitBounds(mapBounds);
      }
    },
    [record?.bounds, position, targetPosition, fallbackCenterLonLat]
  );

  useEffect(() => {
    if (map) {
      google.maps.event.addListenerOnce(map, "bounds_changed", function () {
        this.setZoom(Math.min(this.getZoom(), maximumZoom));
      });
    }
  }, [map, maximumZoom]);

  return (
    <GoogleMap
      onLoad={onLoad}
      mapContainerStyle={{
        height: "100%",
        width: "100%",
      }}
      zoom={maximumZoom}
      onClick={handleMapClick}
      options={{
        styles: brightMap,
        gestureHandling: "cooperative",
        fullscreenControl: false,
        streetViewControl: false,
      }}
      onMouseOut={() => setMouseOver(false)}
      onMouseOver={() => setMouseOver(true)}
    >
      {position && (
        <Marker
          position={position}
          draggable={toolButtons}
          onDragEnd={handleMarker}
          color="#EA4335"
          zIndex={100}
        />
      )}
      <TargetMarker
        parentPosition={position}
        position={targetPosition}
        handleMarker={handleMarker}
      />
      <Box sx={{ position: "absolute", top: 10, right: 10 }}>
        {toolButtons && <ToolButtons mapMouseOver={mouseOver} />}
      </Box>
      <Data
        targetBounds={targetBounds}
        onDraw={onDraw}
        editable={toolButtons}
      />
      <TargetData targetBounds={targetBounds} toolButtons={toolButtons} />
    </GoogleMap>
  );
};

const TargetData = ({ targetBounds, toolButtons }) => {
  const { watch, setValue } = useFormContext();
  const dataRef = useRef();
  const parentBounds = watch("bounds");

  useUpdateEffect(() => {
    const data = dataRef.current;
    if (data) {
      data.forEach((f) => data.remove(f));
      if (targetBounds) {
        const feature = convertMultiPolyToPolyFeature(targetBounds);
        data.addGeoJson(feature);
      }
    }
  }, [targetBounds]);

  if (!targetBounds || !!parentBounds) return null;
  const feature = convertMultiPolyToPolyFeature(targetBounds);
  const handleDraw = (e) => {
    if (toolButtons) {
      dataRef.current.toGeoJson((json) => {
        const value = dataJsonToMultiPolyString(json);
        if (value !== targetBounds)
          setValue("bounds", value, { shouldDirty: true });
      });
    }
  };

  const handleClick = ({ feature }) => {
    dataRef.current.revertStyle();
    if (toolButtons) {
      dataRef.current.overrideStyle(feature, {
        fillOpacity: 0.75,
        draggable: true,
        editable: true,
      });
    }
  };
  return (
    <MapsData
      onLoad={(data) => {
        dataRef.current = data;
        data.addGeoJson(feature);
      }}
      onSetGeometry={handleDraw}
      onClick={handleClick}
      options={{
        style: { fillColor: "#707070" },
        editable: toolButtons, // Set editable based on toolButtons
        draggable: toolButtons, // Set draggable based on toolButtons
      }}
    />
  );
};

const TargetMarker = ({ parentPosition, handleMarker, position }) => {
  const ref = useRef(null);
  if (!position) return null;
  return (
    <Marker
      ref={ref}
      position={position}
      draggable={!parentPosition}
      onDragEnd={(event) => {
        handleMarker(event);
        ref.current.marker.setPosition(position);
      }}
      color="#707070"
      zIndex={99}
    />
  );
};
