import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Buildings, CaretDown, Checks, Eye } from "@phosphor-icons/react";
import {
  ChipField,
  Loading,
  ReferenceManyField,
  TextField,
  useRecordContext,
  useRedirect,
} from "react-admin";
import AvatarField from "~/components/AvatarField";
import { DrawerDatagrid } from "~/components/drawer";
import { FeaturedField } from "~/components/fields";
import { useCustomQuery } from "~/hooks";
import { grey } from "~/theme/colors";
import { useStopDrawerContext } from "../itineraries/ItineraryEdit";
import { StopForm } from "./StopForm";
import { useCurrentPlatform } from "~/context";

export const StopList = (props) => {
  const { setStopRef } = useStopDrawerContext();
  const { getTerm } = useCurrentPlatform();
  const legTerm = getTerm("leg", { capitalize: true, plural: true });
  const stopTerm = getTerm("stop", { capitalize: true, plural: false });
  const leg = useRecordContext();

  return (
    <ReferenceManyField
      reference="stops"
      target="leg_id"
      perPage={null}
      sortable={true}
    >
      <DrawerDatagrid
        ref={setStopRef}
        buttonLabel={stopTerm}
        Form={<StopForm leg_id={leg.id} itinerary={props.itinerary} />}
        sortable_update_source="order_on_leg"
        sx={{
          "& .RaDatagrid-thead ": {
            display: "table-header-group",
            marginTop: "0px",
            borderTop: "none",
          },
          "& .RaDatagrid-table ": {
            marginTop: "20px",
            borderTop: "none",
          },
        }}
      >
        <TextField source="order_on_leg" label="Order" sortable={false} />
        <AvatarField src="hero">
          <Buildings />
        </AvatarField>
        <TextField source="name" label={stopTerm} sortable={false} />
        <ChipField source="main_type" label="Type" sortable={false} />
        <FeaturedField source="featured" />
      </DrawerDatagrid>
    </ReferenceManyField>
  );
};

export const StopItem = () => {
  const record = useRecordContext();
  const stopCheckIns = useCustomQuery(`stops/${record.id}/user_check_ins`);
  if (!record || !stopCheckIns.data) return <Loading />;

  let checkInNo = 0;
  const checkInDates = [];

  stopCheckIns.data.map((c) => {
    checkInNo++;
    const index = checkInDates.findIndex((i) => {
      return i.id === c.user.id;
    });
    if (index === -1) {
      const date = new Date(c.last).toString();
      checkInDates.push({
        name: c.user.name,
        date: date.toString().slice(0, 21),
        id: c.user.id,
        noOfCheckins: 1,
      });
    } else {
      checkInDates[index].noOfCheckins++;
    }
  });

  if (checkInNo < 1) return null;
  return (
    <>
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<CaretDown size={16} weight="fill" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              paddingRight: "30px",
            }}
          >
            <Typography variant="b4"> Check-ins</Typography>
            <Typography variant="b4" style={{ color: grey[600] }}>
              <Checks weight="fill" style={{ marginRight: "5px" }} />
              {checkInNo}
            </Typography>
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Check ins</TableCell>
                  <TableCell align="left">Last check in</TableCell>
                  <TableCell align="right">View User</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {checkInDates.map((ci) => (
                  <CheckInInfo ci={ci} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const CheckInInfo = (ci) => {
  const { date, id, noOfCheckins, name } = ci.ci;
  const redirect = useRedirect();

  if (name.status === "loading") return <Loading />;
  const handleNav = () => {
    redirect(`edit`, `users`, `${id}`);
  };
  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{noOfCheckins}</TableCell>
      <TableCell>{date}</TableCell>
      <TableCell>
        <Box
          onClick={() => handleNav()}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            "&:hover": { cursor: "pointer" },
            color: "grey.500",
          }}
        >
          <Eye />
        </Box>
      </TableCell>
    </TableRow>
  );
};
