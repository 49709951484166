import { Box, CardMedia } from "@mui/material";
import { useRecordContext } from "react-admin";
import { useRandomIllustration } from "~/images/illustrations";
import { ItineraryIcons } from "./Icons";

export const ItineraryHero = () => {
  const Illustration = useRandomIllustration();
  const record = useRecordContext();
  let imageComponent = "img";
  if (!record.hero?.url) imageComponent = Illustration;

  return (
    <>
      <Box display="flex">
        <Box
          sx={{
            width: "100%",
            height: "140px",
          }}
        >
          <CardMedia
            component={imageComponent}
            alt="Need Fallback"
            image={record.hero?.url}
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />
        </Box>
        <Box
          sx={{
            marginLeft: "12px",
            width: "50%",
            height: "120px",
            borderRadius: "12px",
            padding: "10px",
          }}
        >
          <ItineraryIcons />
        </Box>
      </Box>
    </>
  );
};
