import { Datagrid, NumberField, TextField } from "react-admin";
import { ToggleList } from "~/components/lists";
export const PlatformLevelList = () => {
  return (
    <ToggleList modes={MODES} sort={{ field: "threshold", order: "ASC" }} />
  );
};

const PlatformLevelData = () => {
  return (
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" />
      <NumberField source="threshold" />
      <NumberField
        label="Completed"
        source="completion_count"
        sortable={false}
      />
    </Datagrid>
  );
};

const MODES = [{ name: "List", view: <PlatformLevelData /> }];
