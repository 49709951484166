import { Divider } from "@mui/material";
import { MenuItemLink, useLogout } from "react-admin";
import { Settings, DoorFrontOutlined } from "@mui/icons-material";
import { forwardRef } from "react";
import { useProfileActions } from "~/context";
import { useUserMenuContext } from "./UserMenuContext";

export const UserMenuItems = forwardRef((props, ref) => {
  const { toggleDrawer } = useProfileActions();
  const logout = useLogout();
  const { onClose } = useUserMenuContext();
  return (
    <>
      <MenuItemLink
        to="#"
        ref={ref}
        primaryText="My Profile"
        leftIcon={<Settings sx={{ color: "grey.400" }} />}
        onClick={() => {
          toggleDrawer();
          onClose();
        }}
      />
      <Divider sx={{ mt: 0, mb: 0 }} />
      <MenuItemLink
        to="#"
        ref={ref}
        primaryText="Logout"
        leftIcon={<DoorFrontOutlined sx={{ color: "grey.400" }} />}
        onClick={logout}
      />
    </>
  );
});
