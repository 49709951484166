import { Dispatch, SetStateAction, useRef } from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import { max, round } from "lodash";
import { Box } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useCurrentPlatform } from "~/context";
import { yellow, blue, grey } from "~/theme/colors";
import { ChartData, SliderComponent } from "../shared_components";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

ChartJS.register(...registerables);
ChartJS.register(annotationPlugin);

const GADateLine = (datestring: string, period: string | undefined) => {
  switch (period) {
    case "month":
      return dayjs(datestring).format("MMM YYYY");
    case "week":
      return dayjs(datestring).day(1).format("MMM DD, YYYY");
    default:
      return dayjs(datestring).format("MMM DD, YYYY");
  }
};

type CurrentPlatformType = {
  active: boolean;
  app_package_name: string;
  app_start_date: string;
  ga4_start_date: string;
  gua_start_date: string;
  id: number;
  name: string;
};

const options = (
  displayLegend: boolean | undefined,
  reverseToolTip: boolean | undefined,
  isAreaChart: boolean | undefined,
  platform: CurrentPlatformType,
  ymax: number | undefined,
  period: string | undefined,
  startDate?: Dayjs,
  endDate?: Dayjs,
  displayGuaLine?: boolean
) => {
  const hasBothGA =
    platform && platform.gua_start_date && platform.ga4_start_date;
  const GaSwitchDate = GADateLine(platform.ga4_start_date, period);
  const SwitchDateInRange =
    startDate &&
    endDate &&
    dayjs(platform.ga4_start_date).isBetween(startDate, endDate);
  return {
    interaction: {
      intersect: false,
      mode: "index" as const,
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: displayLegend,
        position: "bottom" as const,
        reverse: true,
      },
      title: {
        display: false,
        text: "",
      },
      tooltip: {
        position: "nearest" as const,
        displayColors: true,
        multiKeyBackground: isAreaChart ? "#fff" : "transparent",
        reverse: reverseToolTip,
        callbacks: {
          labelColor: function (context: any) {
            const rgb =
              context.dataset.borderColor.split(",").slice(0, 3).join() +
              ", 1)";
            const rgba =
              context.dataset.borderColor.split(",").slice(0, 3).join() +
              ", 0.5)";
            return {
              backgroundColor: isAreaChart ? rgba : rgb,
              borderWidth: 2,
              borderRadius: 2,
              borderColor: isAreaChart ? rgb : "transparent",
            };
          },
          label: function (context: any) {
            return ": " + round(context.raw, 3);
          },
        },
      },
      annotation: {
        annotations: {
          line1: {
            type: "line",
            z: 0,
            xMin: GaSwitchDate,
            xMax: GaSwitchDate,
            ymin: 0,
            yMax: (ymax || 0) * 0.97,
            display: false || (hasBothGA && SwitchDateInRange),
            borderColor: displayGuaLine ? grey[300] : "transparent",
            borderWidth: 1.5,
            borderDash: [3],
          },
          label1: {
            type: "label",
            xValue: GaSwitchDate,
            yValue: (ymax || 0) * 0.95,
            backgroundColor: "transparent",
            content: ["GUA     GA4"], // leave whitespace characters!
            display: false || (hasBothGA && SwitchDateInRange),
            color: displayGuaLine ? grey[400] : "transparent",
            font: {
              size: 10,
            },
          },
        },
      },
    },
  };
};

type StackedAreaChartProps = {
  formattedData: ChartData;
  hasSlider?: boolean;
  period?: string;
  earliestDate?: Dayjs;
  latestDate?: Dayjs;
  startDate?: Dayjs;
  setStartDate?: Dispatch<SetStateAction<Dayjs>>;
  endDate?: Dayjs;
  setEndDate?: Dispatch<SetStateAction<Dayjs>>;
  stackDisabled?: boolean;
  displayLegend?: boolean | undefined;
  reverseToolTip?: boolean | undefined;
  isAreaChart?: boolean | undefined;
  displayGuaLine?: boolean | undefined;
};

export const StackedAreaChart = ({
  formattedData,
  hasSlider = false,
  period,
  earliestDate,
  latestDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  stackDisabled = false,
  displayLegend = true,
  reverseToolTip = true,
  isAreaChart = true,
  displayGuaLine = false,
}: StackedAreaChartProps) => {
  const { loading, currentPlatform } = useCurrentPlatform();
  if (loading) return null;
  const stacked = !stackDisabled;
  ChartJS.defaults.scale.grid.display = false;
  ChartJS.defaults.scale.ticks.display = false;
  ChartJS.defaults.scales.linear.stacked = stacked;
  ChartJS.defaults.scale.display = false;

  ChartJS.register({
    id: "hoverBar",
    afterDraw: function (chart: any, easing: any) {
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const activePoint = chart.tooltip._active[0];
        const ctx = chart.ctx;
        const x = activePoint.element.x;
        const topY = chart.scales.y.top;
        const bottomY = chart.scales.y.bottom;
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 2;
        ctx.strokeStyle = yellow[400];
        ctx.stroke();
        ctx.restore();
      }
    },
  });

  const chartRef = useRef();
  // @ts-ignore // for "legend does not exist on type never" error but works anyway
  const hiddenColumns = chartRef.current?.legend?.legendItems
    .map((li: any) => {
      return { label: li.text, hidden: li.hidden };
    })
    .filter((item: any) => item.hidden === true)
    .map((item: any) => item.label);

  const ymax = max(
    formattedData.datasets
      .filter((df) => !hiddenColumns?.includes(df.label))
      .map((df) => df.data)
      .flat()
  );
  const chart_options = options(
    displayLegend,
    reverseToolTip,
    isAreaChart,
    currentPlatform as unknown as CurrentPlatformType,
    // @ts-ignore // for "scales does not exist on type never" error
    ymax,
    period,
    startDate,
    endDate,
    displayGuaLine
  );
  return (
    <>
      <Box
        sx={{ width: "100%", height: "70%" }}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap={1}
      >
        {
          /* @ts-ignore */ // a bug exists in "options" with the annotations plugin
          // remove the @ts-ignore comment to debug
          <Line options={chart_options} data={formattedData} ref={chartRef} />
        }
        {hasSlider && (
          <SliderComponent
            period={period!}
            earliestDate={earliestDate!}
            latestDate={latestDate!}
            startDate={startDate!}
            setStartDate={setStartDate!}
            endDate={endDate!}
            setEndDate={setEndDate!}
          />
        )}
      </Box>
    </>
  );
};