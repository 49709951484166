import { Grid } from "@mui/material";
import { Button, ReferenceField, TextField } from "react-admin";
import { useWatch } from "react-hook-form";
import { titleize } from "~/helpers";
import { useSendTestMessage } from "~/hooks";

export const SendTestButtons = () => {
  const [send_email, send_push_notification, send_sms, from_email_source] =
    useWatch({
      name: [
        "send_email",
        "send_push_notification",
        "send_sms",
        "from_email_source",
      ],
    });
  return (
    <Grid container>
      {send_push_notification && (
        <Grid item xs={12}>
          <SendTestButton message_type={"push_notification"} />
        </Grid>
      )}
      {send_email && (
        <Grid item xs={12}>
          E-mail will send From:
          {from_email_source == "owner" && (
            <ReferenceField source="owner_id" reference="users" link={false}>
              <TextField source="email" />
            </ReferenceField>
          )}
          {from_email_source == "platform_contact" && (
            <ReferenceField
              source="platform_id"
              reference="platforms"
              link={false}
            >
              <TextField source="contact_email" />
            </ReferenceField>
          )}
          {from_email_source == "noreply" && (
            <ReferenceField
              source="platform_id"
              reference="platforms"
              link={false}
            >
              <TextField source="noreply_email" />
            </ReferenceField>
          )}
          <SendTestButton message_type={"email"} />
        </Grid>
      )}
      {send_sms && (
        <Grid item xs={12}>
          <SendTestButton message_type={"sms"} />
          SMS will send to:
          <ReferenceField source="owner_id" reference="users" link={false}>
            <TextField source="phone_number" />
          </ReferenceField>
        </Grid>
      )}
    </Grid>
  );
};
const SendTestButton = ({ message_type, ...props }) => {
  const [sendTestMessage] = useSendTestMessage();
  return (
    <Button
      onClick={() => {
        sendTestMessage(message_type);
      }}
      label={`Send Test ${titleize(message_type)}`}
      fullWidth
      {...props}
    />
  );
};
