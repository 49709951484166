import { ItinResourceProps } from "../types";
import { LogList } from "./LogList";
import { TrendUp } from "@phosphor-icons/react";

export const LogResource: ItinResourceProps = {
  name: "logs",
  list: LogList,
  icon: <TrendUp />,
  recordRepresentation: (r) => `${r.category} : ${r.name}`,
  options: { menu_group: "admin" },
};
