import { Link, Stack } from "@mui/material";
import { Envelope, Globe, Ticket } from "@phosphor-icons/react";
import {
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TextField,
  useRecordContext,
} from "react-admin";
import { declassifyResource } from "~/helpers/declassifyResource";
import { EventTimeSnippet } from "./EventTimeSnippet";
import { EventLocationChip } from "./EventLocationChip";

export const IconLink = ({ href, ...rest }) => {
  if (!href) return null;
  return <Link target="_blank" href={href} color="grey.500" {...rest} />;
};
export const eventCardData = (record) => {
  return {
    title: record.name,
    subtitles: [<EventCardComponent />],
  };
};
export const EventCardComponent = () => {
  const record = useRecordContext();
  const reference = declassifyResource(record.target_type);

  return (
    <Stack spacing={1}>
      <EventTimeSnippet />
      <ReferenceField source="target_id" reference={reference} link={false}>
        <TextField
          source="name"
          fontWeight="bold"
          color="grey.600"
          variant="body1"
        />
      </ReferenceField>
      <ReferenceArrayField
        source="location_ids"
        reference="locations"
        label="Locations"
      >
        <SingleFieldList linkType={false}>
          <EventLocationChip />
        </SingleFieldList>
      </ReferenceArrayField>
      <Stack direction="row" alignItems={"center"} spacing={2}>
        <IconLink href={record.email && `mailto:${record.email}`}>
          <Envelope />
        </IconLink>
        <IconLink href={record.ticket_url}>
          <Ticket />
        </IconLink>
        <IconLink href={record.website}>
          <Globe />
        </IconLink>
      </Stack>
    </Stack>
  );
};
