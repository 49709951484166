import {
  BlockNoteSchema,
  defaultBlockSpecs,
  filterSuggestionItems,
  insertOrUpdateBlock,
} from "@blocknote/core";
import "@mantine/core/styles.css";
import "@blocknote/core/fonts/inter.css";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import {
  FormattingToolbarController,
  getDefaultReactSlashMenuItems,
  SuggestionMenuController,
  useCreateBlockNote,
} from "@blocknote/react";
import { Box } from "@mui/material";
import { Building, Calendar, CursorClick } from "@phosphor-icons/react";
import { get, isEmpty } from "lodash";
import { useRecordContext } from "react-admin";
import { useFormContext } from "react-hook-form";
import { BlockNoteButton } from "./BlockNoteButton";
import { BlockNoteImage, insertBlockNoteImage } from "./BlockNoteImage";
import { BlockNoteUpcomingEvents } from "./BlockNoteUpcomingEvents";
import { FormattingToolbar } from "./FormattingToolbar";
import { ListingGridBlock } from "./ListingsGridBlock";
import { BlockNoteSocials, insertBlockNoteSocials } from "./BlockNotesSocials";
import { BlockNoteEmbed, insertBlockNoteEmbed } from "./BlockNoteEmbed";

export const blockSpecs = {
  ...defaultBlockSpecs,
  listingGrid: ListingGridBlock,
  button: BlockNoteButton,
  upcomingEvents: BlockNoteUpcomingEvents,
  socials: BlockNoteSocials,
  image: BlockNoteImage,
  embed: BlockNoteEmbed,
};
export const block_note_schema = BlockNoteSchema.create({
  blockSpecs,
});

export type BlockSchema = typeof block_note_schema.blockSchema;
export type BlockEditorSchema = typeof block_note_schema.BlockNoteEditor;

const insertUpcomingEvents = (editor: BlockEditorSchema) => ({
  title: "Upcoming Events",
  onItemClick: () => {
    insertOrUpdateBlock(editor, { type: "upcomingEvents" });
  },
  group: "Itinerator",
  icon: <Calendar />,
});

const insertListingGrid = (editor: BlockEditorSchema) => ({
  title: "Listing Grid",
  onItemClick: () => {
    insertOrUpdateBlock(editor, { type: "listingGrid" });
  },
  group: "Itinerator",
  icon: <Building />,
});

const insertButton = (editor: BlockEditorSchema) => ({
  title: "Button",
  onItemClick: () => {
    insertOrUpdateBlock(editor, { type: "button" });
  },
  group: "Basic blocks",
  icon: <CursorClick />,
});

type Props = {
  source: string;
  record: any;
  html_source: string;
};

export const BlockInput = ({ source, html_source }: Props) => {
  const record = useRecordContext();
  const value = get(record, source.split(".").pop()!);
  const editor = useCreateBlockNote({
    schema: block_note_schema,
    initialContent: isEmpty(value) ? undefined : value,
  });
  const { setValue } = useFormContext();

  return (
    <Box
      sx={{
        "& .bn-container": {
          display: "flex",
          flexDirection: "column-reverse",
          gap: "8px",
        },

        // "& .bn-formatting-toolbar": {
        //   marginInline: "auto",
        //   position: "sticky",
        //   top: "0px",
        // },
      }}
    >
      <BlockNoteView
        editor={editor}
        theme="light"
        slashMenu={false}
        onChange={async () => {
          // Set the value of the document directly
          setValue(source, editor.document, {
            shouldDirty: true,
            shouldTouch: true,
          });

          // Wait for the promise to resolve and get the HTML content
          try {
            const htmlContent = await editor.blocksToHTMLLossy();

            // Set the value with the resolved HTML content
            setValue(html_source, htmlContent, {
              shouldDirty: true,
              shouldTouch: true,
            });
          } catch (error) {
            console.error("Error fetching HTML content:", error);
          }
        }}
        formattingToolbar={false}
        // sideMenu={false}
      >
        <SuggestionMenuController
          triggerCharacter="/"
          getItems={(query) => getSuggestionItems(editor, query)}
        />
        <FormattingToolbarController
          formattingToolbar={() => <FormattingToolbar />}
        />

        {/* <SideMenuController
          sideMenu={(props) => (
            <SideMenu
              {...props}
              dragHandleMenu={({ block }) => {
                return (
                  <DragHandleMenu {...props}>
                    <RemoveBlockItem {...props}>Delete</RemoveBlockItem>
                    <BlockColorsItem {...props}>Colors</BlockColorsItem>
                  </DragHandleMenu>
                );
              }}
            />
          )}
        /> */}
      </BlockNoteView>
    </Box>
  );
};

// Normally suggestions are populated in the order they are defined, even if they have the same group name.
// This function will insert custom blocks in the group they belong with.
const getSuggestionItems = async (editor: BlockEditorSchema, query: string) => {
  const items = getDefaultReactSlashMenuItems(editor);
  const custom_items = [
    insertListingGrid(editor),
    insertButton(editor),
    insertUpcomingEvents(editor),
    insertBlockNoteSocials(editor),
    insertBlockNoteImage(editor),
    insertBlockNoteEmbed(editor),
  ];
  custom_items.forEach((item) => {
    const index = items.findLastIndex((i) => i.group === item.group);
    const final_index = index === -1 ? items.length - 1 : index + 1;
    items.splice(final_index, 0, item);
  });
  return filterSuggestionItems(items, query);
};
