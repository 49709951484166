import {
  ReferenceManyField,
  required,
  SelectInput,
  TextField,
  SimpleForm,
  TextInput,
  UrlField,
  useEditContext,
} from "react-admin";
import { Stack } from "@mui/material";
import { useTargetType } from "../hooks";
import { startCase } from "lodash";
import { DrawerDatagrid } from "./drawer";

const SOCIAL_OPTIONS = [
  "Facebook",
  "Instagram",
  "Tastely",
  "Tiktok",
  "Twitter",
  "Youtube",
  "Website",
].map((id) => ({ id, name: startCase(id) }));

export const SocialsList = () => {
  const target_type = useTargetType();
  const { record } = useEditContext();

  return (
    <ReferenceManyField
      reference="socials"
      target="target_id"
      filter={{ target_type }}
    >
      <DrawerDatagrid
        buttonLabel={"Socials"}
        Form={<SocialForm target_type={target_type} record={record} />}
        bulkActionButtons={false}
      >
        <TextField source="key" label="Type" textTransform="capitalize" />
        <UrlField source="value" label="Url" />
      </DrawerDatagrid>
    </ReferenceManyField>
  );
};

const SocialForm = ({ target_type, record, ...props }) => {
  const id = record?.id;
  const input_props = {
    validate: required(),
    fullWidth: true,
  };
  return (
    <SimpleForm defaultValues={{ target_type, target_id: id }} {...props}>
      <Stack direction="row" width="100%" gap={3}>
        <SelectInput
          source="key"
          label="Type"
          choices={SOCIAL_OPTIONS}
          {...input_props}
          fullWidth
        />
        <TextInput source="value" label="Url" {...input_props} fullWidth />
      </Stack>
    </SimpleForm>
  );
};
