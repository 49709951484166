import { Grid, Typography } from "@mui/material";
import { Create, SimpleForm, TextInput, required } from "react-admin";
import { StyledToolBar } from "~/components";
import { ItinLoading } from "~/components/ItinLoading";
import { QRCodeGen } from "~/components/maps/QRPseudoCode";
import { useCurrentPlatform } from "../../context";

export const QRCodeCreate = () => {
  const { loading, currentPlatform } = useCurrentPlatform();

  if (loading) return <ItinLoading />;
  return (
    <Create redirect="edit">
      <SimpleForm
        variant="standard"
        defaultValues={{
          platform_id: currentPlatform.id,
          description: "",
          target_type: "Platform",
          target_id: currentPlatform.id,
        }}
        toolbar={<StyledToolBar />}
      >
        <Typography variant="h4" sx={{ mb: 2 }}>
          Create QR Code
        </Typography>
        <Grid container columnSpacing={3} marginBottom={3}>
          <Grid item xs={12} md={6}>
            <TextInput
              label="Scannable URL"
              source="url"
              validate={required()}
              fullWidth
            />
            <TextInput source="description" fullWidth />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
