import dayjs from "dayjs";
import { RRule } from "rrule";

export const showDate = (date) => {
  if (!date) return "";
  return dayjs(date).format("ddd, MMM D, YYYY");
};

export const showDateTime = (date) => {
  if (!date) return "";
  return dayjs(date).format("ddd MMM D, YYYY h:mm a");
};

export const showTime = (date) => {
  if (!date) return "";
  return dayjs(date).format("h:mm a");
};

export const showDiff = (first_date, last_date) => {
  if (!first_date || !last_date) return "";
  const date1 = dayjs(first_date);
  const date2 = dayjs(last_date);
  const hours = date2.diff(date1, "h", true);
  if (hours > 24) {
    return `${date2.diff(date1, "d")} days`;
  } else {
    return `${hours} hours`;
  }
};

export const showRecurringRules = (rrule) => {
  if (rrule == null) return "";
  const rule = new RRule.fromString(rrule);
  return rule.toText();
};
