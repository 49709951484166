import { Create, SimpleForm, TextInput } from "react-admin";
import { useCurrentPlatform } from "~/context";
import { validateUrl } from "~/hooks";

export const IngestionCreate = () => {
  const { currentPlatform } = useCurrentPlatform();
  return (
    <Create redirect="edit">
      <SimpleForm
        width={"50%"}
        defaultValues={{ platform_id: currentPlatform.id }}
      >
        <TextInput source="url" fullWidth validate={validateUrl} />
      </SimpleForm>
    </Create>
  );
};
