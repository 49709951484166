import { Datagrid, List, TextField, useRecordContext } from "react-admin";
import { StatsSummaryField, TaggingsField } from "~/components/fields";

export const NewsletterList = (props) => {
  const record = useRecordContext();
  return (
    <List resource="newsletters" hasCreate={true}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="slug" label="Slug" />
        <TextField source="name" label="Name" />
        <TextField source="description" label="Name" />
        <TaggingsField source="taggings" label="Tags" sortable={false} />
        <TextField
          source="confirmed_subscriber_count"
          label="Confirmed Subscribers"
        />
        <StatsSummaryField label="30 Days" />
      </Datagrid>
    </List>
  );
};
