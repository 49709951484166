import { Polyline as GMPolyline } from "@react-google-maps/api";
import { useRecordContext } from "react-admin";
import { useHandleClick } from "./helpers";
import { get } from "lodash";

export const Polyline = ({
  polyline: encoded_polyline,
  options,
  isActive,
  source = "polyline",
  ...props
}) => {
  const record = useRecordContext();
  const record_polyline = get(record, source);
  const handleClick = useHandleClick(props);
  const polyline = encoded_polyline || record_polyline;
  if (!polyline) return null;
  return (
    <GMPolyline
      path={google.maps.geometry?.encoding.decodePath(polyline)}
      options={{
        strokeColor: isActive ? "#283593" : "#707070",
        strokeOpacity: 1,
        strokeWeight: 6,
        draggable: false,
        editable: false,
        visible: true,
        radius: 30000,
        zIndex: isActive ? 2 : 1,
        ...options,
      }}
      {...props}
      onClick={handleClick}
    />
  );
};
