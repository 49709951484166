import createDataContext from "./createDataContext";

// Profile Reducer Context and Provider
const profileReducer = (state, action) => {
  switch (action.type) {
    case "SET_PROFILE_VERSION":
      return {
        ...state,
        profileVersion: state.profileVersion + 1,
      };
    case "TOGGLE_DRAWER":
      return {
        ...state,
        drawerOpen: !state.drawerOpen,
      };
    default:
      return state;
  }
};

const refreshProfile = (dispatch) => (profile) => {
  dispatch({ type: "SET_PROFILE_VERSION", payload: profile });
};
const toggleDrawer = (dispatch) => () => {
  dispatch({ type: "TOGGLE_DRAWER" });
};

export const {
  Provider: ProfileProvider,
  StateContext: ProfileState,
  ActionsContext: ProfileActions,
  useStateContext: useProfileState,
  useActionsContext: useProfileActions,
} = createDataContext(
  profileReducer,
  { refreshProfile, toggleDrawer },
  { profileVersion: 0, drawerOpen: false }
);
