import { canAccess } from "@react-admin/ra-rbac";
import { isFunction, startCase } from "lodash";
import {
  cloneElement,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  CreateButton,
  Datagrid,
  useListContext,
  usePermissions,
  useResourceContext,
} from "react-admin";
import { Empty } from "~/components/Empty";
import { EditDeleteButtons } from "~/components/buttons";
import {
  SortContext,
  SortableBody,
  SortableHeader,
} from "~/components/sortable";
import { classifyResource, mergeSx } from "~/helpers";
import { DrawerForm, DrawerFormProvider } from "./DrawerForm";

export const DrawerDatagrid = forwardRef(
  (
    {
      canDelete = true,
      canEdit = true,
      canCreate = true,
      bulkActionButtons = false,
      buttonLabel,
      label,
      children,
      datagridProps,
      sortable_update_source,
      sx,
      CustomCreateButton,
      buttonProps,
      rowClick = true,
      hover = rowClick == true ? true : false,
      ...props
    },
    ref
  ) => {
    const [openId, setOpenId] = useState();
    const [open, setOpen] = useState(false);
    const resource = useResourceContext();
    const { total } = useListContext();
    const isEmpty = !total;
    const { permissions } = usePermissions();

    const onClose = useCallback((e, reason) => {
      e?.preventDefault();
      if (reason === "backdropClick") return;
      setOpen(false);
      setOpenId();
    }, []);
    useEffect(() => {
      if (!!openId) setOpen(true);
    }, [openId]);
    const handleOpen = useCallback((id) => {
      setOpenId(id);
      setOpen(true);
    }, []);
    useEffect(() => {
      if (ref) {
        if (isFunction(ref)) {
          ref({ handleOpen, onClose });
        } else {
          ref.current = { handleOpen, onClose };
        }
      }
    }, [ref, handleOpen, onClose]);
    const resourceName = startCase(classifyResource(resource));
    const handleCanEdit = (id, resource, record) => {
      const canUpdate = canAccess({
        permissions,
        action: "update",
        resource,
        record,
      });
      return (
        canUpdate &&
        (isFunction(canEdit) ? canEdit(id, resource, record) : canEdit)
      );
    };
    return (
      <>
        <SortContext update_source={sortable_update_source}>
          <Datagrid
            empty={
              <Empty
                title={buttonLabel ? `No ${buttonLabel} Yet` : undefined}
                onClick={() => setOpenId("create")}
              />
            }
            hover={hover}
            bulkActionButtons={bulkActionButtons}
            rowClick={(id, resource, record) =>
              rowClick &&
              handleCanEdit(id, resource, record) &&
              handleOpen(id, resource, record)
            }
            {...datagridProps}
            body={
              sortable_update_source ? (
                <SortableBody update_source={sortable_update_source} />
              ) : undefined
            }
            header={sortable_update_source ? <SortableHeader /> : undefined}
            sx={mergeSx(DATAGRID_SX, sx)}
          >
            {children}
            <EditDeleteButtons
              canDelete={canDelete}
              canEdit={canEdit}
              buttonProps={buttonProps}
            />
          </Datagrid>
        </SortContext>

        {canCreate && !isEmpty && !CustomCreateButton && (
          <CreateButton
            onClick={(e) => {
              e.preventDefault();
              setOpenId("create");
            }}
            label={`Add New ${buttonLabel || resourceName}`}
            alignIcon="right"
            sx={{ marginBottom: "32px" }}
          />
        )}
        {!!CustomCreateButton &&
          cloneElement(CustomCreateButton, {
            onCreateClick: () => setOpenId("create"),
          })}
        <DrawerFormProvider value={{ open, openId, setOpenId, onClose }}>
          <DrawerForm {...props} />
        </DrawerFormProvider>
      </>
    );
  }
);

const DATAGRID_SX = {
  "& .RaDatagrid-table": {
    borderRadius: 0,
    "& .RaDatagrid-thead": {
      height: "40px",
    },
    "& .RaDatagrid-tbody": {
      "& .MuiTableCell-root": {
        paddingX: 0.75,
      },
      "& .RaDatagrid-expandable": {
        border: "1px solid ",
        borderColor: "grey.200",
      },

      "& .RaDatagrid-expandedPanel": {
        border: "1px solid ",
        borderColor: "grey.200",
        padding: "100px",

        "& > .MuiTableCell-root": {
          padding: 0,
          "& .RaDatagrid-root": {
            marginBottom: "32px",
            "& .RaDatagrid-tableWrapper": {
              padding: "0px",
              "& .RaDatagrid-headerCell": {
                paddingLeft: "3px",
              },
            },
          },
        },
      },
    },
  },
};
