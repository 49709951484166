import { Cookie } from "@phosphor-icons/react";
import { ItinResourceProps } from "../types";
import { IngestionList } from "./IngestionList";
import { IngestionEdit } from "./IngestionEdit";
import { IngestionCreate } from "./IngestionCreate";
export const IngestionResource: ItinResourceProps = {
  name: "ingestions",
  list: <IngestionList />,
  edit: <IngestionEdit />,
  create: <IngestionCreate />,
  icon: <Cookie />,
  recordRepresentation: (r) => r.name,
  options: { label: "Ingestions", menu_group: "admin" },
};

export * from "./IngestionProvider";
export * from "./IngestionImageSelect";
