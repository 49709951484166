import { Create } from "react-admin";
import { FormForm } from "./FormForm";

export const FormCreate = () => {
  return (
    <Create>
      <FormForm />
    </Create>
  );
};
