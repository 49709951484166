import {
  BooleanField,
  Datagrid,
  List,
  ReferenceManyField,
  SimpleList,
  TextField,
  useEditContext,
} from "react-admin";
import { EnumField } from "~/components/fields";
import { EnumChoiceInput } from "~/components/inputs";
import { classifyResource } from "~/helpers";

const filters = [
  <EnumChoiceInput
    label="Class Type"
    source="class_type"
    emptyText="Class Type"
    isRequired={false}
    SelectProps={{ displayEmpty: true, notched: false }}
  />,
];

export const ContentTypeList = (props) => {
  const { resource, record } = useEditContext();
  return (
    <List
      resource="content_types"
      filter={
        record && {
          target_id: record.id,
          target_type: classifyResource(resource),
        }
      }
      filters={filters}
    >
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField source="class_type" label="Record Type" />
        <TextField source="name" label="Name" />
        <EnumField label="Type" source="simple_type_name" sortBy="type" />
        <ReferenceManyField
          label="Publish Endpoints"
          reference="content_type_hooks"
          target="content_type_id"
        >
          <SimpleList
            linkType={false}
            primaryText={(record) => record.name}
            tertiaryText={(record) => record.display_status}
          />
        </ReferenceManyField>
        <BooleanField source="use_title" />
        <BooleanField source="auto_create" />
      </Datagrid>
    </List>
  );
};
