import DefaultSparkles from "@chad.b.morrow/sparkles";
import { forwardRef } from "react";
import { Box } from "@mui/material";

export const Sparkles = ({ show, style, ...props }) => {
  if (!show) return props.children || null;
  return (
    <DefaultSparkles
      colors={["#F7A7EA", "#F7DF96", "#B3B3FD"]}
      minSize={12}
      maxSize={32}
      rate={50}
      isToggleable={false}
      variance={100}
      as={SparkleWrapper}
      {...props}
    />
  );
};

const SparkleWrapper = forwardRef(({ asElement, sx, ...props }, ref) => {
  return (
    <Box
      ref={ref}
      sx={[{ "& > span": { pointerEvents: "none" } }, sx]}
      {...props}
    />
  );
});
