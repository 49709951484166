import { useRecordContext } from "react-admin";
import { RegionInput } from "~/components/inputs/RegionInput";
import { useCurrentPlatform } from "~/context";

export const ParentRegionInput = (props) => {
  const record = useRecordContext();
  const { getTerm } = useCurrentPlatform();
  return (
    <RegionInput
      label={`Parent ${getTerm("region", { capitalize: true })}`}
      helperText={`Leave blank for no parent ${getTerm("region")}`}
      fullWidth
      isRequired={false}
      {...props}
      getOptionDisabled={(option) => option.id === record?.id}
    />
  );
};
