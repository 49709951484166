import {
  ArrowSquareOut,
  BellRinging,
  Buildings,
  Calendar,
  CheckSquare,
  ClockUser,
  CurrencyCircleDollar,
  DeviceMobile,
  EnvelopeSimpleOpen,
  Eye,
  HandTap,
  IconProps,
  Image,
  Log,
  MapPin,
  MapTrifold,
  Newspaper,
  PaperPlaneTilt,
  Pulse,
  ShootingStar,
  Star,
  StarHalf,
  Tag,
  Trophy,
  User,
  UserGear,
  UserMinus,
} from "@phosphor-icons/react";
import { UserCheck } from "@phosphor-icons/react/dist/ssr";
import { ForwardRefExoticComponent } from "react";

type IconRecord = Record<
  string,
  ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>
>;
export const DEFAULT_STATS_ICONS: IconRecord = {
  listings: Buildings,
  users: User,
  newsletters: Newspaper,
  tags: Tag,
  collaborators: UserGear,
  rewards: Trophy,
  star: Star,
  redemption: StarHalf,
  top: ShootingStar,
  tasks: CheckSquare,
  events: Calendar,
  itineraries: MapTrifold,
  clicks: ArrowSquareOut,
  views: Eye,
  currency: CurrencyCircleDollar,
  location: MapPin,
  device: DeviceMobile,
  notifications: BellRinging,
  media: Image,
  sent: PaperPlaneTilt,
  opened: EnvelopeSimpleOpen,
  log: Log,
  user_activity: HandTap,
  admin_activity: Pulse,
  subscribed: UserCheck,
  pending_confirmation: ClockUser,
  opted_out: UserMinus,
};
