import { ChangeEvent, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import _ from "lodash";
import { AppRatingsType } from "../helpers/types";
import { green } from "../../../theme/colors";
import { AppInstallsColumn } from "../shared_components";

const ratingColors: { [name: string]: string } = {
  "1.0": green[50],
  "2.0": green[100],
  "3.0": green[200],
  "4.0": green[300],
  "5.0": green[400],
};

const rowColorHelper = (rating: string) => {
  return ratingColors[rating];
};

// For more table styling: `https://mui.com/material-ui/react-table/#customization`,
// see use of Columns[] under StickyHeader.
const columns: readonly AppInstallsColumn[] = [
  { id: "date", label: "Date", minWidth: 100 },
  { id: "rating", label: "Rating", minWidth: 40 },
  {
    id: "review_text",
    label: "Review Text",
    minWidth: 300,
    format: (value: string) =>
      value === "" ? (
        <Typography variant="body2" sx={{ fontStyle: "italic" }}>
          Rating only, no review.
        </Typography>
      ) : (
        value
      ),
  },
  {
    id: "store",
    label: "Store",
    minWidth: 100,
    format: (value: string) => _.startCase(value.replace("_", " ")),
  },
];

type ReviewsTableProps = {
  name: string;
  text: string;
  data: AppRatingsType[];
};

export const ReviewsTable = ({ name, text, data }: ReviewsTableProps) => {
  const [page, setPage] = useState(0);
  const [reviewsOnly, setReviewsOnly] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let filteredData = reviewsOnly
    ? data.filter((rev) => !!rev.review_text)
    : data;

  const handleShowRatings = () => {
    setReviewsOnly(!reviewsOnly);
  };

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography
          variant={"h5"}
          align="inherit"
          textTransform="capitalize"
          noWrap
          sx={{ marginBottom: "12px" }}
        >
          {name}
        </Typography>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={handleShowRatings}
        >
          <Typography variant="body1" sx={{ fontSize: "10px" }}>
            {reviewsOnly ? "Show Ratings & Reviews" : "Show Reviews Only"}
          </Typography>
        </Button>
      </Box>
      <Typography
        variant={"body1"}
        align="inherit"
        sx={{ marginBottom: "18px", width: "80%" }}
      >
        {`${text}`}
      </Typography>
      <TableContainer sx={{ height: "100%" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  sx={{ minWidth: column.minWidth, backgroundColor: "blue.50" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {filteredData && (
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => (
                  <TableRow key={rowIndex} hover>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          sx={{
                            backgroundColor:
                              column.id === "rating"
                                ? rowColorHelper(row.rating.toString())
                                : undefined,
                          }}
                        >
                          {column.format
                            ? column.format(value?.toString() || "")
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={filteredData?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
