import { Box } from "@mui/material";
import { ImageDropzone } from "./ImageDropzone";
import { ImagePreview } from "./ImagePreview";
import { useWatch } from "react-hook-form";
import { Labeled } from "react-admin";

export const MultiImageInput = ({ source, options }) => {
  const files = useWatch({ name: source }) || [];
  const hasFiles = !!files.length;
  return (
    <Labeled source={source}>
      <Box
        sx={{
          display: "flex",
          height: "25vh",
          overflowY: "hidden",
          overflowX: "auto",
        }}
      >
        <ImageDropzone
          source={source}
          options={{ ...options, multiple: true }}
          sx={{
            flex: `0 0 ${hasFiles ? "25w" : "100%"}`,
          }}
        />
        {hasFiles &&
          files.map((file) => (
            <ImagePreview
              key={file.id}
              source={source}
              file={file}
              sx={{
                marginLeft: "25px",
                flex: "0 0 30vw",
                "& .ItinImagePreview-imageWrapper": {
                  height: "100%",
                  width: "100%",
                },
                "& .ItinImagePreview-image": {
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                },
              }}
            />
          ))}
      </Box>
    </Labeled>
  );
};
