import { ItinResourceProps } from "../types";
import { UserCircle } from "@phosphor-icons/react";
import { UserList } from "./UserList";
import { UserCreate, UserEdit } from "./UserForm";
export { ProfileUserEdit } from "./users";

export const UserResource: ItinResourceProps = {
  name: "users",
  list: UserList,
  edit: UserEdit,
  create: UserCreate,
  icon: <UserCircle />,
  recordRepresentation: (r) => r.name || r.email,
  options: { menu_group: "collaborate" },
};
