import {
  required,
  TextInput,
  SelectInput,
  SimpleForm,
  TimeInput,
  Button,
  useUpdate,
  useRefresh,
  useCreate,
  useRedirect,
} from "react-admin";
import { Grid, Box, Typography, Stack, OutlinedInput } from "@mui/material";

const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
].map((day) => ({ id: day, name: day }));

const time_props = {
  validate: required(),
  fullWidth: true,
  parse: (v) => v,
  InputLabelProps: null, //removes the default shrink:true
};

export const OperatingHoursForm = ({
  target_type,
  target,
  hours,

  ...props
}) => {
  return (
    <SimpleForm
      defaultValues={{ target_type, target_id: target.id }}
      {...props}
    >
      <Grid container columnSpacing={3} width="50vw">
        <Grid item xs={6}>
          <SelectInput
            source="day_of_week"
            label="Day"
            validate={required()}
            fullWidth
            choices={DAYS}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput source="subtitle" label="Subtitle" fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TimeInput source="open_time" label="Open" {...time_props} />
        </Grid>
        <Grid item xs={6}>
          <TimeInput source="close_time" label="Close" {...time_props} />
        </Grid>
        <Grid item xs={12}>
          <TextInput source="description" label="Description" fullWidth />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
