import { SimpleForm } from "react-admin";
import { ReelStopFields } from "./ReelStopFields";

export const ReelStopForm = ({ leg_id, ...props }) => {
  return (
    <SimpleForm
      defaultValues={{
        leg_id: leg_id,
        status: "active",
        checkinable: true,
      }}
      {...props}
    >
      <ReelStopFields />
    </SimpleForm>
  );
};
