import { stringify } from "query-string";
import {
  useDataProvider,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { UseQueryOptions, useQuery } from "react-query";
import { useReportFilter } from "~/context/ReportFilterContext";

export type QueryOptions<TQueryFnData = unknown> = {
  key?: string[];
  options?: Omit<UseQueryOptions<TQueryFnData>, "queryKey" | "queryFn">;
};

export const useCustomQuery = <TQueryFnData = unknown>(
  url: string,
  params?: any,
  query_options: QueryOptions<TQueryFnData> = {}
) => {
  const dataProvider = useDataProvider();
  const { key, options } = query_options;
  const query_key = !!key?.length ? key : url.split("/");

  return useQuery<TQueryFnData>(
    query_key,
    () =>
      dataProvider
        .getCustom(url, params)
        .then(({ data }: { data: any }) => data),
    options
  );
};

export const useChallengeTargets = (target_type: string) =>
  useCustomQuery(
    "challenges/targets",
    { target_type },
    {
      key: ["challenges", "targets", target_type],
      options: { enabled: !!target_type },
    }
  );

export const useChallengeTaskTargets = (id: number, target_type: string) =>
  useCustomQuery(
    `challenges/${id}/task_targets`,
    {
      target_type,
    },
    {
      key: ["challenges", "task_targets", target_type],
      options: { enabled: !!target_type },
    }
  );
export const useTagCategories = () =>
  useCustomQuery<{ id: string; name: string }[]>("tag_categories");
export const useCheckInsHeatMap = () =>
  useCustomQuery("platform_user_check_ins/heat_map");
export const useTopCheckIns = () =>
  useCustomQuery("platform_user_check_ins/top_check_ins");
export const useCheckInsGraph = () =>
  useCustomQuery("platform_user_check_ins/check_ins_over_time");
export const useContentTypes = () => useCustomQuery("content_types");
export const useRoles = () => useCustomQuery("users/roles");
export const usePlatformRoles = () => useCustomQuery("platform_users/roles");
export const useStatuses = () => useCustomQuery("platform_users/user_statuses");
export const useUpdateStatus = (props: number) =>
  useCustomQuery("platform_users/update_status", { props });
export const useAdminUsers = () => useCustomQuery("users/admin_users");
export const useListingTypes = () => useCustomQuery("listings/main_types");
export const useParentRegions = () => useCustomQuery("regions/parent_regions");
export const useStopTypes = () => useCustomQuery("stops/main_types");
export const useStopSubTypes = () => useCustomQuery("stops/subtypes");
export const useEnumChoices = <
  T extends { source: string; resource?: string }
>({
  source,
  ...props
}: T) => {
  const resource = useResourceContext(props);
  const enum_name = source.split(".").pop();
  return useCustomQuery<{ name: string; id: string }[]>(
    `${resource}/${enum_name}_choices`
  );
};

export const useStats = <
  T extends { record?: any; resource?: string; category?: string; id?: number }
>(
  props?: T
) => {
  const resource = useResourceContext(props);
  const record = useRecordContext(props);
  const category = props?.category;
  const id = props?.id;

  const { startDate, endDate } = useReportFilter();

  return useCustomQuery<StatsDetail | null>(
    `${resource}/${id || record?.id}/stats/${category ? category : ""}?${stringify({
      start_date: props?.start_date ? props.start_date : startDate,
      end_date: props?.end_date ? props.end_date : endDate,
    })}`,
    undefined,
    {
      options: { enabled: !!record },
    }
  );
};
