import { Box, Stack, Typography } from "@mui/material";
import {
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  useGetIdentity,
} from "react-admin";

import { StyledToolBar } from "~/components";
import { ItinLoading } from "~/components/ItinLoading";
import { EnumChoiceInput } from "~/components/inputs";
import { RegionInput } from "~/components/inputs/RegionInput";
import { useCurrentPlatform } from "~/context";

export const ReelCreate = () => {
  const { identity, isLoading } = useGetIdentity();
  const { loading, currentPlatform, getTerm } = useCurrentPlatform();
  const reelCapTerm = getTerm("reel", {
    plural: false,
    capitalize: true,
  });
  const reelTerm = getTerm("reel", {
    plural: false,
    capitalize: false,
  });
  if (isLoading || loading) return <ItinLoading />;
  return (
    <Create>
      <SimpleForm
        variant="standard"
        redirect="edit"
        defaultValues={{
          user_id: identity.id,
          platform_id: currentPlatform.id,
          type: "Reel",
        }}
        toolbar={<StyledToolBar />}
      >
        <Typography variant="h4" pb="16px">
          {`Let's Make a ${reelCapTerm}!`}
        </Typography>
        <Stack width="97%">
          <Stack direction="row" gap={3}>
            <TextInput source="name" />
            <RegionInput />
          </Stack>
          <Stack direction="row" gap={3} width="98%">
            <EnumChoiceInput
              source="status"
              defaultValue={"draft"}
              fullWidth
              isRadio={true}
            />
          </Stack>
        </Stack>

        <BooleanInput
          label={`Make this a featured ${reelTerm}?`}
          source="featured"
        />
      </SimpleForm>
    </Create>
  );
};
