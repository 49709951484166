import { Alert } from "@mui/material";
import { useState } from "react";
import { useNotify } from "react-admin";
import ConfettiExplosion from "react-confetti-explosion";

export const useMagicNotify = () => {
  const notify = useNotify();
  const [isExploding, explode] = useState(true);

  return (notice, options) => {
    notify(
      <Alert>
        {isExploding && (
          <ConfettiExplosion
            colors={["#F7A7EA", "#F7DF96", "#B3B3FD"]}
            zIndex={9999999}
            duration={5000}
            particleCount={80}
            width={1800}
            style={{ position: "absolute", left: "25%", top: "25%" }}
            onComplete={() => explode(false)}
          />
        )}
        {notice}
      </Alert>,
      options
    );
  };
};
