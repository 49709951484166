import { ReferenceManyInput } from "@react-admin/ra-relationships";
import { useCallback, useState } from "react";
import { SimpleFormIterator, useInput } from "react-admin";
import { useFormContext } from "react-hook-form";
import {
  useCustomMutation,
  useFallbackResourceValue,
  UseFallbackResourceValueProps,
} from "~/hooks";
import { DrawingManager } from "./DrawingManager";
import { PolygonInput } from "./PolygonInput";

type MultiPolygonInputProps = UseFallbackResourceValueProps & {
  record?: any;
};

export const MultiPolygonInput = (props: MultiPolygonInputProps) => {
  const { setValue, watch } = useFormContext();
  const multipolygon_id = watch(props.source);
  const [fallback_multipolygon_id] = useFallbackResourceValue(props);
  const id = multipolygon_id || fallback_multipolygon_id;
  const isFallback = !multipolygon_id && !!fallback_multipolygon_id;
  const { mutateAsync } = useCustomMutation();
  const [editable, setEditable] = useState(false);

  const handlePolygonClick = useCallback(async () => {
    if (isFallback) {
      const { data: poly } = await mutateAsync({
        resource: "multipolygons",
        id,
        custom_url: "clone",
      });
      setValue(props.source, poly?.id, {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
    setEditable((e) => !e);
  }, [isFallback]);

  if (!id) return null;
  return (
    <ReferenceManyInput
      reference="polygons"
      target="multipolygon_id"
      perPage={1000}
      resource="multipolygons"
      record={{ id, multipolygon_id: id }}
      source="multipolygon_id"
      label={false}
    >
      <SimpleFormIterator
        disableClear
        disableRemove
        disableReordering
        addButton={<DrawingManager multipolygon_id={id} />}
      >
        <PolygonInput
          deletable={!isFallback}
          source="bounds"
          onEditClick={handlePolygonClick}
          editable={editable}
        />
        <Test source="multipolygon_id" />
      </SimpleFormIterator>
    </ReferenceManyInput>
  );
};

const Test = (props: { source: string }) => {
  const input = useInput(props);
  return null;
};
