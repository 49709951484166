import { Exam } from "@phosphor-icons/react";
import { ItinResourceProps } from "../types";
import { FormEntryList } from "./FormEntryList";
import { FormEntryShow } from "./FormEntryShow";

export const FormEntryResource: ItinResourceProps = {
  name: "form_entries",
  list: <FormEntryList />,
  show: <FormEntryShow />,
  icon: <Exam />,
  recordRepresentation: (r) => r.name,
  options: { menu_group: "contesting" },
};
