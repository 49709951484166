import { Box } from "@mui/material";
import { chain } from "lodash";
import { useMemo, useState } from "react";
import { AutocompleteInput, useGetList } from "react-admin";
import { useFormContext } from "react-hook-form";
import { GenericAutoCompleteInput } from "~/components/inputs";
import { useTagCategories } from "~/hooks";

type Tag = {
  id: number;
  name: string;
  category: string;
};

export const TagFilterInput = () => {
  const { data: categories = [] } = useTagCategories();
  const [category, setCategory] = useState("");
  const { data: tag_choices = [], isLoading } = useGetList<Tag>(
    "tags",
    { filter: { category }, pagination: { page: 1, perPage: 200 } },
    { enabled: !!category }
  );
  const { setValue } = useFormContext();

  return (
    <Box minWidth={200}>
      <GenericAutoCompleteInput
        emptyText="Tag Category"
        optionLabel="name"
        options={categories}
        value={{ id: category, name: category }}
        onChange={(v: string) => {
          setCategory(v);
          setValue("tags.id", "");
        }}
        filterSelectedOptions={false}
        label={undefined}
        helperText={undefined}
        onBlur={undefined}
        onCreate={undefined}
      />
      <AutocompleteInput
        disabled={!category}
        choices={tag_choices}
        isLoading={isLoading}
        source="tags.id"
        emptyText="Tag Name"
        filterSelectedOptions={false}
      />
    </Box>
  );
};
