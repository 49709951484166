import { Box, Grid, Typography } from "@mui/material";
import {
  ImageField,
  ListBase,
  Show,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";
import { SimpleMapList, Stats } from "~/components";
import { GridCard } from "~/components/GridCard";
import HasPlatformExtension from "~/components/HasPlatformExtension";
import { MapDataProvider } from "~/components/maps//MapDataProvider";
import { TourismoDateRangePicker } from "~/components/TourismoDateRangePicker";
import { useCurrentPlatform } from "~/context";
import { ReportFilterProvider } from "~/context/ReportFilterContext";
import { useStats } from "~/hooks";

export const DmoOverview = (props) => (
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <Grid container>
        <Grid item xs={4}>
          <ImageField source="logo.url" />
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h3">
            <TextField source="name" variant="h3" />
          </Typography>
          <TextField source="description" />
        </Grid>
      </Grid>
      <Stats />
    </Grid>
    <Grid item xs={6}>
      <GridCard>
        <DmoMap />
      </GridCard>

      {/* <InviteCollaborator /> */}
    </Grid>
  </Grid>
);
export const DmoMap = (props) => {
  const record = useRecordContext();
  const { data, isLoading } = useStats({ category: "check_ins_map" });

  if (!record) return <></>;
  //would be nice to show events and listings as well
  return (
    <MapDataProvider>
      <Box height={"55vh"} width="100%" marginTop={"0px"} marginBottom={"0px"}>
        <ListBase
          resource="areas"
          filter={{ id: record.area_ids }}
          pagination={[120]}
          perPage={120}
        >
          <SimpleMapList
            toolButtons={false}
            showPins={false}
            heatmapData={!isLoading && data.points}
          />
        </ListBase>
      </Box>
    </MapDataProvider>
  );
};

export const DmoShow = (props) => {
  const { currentPlatform } = useCurrentPlatform();

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <DmoOverview />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Regional Web Traffic">
          <Grid container spacing={2}>
            <ReportFilterProvider>
              <TourismoDateRangePicker />
              <Stats category="web" />
            </ReportFilterProvider>
          </Grid>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Regional App Activity">
          <HasPlatformExtension platform_extension="AppExtension">
            <Grid container spacing={2}>
              <ReportFilterProvider>
                <TourismoDateRangePicker />
                <Stats category="app" />
              </ReportFilterProvider>
            </Grid>
          </HasPlatformExtension>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Surveys & Forms">
          <Grid container spacing={2}>
            <ReportFilterProvider>
              <TourismoDateRangePicker />
              <Stats category="forms" />
            </ReportFilterProvider>
          </Grid>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default DmoShow;
