import React, { Suspense, useEffect, useState } from "react";

export const loadTagIcon = (iconName) => {
  return React.lazy(() =>
    import(`@phosphor-icons/react`).then((module) => {
      const IconComponent = module[iconName];
      if (IconComponent) {
        return { default: IconComponent };
      } else {
        // Return a fallback if the icon does not exist
        return { default: () => <></> };
      }
    })
  );
};

// Component to handle dynamic loading of icons
export const DynamicIconRenderer = ({ iconName }) => {
  const [IconComponent, setIconComponent] = useState(null);

  useEffect(() => {
    const loadIcon = async () => {
      const LoadedIcon = await loadTagIcon(iconName);
      setIconComponent(() => LoadedIcon);
    };

    loadIcon();
  }, [iconName]);

  return (
    <Suspense fallback={<span>Loading...</span>}>
      {IconComponent ? <IconComponent size={24} /> : null}
    </Suspense>
  );
};

export const TAG_ICONS = [
  { name: "Acorn", id: "Acorn" },
  { name: "AddressBook", id: "AddressBook" },
  { name: "AddressBookTabs", id: "AddressBookTabs" },
  { name: "AirTrafficControl", id: "AirTrafficControl" },
  { name: "Airplane", id: "Airplane" },
  { name: "AirplaneInFlight", id: "AirplaneInFlight" },
  { name: "AirplaneLanding", id: "AirplaneLanding" },
  { name: "AirplaneTakeoff", id: "AirplaneTakeoff" },
  { name: "AirplaneTaxiing", id: "AirplaneTaxiing" },
  { name: "AirplaneTilt", id: "AirplaneTilt" },
  { name: "Airplay", id: "Airplay" },
  { name: "Alarm", id: "Alarm" },
  { name: "Alien", id: "Alien" },
  { name: "AlignBottom", id: "AlignBottom" },
  { name: "AlignBottomSimple", id: "AlignBottomSimple" },
  { name: "AlignCenterHorizontal", id: "AlignCenterHorizontal" },
  { name: "AlignCenterHorizontalSimple", id: "AlignCenterHorizontalSimple" },
  { name: "AlignCenterVertical", id: "AlignCenterVertical" },
  { name: "AlignCenterVerticalSimple", id: "AlignCenterVerticalSimple" },
  { name: "AlignLeft", id: "AlignLeft" },
  { name: "AlignLeftSimple", id: "AlignLeftSimple" },
  { name: "AlignRight", id: "AlignRight" },
  { name: "AlignRightSimple", id: "AlignRightSimple" },
  { name: "AlignTop", id: "AlignTop" },
  { name: "AlignTopSimple", id: "AlignTopSimple" },
  { name: "AmazonLogo", id: "AmazonLogo" },
  { name: "Ambulance", id: "Ambulance" },
  { name: "Anchor", id: "Anchor" },
  { name: "AnchorSimple", id: "AnchorSimple" },
  { name: "AndroidLogo", id: "AndroidLogo" },
  { name: "Angle", id: "Angle" },
  { name: "AngularLogo", id: "AngularLogo" },
  { name: "Aperture", id: "Aperture" },
  { name: "AppStoreLogo", id: "AppStoreLogo" },
  { name: "AppWindow", id: "AppWindow" },
  { name: "AppleLogo", id: "AppleLogo" },
  { name: "ApplePodcastsLogo", id: "ApplePodcastsLogo" },
  { name: "ApproximateEquals", id: "ApproximateEquals" },
  { name: "Archive", id: "Archive" },
  { name: "Armchair", id: "Armchair" },
  { name: "ArrowArcLeft", id: "ArrowArcLeft" },
  { name: "ArrowArcRight", id: "ArrowArcRight" },
  { name: "ArrowBendDoubleUpLeft", id: "ArrowBendDoubleUpLeft" },
  { name: "ArrowBendDoubleUpRight", id: "ArrowBendDoubleUpRight" },
  { name: "ArrowBendDownLeft", id: "ArrowBendDownLeft" },
  { name: "ArrowBendDownRight", id: "ArrowBendDownRight" },
  { name: "ArrowBendLeftDown", id: "ArrowBendLeftDown" },
  { name: "ArrowBendLeftUp", id: "ArrowBendLeftUp" },
  { name: "ArrowBendRightDown", id: "ArrowBendRightDown" },
  { name: "ArrowBendRightUp", id: "ArrowBendRightUp" },
  { name: "ArrowBendUpLeft", id: "ArrowBendUpLeft" },
  { name: "ArrowBendUpRight", id: "ArrowBendUpRight" },
  { name: "ArrowCircleDown", id: "ArrowCircleDown" },
  { name: "ArrowCircleDownLeft", id: "ArrowCircleDownLeft" },
  { name: "ArrowCircleDownRight", id: "ArrowCircleDownRight" },
  { name: "ArrowCircleLeft", id: "ArrowCircleLeft" },
  { name: "ArrowCircleRight", id: "ArrowCircleRight" },
  { name: "ArrowCircleUp", id: "ArrowCircleUp" },
  { name: "ArrowCircleUpLeft", id: "ArrowCircleUpLeft" },
  { name: "ArrowCircleUpRight", id: "ArrowCircleUpRight" },
  { name: "ArrowClockwise", id: "ArrowClockwise" },
  { name: "ArrowCounterClockwise", id: "ArrowCounterClockwise" },
  { name: "ArrowDown", id: "ArrowDown" },
  { name: "ArrowDownLeft", id: "ArrowDownLeft" },
  { name: "ArrowDownRight", id: "ArrowDownRight" },
  { name: "ArrowElbowDownLeft", id: "ArrowElbowDownLeft" },
  { name: "ArrowElbowDownRight", id: "ArrowElbowDownRight" },
  { name: "ArrowElbowLeft", id: "ArrowElbowLeft" },
  { name: "ArrowElbowLeftDown", id: "ArrowElbowLeftDown" },
  { name: "ArrowElbowLeftUp", id: "ArrowElbowLeftUp" },
  { name: "ArrowElbowRight", id: "ArrowElbowRight" },
  { name: "ArrowElbowRightDown", id: "ArrowElbowRightDown" },
  { name: "ArrowElbowRightUp", id: "ArrowElbowRightUp" },
  { name: "ArrowElbowUpLeft", id: "ArrowElbowUpLeft" },
  { name: "ArrowElbowUpRight", id: "ArrowElbowUpRight" },
  { name: "ArrowFatDown", id: "ArrowFatDown" },
  { name: "ArrowFatLeft", id: "ArrowFatLeft" },
  { name: "ArrowFatLineDown", id: "ArrowFatLineDown" },
  { name: "ArrowFatLineLeft", id: "ArrowFatLineLeft" },
  { name: "ArrowFatLineRight", id: "ArrowFatLineRight" },
  { name: "ArrowFatLineUp", id: "ArrowFatLineUp" },
  { name: "ArrowFatLinesDown", id: "ArrowFatLinesDown" },
  { name: "ArrowFatLinesLeft", id: "ArrowFatLinesLeft" },
  { name: "ArrowFatLinesRight", id: "ArrowFatLinesRight" },
  { name: "ArrowFatLinesUp", id: "ArrowFatLinesUp" },
  { name: "ArrowFatRight", id: "ArrowFatRight" },
  { name: "ArrowFatUp", id: "ArrowFatUp" },
  { name: "ArrowLeft", id: "ArrowLeft" },
  { name: "ArrowLineDown", id: "ArrowLineDown" },
  { name: "ArrowLineDownLeft", id: "ArrowLineDownLeft" },
  { name: "ArrowLineDownRight", id: "ArrowLineDownRight" },
  { name: "ArrowLineLeft", id: "ArrowLineLeft" },
  { name: "ArrowLineRight", id: "ArrowLineRight" },
  { name: "ArrowLineUp", id: "ArrowLineUp" },
  { name: "ArrowLineUpLeft", id: "ArrowLineUpLeft" },
  { name: "ArrowLineUpRight", id: "ArrowLineUpRight" },
  { name: "ArrowRight", id: "ArrowRight" },
  { name: "ArrowSquareDown", id: "ArrowSquareDown" },
  { name: "ArrowSquareDownLeft", id: "ArrowSquareDownLeft" },
  { name: "ArrowSquareDownRight", id: "ArrowSquareDownRight" },
  { name: "ArrowSquareIn", id: "ArrowSquareIn" },
  { name: "ArrowSquareLeft", id: "ArrowSquareLeft" },
  { name: "ArrowSquareOut", id: "ArrowSquareOut" },
  { name: "ArrowSquareRight", id: "ArrowSquareRight" },
  { name: "ArrowSquareUp", id: "ArrowSquareUp" },
  { name: "ArrowSquareUpLeft", id: "ArrowSquareUpLeft" },
  { name: "ArrowSquareUpRight", id: "ArrowSquareUpRight" },
  { name: "ArrowUDownLeft", id: "ArrowUDownLeft" },
  { name: "ArrowUDownRight", id: "ArrowUDownRight" },
  { name: "ArrowULeftDown", id: "ArrowULeftDown" },
  { name: "ArrowULeftUp", id: "ArrowULeftUp" },
  { name: "ArrowURightDown", id: "ArrowURightDown" },
  { name: "ArrowURightUp", id: "ArrowURightUp" },
  { name: "ArrowUUpLeft", id: "ArrowUUpLeft" },
  { name: "ArrowUUpRight", id: "ArrowUUpRight" },
  { name: "ArrowUp", id: "ArrowUp" },
  { name: "ArrowUpLeft", id: "ArrowUpLeft" },
  { name: "ArrowUpRight", id: "ArrowUpRight" },
  { name: "ArrowsClockwise", id: "ArrowsClockwise" },
  { name: "ArrowsCounterClockwise", id: "ArrowsCounterClockwise" },
  { name: "ArrowsDownUp", id: "ArrowsDownUp" },
  { name: "ArrowsHorizontal", id: "ArrowsHorizontal" },
  { name: "ArrowsIn", id: "ArrowsIn" },
  { name: "ArrowsInCardinal", id: "ArrowsInCardinal" },
  { name: "ArrowsInLineHorizontal", id: "ArrowsInLineHorizontal" },
  { name: "ArrowsInLineVertical", id: "ArrowsInLineVertical" },
  { name: "ArrowsInSimple", id: "ArrowsInSimple" },
  { name: "ArrowsLeftRight", id: "ArrowsLeftRight" },
  { name: "ArrowsMerge", id: "ArrowsMerge" },
  { name: "ArrowsOut", id: "ArrowsOut" },
  { name: "ArrowsOutCardinal", id: "ArrowsOutCardinal" },
  { name: "ArrowsOutLineHorizontal", id: "ArrowsOutLineHorizontal" },
  { name: "ArrowsOutLineVertical", id: "ArrowsOutLineVertical" },
  { name: "ArrowsOutSimple", id: "ArrowsOutSimple" },
  { name: "ArrowsSplit", id: "ArrowsSplit" },
  { name: "ArrowsVertical", id: "ArrowsVertical" },
  { name: "Article", id: "Article" },
  { name: "ArticleMedium", id: "ArticleMedium" },
  { name: "ArticleNyTimes", id: "ArticleNyTimes" },
  { name: "Asclepius", id: "Asclepius" },
  { name: "Asterisk", id: "Asterisk" },
  { name: "AsteriskSimple", id: "AsteriskSimple" },
  { name: "At", id: "At" },
  { name: "Atom", id: "Atom" },
  { name: "Avocado", id: "Avocado" },
  { name: "Axe", id: "Axe" },
  { name: "Baby", id: "Baby" },
  { name: "BabyCarriage", id: "BabyCarriage" },
  { name: "Backpack", id: "Backpack" },
  { name: "Backspace", id: "Backspace" },
  { name: "Bag", id: "Bag" },
  { name: "BagSimple", id: "BagSimple" },
  { name: "Balloon", id: "Balloon" },
  { name: "Bandaids", id: "Bandaids" },
  { name: "Bank", id: "Bank" },
  { name: "Barbell", id: "Barbell" },
  { name: "Barcode", id: "Barcode" },
  { name: "Barn", id: "Barn" },
  { name: "Barricade", id: "Barricade" },
  { name: "Baseball", id: "Baseball" },
  { name: "BaseballCap", id: "BaseballCap" },
  { name: "BaseballHelmet", id: "BaseballHelmet" },
  { name: "Basket", id: "Basket" },
  { name: "Basketball", id: "Basketball" },
  { name: "Bathtub", id: "Bathtub" },
  { name: "BatteryCharging", id: "BatteryCharging" },
  { name: "BatteryChargingVertical", id: "BatteryChargingVertical" },
  { name: "BatteryEmpty", id: "BatteryEmpty" },
  { name: "BatteryFull", id: "BatteryFull" },
  { name: "BatteryHigh", id: "BatteryHigh" },
  { name: "BatteryLow", id: "BatteryLow" },
  { name: "BatteryMedium", id: "BatteryMedium" },
  { name: "BatteryPlus", id: "BatteryPlus" },
  { name: "BatteryPlusVertical", id: "BatteryPlusVertical" },
  { name: "BatteryVerticalEmpty", id: "BatteryVerticalEmpty" },
  { name: "BatteryVerticalFull", id: "BatteryVerticalFull" },
  { name: "BatteryVerticalHigh", id: "BatteryVerticalHigh" },
  { name: "BatteryVerticalLow", id: "BatteryVerticalLow" },
  { name: "BatteryVerticalMedium", id: "BatteryVerticalMedium" },
  { name: "BatteryWarning", id: "BatteryWarning" },
  { name: "BatteryWarningVertical", id: "BatteryWarningVertical" },
  { name: "BeachBall", id: "BeachBall" },
  { name: "Beanie", id: "Beanie" },
  { name: "Bed", id: "Bed" },
  { name: "BeerBottle", id: "BeerBottle" },
  { name: "BeerStein", id: "BeerStein" },
  { name: "BehanceLogo", id: "BehanceLogo" },
  { name: "Bell", id: "Bell" },
  { name: "BellRinging", id: "BellRinging" },
  { name: "BellSimple", id: "BellSimple" },
  { name: "BellSimpleRinging", id: "BellSimpleRinging" },
  { name: "BellSimpleSlash", id: "BellSimpleSlash" },
  { name: "BellSimpleZ", id: "BellSimpleZ" },
  { name: "BellSlash", id: "BellSlash" },
  { name: "BellZ", id: "BellZ" },
  { name: "Belt", id: "Belt" },
  { name: "BezierCurve", id: "BezierCurve" },
  { name: "Bicycle", id: "Bicycle" },
  { name: "Binary", id: "Binary" },
  { name: "Binoculars", id: "Binoculars" },
  { name: "Biohazard", id: "Biohazard" },
  { name: "Bird", id: "Bird" },
  { name: "Blueprint", id: "Blueprint" },
  { name: "Bluetooth", id: "Bluetooth" },
  { name: "BluetoothConnected", id: "BluetoothConnected" },
  { name: "BluetoothSlash", id: "BluetoothSlash" },
  { name: "BluetoothX", id: "BluetoothX" },
  { name: "Boat", id: "Boat" },
  { name: "Bomb", id: "Bomb" },
  { name: "Bone", id: "Bone" },
  { name: "Book", id: "Book" },
  { name: "BookBookmark", id: "BookBookmark" },
  { name: "BookOpen", id: "BookOpen" },
  { name: "BookOpenText", id: "BookOpenText" },
  { name: "BookOpenUser", id: "BookOpenUser" },
  { name: "Bookmark", id: "Bookmark" },
  { name: "BookmarkSimple", id: "BookmarkSimple" },
  { name: "Bookmarks", id: "Bookmarks" },
  { name: "BookmarksSimple", id: "BookmarksSimple" },
  { name: "Books", id: "Books" },
  { name: "Boot", id: "Boot" },
  { name: "Boules", id: "Boules" },
  { name: "BoundingBox", id: "BoundingBox" },
  { name: "BowlFood", id: "BowlFood" },
  { name: "BowlSteam", id: "BowlSteam" },
  { name: "BowlingBall", id: "BowlingBall" },
  { name: "BoxArrowDown", id: "BoxArrowDown" },
  { name: "BoxArrowUp", id: "BoxArrowUp" },
  { name: "BoxingGlove", id: "BoxingGlove" },
  { name: "BracketsAngle", id: "BracketsAngle" },
  { name: "BracketsCurly", id: "BracketsCurly" },
  { name: "BracketsRound", id: "BracketsRound" },
  { name: "BracketsSquare", id: "BracketsSquare" },
  { name: "Brain", id: "Brain" },
  { name: "Brandy", id: "Brandy" },
  { name: "Bread", id: "Bread" },
  { name: "Bridge", id: "Bridge" },
  { name: "Briefcase", id: "Briefcase" },
  { name: "BriefcaseMetal", id: "BriefcaseMetal" },
  { name: "Broadcast", id: "Broadcast" },
  { name: "Broom", id: "Broom" },
  { name: "Browser", id: "Browser" },
  { name: "Browsers", id: "Browsers" },
  { name: "BugBeetle", id: "BugBeetle" },
  { name: "Bug", id: "Bug" },
  { name: "BugDroid", id: "BugDroid" },
  { name: "BuildingApartment", id: "BuildingApartment" },
  { name: "Building", id: "Building" },
  { name: "BuildingOffice", id: "BuildingOffice" },
  { name: "Buildings", id: "Buildings" },
  { name: "Bulldozer", id: "Bulldozer" },
  { name: "Bus", id: "Bus" },
  { name: "Butterfly", id: "Butterfly" },
  { name: "CableCar", id: "CableCar" },
  { name: "Cactus", id: "Cactus" },
  { name: "Cake", id: "Cake" },
  { name: "Calculator", id: "Calculator" },
  { name: "CalendarBlank", id: "CalendarBlank" },
  { name: "Calendar", id: "Calendar" },
  { name: "CalendarCheck", id: "CalendarCheck" },
  { name: "CalendarDot", id: "CalendarDot" },
  { name: "CalendarDots", id: "CalendarDots" },
  { name: "CalendarHeart", id: "CalendarHeart" },
  { name: "CalendarMinus", id: "CalendarMinus" },
  { name: "CalendarPlus", id: "CalendarPlus" },
  { name: "CalendarSlash", id: "CalendarSlash" },
  { name: "CalendarStar", id: "CalendarStar" },
  { name: "CalendarX", id: "CalendarX" },
  { name: "CallBell", id: "CallBell" },
  { name: "Camera", id: "Camera" },
  { name: "CameraPlus", id: "CameraPlus" },
  { name: "CameraRotate", id: "CameraRotate" },
  { name: "CameraSlash", id: "CameraSlash" },
  { name: "Campfire", id: "Campfire" },
  { name: "CarBattery", id: "CarBattery" },
  { name: "Car", id: "Car" },
  { name: "CarProfile", id: "CarProfile" },
  { name: "CarSimple", id: "CarSimple" },
  { name: "Cardholder", id: "Cardholder" },
  { name: "Cards", id: "Cards" },
  { name: "CardsThree", id: "CardsThree" },
  { name: "CaretCircleDoubleDown", id: "CaretCircleDoubleDown" },
  { name: "CaretCircleDoubleLeft", id: "CaretCircleDoubleLeft" },
  { name: "CaretCircleDoubleRight", id: "CaretCircleDoubleRight" },
  { name: "CaretCircleDoubleUp", id: "CaretCircleDoubleUp" },
  { name: "CaretCircleDown", id: "CaretCircleDown" },
  { name: "CaretCircleLeft", id: "CaretCircleLeft" },
  { name: "CaretCircleRight", id: "CaretCircleRight" },
  { name: "CaretCircleUp", id: "CaretCircleUp" },
  { name: "CaretCircleUpDown", id: "CaretCircleUpDown" },
  { name: "CaretDoubleDown", id: "CaretDoubleDown" },
  { name: "CaretDoubleLeft", id: "CaretDoubleLeft" },
  { name: "CaretDoubleRight", id: "CaretDoubleRight" },
  { name: "CaretDoubleUp", id: "CaretDoubleUp" },
  { name: "CaretDown", id: "CaretDown" },
  { name: "CaretLeft", id: "CaretLeft" },
  { name: "CaretLineDown", id: "CaretLineDown" },
  { name: "CaretLineLeft", id: "CaretLineLeft" },
  { name: "CaretLineRight", id: "CaretLineRight" },
  { name: "CaretLineUp", id: "CaretLineUp" },
  { name: "CaretRight", id: "CaretRight" },
  { name: "CaretUp", id: "CaretUp" },
  { name: "CaretUpDown", id: "CaretUpDown" },
  { name: "Carrot", id: "Carrot" },
  { name: "CashRegister", id: "CashRegister" },
  { name: "CassetteTape", id: "CassetteTape" },
  { name: "CastleTurret", id: "CastleTurret" },
  { name: "Cat", id: "Cat" },
  { name: "CellSignalFull", id: "CellSignalFull" },
  { name: "CellSignalHigh", id: "CellSignalHigh" },
  { name: "CellSignalLow", id: "CellSignalLow" },
  { name: "CellSignalMedium", id: "CellSignalMedium" },
  { name: "CellSignalNone", id: "CellSignalNone" },
  { name: "CellSignalSlash", id: "CellSignalSlash" },
  { name: "CellSignalX", id: "CellSignalX" },
  { name: "CellTower", id: "CellTower" },
  { name: "Certificate", id: "Certificate" },
  { name: "Chair", id: "Chair" },
  { name: "Chalkboard", id: "Chalkboard" },
  { name: "ChalkboardSimple", id: "ChalkboardSimple" },
  { name: "ChalkboardTeacher", id: "ChalkboardTeacher" },
  { name: "Champagne", id: "Champagne" },
  { name: "ChargingStation", id: "ChargingStation" },
  { name: "ChartBar", id: "ChartBar" },
  { name: "ChartBarHorizontal", id: "ChartBarHorizontal" },
  { name: "ChartDonut", id: "ChartDonut" },
  { name: "ChartLine", id: "ChartLine" },
  { name: "ChartLineDown", id: "ChartLineDown" },
  { name: "ChartLineUp", id: "ChartLineUp" },
  { name: "ChartPie", id: "ChartPie" },
  { name: "ChartPieSlice", id: "ChartPieSlice" },
  { name: "ChartPolar", id: "ChartPolar" },
  { name: "ChartScatter", id: "ChartScatter" },
  { name: "Chat", id: "Chat" },
  { name: "ChatCentered", id: "ChatCentered" },
  { name: "ChatCenteredDots", id: "ChatCenteredDots" },
  { name: "ChatCenteredSlash", id: "ChatCenteredSlash" },
  { name: "ChatCenteredText", id: "ChatCenteredText" },
  { name: "ChatCircle", id: "ChatCircle" },
  { name: "ChatCircleDots", id: "ChatCircleDots" },
  { name: "ChatCircleSlash", id: "ChatCircleSlash" },
  { name: "ChatCircleText", id: "ChatCircleText" },
  { name: "ChatDots", id: "ChatDots" },
  { name: "ChatSlash", id: "ChatSlash" },
  { name: "ChatTeardrop", id: "ChatTeardrop" },
  { name: "ChatTeardropDots", id: "ChatTeardropDots" },
  { name: "ChatTeardropSlash", id: "ChatTeardropSlash" },
  { name: "ChatTeardropText", id: "ChatTeardropText" },
  { name: "ChatText", id: "ChatText" },
  { name: "Chats", id: "Chats" },
  { name: "ChatsCircle", id: "ChatsCircle" },
  { name: "ChatsTeardrop", id: "ChatsTeardrop" },
  { name: "Check", id: "Check" },
  { name: "CheckCircle", id: "CheckCircle" },
  { name: "CheckFat", id: "CheckFat" },
  { name: "CheckSquare", id: "CheckSquare" },
  { name: "CheckSquareOffset", id: "CheckSquareOffset" },
  { name: "Checkerboard", id: "Checkerboard" },
  { name: "Checks", id: "Checks" },
  { name: "Cheers", id: "Cheers" },
  { name: "Cheese", id: "Cheese" },
  { name: "ChefHat", id: "ChefHat" },
  { name: "Cherries", id: "Cherries" },
  { name: "Church", id: "Church" },
  { name: "Cigarette", id: "Cigarette" },
  { name: "CigaretteSlash", id: "CigaretteSlash" },
  { name: "Circle", id: "Circle" },
  { name: "CircleDashed", id: "CircleDashed" },
  { name: "CircleHalf", id: "CircleHalf" },
  { name: "CircleHalfTilt", id: "CircleHalfTilt" },
  { name: "CircleNotch", id: "CircleNotch" },
  { name: "CirclesFour", id: "CirclesFour" },
  { name: "CirclesThree", id: "CirclesThree" },
  { name: "CirclesThreePlus", id: "CirclesThreePlus" },
  { name: "Circuitry", id: "Circuitry" },
  { name: "City", id: "City" },
  { name: "Clipboard", id: "Clipboard" },
  { name: "ClipboardText", id: "ClipboardText" },
  { name: "ClockAfternoon", id: "ClockAfternoon" },
  { name: "Clock", id: "Clock" },
  { name: "ClockClockwise", id: "ClockClockwise" },
  { name: "ClockCountdown", id: "ClockCountdown" },
  { name: "ClockCounterClockwise", id: "ClockCounterClockwise" },
  { name: "ClockUser", id: "ClockUser" },
  { name: "ClosedCaptioning", id: "ClosedCaptioning" },
  { name: "CloudArrowDown", id: "CloudArrowDown" },
  { name: "CloudArrowUp", id: "CloudArrowUp" },
  { name: "Cloud", id: "Cloud" },
  { name: "CloudCheck", id: "CloudCheck" },
  { name: "CloudFog", id: "CloudFog" },
  { name: "CloudLightning", id: "CloudLightning" },
  { name: "CloudMoon", id: "CloudMoon" },
  { name: "CloudRain", id: "CloudRain" },
  { name: "CloudSlash", id: "CloudSlash" },
  { name: "CloudSnow", id: "CloudSnow" },
  { name: "CloudSun", id: "CloudSun" },
  { name: "CloudWarning", id: "CloudWarning" },
  { name: "CloudX", id: "CloudX" },
  { name: "Clover", id: "Clover" },
  { name: "Club", id: "Club" },
  { name: "CoatHanger", id: "CoatHanger" },
  { name: "CodaLogo", id: "CodaLogo" },
  { name: "CodeBlock", id: "CodeBlock" },
  { name: "Code", id: "Code" },
  { name: "CodeSimple", id: "CodeSimple" },
  { name: "CodepenLogo", id: "CodepenLogo" },
  { name: "CodesandboxLogo", id: "CodesandboxLogo" },
  { name: "CoffeeBean", id: "CoffeeBean" },
  { name: "Coffee", id: "Coffee" },
  { name: "Coin", id: "Coin" },
  { name: "CoinVertical", id: "CoinVertical" },
  { name: "Coins", id: "Coins" },
  { name: "Columns", id: "Columns" },
  { name: "ColumnsPlusLeft", id: "ColumnsPlusLeft" },
  { name: "ColumnsPlusRight", id: "ColumnsPlusRight" },
  { name: "Command", id: "Command" },
  { name: "Compass", id: "Compass" },
  { name: "CompassRose", id: "CompassRose" },
  { name: "CompassTool", id: "CompassTool" },
  { name: "ComputerTower", id: "ComputerTower" },
  { name: "Confetti", id: "Confetti" },
  { name: "ContactlessPayment", id: "ContactlessPayment" },
  { name: "Control", id: "Control" },
  { name: "Cookie", id: "Cookie" },
  { name: "CookingPot", id: "CookingPot" },
  { name: "Copy", id: "Copy" },
  { name: "CopySimple", id: "CopySimple" },
  { name: "Copyleft", id: "Copyleft" },
  { name: "Copyright", id: "Copyright" },
  { name: "CornersIn", id: "CornersIn" },
  { name: "CornersOut", id: "CornersOut" },
  { name: "Couch", id: "Couch" },
  { name: "CourtBasketball", id: "CourtBasketball" },
  { name: "Cow", id: "Cow" },
  { name: "CowboyHat", id: "CowboyHat" },
  { name: "Cpu", id: "Cpu" },
  { name: "Crane", id: "Crane" },
  { name: "CraneTower", id: "CraneTower" },
  { name: "CreditCard", id: "CreditCard" },
  { name: "Cricket", id: "Cricket" },
  { name: "Crop", id: "Crop" },
  { name: "Cross", id: "Cross" },
  { name: "Crosshair", id: "Crosshair" },
  { name: "CrosshairSimple", id: "CrosshairSimple" },
  { name: "Crown", id: "Crown" },
  { name: "CrownCross", id: "CrownCross" },
  { name: "CrownSimple", id: "CrownSimple" },
  { name: "Cube", id: "Cube" },
  { name: "CubeFocus", id: "CubeFocus" },
  { name: "CubeTransparent", id: "CubeTransparent" },
  { name: "CurrencyBtc", id: "CurrencyBtc" },
  { name: "CurrencyCircleDollar", id: "CurrencyCircleDollar" },
  { name: "CurrencyCny", id: "CurrencyCny" },
  { name: "CurrencyDollar", id: "CurrencyDollar" },
  { name: "CurrencyDollarSimple", id: "CurrencyDollarSimple" },
  { name: "CurrencyEth", id: "CurrencyEth" },
  { name: "CurrencyEur", id: "CurrencyEur" },
  { name: "CurrencyGbp", id: "CurrencyGbp" },
  { name: "CurrencyInr", id: "CurrencyInr" },
  { name: "CurrencyJpy", id: "CurrencyJpy" },
  { name: "CurrencyKrw", id: "CurrencyKrw" },
  { name: "CurrencyKzt", id: "CurrencyKzt" },
  { name: "CurrencyNgn", id: "CurrencyNgn" },
  { name: "CurrencyRub", id: "CurrencyRub" },
  { name: "Cursor", id: "Cursor" },
  { name: "CursorClick", id: "CursorClick" },
  { name: "CursorText", id: "CursorText" },
  { name: "Cylinder", id: "Cylinder" },
  { name: "Database", id: "Database" },
  { name: "Desk", id: "Desk" },
  { name: "Desktop", id: "Desktop" },
  { name: "DesktopTower", id: "DesktopTower" },
  { name: "Detective", id: "Detective" },
  { name: "DevToLogo", id: "DevToLogo" },
  { name: "DeviceMobile", id: "DeviceMobile" },
  { name: "DeviceMobileCamera", id: "DeviceMobileCamera" },
  { name: "DeviceMobileSlash", id: "DeviceMobileSlash" },
  { name: "DeviceMobileSpeaker", id: "DeviceMobileSpeaker" },
  { name: "DeviceRotate", id: "DeviceRotate" },
  { name: "DeviceTablet", id: "DeviceTablet" },
  { name: "DeviceTabletCamera", id: "DeviceTabletCamera" },
  { name: "DeviceTabletSpeaker", id: "DeviceTabletSpeaker" },
  { name: "Devices", id: "Devices" },
  { name: "Diamond", id: "Diamond" },
  { name: "DiamondsFour", id: "DiamondsFour" },
  { name: "DiceFive", id: "DiceFive" },
  { name: "DiceFour", id: "DiceFour" },
  { name: "DiceOne", id: "DiceOne" },
  { name: "DiceSix", id: "DiceSix" },
  { name: "DiceThree", id: "DiceThree" },
  { name: "DiceTwo", id: "DiceTwo" },
  { name: "Disc", id: "Disc" },
  { name: "DiscoBall", id: "DiscoBall" },
  { name: "DiscordLogo", id: "DiscordLogo" },
  { name: "Divide", id: "Divide" },
  { name: "Dna", id: "Dna" },
  { name: "Dog", id: "Dog" },
  { name: "Door", id: "Door" },
  { name: "DoorOpen", id: "DoorOpen" },
  { name: "Dot", id: "Dot" },
  { name: "DotOutline", id: "DotOutline" },
  { name: "DotsNine", id: "DotsNine" },
  { name: "DotsSix", id: "DotsSix" },
  { name: "DotsSixVertical", id: "DotsSixVertical" },
  { name: "DotsThree", id: "DotsThree" },
  { name: "DotsThreeCircle", id: "DotsThreeCircle" },
  { name: "DotsThreeCircleVertical", id: "DotsThreeCircleVertical" },
  { name: "DotsThreeOutline", id: "DotsThreeOutline" },
  { name: "DotsThreeOutlineVertical", id: "DotsThreeOutlineVertical" },
  { name: "DotsThreeVertical", id: "DotsThreeVertical" },
  { name: "Download", id: "Download" },
  { name: "DownloadSimple", id: "DownloadSimple" },
  { name: "Dress", id: "Dress" },
  { name: "Dresser", id: "Dresser" },
  { name: "DribbbleLogo", id: "DribbbleLogo" },
  { name: "Drone", id: "Drone" },
  { name: "Drop", id: "Drop" },
  { name: "DropHalf", id: "DropHalf" },
  { name: "DropHalfBottom", id: "DropHalfBottom" },
  { name: "DropSimple", id: "DropSimple" },
  { name: "DropSlash", id: "DropSlash" },
  { name: "DropboxLogo", id: "DropboxLogo" },
  { name: "Ear", id: "Ear" },
  { name: "EarSlash", id: "EarSlash" },
  { name: "Egg", id: "Egg" },
  { name: "EggCrack", id: "EggCrack" },
  { name: "Eject", id: "Eject" },
  { name: "EjectSimple", id: "EjectSimple" },
  { name: "Elevator", id: "Elevator" },
  { name: "Empty", id: "Empty" },
  { name: "Engine", id: "Engine" },
  { name: "Envelope", id: "Envelope" },
  { name: "EnvelopeOpen", id: "EnvelopeOpen" },
  { name: "EnvelopeSimple", id: "EnvelopeSimple" },
  { name: "EnvelopeSimpleOpen", id: "EnvelopeSimpleOpen" },
  { name: "Equalizer", id: "Equalizer" },
  { name: "Equals", id: "Equals" },
  { name: "Eraser", id: "Eraser" },
  { name: "EscalatorDown", id: "EscalatorDown" },
  { name: "EscalatorUp", id: "EscalatorUp" },
  { name: "Exam", id: "Exam" },
  { name: "ExclamationMark", id: "ExclamationMark" },
  { name: "Exclude", id: "Exclude" },
  { name: "ExcludeSquare", id: "ExcludeSquare" },
  { name: "Export", id: "Export" },
  { name: "Eye", id: "Eye" },
  { name: "EyeClosed", id: "EyeClosed" },
  { name: "EyeSlash", id: "EyeSlash" },
  { name: "Eyedropper", id: "Eyedropper" },
  { name: "EyedropperSample", id: "EyedropperSample" },
  { name: "Eyeglasses", id: "Eyeglasses" },
  { name: "Eyes", id: "Eyes" },
  { name: "FaceMask", id: "FaceMask" },
  { name: "FacebookLogo", id: "FacebookLogo" },
  { name: "Factory", id: "Factory" },
  { name: "Faders", id: "Faders" },
  { name: "FadersHorizontal", id: "FadersHorizontal" },
  { name: "FalloutShelter", id: "FalloutShelter" },
  { name: "Fan", id: "Fan" },
  { name: "Farm", id: "Farm" },
  { name: "FastForward", id: "FastForward" },
  { name: "FastForwardCircle", id: "FastForwardCircle" },
  { name: "Feather", id: "Feather" },
  { name: "FediverseLogo", id: "FediverseLogo" },
  { name: "FigmaLogo", id: "FigmaLogo" },
  { name: "FileArchive", id: "FileArchive" },
  { name: "FileArrowDown", id: "FileArrowDown" },
  { name: "FileArrowUp", id: "FileArrowUp" },
  { name: "FileAudio", id: "FileAudio" },
  { name: "File", id: "File" },
  { name: "FileC", id: "FileC" },
  { name: "FileCSharp", id: "FileCSharp" },
  { name: "FileCloud", id: "FileCloud" },
  { name: "FileCode", id: "FileCode" },
  { name: "FileCpp", id: "FileCpp" },
  { name: "FileCss", id: "FileCss" },
  { name: "FileCsv", id: "FileCsv" },
  { name: "FileDashed", id: "FileDashed" },
  { name: "FileDoc", id: "FileDoc" },
  { name: "FileHtml", id: "FileHtml" },
  { name: "FileImage", id: "FileImage" },
  { name: "FileIni", id: "FileIni" },
  { name: "FileJpg", id: "FileJpg" },
  { name: "FileJs", id: "FileJs" },
  { name: "FileJsx", id: "FileJsx" },
  { name: "FileLock", id: "FileLock" },
  { name: "FileMagnifyingGlass", id: "FileMagnifyingGlass" },
  { name: "FileMd", id: "FileMd" },
  { name: "FileMinus", id: "FileMinus" },
  { name: "FilePdf", id: "FilePdf" },
  { name: "FilePlus", id: "FilePlus" },
  { name: "FilePng", id: "FilePng" },
  { name: "FilePpt", id: "FilePpt" },
  { name: "FilePy", id: "FilePy" },
  { name: "FileRs", id: "FileRs" },
  { name: "FileSql", id: "FileSql" },
  { name: "FileSvg", id: "FileSvg" },
  { name: "FileText", id: "FileText" },
  { name: "FileTs", id: "FileTs" },
  { name: "FileTsx", id: "FileTsx" },
  { name: "FileTxt", id: "FileTxt" },
  { name: "FileVideo", id: "FileVideo" },
  { name: "FileVue", id: "FileVue" },
  { name: "FileX", id: "FileX" },
  { name: "FileXls", id: "FileXls" },
  { name: "FileZip", id: "FileZip" },
  { name: "Files", id: "Files" },
  { name: "FilmReel", id: "FilmReel" },
  { name: "FilmScript", id: "FilmScript" },
  { name: "FilmSlate", id: "FilmSlate" },
  { name: "FilmStrip", id: "FilmStrip" },
  { name: "Fingerprint", id: "Fingerprint" },
  { name: "FingerprintSimple", id: "FingerprintSimple" },
  { name: "FinnTheHuman", id: "FinnTheHuman" },
  { name: "Fire", id: "Fire" },
  { name: "FireExtinguisher", id: "FireExtinguisher" },
  { name: "FireSimple", id: "FireSimple" },
  { name: "FireTruck", id: "FireTruck" },
  { name: "FirstAid", id: "FirstAid" },
  { name: "FirstAidKit", id: "FirstAidKit" },
  { name: "Fish", id: "Fish" },
  { name: "FishSimple", id: "FishSimple" },
  { name: "FlagBanner", id: "FlagBanner" },
  { name: "FlagBannerFold", id: "FlagBannerFold" },
  { name: "Flag", id: "Flag" },
  { name: "FlagCheckered", id: "FlagCheckered" },
  { name: "FlagPennant", id: "FlagPennant" },
  { name: "Flame", id: "Flame" },
  { name: "Flashlight", id: "Flashlight" },
  { name: "Flask", id: "Flask" },
  { name: "FlipHorizontal", id: "FlipHorizontal" },
  { name: "FlipVertical", id: "FlipVertical" },
  { name: "FloppyDiskBack", id: "FloppyDiskBack" },
  { name: "FloppyDisk", id: "FloppyDisk" },
  { name: "FlowArrow", id: "FlowArrow" },
  { name: "Flower", id: "Flower" },
  { name: "FlowerLotus", id: "FlowerLotus" },
  { name: "FlowerTulip", id: "FlowerTulip" },
  { name: "FlyingSaucer", id: "FlyingSaucer" },
  { name: "Folder", id: "Folder" },
  { name: "FolderDashed", id: "FolderDashed" },
  { name: "FolderLock", id: "FolderLock" },
  { name: "FolderMinus", id: "FolderMinus" },
  { name: "FolderOpen", id: "FolderOpen" },
  { name: "FolderPlus", id: "FolderPlus" },
  { name: "FolderSimple", id: "FolderSimple" },
  { name: "FolderSimpleDashed", id: "FolderSimpleDashed" },
  { name: "FolderSimpleLock", id: "FolderSimpleLock" },
  { name: "FolderSimpleMinus", id: "FolderSimpleMinus" },
  { name: "FolderSimplePlus", id: "FolderSimplePlus" },
  { name: "FolderSimpleStar", id: "FolderSimpleStar" },
  { name: "FolderSimpleUser", id: "FolderSimpleUser" },
  { name: "FolderStar", id: "FolderStar" },
  { name: "FolderUser", id: "FolderUser" },
  { name: "Folders", id: "Folders" },
  { name: "Football", id: "Football" },
  { name: "FootballHelmet", id: "FootballHelmet" },
  { name: "Footprints", id: "Footprints" },
  { name: "ForkKnife", id: "ForkKnife" },
  { name: "FourK", id: "FourK" },
  { name: "FrameCorners", id: "FrameCorners" },
  { name: "FramerLogo", id: "FramerLogo" },
  { name: "Function", id: "Function" },
  { name: "Funnel", id: "Funnel" },
  { name: "FunnelSimple", id: "FunnelSimple" },
  { name: "FunnelSimpleX", id: "FunnelSimpleX" },
  { name: "FunnelX", id: "FunnelX" },
  { name: "GameController", id: "GameController" },
  { name: "Garage", id: "Garage" },
  { name: "GasCan", id: "GasCan" },
  { name: "GasPump", id: "GasPump" },
  { name: "Gauge", id: "Gauge" },
  { name: "Gavel", id: "Gavel" },
  { name: "Gear", id: "Gear" },
  { name: "GearFine", id: "GearFine" },
  { name: "GearSix", id: "GearSix" },
  { name: "GenderFemale", id: "GenderFemale" },
  { name: "GenderIntersex", id: "GenderIntersex" },
  { name: "GenderMale", id: "GenderMale" },
  { name: "GenderNeuter", id: "GenderNeuter" },
  { name: "GenderNonbinary", id: "GenderNonbinary" },
  { name: "GenderTransgender", id: "GenderTransgender" },
  { name: "Ghost", id: "Ghost" },
  { name: "Gif", id: "Gif" },
  { name: "Gift", id: "Gift" },
  { name: "GitBranch", id: "GitBranch" },
  { name: "GitCommit", id: "GitCommit" },
  { name: "GitDiff", id: "GitDiff" },
  { name: "GitFork", id: "GitFork" },
  { name: "GitMerge", id: "GitMerge" },
  { name: "GitPullRequest", id: "GitPullRequest" },
  { name: "GithubLogo", id: "GithubLogo" },
  { name: "GitlabLogo", id: "GitlabLogo" },
  { name: "GitlabLogoSimple", id: "GitlabLogoSimple" },
  { name: "Globe", id: "Globe" },
  { name: "GlobeHemisphereEast", id: "GlobeHemisphereEast" },
  { name: "GlobeHemisphereWest", id: "GlobeHemisphereWest" },
  { name: "GlobeSimple", id: "GlobeSimple" },
  { name: "GlobeSimpleX", id: "GlobeSimpleX" },
  { name: "GlobeStand", id: "GlobeStand" },
  { name: "GlobeX", id: "GlobeX" },
  { name: "Goggles", id: "Goggles" },
  { name: "Golf", id: "Golf" },
  { name: "GoodreadsLogo", id: "GoodreadsLogo" },
  { name: "GoogleCardboardLogo", id: "GoogleCardboardLogo" },
  { name: "GoogleChromeLogo", id: "GoogleChromeLogo" },
  { name: "GoogleDriveLogo", id: "GoogleDriveLogo" },
  { name: "GoogleLogo", id: "GoogleLogo" },
  { name: "GooglePhotosLogo", id: "GooglePhotosLogo" },
  { name: "GooglePlayLogo", id: "GooglePlayLogo" },
  { name: "GooglePodcastsLogo", id: "GooglePodcastsLogo" },
  { name: "Gps", id: "Gps" },
  { name: "GpsFix", id: "GpsFix" },
  { name: "GpsSlash", id: "GpsSlash" },
  { name: "Gradient", id: "Gradient" },
  { name: "GraduationCap", id: "GraduationCap" },
  { name: "Grains", id: "Grains" },
  { name: "GrainsSlash", id: "GrainsSlash" },
  { name: "Graph", id: "Graph" },
  { name: "GraphicsCard", id: "GraphicsCard" },
  { name: "GreaterThan", id: "GreaterThan" },
  { name: "GreaterThanOrEqual", id: "GreaterThanOrEqual" },
  { name: "GridFour", id: "GridFour" },
  { name: "GridNine", id: "GridNine" },
  { name: "Guitar", id: "Guitar" },
  { name: "HairDryer", id: "HairDryer" },
  { name: "Hamburger", id: "Hamburger" },
  { name: "Hammer", id: "Hammer" },
  { name: "HandArrowDown", id: "HandArrowDown" },
  { name: "HandArrowUp", id: "HandArrowUp" },
  { name: "Hand", id: "Hand" },
  { name: "HandCoins", id: "HandCoins" },
  { name: "HandDeposit", id: "HandDeposit" },
  { name: "HandEye", id: "HandEye" },
  { name: "HandFist", id: "HandFist" },
  { name: "HandGrabbing", id: "HandGrabbing" },
  { name: "HandHeart", id: "HandHeart" },
  { name: "HandPalm", id: "HandPalm" },
  { name: "HandPeace", id: "HandPeace" },
  { name: "HandPointing", id: "HandPointing" },
  { name: "HandSoap", id: "HandSoap" },
  { name: "HandSwipeLeft", id: "HandSwipeLeft" },
  { name: "HandSwipeRight", id: "HandSwipeRight" },
  { name: "HandTap", id: "HandTap" },
  { name: "HandWaving", id: "HandWaving" },
  { name: "HandWithdraw", id: "HandWithdraw" },
  { name: "Handbag", id: "Handbag" },
  { name: "HandbagSimple", id: "HandbagSimple" },
  { name: "HandsClapping", id: "HandsClapping" },
  { name: "HandsPraying", id: "HandsPraying" },
  { name: "Handshake", id: "Handshake" },
  { name: "HardDrive", id: "HardDrive" },
  { name: "HardDrives", id: "HardDrives" },
  { name: "HardHat", id: "HardHat" },
  { name: "Hash", id: "Hash" },
  { name: "HashStraight", id: "HashStraight" },
  { name: "HeadCircuit", id: "HeadCircuit" },
  { name: "Headlights", id: "Headlights" },
  { name: "Headphones", id: "Headphones" },
  { name: "Headset", id: "Headset" },
  { name: "Heart", id: "Heart" },
  { name: "HeartBreak", id: "HeartBreak" },
  { name: "HeartHalf", id: "HeartHalf" },
  { name: "HeartStraight", id: "HeartStraight" },
  { name: "HeartStraightBreak", id: "HeartStraightBreak" },
  { name: "Heartbeat", id: "Heartbeat" },
  { name: "Hexagon", id: "Hexagon" },
  { name: "HighDefinition", id: "HighDefinition" },
  { name: "HighHeel", id: "HighHeel" },
  { name: "Highlighter", id: "Highlighter" },
  { name: "HighlighterCircle", id: "HighlighterCircle" },
  { name: "Hockey", id: "Hockey" },
  { name: "Hoodie", id: "Hoodie" },
  { name: "Horse", id: "Horse" },
  { name: "Hospital", id: "Hospital" },
  { name: "Hourglass", id: "Hourglass" },
  { name: "HourglassHigh", id: "HourglassHigh" },
  { name: "HourglassLow", id: "HourglassLow" },
  { name: "HourglassMedium", id: "HourglassMedium" },
  { name: "HourglassSimple", id: "HourglassSimple" },
  { name: "HourglassSimpleHigh", id: "HourglassSimpleHigh" },
  { name: "HourglassSimpleLow", id: "HourglassSimpleLow" },
  { name: "HourglassSimpleMedium", id: "HourglassSimpleMedium" },
  { name: "House", id: "House" },
  { name: "HouseLine", id: "HouseLine" },
  { name: "HouseSimple", id: "HouseSimple" },
  { name: "Hurricane", id: "Hurricane" },
  { name: "IceCream", id: "IceCream" },
  { name: "IdentificationBadge", id: "IdentificationBadge" },
  { name: "IdentificationCard", id: "IdentificationCard" },
  { name: "Image", id: "Image" },
  { name: "ImageBroken", id: "ImageBroken" },
  { name: "ImageSquare", id: "ImageSquare" },
  { name: "Images", id: "Images" },
  { name: "ImagesSquare", id: "ImagesSquare" },
  { name: "Infinity", id: "Infinity" },
  { name: "Info", id: "Info" },
  { name: "InstagramLogo", id: "InstagramLogo" },
  { name: "Intersect", id: "Intersect" },
  { name: "IntersectSquare", id: "IntersectSquare" },
  { name: "IntersectThree", id: "IntersectThree" },
  { name: "Intersection", id: "Intersection" },
  { name: "Invoice", id: "Invoice" },
  { name: "Island", id: "Island" },
  { name: "Jar", id: "Jar" },
  { name: "JarLabel", id: "JarLabel" },
  { name: "Jeep", id: "Jeep" },
  { name: "Joystick", id: "Joystick" },
  { name: "Kanban", id: "Kanban" },
  { name: "Key", id: "Key" },
  { name: "KeyReturn", id: "KeyReturn" },
  { name: "Keyboard", id: "Keyboard" },
  { name: "Keyhole", id: "Keyhole" },
  { name: "Knife", id: "Knife" },
  { name: "Ladder", id: "Ladder" },
  { name: "LadderSimple", id: "LadderSimple" },
  { name: "Lamp", id: "Lamp" },
  { name: "LampPendant", id: "LampPendant" },
  { name: "Laptop", id: "Laptop" },
  { name: "Lasso", id: "Lasso" },
  { name: "LastfmLogo", id: "LastfmLogo" },
  { name: "Layout", id: "Layout" },
  { name: "Leaf", id: "Leaf" },
  { name: "Lectern", id: "Lectern" },
  { name: "Lego", id: "Lego" },
  { name: "LegoSmiley", id: "LegoSmiley" },
  { name: "LessThan", id: "LessThan" },
  { name: "LessThanOrEqual", id: "LessThanOrEqual" },
  { name: "LetterCircleH", id: "LetterCircleH" },
  { name: "LetterCircleP", id: "LetterCircleP" },
  { name: "LetterCircleV", id: "LetterCircleV" },
  { name: "Lifebuoy", id: "Lifebuoy" },
  { name: "Lightbulb", id: "Lightbulb" },
  { name: "LightbulbFilament", id: "LightbulbFilament" },
  { name: "Lighthouse", id: "Lighthouse" },
  { name: "LightningA", id: "LightningA" },
  { name: "Lightning", id: "Lightning" },
  { name: "LightningSlash", id: "LightningSlash" },
  { name: "LineSegment", id: "LineSegment" },
  { name: "LineSegments", id: "LineSegments" },
  { name: "LineVertical", id: "LineVertical" },
  { name: "Link", id: "Link" },
  { name: "LinkBreak", id: "LinkBreak" },
  { name: "LinkSimple", id: "LinkSimple" },
  { name: "LinkSimpleBreak", id: "LinkSimpleBreak" },
  { name: "LinkSimpleHorizontal", id: "LinkSimpleHorizontal" },
  { name: "LinkSimpleHorizontalBreak", id: "LinkSimpleHorizontalBreak" },
  { name: "LinkedinLogo", id: "LinkedinLogo" },
  { name: "LinktreeLogo", id: "LinktreeLogo" },
  { name: "LinuxLogo", id: "LinuxLogo" },
  { name: "List", id: "List" },
  { name: "ListBullets", id: "ListBullets" },
  { name: "ListChecks", id: "ListChecks" },
  { name: "ListDashes", id: "ListDashes" },
  { name: "ListHeart", id: "ListHeart" },
  { name: "ListMagnifyingGlass", id: "ListMagnifyingGlass" },
  { name: "ListNumbers", id: "ListNumbers" },
  { name: "ListPlus", id: "ListPlus" },
  { name: "ListStar", id: "ListStar" },
  { name: "Lock", id: "Lock" },
  { name: "LockKey", id: "LockKey" },
  { name: "LockKeyOpen", id: "LockKeyOpen" },
  { name: "LockLaminated", id: "LockLaminated" },
  { name: "LockLaminatedOpen", id: "LockLaminatedOpen" },
  { name: "LockOpen", id: "LockOpen" },
  { name: "LockSimple", id: "LockSimple" },
  { name: "LockSimpleOpen", id: "LockSimpleOpen" },
  { name: "Lockers", id: "Lockers" },
  { name: "Log", id: "Log" },
  { name: "MagicWand", id: "MagicWand" },
  { name: "Magnet", id: "Magnet" },
  { name: "MagnetStraight", id: "MagnetStraight" },
  { name: "MagnifyingGlass", id: "MagnifyingGlass" },
  { name: "MagnifyingGlassMinus", id: "MagnifyingGlassMinus" },
  { name: "MagnifyingGlassPlus", id: "MagnifyingGlassPlus" },
  { name: "Mailbox", id: "Mailbox" },
  { name: "MapPinArea", id: "MapPinArea" },
  { name: "MapPin", id: "MapPin" },
  { name: "MapPinLine", id: "MapPinLine" },
  { name: "MapPinPlus", id: "MapPinPlus" },
  { name: "MapPinSimpleArea", id: "MapPinSimpleArea" },
  { name: "MapPinSimple", id: "MapPinSimple" },
  { name: "MapPinSimpleLine", id: "MapPinSimpleLine" },
  { name: "MapTrifold", id: "MapTrifold" },
  { name: "MarkdownLogo", id: "MarkdownLogo" },
  { name: "MarkerCircle", id: "MarkerCircle" },
  { name: "Martini", id: "Martini" },
  { name: "MaskHappy", id: "MaskHappy" },
  { name: "MaskSad", id: "MaskSad" },
  { name: "MastodonLogo", id: "MastodonLogo" },
  { name: "MathOperations", id: "MathOperations" },
  { name: "MatrixLogo", id: "MatrixLogo" },
  { name: "Medal", id: "Medal" },
  { name: "MedalMilitary", id: "MedalMilitary" },
  { name: "MediumLogo", id: "MediumLogo" },
  { name: "Megaphone", id: "Megaphone" },
  { name: "MegaphoneSimple", id: "MegaphoneSimple" },
  { name: "MemberOf", id: "MemberOf" },
  { name: "Memory", id: "Memory" },
  { name: "MessengerLogo", id: "MessengerLogo" },
  { name: "MetaLogo", id: "MetaLogo" },
  { name: "Meteor", id: "Meteor" },
  { name: "Metronome", id: "Metronome" },
  { name: "Microphone", id: "Microphone" },
  { name: "MicrophoneSlash", id: "MicrophoneSlash" },
  { name: "MicrophoneStage", id: "MicrophoneStage" },
  { name: "Microscope", id: "Microscope" },
  { name: "MicrosoftExcelLogo", id: "MicrosoftExcelLogo" },
  { name: "MicrosoftOutlookLogo", id: "MicrosoftOutlookLogo" },
  { name: "MicrosoftPowerpointLogo", id: "MicrosoftPowerpointLogo" },
  { name: "MicrosoftTeamsLogo", id: "MicrosoftTeamsLogo" },
  { name: "MicrosoftWordLogo", id: "MicrosoftWordLogo" },
  { name: "Minus", id: "Minus" },
  { name: "MinusCircle", id: "MinusCircle" },
  { name: "MinusSquare", id: "MinusSquare" },
  { name: "Money", id: "Money" },
  { name: "MoneyWavy", id: "MoneyWavy" },
  { name: "MonitorArrowUp", id: "MonitorArrowUp" },
  { name: "Monitor", id: "Monitor" },
  { name: "MonitorPlay", id: "MonitorPlay" },
  { name: "Moon", id: "Moon" },
  { name: "MoonStars", id: "MoonStars" },
  { name: "Moped", id: "Moped" },
  { name: "MopedFront", id: "MopedFront" },
  { name: "Mosque", id: "Mosque" },
  { name: "Motorcycle", id: "Motorcycle" },
  { name: "Mountains", id: "Mountains" },
  { name: "Mouse", id: "Mouse" },
  { name: "MouseLeftClick", id: "MouseLeftClick" },
  { name: "MouseMiddleClick", id: "MouseMiddleClick" },
  { name: "MouseRightClick", id: "MouseRightClick" },
  { name: "MouseScroll", id: "MouseScroll" },
  { name: "MouseSimple", id: "MouseSimple" },
  { name: "MusicNote", id: "MusicNote" },
  { name: "MusicNoteSimple", id: "MusicNoteSimple" },
  { name: "MusicNotes", id: "MusicNotes" },
  { name: "MusicNotesMinus", id: "MusicNotesMinus" },
  { name: "MusicNotesPlus", id: "MusicNotesPlus" },
  { name: "MusicNotesSimple", id: "MusicNotesSimple" },
  { name: "NavigationArrow", id: "NavigationArrow" },
  { name: "Needle", id: "Needle" },
  { name: "Network", id: "Network" },
  { name: "NetworkSlash", id: "NetworkSlash" },
  { name: "NetworkX", id: "NetworkX" },
  { name: "Newspaper", id: "Newspaper" },
  { name: "NewspaperClipping", id: "NewspaperClipping" },
  { name: "NotEquals", id: "NotEquals" },
  { name: "NotMemberOf", id: "NotMemberOf" },
  { name: "NotSubsetOf", id: "NotSubsetOf" },
  { name: "NotSupersetOf", id: "NotSupersetOf" },
  { name: "Notches", id: "Notches" },
  { name: "NoteBlank", id: "NoteBlank" },
  { name: "Note", id: "Note" },
  { name: "NotePencil", id: "NotePencil" },
  { name: "Notebook", id: "Notebook" },
  { name: "Notepad", id: "Notepad" },
  { name: "Notification", id: "Notification" },
  { name: "NotionLogo", id: "NotionLogo" },
  { name: "NuclearPlant", id: "NuclearPlant" },
  { name: "NumberCircleEight", id: "NumberCircleEight" },
  { name: "NumberCircleFive", id: "NumberCircleFive" },
  { name: "NumberCircleFour", id: "NumberCircleFour" },
  { name: "NumberCircleNine", id: "NumberCircleNine" },
  { name: "NumberCircleOne", id: "NumberCircleOne" },
  { name: "NumberCircleSeven", id: "NumberCircleSeven" },
  { name: "NumberCircleSix", id: "NumberCircleSix" },
  { name: "NumberCircleThree", id: "NumberCircleThree" },
  { name: "NumberCircleTwo", id: "NumberCircleTwo" },
  { name: "NumberCircleZero", id: "NumberCircleZero" },
  { name: "NumberEight", id: "NumberEight" },
  { name: "NumberFive", id: "NumberFive" },
  { name: "NumberFour", id: "NumberFour" },
  { name: "NumberNine", id: "NumberNine" },
  { name: "NumberOne", id: "NumberOne" },
  { name: "NumberSeven", id: "NumberSeven" },
  { name: "NumberSix", id: "NumberSix" },
  { name: "NumberSquareEight", id: "NumberSquareEight" },
  { name: "NumberSquareFive", id: "NumberSquareFive" },
  { name: "NumberSquareFour", id: "NumberSquareFour" },
  { name: "NumberSquareNine", id: "NumberSquareNine" },
  { name: "NumberSquareOne", id: "NumberSquareOne" },
  { name: "NumberSquareSeven", id: "NumberSquareSeven" },
  { name: "NumberSquareSix", id: "NumberSquareSix" },
  { name: "NumberSquareThree", id: "NumberSquareThree" },
  { name: "NumberSquareTwo", id: "NumberSquareTwo" },
  { name: "NumberSquareZero", id: "NumberSquareZero" },
  { name: "NumberThree", id: "NumberThree" },
  { name: "NumberTwo", id: "NumberTwo" },
  { name: "NumberZero", id: "NumberZero" },
  { name: "Numpad", id: "Numpad" },
  { name: "Nut", id: "Nut" },
  { name: "NyTimesLogo", id: "NyTimesLogo" },
  { name: "Octagon", id: "Octagon" },
  { name: "OfficeChair", id: "OfficeChair" },
  { name: "Onigiri", id: "Onigiri" },
  { name: "OpenAiLogo", id: "OpenAiLogo" },
  { name: "Option", id: "Option" },
  { name: "Orange", id: "Orange" },
  { name: "OrangeSlice", id: "OrangeSlice" },
  { name: "Oven", id: "Oven" },
  { name: "Package", id: "Package" },
  { name: "PaintBrush", id: "PaintBrush" },
  { name: "PaintBrushBroad", id: "PaintBrushBroad" },
  { name: "PaintBrushHousehold", id: "PaintBrushHousehold" },
  { name: "PaintBucket", id: "PaintBucket" },
  { name: "PaintRoller", id: "PaintRoller" },
  { name: "Palette", id: "Palette" },
  { name: "Panorama", id: "Panorama" },
  { name: "Pants", id: "Pants" },
  { name: "PaperPlane", id: "PaperPlane" },
  { name: "PaperPlaneRight", id: "PaperPlaneRight" },
  { name: "PaperPlaneTilt", id: "PaperPlaneTilt" },
  { name: "Paperclip", id: "Paperclip" },
  { name: "PaperclipHorizontal", id: "PaperclipHorizontal" },
  { name: "Parachute", id: "Parachute" },
  { name: "Paragraph", id: "Paragraph" },
  { name: "Parallelogram", id: "Parallelogram" },
  { name: "Park", id: "Park" },
  { name: "Password", id: "Password" },
  { name: "Path", id: "Path" },
  { name: "PatreonLogo", id: "PatreonLogo" },
  { name: "Pause", id: "Pause" },
  { name: "PauseCircle", id: "PauseCircle" },
  { name: "PawPrint", id: "PawPrint" },
  { name: "PaypalLogo", id: "PaypalLogo" },
  { name: "Peace", id: "Peace" },
  { name: "Pen", id: "Pen" },
  { name: "PenNib", id: "PenNib" },
  { name: "PenNibStraight", id: "PenNibStraight" },
  { name: "Pencil", id: "Pencil" },
  { name: "PencilCircle", id: "PencilCircle" },
  { name: "PencilLine", id: "PencilLine" },
  { name: "PencilRuler", id: "PencilRuler" },
  { name: "PencilSimple", id: "PencilSimple" },
  { name: "PencilSimpleLine", id: "PencilSimpleLine" },
  { name: "PencilSimpleSlash", id: "PencilSimpleSlash" },
  { name: "PencilSlash", id: "PencilSlash" },
  { name: "Pentagon", id: "Pentagon" },
  { name: "Pentagram", id: "Pentagram" },
  { name: "Pepper", id: "Pepper" },
  { name: "Percent", id: "Percent" },
  { name: "PersonArmsSpread", id: "PersonArmsSpread" },
  { name: "Person", id: "Person" },
  { name: "PersonSimpleBike", id: "PersonSimpleBike" },
  { name: "PersonSimple", id: "PersonSimple" },
  { name: "PersonSimpleCircle", id: "PersonSimpleCircle" },
  { name: "PersonSimpleHike", id: "PersonSimpleHike" },
  { name: "PersonSimpleRun", id: "PersonSimpleRun" },
  { name: "PersonSimpleSki", id: "PersonSimpleSki" },
  { name: "PersonSimpleSnowboard", id: "PersonSimpleSnowboard" },
  { name: "PersonSimpleSwim", id: "PersonSimpleSwim" },
  { name: "PersonSimpleTaiChi", id: "PersonSimpleTaiChi" },
  { name: "PersonSimpleThrow", id: "PersonSimpleThrow" },
  { name: "PersonSimpleWalk", id: "PersonSimpleWalk" },
  { name: "Perspective", id: "Perspective" },
  { name: "Phone", id: "Phone" },
  { name: "PhoneCall", id: "PhoneCall" },
  { name: "PhoneDisconnect", id: "PhoneDisconnect" },
  { name: "PhoneIncoming", id: "PhoneIncoming" },
  { name: "PhoneList", id: "PhoneList" },
  { name: "PhoneOutgoing", id: "PhoneOutgoing" },
  { name: "PhonePause", id: "PhonePause" },
  { name: "PhonePlus", id: "PhonePlus" },
  { name: "PhoneSlash", id: "PhoneSlash" },
  { name: "PhoneTransfer", id: "PhoneTransfer" },
  { name: "PhoneX", id: "PhoneX" },
  { name: "PhosphorLogo", id: "PhosphorLogo" },
  { name: "Pi", id: "Pi" },
  { name: "PianoKeys", id: "PianoKeys" },
  { name: "PicnicTable", id: "PicnicTable" },
  { name: "PictureInPicture", id: "PictureInPicture" },
  { name: "PiggyBank", id: "PiggyBank" },
  { name: "Pill", id: "Pill" },
  { name: "PingPong", id: "PingPong" },
  { name: "PintGlass", id: "PintGlass" },
  { name: "PinterestLogo", id: "PinterestLogo" },
  { name: "Pinwheel", id: "Pinwheel" },
  { name: "Pipe", id: "Pipe" },
  { name: "PipeWrench", id: "PipeWrench" },
  { name: "PixLogo", id: "PixLogo" },
  { name: "Pizza", id: "Pizza" },
  { name: "Placeholder", id: "Placeholder" },
  { name: "Planet", id: "Planet" },
  { name: "Plant", id: "Plant" },
  { name: "Play", id: "Play" },
  { name: "PlayCircle", id: "PlayCircle" },
  { name: "PlayPause", id: "PlayPause" },
  { name: "Playlist", id: "Playlist" },
  { name: "Plug", id: "Plug" },
  { name: "PlugCharging", id: "PlugCharging" },
  { name: "Plugs", id: "Plugs" },
  { name: "PlugsConnected", id: "PlugsConnected" },
  { name: "Plus", id: "Plus" },
  { name: "PlusCircle", id: "PlusCircle" },
  { name: "PlusMinus", id: "PlusMinus" },
  { name: "PlusSquare", id: "PlusSquare" },
  { name: "PokerChip", id: "PokerChip" },
  { name: "PoliceCar", id: "PoliceCar" },
  { name: "Polygon", id: "Polygon" },
  { name: "Popcorn", id: "Popcorn" },
  { name: "Popsicle", id: "Popsicle" },
  { name: "PottedPlant", id: "PottedPlant" },
  { name: "Power", id: "Power" },
  { name: "Prescription", id: "Prescription" },
  { name: "Presentation", id: "Presentation" },
  { name: "PresentationChart", id: "PresentationChart" },
  { name: "Printer", id: "Printer" },
  { name: "Prohibit", id: "Prohibit" },
  { name: "ProhibitInset", id: "ProhibitInset" },
  { name: "ProjectorScreen", id: "ProjectorScreen" },
  { name: "ProjectorScreenChart", id: "ProjectorScreenChart" },
  { name: "Pulse", id: "Pulse" },
  { name: "PushPin", id: "PushPin" },
  { name: "PushPinSimple", id: "PushPinSimple" },
  { name: "PushPinSimpleSlash", id: "PushPinSimpleSlash" },
  { name: "PushPinSlash", id: "PushPinSlash" },
  { name: "PuzzlePiece", id: "PuzzlePiece" },
  { name: "QrCode", id: "QrCode" },
  { name: "Question", id: "Question" },
  { name: "QuestionMark", id: "QuestionMark" },
  { name: "Queue", id: "Queue" },
  { name: "Quotes", id: "Quotes" },
  { name: "Rabbit", id: "Rabbit" },
  { name: "Racquet", id: "Racquet" },
  { name: "Radical", id: "Radical" },
  { name: "Radio", id: "Radio" },
  { name: "RadioButton", id: "RadioButton" },
  { name: "Radioactive", id: "Radioactive" },
  { name: "Rainbow", id: "Rainbow" },
  { name: "RainbowCloud", id: "RainbowCloud" },
  { name: "Ranking", id: "Ranking" },
  { name: "ReadCvLogo", id: "ReadCvLogo" },
  { name: "Receipt", id: "Receipt" },
  { name: "ReceiptX", id: "ReceiptX" },
  { name: "Record", id: "Record" },
  { name: "Rectangle", id: "Rectangle" },
  { name: "RectangleDashed", id: "RectangleDashed" },
  { name: "Recycle", id: "Recycle" },
  { name: "RedditLogo", id: "RedditLogo" },
  { name: "Repeat", id: "Repeat" },
  { name: "RepeatOnce", id: "RepeatOnce" },
  { name: "ReplitLogo", id: "ReplitLogo" },
  { name: "Resize", id: "Resize" },
  { name: "Rewind", id: "Rewind" },
  { name: "RewindCircle", id: "RewindCircle" },
  { name: "RoadHorizon", id: "RoadHorizon" },
  { name: "Robot", id: "Robot" },
  { name: "Rocket", id: "Rocket" },
  { name: "RocketLaunch", id: "RocketLaunch" },
  { name: "Rows", id: "Rows" },
  { name: "RowsPlusBottom", id: "RowsPlusBottom" },
  { name: "RowsPlusTop", id: "RowsPlusTop" },
  { name: "Rss", id: "Rss" },
  { name: "RssSimple", id: "RssSimple" },
  { name: "Rug", id: "Rug" },
  { name: "Ruler", id: "Ruler" },
  { name: "Sailboat", id: "Sailboat" },
  { name: "Scales", id: "Scales" },
  { name: "Scan", id: "Scan" },
  { name: "ScanSmiley", id: "ScanSmiley" },
  { name: "Scissors", id: "Scissors" },
  { name: "Scooter", id: "Scooter" },
  { name: "Screencast", id: "Screencast" },
  { name: "Screwdriver", id: "Screwdriver" },
  { name: "Scribble", id: "Scribble" },
  { name: "ScribbleLoop", id: "ScribbleLoop" },
  { name: "Scroll", id: "Scroll" },
  { name: "Seal", id: "Seal" },
  { name: "SealCheck", id: "SealCheck" },
  { name: "SealPercent", id: "SealPercent" },
  { name: "SealQuestion", id: "SealQuestion" },
  { name: "SealWarning", id: "SealWarning" },
  { name: "Seat", id: "Seat" },
  { name: "Seatbelt", id: "Seatbelt" },
  { name: "SecurityCamera", id: "SecurityCamera" },
  { name: "SelectionAll", id: "SelectionAll" },
  { name: "SelectionBackground", id: "SelectionBackground" },
  { name: "Selection", id: "Selection" },
  { name: "SelectionForeground", id: "SelectionForeground" },
  { name: "SelectionInverse", id: "SelectionInverse" },
  { name: "SelectionPlus", id: "SelectionPlus" },
  { name: "SelectionSlash", id: "SelectionSlash" },
  { name: "Shapes", id: "Shapes" },
  { name: "Share", id: "Share" },
  { name: "ShareFat", id: "ShareFat" },
  { name: "ShareNetwork", id: "ShareNetwork" },
  { name: "Shield", id: "Shield" },
  { name: "ShieldCheck", id: "ShieldCheck" },
  { name: "ShieldCheckered", id: "ShieldCheckered" },
  { name: "ShieldChevron", id: "ShieldChevron" },
  { name: "ShieldPlus", id: "ShieldPlus" },
  { name: "ShieldSlash", id: "ShieldSlash" },
  { name: "ShieldStar", id: "ShieldStar" },
  { name: "ShieldWarning", id: "ShieldWarning" },
  { name: "ShippingContainer", id: "ShippingContainer" },
  { name: "ShirtFolded", id: "ShirtFolded" },
  { name: "ShootingStar", id: "ShootingStar" },
  { name: "ShoppingBag", id: "ShoppingBag" },
  { name: "ShoppingBagOpen", id: "ShoppingBagOpen" },
  { name: "ShoppingCart", id: "ShoppingCart" },
  { name: "ShoppingCartSimple", id: "ShoppingCartSimple" },
  { name: "Shovel", id: "Shovel" },
  { name: "Shower", id: "Shower" },
  { name: "Shrimp", id: "Shrimp" },
  { name: "ShuffleAngular", id: "ShuffleAngular" },
  { name: "Shuffle", id: "Shuffle" },
  { name: "ShuffleSimple", id: "ShuffleSimple" },
  { name: "Sidebar", id: "Sidebar" },
  { name: "SidebarSimple", id: "SidebarSimple" },
  { name: "Sigma", id: "Sigma" },
  { name: "SignIn", id: "SignIn" },
  { name: "SignOut", id: "SignOut" },
  { name: "Signature", id: "Signature" },
  { name: "Signpost", id: "Signpost" },
  { name: "SimCard", id: "SimCard" },
  { name: "Siren", id: "Siren" },
  { name: "SketchLogo", id: "SketchLogo" },
  { name: "SkipBack", id: "SkipBack" },
  { name: "SkipBackCircle", id: "SkipBackCircle" },
  { name: "SkipForward", id: "SkipForward" },
  { name: "SkipForwardCircle", id: "SkipForwardCircle" },
  { name: "Skull", id: "Skull" },
  { name: "SkypeLogo", id: "SkypeLogo" },
  { name: "SlackLogo", id: "SlackLogo" },
  { name: "Sliders", id: "Sliders" },
  { name: "SlidersHorizontal", id: "SlidersHorizontal" },
  { name: "Slideshow", id: "Slideshow" },
  { name: "SmileyAngry", id: "SmileyAngry" },
  { name: "SmileyBlank", id: "SmileyBlank" },
  { name: "Smiley", id: "Smiley" },
  { name: "SmileyMeh", id: "SmileyMeh" },
  { name: "SmileyMelting", id: "SmileyMelting" },
  { name: "SmileyNervous", id: "SmileyNervous" },
  { name: "SmileySad", id: "SmileySad" },
  { name: "SmileySticker", id: "SmileySticker" },
  { name: "SmileyWink", id: "SmileyWink" },
  { name: "SmileyXEyes", id: "SmileyXEyes" },
  { name: "SnapchatLogo", id: "SnapchatLogo" },
  { name: "Sneaker", id: "Sneaker" },
  { name: "SneakerMove", id: "SneakerMove" },
  { name: "Snowflake", id: "Snowflake" },
  { name: "SoccerBall", id: "SoccerBall" },
  { name: "Sock", id: "Sock" },
  { name: "SolarPanel", id: "SolarPanel" },
  { name: "SolarRoof", id: "SolarRoof" },
  { name: "SortAscending", id: "SortAscending" },
  { name: "SortDescending", id: "SortDescending" },
  { name: "SoundcloudLogo", id: "SoundcloudLogo" },
  { name: "Spade", id: "Spade" },
  { name: "Sparkle", id: "Sparkle" },
  { name: "SpeakerHifi", id: "SpeakerHifi" },
  { name: "SpeakerHigh", id: "SpeakerHigh" },
  { name: "SpeakerLow", id: "SpeakerLow" },
  { name: "SpeakerNone", id: "SpeakerNone" },
  { name: "SpeakerSimpleHigh", id: "SpeakerSimpleHigh" },
  { name: "SpeakerSimpleLow", id: "SpeakerSimpleLow" },
  { name: "SpeakerSimpleNone", id: "SpeakerSimpleNone" },
  { name: "SpeakerSimpleSlash", id: "SpeakerSimpleSlash" },
  { name: "SpeakerSimpleX", id: "SpeakerSimpleX" },
  { name: "SpeakerSlash", id: "SpeakerSlash" },
  { name: "SpeakerX", id: "SpeakerX" },
  { name: "Speedometer", id: "Speedometer" },
  { name: "Sphere", id: "Sphere" },
  { name: "SpinnerBall", id: "SpinnerBall" },
  { name: "Spinner", id: "Spinner" },
  { name: "SpinnerGap", id: "SpinnerGap" },
  { name: "Spiral", id: "Spiral" },
  { name: "SplitHorizontal", id: "SplitHorizontal" },
  { name: "SplitVertical", id: "SplitVertical" },
  { name: "SpotifyLogo", id: "SpotifyLogo" },
  { name: "SprayBottle", id: "SprayBottle" },
  { name: "Square", id: "Square" },
  { name: "SquareHalf", id: "SquareHalf" },
  { name: "SquareHalfBottom", id: "SquareHalfBottom" },
  { name: "SquareLogo", id: "SquareLogo" },
  { name: "SquareSplitHorizontal", id: "SquareSplitHorizontal" },
  { name: "SquareSplitVertical", id: "SquareSplitVertical" },
  { name: "SquaresFour", id: "SquaresFour" },
  { name: "Stack", id: "Stack" },
  { name: "StackMinus", id: "StackMinus" },
  { name: "StackOverflowLogo", id: "StackOverflowLogo" },
  { name: "StackPlus", id: "StackPlus" },
  { name: "StackSimple", id: "StackSimple" },
  { name: "Stairs", id: "Stairs" },
  { name: "Stamp", id: "Stamp" },
  { name: "StandardDefinition", id: "StandardDefinition" },
  { name: "StarAndCrescent", id: "StarAndCrescent" },
  { name: "Star", id: "Star" },
  { name: "StarFour", id: "StarFour" },
  { name: "StarHalf", id: "StarHalf" },
  { name: "StarOfDavid", id: "StarOfDavid" },
  { name: "SteamLogo", id: "SteamLogo" },
  { name: "SteeringWheel", id: "SteeringWheel" },
  { name: "Steps", id: "Steps" },
  { name: "Stethoscope", id: "Stethoscope" },
  { name: "Sticker", id: "Sticker" },
  { name: "Stool", id: "Stool" },
  { name: "Stop", id: "Stop" },
  { name: "StopCircle", id: "StopCircle" },
  { name: "Storefront", id: "Storefront" },
  { name: "Strategy", id: "Strategy" },
  { name: "StripeLogo", id: "StripeLogo" },
  { name: "Student", id: "Student" },
  { name: "SubsetOf", id: "SubsetOf" },
  { name: "SubsetProperOf", id: "SubsetProperOf" },
  { name: "Subtitles", id: "Subtitles" },
  { name: "SubtitlesSlash", id: "SubtitlesSlash" },
  { name: "Subtract", id: "Subtract" },
  { name: "SubtractSquare", id: "SubtractSquare" },
  { name: "Subway", id: "Subway" },
  { name: "Suitcase", id: "Suitcase" },
  { name: "SuitcaseRolling", id: "SuitcaseRolling" },
  { name: "SuitcaseSimple", id: "SuitcaseSimple" },
  { name: "Sun", id: "Sun" },
  { name: "SunDim", id: "SunDim" },
  { name: "SunHorizon", id: "SunHorizon" },
  { name: "Sunglasses", id: "Sunglasses" },
  { name: "SupersetOf", id: "SupersetOf" },
  { name: "SupersetProperOf", id: "SupersetProperOf" },
  { name: "Swap", id: "Swap" },
  { name: "Swatches", id: "Swatches" },
  { name: "SwimmingPool", id: "SwimmingPool" },
  { name: "Sword", id: "Sword" },
  { name: "Synagogue", id: "Synagogue" },
  { name: "Syringe", id: "Syringe" },
  { name: "TShirt", id: "TShirt" },
  { name: "Table", id: "Table" },
  { name: "Tabs", id: "Tabs" },
  { name: "Tag", id: "Tag" },
  { name: "TagChevron", id: "TagChevron" },
  { name: "TagSimple", id: "TagSimple" },
  { name: "Target", id: "Target" },
  { name: "Taxi", id: "Taxi" },
  { name: "TeaBag", id: "TeaBag" },
  { name: "TelegramLogo", id: "TelegramLogo" },
  { name: "Television", id: "Television" },
  { name: "TelevisionSimple", id: "TelevisionSimple" },
  { name: "TennisBall", id: "TennisBall" },
  { name: "Tent", id: "Tent" },
  { name: "Terminal", id: "Terminal" },
  { name: "TerminalWindow", id: "TerminalWindow" },
  { name: "TestTube", id: "TestTube" },
  { name: "TextAUnderline", id: "TextAUnderline" },
  { name: "TextAa", id: "TextAa" },
  { name: "TextAlignCenter", id: "TextAlignCenter" },
  { name: "TextAlignJustify", id: "TextAlignJustify" },
  { name: "TextAlignLeft", id: "TextAlignLeft" },
  { name: "TextAlignRight", id: "TextAlignRight" },
  { name: "TextB", id: "TextB" },
  { name: "TextColumns", id: "TextColumns" },
  { name: "TextH", id: "TextH" },
  { name: "TextHFive", id: "TextHFive" },
  { name: "TextHFour", id: "TextHFour" },
  { name: "TextHOne", id: "TextHOne" },
  { name: "TextHSix", id: "TextHSix" },
  { name: "TextHThree", id: "TextHThree" },
  { name: "TextHTwo", id: "TextHTwo" },
  { name: "TextIndent", id: "TextIndent" },
  { name: "TextItalic", id: "TextItalic" },
  { name: "TextOutdent", id: "TextOutdent" },
  { name: "TextStrikethrough", id: "TextStrikethrough" },
  { name: "TextSubscript", id: "TextSubscript" },
  { name: "TextSuperscript", id: "TextSuperscript" },
  { name: "TextT", id: "TextT" },
  { name: "TextTSlash", id: "TextTSlash" },
  { name: "TextUnderline", id: "TextUnderline" },
  { name: "Textbox", id: "Textbox" },
  { name: "Thermometer", id: "Thermometer" },
  { name: "ThermometerCold", id: "ThermometerCold" },
  { name: "ThermometerHot", id: "ThermometerHot" },
  { name: "ThermometerSimple", id: "ThermometerSimple" },
  { name: "ThreadsLogo", id: "ThreadsLogo" },
  { name: "ThreeD", id: "ThreeD" },
  { name: "ThumbsDown", id: "ThumbsDown" },
  { name: "ThumbsUp", id: "ThumbsUp" },
  { name: "Ticket", id: "Ticket" },
  { name: "TidalLogo", id: "TidalLogo" },
  { name: "TiktokLogo", id: "TiktokLogo" },
  { name: "Tilde", id: "Tilde" },
  { name: "Timer", id: "Timer" },
  { name: "TipJar", id: "TipJar" },
  { name: "Tipi", id: "Tipi" },
  { name: "Tire", id: "Tire" },
  { name: "ToggleLeft", id: "ToggleLeft" },
  { name: "ToggleRight", id: "ToggleRight" },
  { name: "Toilet", id: "Toilet" },
  { name: "ToiletPaper", id: "ToiletPaper" },
  { name: "Toolbox", id: "Toolbox" },
  { name: "Tooth", id: "Tooth" },
  { name: "Tornado", id: "Tornado" },
  { name: "Tote", id: "Tote" },
  { name: "ToteSimple", id: "ToteSimple" },
  { name: "Towel", id: "Towel" },
  { name: "Tractor", id: "Tractor" },
  { name: "Trademark", id: "Trademark" },
  { name: "TrademarkRegistered", id: "TrademarkRegistered" },
  { name: "TrafficCone", id: "TrafficCone" },
  { name: "TrafficSign", id: "TrafficSign" },
  { name: "TrafficSignal", id: "TrafficSignal" },
  { name: "Train", id: "Train" },
  { name: "TrainRegional", id: "TrainRegional" },
  { name: "TrainSimple", id: "TrainSimple" },
  { name: "Tram", id: "Tram" },
  { name: "Translate", id: "Translate" },
  { name: "Trash", id: "Trash" },
  { name: "TrashSimple", id: "TrashSimple" },
  { name: "TrayArrowDown", id: "TrayArrowDown" },
  { name: "TrayArrowUp", id: "TrayArrowUp" },
  { name: "Tray", id: "Tray" },
  { name: "TreasureChest", id: "TreasureChest" },
  { name: "Tree", id: "Tree" },
  { name: "TreeEvergreen", id: "TreeEvergreen" },
  { name: "TreePalm", id: "TreePalm" },
  { name: "TreeStructure", id: "TreeStructure" },
  { name: "TreeView", id: "TreeView" },
  { name: "TrendDown", id: "TrendDown" },
  { name: "TrendUp", id: "TrendUp" },
  { name: "Triangle", id: "Triangle" },
  { name: "TriangleDashed", id: "TriangleDashed" },
  { name: "Trolley", id: "Trolley" },
  { name: "TrolleySuitcase", id: "TrolleySuitcase" },
  { name: "Trophy", id: "Trophy" },
  { name: "Truck", id: "Truck" },
  { name: "TruckTrailer", id: "TruckTrailer" },
  { name: "TumblrLogo", id: "TumblrLogo" },
  { name: "TwitchLogo", id: "TwitchLogo" },
  { name: "TwitterLogo", id: "TwitterLogo" },
  { name: "Umbrella", id: "Umbrella" },
  { name: "UmbrellaSimple", id: "UmbrellaSimple" },
  { name: "Union", id: "Union" },
  { name: "Unite", id: "Unite" },
  { name: "UniteSquare", id: "UniteSquare" },
  { name: "Upload", id: "Upload" },
  { name: "UploadSimple", id: "UploadSimple" },
  { name: "Usb", id: "Usb" },
  { name: "User", id: "User" },
  { name: "UserCheck", id: "UserCheck" },
  { name: "UserCircle", id: "UserCircle" },
  { name: "UserCircleCheck", id: "UserCircleCheck" },
  { name: "UserCircleDashed", id: "UserCircleDashed" },
  { name: "UserCircleGear", id: "UserCircleGear" },
  { name: "UserCircleMinus", id: "UserCircleMinus" },
  { name: "UserCirclePlus", id: "UserCirclePlus" },
  { name: "UserFocus", id: "UserFocus" },
  { name: "UserGear", id: "UserGear" },
  { name: "UserList", id: "UserList" },
  { name: "UserMinus", id: "UserMinus" },
  { name: "UserPlus", id: "UserPlus" },
  { name: "UserRectangle", id: "UserRectangle" },
  { name: "UserSound", id: "UserSound" },
  { name: "UserSquare", id: "UserSquare" },
  { name: "UserSwitch", id: "UserSwitch" },
  { name: "Users", id: "Users" },
  { name: "UsersFour", id: "UsersFour" },
  { name: "UsersThree", id: "UsersThree" },
  { name: "Van", id: "Van" },
  { name: "Vault", id: "Vault" },
  { name: "VectorThree", id: "VectorThree" },
  { name: "VectorTwo", id: "VectorTwo" },
  { name: "Vibrate", id: "Vibrate" },
  { name: "Video", id: "Video" },
  { name: "VideoCamera", id: "VideoCamera" },
  { name: "VideoCameraSlash", id: "VideoCameraSlash" },
  { name: "VideoConference", id: "VideoConference" },
  { name: "Vignette", id: "Vignette" },
  { name: "VinylRecord", id: "VinylRecord" },
  { name: "VirtualReality", id: "VirtualReality" },
  { name: "Virus", id: "Virus" },
  { name: "Visor", id: "Visor" },
  { name: "Voicemail", id: "Voicemail" },
  { name: "Volleyball", id: "Volleyball" },
  { name: "Wall", id: "Wall" },
  { name: "Wallet", id: "Wallet" },
  { name: "Warehouse", id: "Warehouse" },
  { name: "Warning", id: "Warning" },
  { name: "WarningCircle", id: "WarningCircle" },
  { name: "WarningDiamond", id: "WarningDiamond" },
  { name: "WarningOctagon", id: "WarningOctagon" },
  { name: "WashingMachine", id: "WashingMachine" },
  { name: "Watch", id: "Watch" },
  { name: "WaveSawtooth", id: "WaveSawtooth" },
  { name: "WaveSine", id: "WaveSine" },
  { name: "WaveSquare", id: "WaveSquare" },
  { name: "WaveTriangle", id: "WaveTriangle" },
  { name: "Waveform", id: "Waveform" },
  { name: "WaveformSlash", id: "WaveformSlash" },
  { name: "Waves", id: "Waves" },
  { name: "Webcam", id: "Webcam" },
  { name: "WebcamSlash", id: "WebcamSlash" },
  { name: "WebhooksLogo", id: "WebhooksLogo" },
  { name: "WechatLogo", id: "WechatLogo" },
  { name: "WhatsappLogo", id: "WhatsappLogo" },
  { name: "Wheelchair", id: "Wheelchair" },
  { name: "WheelchairMotion", id: "WheelchairMotion" },
  { name: "WifiHigh", id: "WifiHigh" },
  { name: "WifiLow", id: "WifiLow" },
  { name: "WifiMedium", id: "WifiMedium" },
  { name: "WifiNone", id: "WifiNone" },
  { name: "WifiSlash", id: "WifiSlash" },
  { name: "WifiX", id: "WifiX" },
  { name: "Wind", id: "Wind" },
  { name: "Windmill", id: "Windmill" },
  { name: "WindowsLogo", id: "WindowsLogo" },
  { name: "Wine", id: "Wine" },
  { name: "Wrench", id: "Wrench" },
  { name: "X", id: "X" },
  { name: "XCircle", id: "XCircle" },
  { name: "XLogo", id: "XLogo" },
  { name: "XSquare", id: "XSquare" },
  { name: "Yarn", id: "Yarn" },
  { name: "YinYang", id: "YinYang" },
  { name: "YoutubeLogo", id: "YoutubeLogo" },
];
