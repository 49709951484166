import { ItinResourceProps } from "../types";
import { Handshake } from "@phosphor-icons/react";
import { DmoList } from "./DmoList";
import { DmoShow } from "./DmoShow";

export const DmoResource: ItinResourceProps = {
  name: "dmos",
  list: DmoList,
  show: DmoShow,
  icon: <Handshake />,
  recordRepresentation: "name",
  options: { menu_group: "collaborate", label: "Organizations" },
};
export * from "./LinkedDmos";
