import { Box, Grid, Typography } from "@mui/material";
import { Article, FileHtml, ImageSquare, Plus } from "@phosphor-icons/react";
import { MarkdownLogo } from "@phosphor-icons/react/dist/ssr";
import { ReferenceManyInput } from "@react-admin/ra-relationships";
import { map } from "lodash";
import { useRef, useState } from "react";
import {
  Button,
  FormDataConsumer,
  Labeled,
  Link,
  RecordContextProvider,
  SimpleFormIterator,
  TextField,
  TextInput,
  useCreate,
  useGetList,
  useGetManyReference,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { GridCard } from "~/components/GridCard";
import { classifyResource, titleize } from "~/helpers";
import { useTargetType } from "~/hooks";
import { useContentField } from "./hooks";

export const ContentInlineEditList = ({
  disableAdd,
  disableRemove,
  filter,
}) => {
  const target_type = useTargetType();

  return (
    <Grid container>
      <ReferenceManyInput
        reference="contents"
        target="target_id"
        label={false}
        filter={{ target_type, ...filter }}
      >
        <SimpleFormIterator
          fullWidth
          disableClear
          disableReordering
          disableAdd={true}
          disableRemove={disableRemove}
          sx={{ "& .RaSimpleFormIterator-line": { paddingY: 2 } }}
        >
          <FormDataConsumer>
            {(props) => <ContentInlineEditForm {...props} />}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ReferenceManyInput>
      {!disableAdd && <AvailableContentTypeButtons />}
    </Grid>
  );
};

const ContentInlineEditForm = ({
  getSource: baseGetSource,
  scopedFormData,
}) => {
  const getSource = useRef(baseGetSource).current;
  const [record] = useState(() => ({ ...scopedFormData }));
  const { content_type_type, content_type_name, content_type_use_title } =
    record;
  const field = useContentField({ getSource, type: content_type_type });

  const label = titleize(content_type_name);
  return (
    <Labeled label={label} fullWidth>
      <RecordContextProvider value={record}>
        <TextField source="content_type_description" />
        <GridCard
          width="100%"
          sx={{ minHeight: "20vh", maxHeight: "70vh", overflow: "auto" }}
        >
          {content_type_use_title && (
            <TextInput
              source={getSource("title")}
              label={false}
              helperText="Optional Title"
            />
          )}
          {field}
        </GridCard>
      </RecordContextProvider>
    </Labeled>
  );
};

export const AvailableContentTypeButtons = (props) => {
  const target_type = useTargetType();

  const resource = useResourceContext(props);
  const record = useRecordContext(props);

  const { data: contents, isLoading } = useGetManyReference("contents", {
    target: "target_id",
    id: record.id,
    filter: { target_type },
  });

  const { data: available_content_types, isLoading: isLoadingContentTypes } =
    useGetList("content_types", {
      filter: {
        class_type: classifyResource(resource),
        not: { id: map(contents, "content_type_id") },
      },
    });

  if (isLoading || isLoadingContentTypes) return "loading...";
  return available_content_types?.map((act) => (
    <CreateContentButton key={act.id} act={act} />
  ));
};

const CONTENT_TYPE_ICONS = {
  "ContentType::Markdown": <MarkdownLogo />,
  "ContentType::Media": <ImageSquare />,
  "ContentType::MediaAndMarkdown": <ImageSquare />,
  "ContentType::BlockEditor": <FileHtml />,
  "ContentType::PlainText": <Article />,
  "ContentType::Url": <Link />,
};

const CreateContentButton = ({ act }) => {
  const record = useRecordContext();

  const [create] = useCreate("contents", {
    data: {
      target_type: act.class_type,
      target_id: record.id,
      content_type_id: act.id,
    },
  });

  const handleClick = () => {
    create();
  };
  return (
    <GridCard item xs={4} justifyContent="center">
      <Typography variant="h2" align="center">
        {CONTENT_TYPE_ICONS[act.type]}
      </Typography>
      <Typography variant="h6" align="center">
        {titleize(act.name)}
      </Typography>
      <Typography align="center">
        <em>{act.description}</em>
      </Typography>
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          startIcon={<Plus />}
          label={"Add Content"}
          onClick={handleClick}
          variant="contained"
          sx={{ width: "150px" }}
        />
      </Box>
    </GridCard>
  );
};
