import { ItinResourceProps } from "../types";
import { FlagCheckered } from "@phosphor-icons/react";
import { ChallengeList } from "./ChallengeList";
import { ChallengeEdit } from "./ChallengeEdit";
import { ChallengeCreate } from "./ChallengeCreate";

export const ChallengeResource: ItinResourceProps = {
  name: "challenges",
  list: ChallengeList,
  edit: ChallengeEdit,
  create: ChallengeCreate,
  icon: <FlagCheckered weight="fill" />,
  recordRepresentation: (r) => r.name,
  options: {menu_group: "contesting"}

};
