import {
  IconButton,
  FormControl,
  InputLabel,
  Menu,
  Box,
  FormHelperText,
} from "@mui/material";
import { useMenuAnchor } from "~/hooks";
import { map } from "lodash";
import { UserCircle } from "@phosphor-icons/react";
import {
  CommonInputProps,
  FieldTitle,
  InputHelperText,
  useInput,
  useResourceContext,
} from "react-admin";
import { HELP_TOPIC_ICONS } from "~/resources/help_topics/HelpTopicIcons";

export const IconPickerInput = (props: CommonInputProps) => {
  const { anchorEl, open, handleClick, handleClose } = useMenuAnchor();
  const {
    field: { value, onChange },
    fieldState: { error, isTouched },
    formState: { isSubmitted },
    isRequired,
  } = useInput(props);
  const { source, label, helperText } = props;
  const resource = useResourceContext();
  const { Icon = UserCircle, props: iconProps } = HELP_TOPIC_ICONS[value] || {};
  return (
    <>
      <FormControl sx={{ width: "100%" }} error={!!error}>
        <InputLabel shrink={false}>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </InputLabel>
        <IconButton
          size="large"
          color={!error ? "primary" : "error"}
          sx={{
            alignSelf: "flex-start",
            backgroundColor: `${!error ? "blue" : "red"}.100`,
            borderRadius: 2,
            "&:hover": {
              backgroundColor: "primary.main",
              color: "blue.100",
            },
          }}
          onClick={handleClick}
        >
          <Icon {...iconProps} />
        </IconButton>
        <FormHelperText error={!!error}>
          <InputHelperText
            touched={isTouched || isSubmitted}
            error={error?.message}
            helperText={helperText}
          />
        </FormHelperText>
      </FormControl>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        <Box sx={{ width: `${52 * 5}px` }}>
          {map(HELP_TOPIC_ICONS, ({ Icon, props: iconProps }, key) => {
            return (
              <IconButton
                size="large"
                key={key}
                color="primary"
                sx={{
                  borderRadius: 2,
                  alignSelf: "flex-start",
                  backgroundColor: key === value ? "blue.100" : undefined,
                  "&:hover": {
                    backgroundColor: "blue.100",
                  },
                }}
                onClick={() => {
                  onChange(key);
                  handleClose();
                }}
              >
                <Icon {...iconProps} />
              </IconButton>
            );
          })}
        </Box>
      </Menu>
    </>
  );
};
