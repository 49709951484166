import { Avatar, Grid, Typography } from "@mui/material";
import { get } from "lodash";
import { Buildings } from "@phosphor-icons/react";
import { useGetList } from "react-admin";

import {
  DateField,
  ListContextProvider,
  SimpleList,
  useList,
} from "react-admin";
import { useCurrentPlatform } from "~/context";

export const RecentlyUpdatedContent = ({ props }) => {
  const { currentPlatform, isLoading: currentPlatformLoading } =
    useCurrentPlatform();
  const { data, isLoading, isRefetching } = useGetList("logs", {
    filter: {
      platform_id: currentPlatform.id,
      not: { target_type: "User" },
    },
    pagination: { page: 1, perPage: 5 },
  });
  const listContext = useList({ data });
  return (
    !isLoading && (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" mb={2} textTransform="capitalize">
            Recent Updates
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ListContextProvider value={listContext}>
            <SimpleList
              linkType={"edit"}
              primaryText={(record) => record.target_name}
              tertiaryText={(record) => {
                const date = get(record, "created_at");
                return !!date && new Date(date).toLocaleDateString();
              }}
              leftAvatar={(record) => {
                return (
                  <Avatar src={record.hero?.url}>
                    <Buildings />
                  </Avatar>
                );
              }}
            />
          </ListContextProvider>
        </Grid>
      </Grid>
    )
  );
};
