import {
  Box,
  FormControl,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonProps,
  Tooltip,
} from "@mui/material";
import { BoundingBox, Hand, MapPin } from "@phosphor-icons/react";
import { DrawingManagerF } from "@react-google-maps/api";
import { forwardRef, useState } from "react";
import { useSimpleFormIterator } from "react-admin";
import { useFormContext } from "react-hook-form";
import { getNestedAttributeKey } from "~/hooks/useAcceptsNestedAttributes";
import { CursorIcon } from "../CursorIcon";
import { googlePolygonToWkt } from "./helpers";

type DrawingProps = {
  multipolygon_id: string;
};

export const DrawingManager = ({ multipolygon_id }: DrawingProps) => {
  const [drawingMode, setDrawingMode] = useState<
    google.maps.drawing.OverlayType | ""
  >("");
  const { add } = useSimpleFormIterator();
  const { setValue } = useFormContext();
  const point_source = getNestedAttributeKey("points");
  return (
    <Box sx={{ position: "absolute", top: 10, right: 10 }}>
      <DrawingManagerF
        options={{ drawingControl: false }}
        drawingMode={drawingMode || null}
        onPolygonComplete={(polygon) => {
          const bounds = googlePolygonToWkt(polygon);
          // @ts-expect-error add is incorrectly typed and does accept arguments
          add({ bounds });
          polygon.setMap(null);
          setDrawingMode("");
        }}
        onMarkerComplete={(marker) => {
          const position = marker.getPosition();
          const latitude = position?.lat();
          const longitude = position?.lng();
          if (latitude && longitude) {
            setValue(
              point_source,
              { latitude, longitude },
              { shouldDirty: true, shouldTouch: true }
            );
            marker.setMap(null);
            setDrawingMode("");
          }
        }}
      />

      <CursorIcon color="error" Icon={MapPin} show={drawingMode === "marker"} />
      <FormControl sx={{ margin: 0 }}>
        <ToggleButtonGroup
          value={drawingMode}
          exclusive
          onChange={(e, val) => setDrawingMode(val)}
          size="small"
        >
          <TooltipToggleButton title="Move Map or Items" value="">
            <Hand />
          </TooltipToggleButton>
          <TooltipToggleButton
            title="Add Boundary Area"
            value={google.maps.drawing.OverlayType.POLYGON}
          >
            <BoundingBox />
          </TooltipToggleButton>
          <TooltipToggleButton
            title="Add Marker"
            value={google.maps.drawing.OverlayType.MARKER}
          >
            <MapPin />
          </TooltipToggleButton>
        </ToggleButtonGroup>
      </FormControl>
    </Box>
  );
};

type TooltipToggleButtonProps = ToggleButtonProps & {
  title: string;
};

const TooltipToggleButton = forwardRef<
  HTMLButtonElement,
  TooltipToggleButtonProps
>(({ title, ...props }, ref) => {
  return (
    <Tooltip title={title}>
      <Box>
        <ToggleButton
          ref={ref}
          sx={{
            marginLeft: 2,
            color: "primary.contrastText",
            backgroundColor: "primary.main",
            borderRadius: "50%",
            borderWidth: 0,
            fontSize: 28,
            "&:hover, &.Mui-selected:hover": {
              backgroundColor: "primary.light",
              color: "primary.dark",
            },
            "&.Mui-selected": {
              backgroundColor: "primary.dark",
              color: "primary.contrastText",
            },
          }}
          {...props}
        />
      </Box>
    </Tooltip>
  );
});
