import { useState } from "react";
import { useBoolean } from "usehooks-ts";
import { ResizeHandlesWrapper } from "../generic";
import { type EmbedBlock } from "./types";
import { InstagramIframe, TikTokIframe, YoutubeIframe } from "./iframes";

export const EmbedPreview = ({ block, editor }: EmbedBlock) => {
  const [width, setWidth] = useState<number>(
    Math.min(
      block.props.previewWidth!,
      editor.domElement.firstElementChild!.clientWidth
    )
  );
  const { url, fullWidth } = block.props;
  const { value, setTrue, setFalse } = useBoolean(false);
  if (url.includes("instagram")) {
    return <InstagramIframe url={url} />;
  }
  if (url.includes("tiktok")) {
    return <TikTokIframe url={url} />;
  }
  return (
    <ResizeHandlesWrapper
      block={block}
      editor={editor}
      width={width}
      setWidth={setWidth}
      onResizeStart={setTrue}
      onResizeEnd={setFalse}
    >
      <YoutubeIframe fullWidth={fullWidth} url={!value && url} width={width} />
    </ResizeHandlesWrapper>
  );
};
// https://www.tiktok.com/@aiyanatwigg/video/7180192331511893254?referer_url=ktunaxahomelands.com%2Fstories%2Fktunaxa-language-revitalization
