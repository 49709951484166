import "@fullcalendar/react/dist/vdom"; // Solution for importing plugins before FullCalendar. https://github.com/fullcalendar/fullcalendar/issues/6371
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";
import { Container, Typography } from "@mui/material";
import { useCreatePath } from "react-admin";
import { Calendar } from "@react-admin/ra-calendar";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useTheme } from "@mui/styles";

dayjs.extend(duration);

export const EventCalendar = () => {
  const theme = useTheme();
  const createPath = useCreatePath();
  const navigate = useNavigate();
  const convertToEvent = (event_time) => {
    const { rrule, start, end } = event_time;
    const recurring = !!rrule && {
      duration: dayjs.duration(dayjs(end).diff(dayjs(start))).$d,
      rrule: {
        ...rrule,
        dtstart: start,
      },
    };
    return {
      ...event_time,
      ...recurring,
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.dark,
    };
  };
  const handleEventClick = ({ event }) => {
    navigate(
      createPath({
        resource: "events",
        id: event.extendedProps.eventId,
        type: "edit",
      })
    );
  };
  return (
    <Calendar
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, rrulePlugin]}
      convertToEvent={convertToEvent}
      headerToolbar={{
        left: "title",
        right: "prev,next today dayGridMonth,timeGridWeek,timeGridDay",
      }}
      eventClick={handleEventClick}
    />
  );
};

const renderEventContent = (eventInfo) => {
  return (
    <>
      <Container
        sx={{
          display: "flex",
          padding: "15px",
          paddingTop: "5px",
          paddingBottom: "5px",
          backgroundColor: "blue.500",
          borderRadius: "50px",
          justifyContent: "center",
        }}
      >
        <Typography style={{ fontWeight: "bold" }}>
          {eventInfo.event.title}
        </Typography>
      </Container>
    </>
  );
};
