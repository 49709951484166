import { Typography } from "@mui/material";
import {
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import { StyledToolBar } from "~/components";
import { ItinLoading } from "~/components/ItinLoading";
import { EnumChoiceInput } from "~/components/inputs";
import { useCurrentPlatform } from "~/context";
import { TargetSelect } from "./TargetSelect";
import { TaskIntervalInputs } from "./TaskIntervalInputs";

export const ChallengeCreate = () => {
  const { loading, currentPlatform, getTerm } = useCurrentPlatform();
  if (loading) return <ItinLoading />;

  const challengeCapTerm = getTerm("challenge", { capitalize: true });
  const challengeTerm = getTerm("challenge");
  return (
    <Create>
      <SimpleForm
        defaultValues={{ platform_id: currentPlatform.id }}
        toolbar={<StyledToolBar />}
      >
        <Typography variant="h4" mb="16px">
          {`New ${challengeCapTerm}`}
        </Typography>
        <TextInput source="name" validate={required()} />
        <EnumChoiceInput source="type" defaultValue="UniqueTask" />
        <TaskIntervalInputs />
        <EnumChoiceInput source="status" defaultValue={"draft"} />
        <TargetSelect />
        <BooleanInput
          source="featured"
          label={`Make this a featured ${challengeTerm}`}
        />
      </SimpleForm>
    </Create>
  );
};
