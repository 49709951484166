import {
  AppleLogo,
  BookOpen,
  Books,
  ChartLineUp,
  FacebookLogo,
  FlagCheckered,
  Gear,
  Handshake,
  Newspaper,
  User,
} from "@phosphor-icons/react";
import { reduce, startCase } from "lodash";
import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import {
  ResourceDefinitions,
  useGetManyReference,
  useResourceDefinitions,
} from "react-admin";
import { useCurrentPlatform } from "~/context";

type MenuResourcesContextType = {
  menu_resources: ResourceDefinitions<any>;
};
const MenuResourcesContext = createContext<MenuResourcesContextType | null>(
  null
);

export const MenuResourcesProvider = (props: PropsWithChildren) => {
  const resources = useResourceDefinitions();
  const { currentPlatform, loading } = useCurrentPlatform();

  const { data = [], isLoading } = useGetManyReference(
    "content_types",
    {
      target: "platform_id",
      id: currentPlatform?.id,
      filter: { class_type: "Platform", is_single: false },
    },
    { enabled: !!currentPlatform?.id }
  );
  const content_type_resources = useMemo(
    () =>
      reduce(
        data,
        (accu, { id, name }) => ({
          ...accu,
          [name]: {
            icon: <BookOpen />,
            name,
            to: `/content_types/${id}/contents`,
            options: { menu_group: "content", name: startCase(name) },
          },
        }),
        {}
      ),
    [data]
  );
  const menu_resources = useMemo(
    () => ({
      ...resources,
      ...content_type_resources,
      ...customResources(currentPlatform?.id),
    }),
    [resources, content_type_resources, currentPlatform?.id]
  );

  return (
    <MenuResourcesContext.Provider value={{ menu_resources }} {...props} />
  );
};

export const useMenuResources = () => {
  const context = useContext(MenuResourcesContext);
  if (!context)
    throw new Error(
      "useMenuResources must be used within a MenuResourcesProvider"
    );
  return context;
};

const customResources = (platform_id?: number) => ({
  platform: {
    icon: <Gear />,
    name: "platforms",
    type: "edit",
    options: {
      menu_group: "admin",
      label: "Platform Settings",
      resource_id: platform_id,
    },
  },
  content_report: {
    icon: <Books />,
    name: "reports",
    to: "/reports/content",
    type: "content",
    options: {
      menu_group: "reports",
      label: "Content",
    },
  },
  web_report: {
    icon: <ChartLineUp />,
    name: "reports",
    to: "/reports/web",
    type: "web",
    options: {
      menu_group: "reports",
      label: "Web Traffic",
    },
  },
  app_report: {
    icon: <AppleLogo />,
    name: "reports",
    to: "/reports/app",
    type: "app",
    options: {
      menu_group: "reports",
      label: "App Activity",
      requiredExtension: "App",
    },
  },
  user_feedback: {
    icon: <User />,
    name: "reports",
    to: "/reports/user_feedback",
    type: "user_feedback",
    options: {
      menu_group: "reports",
      label: "User Feedback",
    },
  },
  collaboration_report: {
    icon: <Handshake />,
    name: "reports",
    to: "/reports/collaboration",
    type: "collaboration",
    options: {
      menu_group: "reports",
      label: "Collaboration",
    },
  },
  contest_report: {
    icon: <FlagCheckered />,
    name: "reports",
    to: "/reports/contest",
    type: "contest",
    options: {
      menu_group: "reports",
      label: "Contests",
    },
  },
  email_report: {
    icon: <Newspaper />,
    name: "reports",
    to: "/reports/communication",
    type: "communication",
    options: {
      menu_group: "reports",
      label: "Communications",
    },
  },
  socials_report: {
    icon: <FacebookLogo />,
    name: "reports",
    to: "/reports/socials",
    type: "socials",
    options: {
      menu_group: "reports",
      label: "Socials",
    },
  },
});

const reports = [];
