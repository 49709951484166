import { Box } from "@mui/material";
import { SimpleMapList } from "~/components";
import { MapDataProvider } from "~/components/maps/MapDataProvider";
import { HeatMapPoint } from "~/resources/types";

type Props = {
  map_points: HeatMapPoint[];
};
export const HeatMapCard = ({ map_points }: Props) => {
  return (
    <Box height={"550px"} width={"100%"}>
      <MapDataProvider>
        {/* @ts-expect-error SimpleMapList isn't typesafe */}
        <SimpleMapList heatmapData={map_points} />
      </MapDataProvider>
    </Box>
  );
};
