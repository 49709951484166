import { useState, useCallback, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem, styled } from "@mui/material";
import lodashGet from "lodash/get";
import isEqual from "lodash/isEqual";
import {
  useListContext,
  useResourceContext,
  useTranslate,
  Button,
  FilterButtonMenuItem,
  useSavedQueries,
  extractValidSavedQueries,
  FilterContext,
  AddSavedQueryDialog,
  RemoveSavedQueryDialog,
} from "react-admin";
import { stringify } from "query-string";
import { useNavigate } from "react-router";
import { Plus, FunnelSimple } from "@phosphor-icons/react";

export const FilterButton = (props) => {
  const { className, disableSaveQuery, ...rest } = props;
  const filters = useContext(FilterContext);
  const resource = useResourceContext();
  const translate = useTranslate();
  const [savedQueries] = useSavedQueries(resource);
  const navigate = useNavigate();
  const {
    displayedFilters = {},
    filterValues,
    perPage,
    setFilters,
    showFilter,
    sort,
  } = useListContext(props);

  const hasFilterValues = !isEqual(filterValues, {});
  const validSavedQueries = extractValidSavedQueries(savedQueries);
  const hasSavedCurrentQuery = validSavedQueries.some((savedQuery) =>
    isEqual(savedQuery.value, {
      filter: filterValues,
      sort,
      perPage,
      displayedFilters,
    })
  );
  const [open, setOpen] = useState(false);
  const anchorEl = useRef();

  if (filters === undefined) {
    throw new Error("FilterButton requires filters prop to be set");
  }

  const hiddenFilters = filters.filter(
    (filterElement) =>
      !filterElement.props.alwaysOn &&
      !displayedFilters[filterElement.props.source] &&
      typeof lodashGet(filterValues, filterElement.props.source) === "undefined"
  );

  const handleClickButton = useCallback(
    (event) => {
      // This prevents ghost click.
      event.preventDefault();
      setOpen(true);
      anchorEl.current = event.currentTarget;
    },
    [anchorEl, setOpen]
  );

  const handleRequestClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleShow = useCallback(
    ({ source, defaultValue }) => {
      showFilter(source, defaultValue === "" ? undefined : defaultValue);
      setOpen(false);
    },
    [showFilter, setOpen]
  );

  // add query dialog state
  const [addSavedQueryDialogOpen, setAddSavedQueryDialogOpen] = useState(false);
  const hideAddSavedQueryDialog = () => {
    setAddSavedQueryDialogOpen(false);
  };
  const showAddSavedQueryDialog = () => {
    setOpen(false);
    setAddSavedQueryDialogOpen(true);
  };

  // remove query dialog state
  const [removeSavedQueryDialogOpen, setRemoveSavedQueryDialogOpen] =
    useState(false);
  const hideRemoveSavedQueryDialog = () => {
    setRemoveSavedQueryDialogOpen(false);
  };
  const showRemoveSavedQueryDialog = () => {
    setOpen(false);
    setRemoveSavedQueryDialogOpen(true);
  };

  if (
    hiddenFilters.length === 0 &&
    validSavedQueries.length === 0 &&
    !hasFilterValues
  ) {
    return null;
  }
  return (
    <Root className={className} {...sanitizeRestProps(rest)}>
      <Button
        className="add-filter"
        label="Filter"
        aria-haspopup="true"
        onClick={handleClickButton}
        variant="outlined"
        alignIcon="right"
      >
        <FunnelSimple size={14} weight="fill" />
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl.current}
        onClose={handleRequestClose}
      >
        {hiddenFilters.map((filterElement, index) => (
          <FilterButtonMenuItem
            key={filterElement.props.source}
            filter={filterElement}
            resource={resource}
            onShow={handleShow}
            autoFocus={index === 0}
          />
        ))}
        {validSavedQueries.map((savedQuery, index) =>
          isEqual(savedQuery.value, {
            filter: filterValues,
            sort,
            perPage,
            displayedFilters,
          }) ? (
            <MenuItem onClick={showRemoveSavedQueryDialog} key={index}>
              {translate("ra.saved_queries.remove_label_with_name", {
                _: 'Remove query "%{name}"',
                name: savedQuery.label,
              })}
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                navigate({
                  search: stringify({
                    filter: JSON.stringify(savedQuery.value.filter),
                    sort: savedQuery.value.sort.field,
                    order: savedQuery.value.sort.order,
                    page: 1,
                    perPage: savedQuery.value.perPage,
                    displayedFilters: JSON.stringify(
                      savedQuery.value.displayedFilters
                    ),
                  }),
                });
                setOpen(false);
              }}
              key={index}
            >
              {savedQuery.label}
            </MenuItem>
          )
        )}
        {hasFilterValues && !hasSavedCurrentQuery && !disableSaveQuery && (
          <MenuItem onClick={showAddSavedQueryDialog}>
            {translate("ra.saved_queries.new_label", {
              _: "Save current query...",
            })}
          </MenuItem>
        )}
        {hasFilterValues && (
          <MenuItem onClick={() => setFilters({}, {}, false)}>
            {translate("ra.action.remove_all_filters", {
              _: "Remove all filters",
            })}
          </MenuItem>
        )}
      </Menu>
      {!disableSaveQuery && (
        <>
          <AddSavedQueryDialog
            open={addSavedQueryDialogOpen}
            onClose={hideAddSavedQueryDialog}
          />
          <RemoveSavedQueryDialog
            open={removeSavedQueryDialogOpen}
            onClose={hideRemoveSavedQueryDialog}
          />
        </>
      )}
    </Root>
  );
};

const sanitizeRestProps = ({
  displayedFilters = null,
  filterValues = null,
  showFilter = null,
  ...rest
}) => rest;

FilterButton.propTypes = {
  resource: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.node),
  displayedFilters: PropTypes.object,
  filterValues: PropTypes.object,
  showFilter: PropTypes.func,
  className: PropTypes.string,
};

const PREFIX = "ItinFilterButton";

const Root = styled("div", {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: "inline-block",
}));
