import { GlobeHemisphereEast } from "@phosphor-icons/react";
import { ItinResourceProps } from "../types";
import { AreaCreate } from "./AreaCreate";
import { AreaEdit } from "./AreaEdit";
import { AreaList } from "./AreaList";

export const AreaResource: ItinResourceProps = {
  name: "areas",
  list: AreaList,
  edit: AreaEdit,
  create: AreaCreate,
  icon: <GlobeHemisphereEast />,
  recordRepresentation: ({ name, area_name }) => name || area_name,
  options: {
    // menu_group: "places",
    label: "Areas",
  },
};
