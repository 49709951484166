import { Grid } from "@mui/material";
import { BooleanInput, FormTab, TextInput, required } from "react-admin";
import { AdminUserInput, EnumChoiceInput } from "~/components/inputs";
import { RegionInput } from "~/components/inputs/RegionInput";
import { useCurrentPlatform } from "~/context";
import { UrlsList } from "~/resources/urls/UrlsList";

const EditBasicTab = (props) => {
  const { getTerm } = useCurrentPlatform();
  return (
    <FormTab {...props} label="Basic info">
      <Grid container columnSpacing={3}>
        <Grid item xs={12} md={6}>
          <TextInput source="name" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <RegionInput />
        </Grid>
        <Grid item xs={12} md={6}>
          <AdminUserInput fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <EnumChoiceInput source="status" isRadio={true} />
        </Grid>
        <Grid item>
          <BooleanInput
            label={`Make this a featured ${getTerm("itinerary")}?`}
            source="featured"
          />
          <BooleanInput
            label={`Publish as ${getTerm("reel")}`}
            source="publish_as_reel"
          />
        </Grid>
        <Grid item>
          <BooleanInput
            label={`Show the map for this ${getTerm("itinerary")}?`}
            source="show_map"
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={3}>
        <UrlsList />
      </Grid>
    </FormTab>
  );
};

export default EditBasicTab;
