import { Grid } from "@mui/material";
import { ResourceContextProvider, SimpleForm, TextInput } from "react-admin";
import { DrawerForm } from "~/components/drawer/DrawerForm";
import { MediaInfoCard } from "~/resources/media/MediaEdit";
import { TagSidebar } from "~/resources/taggings";
import { DoMagicButton } from "../buttons/DoMagicButton";
import { ImageField } from "./ImageField";

export const ImageForm = () => {
  return (
    <ResourceContextProvider value="images">
      <DrawerForm Form={<Form />} />
    </ResourceContextProvider>
  );
};

export const Form = (props) => {
  return (
    <SimpleForm {...props}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ImageField source="" sx={{ height: "350px", mb: 3 }} />
        </Grid>
        <Grid item xs={6}>
          <MediaInfoCard />
          <DoMagicButton />
        </Grid>
      </Grid>
      <TextInput source="caption" multiline fullWidth />
      <TagSidebar />
    </SimpleForm>
  );
};
