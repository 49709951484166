import { TagCreate } from "./tags";
import { TagList } from "./TagList";
import { TagEdit } from "./TagEdit";
import { Tag } from "@phosphor-icons/react";
import { ItinResourceProps } from "../types";

export const TagResource: ItinResourceProps = {
  name: "tags",
  recordRepresentation: "name",
  list: TagList,
  // show: TagShow,
  edit: TagEdit,
  create: TagCreate,
  icon: <Tag />,
  options: {menu_group: "admin"}

};
