import { Box } from "@mui/material";
import { ImageDropzone } from "./ImageDropzone";
import { ImagePreview } from "./ImagePreview";
import { useWatch } from "react-hook-form";
import { Labeled } from "react-admin";
export const SingleImageInput = ({
  source,
  options,
  show_caption,
  children,
  label = "Image",
  ...props
}) => {
  const file = useWatch({ name: source });
  return (
    <Box
      overflow="hidden"
      display="flex"
      flexDirection="column"
      minHeight={200}
      {...props}
    >
      <Labeled label={label} sx={{ flexGrow: 1 }}>
        <ImageDropzone
          source={source}
          options={{ ...options, multiple: false }}
          show={!file}
        />
      </Labeled>
      <ImagePreview source={source} file={file} show_caption={show_caption} />
      {children}
    </Box>
  );
};
