import { Grid } from "@mui/material";
import { useState } from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  required,
  useGetOne,
  useRecordContext,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { useCurrentPlatform } from "~/context";
import { declassifyResource } from "~/helpers";

export const usePolymorphic = (source = "target") => {
  const [id, type] = useWatch({ name: [`${source}_id`, `${source}_type`] });
  const resource = declassifyResource(type);
  const query = useGetOne(resource, { id }, { enabled: !!resource && !!id });
  return { ...query, id, type, resource };
};

export const PolymorphicInputs = ({ target_types, source = "target" }) => {
  const { setValue, clearErrors } = useFormContext();
  const record = useRecordContext();
  const [targetType, setTargetType] = useState(record?.[`${source}_type`]);
  const resource = declassifyResource(targetType);
  const { getTerm } = useCurrentPlatform();
  const resourceCapTerm = getTerm(resource, { capitalize: true });
  return (
    <Grid container columnSpacing={2}>
      <Grid item md={6}>
        <SelectInput
          source={`${source}_type`}
          choices={target_types.map((type) => ({
            id: type,
            name: getTerm(type, {
              plural: true,
              capitalize: true,
            }),
          }))}
          onChange={(e) => {
            setValue(`${source}_id`, null);
            setTargetType(e?.target?.value);
            clearErrors();
          }}
          label="Link Record"
          fullWidth
          emptyText="None"
          emptyValue="None"
        />
      </Grid>{" "}
      <Grid item md={6}>
        {!!resource && (
          <ReferenceInput
            source={`${source}_id`}
            reference={resource}
            enableGetChoices={() => !!resource}
          >
            <AutocompleteInput
              noOptionsText="No Records Found"
              validate={required()}
              label={resourceCapTerm}
            />
          </ReferenceInput>
        )}
      </Grid>
    </Grid>
  );
};
