import { Box, Grid } from "@mui/material";
import { geojsonToWKT, wktToGeoJSON } from "@terraformer/wkt";
import { useCallback, useEffect } from "react";
import { regex, required, useInput } from "react-admin";
import { useWatch } from "react-hook-form";
import { Point } from "terraformer";
import { LonLatField } from "./LonLatField";
import { useWktPointValues } from "./hooks";

const LAT_PATTERN = /^-?(([0-8]?[0-9])(\.[0-9]+)|90(\.0+))$/;
const LON_PATTERN = /^-?(([0-9]{1,2}|1[0-7][0-9])(\.[0-9]+)|180(\.0+))$/;

const otherValueSet = () => (value, values, field) => {
  const other_field = field.source === "latitude" ? "longitude" : "latitude";
  if (!value && !!values[other_field])
    return "Both latitude & longitude must be set.";
  return undefined;
};

export const LonLatFields = ({ children, isRequired = true, targetLonLat }) => {
  const {
    field: { onChange },
  } = useInput({ source: "lonlat" });
  const lonlat = useWktPointValues("lonlat");
  const [targetLon, targetLat] = targetLonLat
    ? wktToGeoJSON(targetLonLat).coordinates
    : [];

  const [lat, lon] = useWatch({ name: ["latitude", "longitude"] }); //similat just watch longlat if value disable

  useEffect(() => {
    if (lon && LON_PATTERN.test(lon) && lat && LAT_PATTERN.test(lat)) {
      const point = new Point([lon, lat]);
      onChange(geojsonToWKT(point));
    } else if (!lon && !lat) {
      onChange("");
    }
  }, [lat, lon]);

  const validate = useCallback(
    (pattern, message) => [
      isRequired && required(),
      regex(pattern, message),
      otherValueSet(),
    ],
    [isRequired]
  );
  return (
    <Box
      sx={{
        mb: 2,
        "& > :not(style)": { mr: 2 },
        alignItems: "center",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <Grid container columnSpacing={3}>
        <Grid item xs={12} md={6}>
          <LonLatField
            label="Latitude"
            name="latitude"
            placeholder={targetLat?.toString()}
            validate={validate(
              LAT_PATTERN,
              "Latitude must be between -90.0 and 90.0 and include decimal precision"
            )}
            defaultValue={lonlat[1]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LonLatField
            label="Longitude"
            name="longitude"
            placeholder={targetLon?.toString()}
            defaultValue={lonlat[0]}
            validate={validate(
              LON_PATTERN,
              "Longitude must be between -180.0 and 180.0 and include decimal precision"
            )}
          />
        </Grid>
      </Grid>
      {children}
    </Box>
  );
};
