import { cloneElement, createContext, useContext, useMemo } from "react";
import { Box, Drawer } from "@mui/material";
import { CreateContext } from "./CreateContext";
import { EditContext } from "./EditContext";
import { StyledToolBar } from "~/components/StyledToolbar";
import { useResourceContext } from "react-admin";

const DrawerFormContext = createContext({});
export const DrawerFormProvider = DrawerFormContext.Provider;
export const useDrawerForm = () => useContext(DrawerFormContext);

export const DrawerForm = ({ Form, ...props }) => {
  const { openId, open, onClose } = useDrawerForm();
  const FormTypeComponent = useMemo(
    () => (openId === "create" ? CreateContext : EditContext),
    [openId]
  );
  const resource = useResourceContext();
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "white",
          "& .MuiToolbar-root": {
            paddingX: 0,
          },
        },
      }}
    >
      <Box
        sx={{
          minWidth: "50vw",
          maxWidth: "90vw",
          padding: 4,
          backgroundColor: "white",
        }}
      >
        {!!openId && (
          <FormTypeComponent
            id={openId}
            mutationMode="pessimistic"
            onClose={onClose}
            resource={resource}
            {...props}
          >
            {cloneElement(Form, {
              toolbar: (
                <Box sx={{ position: "sticky", bottom: 0, zIndex: 2 }}>
                  <StyledToolBar
                    deleteButtonProps={{
                      redirect: false,
                      mutationOptions: {
                        onSettled: () => onClose(),
                      },
                    }}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </Box>
              ),
            })}
          </FormTypeComponent>
        )}
      </Box>
    </Drawer>
  );
};
