import { useListContext } from "react-admin";
import { Media } from "~/resources/media/types";
import { MediaCard } from "./MediaCard";
import { Box, useTheme } from "@mui/material";
import { CheckCircle } from "@phosphor-icons/react";

type Props = {
  toggleSelect: (id: number) => void;
  selected_ids: number[];
};

export const AddMediaList = ({
  toggleSelect,
  selected_ids,
  ...props
}: Props) => {
  const { data, isLoading } = useListContext<Media>(props);
  const theme = useTheme();
  if (isLoading) return <div>Loading...</div>;
  return data.map((media) => {
    const selected = selected_ids.includes(media.id);
    return (
      <MediaCard
        gridColumn={{ xs: "span 6", sm: "span 4", lg: "span 3" }}
        gridRow="span 1"
        height={250}
        key={media.id}
        media={media}
        onClick={toggleSelect}
      >
        {selected && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            position={"absolute"}
            top={0}
            bottom={0}
            left={0}
            right={0}
            sx={{ pointerEvents: "none" }}
          >
            <CheckCircle size={100} color={theme.palette.primary.light} />
          </Box>
        )}
      </MediaCard>
    );
  });
};
