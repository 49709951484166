import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import {
  BooleanField,
  BooleanInput,
  DateField,
  ReferenceManyField,
  TextField,
  TextInput,
  useRecordContext,
} from "react-admin";
import { ItinLoading } from "~/components";
import { useCurrentPlatform } from "~/context";
import { useTargetType } from "~/hooks";

export const UrlsList = () => {
  const target_type = useTargetType();
  const target = useRecordContext();
  return (
    <ReferenceManyField
      reference="urls"
      target="target_id"
      filter={{ target_type }}
    >
      <EditableDatagrid
        createForm={<UrlForm target={target} target_type={target_type} />}
        editForm={<UrlForm target={target} target_type={target_type} />}
      >
        <TextField source="url" />
        <DateField source="updated_at" />
        <BooleanField source="primary" />
      </EditableDatagrid>
    </ReferenceManyField>
  );
};

const UrlForm = (props) => {
  const { isLoading, currentPlatform } = useCurrentPlatform();
  if (isLoading) return <ItinLoading />;

  return (
    <RowForm
      defaultValues={{
        platform_id: currentPlatform.id,
        target_type: props.target_type,
        target_id: props.target ? props.target.id : null,
      }}
    >
      <TextInput source="url" />
      <BooleanInput source="primary" />
    </RowForm>
  );
};
