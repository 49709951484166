import { ToggleButton, ToggleButtonGroup } from "@mui/lab";
import { Box, ToggleButtonGroupProps } from "@mui/material";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";
import { ChartPie, Table } from "@phosphor-icons/react";
import { MouseEvent, useState } from "react";
import { StatSummaryCard } from "~/resources/types";
import { ReportPie } from "./ReportPie";
import { ReportDataGrid } from "./ReportDataGrid";

type Props = {
  data: NonNullable<StatSummaryCard["table"]>;
  name: StatSummaryCard["name"];
};

export const ToggleTablePie = ({ data, name }: Props) => {
  const [view, setView] = useState("table");

  const handleViewChange = (
    event: MouseEvent<HTMLElement>,
    newView: string
  ) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box>
        {view === "table" && (
          <ReportDataGrid
            data={data}
            name={name}
            gridProps={{
              slots: {
                toolbar: () => (
                  <CustomGridToolbar
                    data={data}
                    view={view}
                    onViewChange={handleViewChange}
                  />
                ),
              },
            }}
          />
        )}
        {view === "pie" && <ReportPie data={data} />}
      </Box>
    </Box>
  );
};

type CustomGridToolbarProps = {
  data: NonNullable<StatSummaryCard["table"]>;
  view: string;
  onViewChange: ToggleButtonGroupProps["onChange"];
};

const CustomGridToolbar = ({
  data,
  view,
  onViewChange,
}: CustomGridToolbarProps) => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
      {data?.length <= 10 && (
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={onViewChange}
          size="small"
        >
          <ToggleButton value="table">
            <Table />
          </ToggleButton>
          <ToggleButton value="pie">
            <ChartPie />
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </GridToolbarContainer>
  );
};
