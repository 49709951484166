import {
  SelectInput,
  required,
  AutocompleteInput,
  useRecordContext,
} from "react-admin";
import { useState } from "react";
import { useChallengeTargets } from "~/hooks";
import { TARGET_TYPES } from "./constants";
import { useCurrentPlatform } from "~/context";
import { Box, Grid } from "@mui/material";

export const TargetSelect = ({ parent_target_type, parent_record }) => {
  const record = useRecordContext();
  const [targetType, setTargetType] = useState(
    record?.target_type || parent_target_type || "Platform"
  );
  const { data: targets = [] } = useChallengeTargets(targetType);
  const { getTerm } = useCurrentPlatform();
  const termTargetType = TARGET_TYPES.map((tt) => {
    return {
      id: tt.id,
      name: getTerm(tt.name, { plural: true, capitalize: true }),
    };
  });
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SelectInput
          source="target_type"
          defaultValue={targetType}
          translateChoice={false}
          choices={termTargetType}
          onChange={(e) => {
            setTargetType(e.target.value);
          }}
          validate={required()}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteInput
          label="Target"
          source="target_id"
          defaultValue={parent_record?.id}
          optionValue="id"
          optionText={"name"}
          translateChoice={false}
          choices={targets}
          validate={required()}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
