import { ReferenceField } from "react-admin";
import { Box } from "@mui/material";
import AvatarField from "../AvatarField";

export const PlatformUserField = (props) => {
  return (
    <ReferenceField reference="platform_users" {...props}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <AvatarField sx={{ width: 40, height: 40 }} source="avatar" link />
        <ReferenceField source="user_id" reference="users" />
      </Box>
    </ReferenceField>
  );
};
