import { FilmStrip } from "@phosphor-icons/react";
import { ItinResourceProps } from "../types";
import { ReelCreate } from "./ReelCreate";
import { ReelEdit } from "./ReelEdit";
import { ReelList } from "./ReelList";

export const ReelResource: ItinResourceProps = {
  name: "reels",
  list: ReelList,
  edit: ReelEdit,
  create: ReelCreate,
  icon: <FilmStrip />,
  recordRepresentation: (r) => r.name,
  options: { menu_group: "content" },
};
