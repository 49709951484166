import { Box, Tooltip, styled } from "@mui/material";
import { useState } from "react";
import { replaceMediaSizeInUrl } from "~/helpers";
import { AudioThumbnail } from "./AudioThumbnail";
import { ImageError } from "./ImageError";

export const MediaThumbnail = ({ url, contentType, openModal, ...props }) => {
  const isAudio = contentType.startsWith("audio");
  const isVideo = contentType.startsWith("video");

  const [loaded, setLoaded] = useState(isAudio);

  const thumbnailSrc = replaceMediaSizeInUrl(
    url,
    isVideo ? "large_image" : "large"
  );

  return (
    <StyledImageField loaded={loaded.toString()} {...props}>
      {isAudio && <AudioThumbnail src={url} height="100%" />}
      {!isAudio && (
        <ImageThumbnail
          src={thumbnailSrc}
          openModal={openModal}
          onLoad={() => setLoaded(true)}
          {...props}
        />
      )}
    </StyledImageField>
  );
};

const ImageThumbnail = ({ src, onLoad, openModal, text }) => {
  const [error, setError] = useState(false);
  if (error) return <ImageError height="150px" width={1} size="sm" />;

  return (
    <Tooltip title="Click To Enlarge">
      <img
        title={text}
        alt={text}
        src={src}
        className={ImageFieldClasses.image}
        onClick={(e) => {
          e.stopPropagation();
          openModal();
        }}
        onError={() => {
          onLoad?.();
          setError(true);
        }}
        onLoad={onLoad}
      />
    </Tooltip>
  );
};

const PREFIX = "ItinImageField";
const ImageFieldClasses = {
  image: `${PREFIX}-image`,
};

const StyledImageField = styled(Box, {
  name: PREFIX,
  slot: "Root",
  overridesResolver: (props, styles) => {
    return styles.root;
  },
})(({ loaded }) => {
  return {
    // flex: 1,
    position: "relative",
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    [`& .${ImageFieldClasses.image}`]: {
      opacity: loaded === "true" ? 1 : 0,
      borderRadius: 12,
      maxHeight: "100%",
      maxWidth: "100%",
      objectFit: "contain",
    },
  };
});
