import { GridFour, ListBullets, MapTrifold } from "@phosphor-icons/react";
import { useToggleListMode } from "../ToggleList";
import { ButtonGroup, Button } from "@mui/material";

export const ListModeButton = () => {
  const { modes, nextMode, setCurrentModeName, currentMode } =
    useToggleListMode();

  if (modes.length <= 1) return null;

  return (
    <ButtonGroup>
      {modes.map((mode) => {
        return (
          <Button
            variant={mode.name == currentMode.name ? "contained" : "outlined"}
            key={mode.name}
            endIcon={
              mode.icon ||
              (mode.name === "Grid" ? (
                <GridFour />
              ) : mode.name === "List" ? (
                <ListBullets />
              ) : (
                <MapTrifold />
              ))
            }
            onClick={() => setCurrentModeName(mode.name)}
          >{`${mode.name}`}</Button>
        );
      })}
    </ButtonGroup>
  );
};
