import { SmartRichTextInput } from "@react-admin/ra-ai";
import { RichTextInput } from "ra-input-rich-text";
import { useRecordContext, useResourceContext } from "react-admin";
import { HasPlatformExtension } from "../HasPlatformExtension";

type Props = {
  label?: false | string;
  isRequired?: boolean;
  source?: string;
  height?: string;
  placeholder?: string;
  helperText?: false | string;
};

const ItinRichTextArea = ({
  label = false,
  isRequired = false,
  source = "description",
  height = "260px",
  placeholder = "Compose an epic...",
  helperText = false,
}: Props) => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const input_props = {
    placeholder,
    source,
    label,
    helperText,
    sx: { "& .tiptap": { height: height, overflow: "scroll" } },
    isRequired,
    // editorOptions: {
    //   ...DefaultEditorOptions,
    //   extensions: [
    //     StarterKit.configure({ hardBreak: false }),
    //     ...tail(DefaultEditorOptions.extensions),
    //     HardBreak.extend({
    //       addKeyboardShortcuts() {
    //         return {
    //           Enter: () => this.editor.commands.setHardBreak(),
    //         };
    //       },
    //     }),
    //   ],
    // },
  };

  return (
    <HasPlatformExtension
      platform_extension="AiExtension"
      //@ts-expect-error
      fallback={<RichTextInput {...input_props} />}
    >
      <SmartRichTextInput
        meta={{ resource: resource, id: record?.id }}
        fullWidth
        {...input_props}
      />
    </HasPlatformExtension>
  );
};

export default ItinRichTextArea;
