import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { FieldTitle, InputHelperText } from "react-admin";
const filter = createFilterOptions();

/**
 * Jacob wrote this because TaggingForm AutocompleteInput was buggy when editing the category.
 * But then realized that using name instead of source fixed the issue.
 * I'm keeping it here for now incase another issue comes up.
 */

export const GenericAutoCompleteInput = ({
  label,
  optionLabel = "name",
  helperText,
  onChange,
  onBlur,
  onCreate,
  emptyText,
  ...props
}) => {
  const handleChange = (_, newValue = "") => {
    if (newValue && newValue.inputValue && !!onCreate) {
      newValue = onCreate(newValue.inputValue);
    }
    onChange && onChange(newValue?.[optionLabel] || "");
  };

  return (
    <Autocomplete
      getOptionLabel={(o) => o.inputValue || o[optionLabel] || emptyText || ""}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) =>
        option[optionLabel] === value[optionLabel]
      }
      selectOnFocus
      handleHomeEndKeys={!!onCreate}
      clearOnBlur
      freeSolo={!!onCreate}
      renderInput={(params) => (
        <TextField
          label={<FieldTitle label={label} />}
          helperText={<InputHelperText helperText={helperText} />}
          {...params}
        />
      )}
      renderOption={(props, option) => {
        return <li {...props}>{option[optionLabel]}</li>;
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (!onCreate) return filtered;
        const { inputValue } = params;
        const isExisting = options.some(
          (option) => inputValue === option[optionLabel]
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            [optionLabel]: `Add ${inputValue}`,
          });
        }
        return filtered;
      }}
      {...props}
    />
  );
};
