import { Datagrid, TextField, useCreatePath } from "react-admin";
import { ToggleList } from "~/components/lists";
import { ImageCard } from "~/components/lists/ImageCard";
import { platformAreaCardData } from "./platformAreaCardData";
import { SimpleMapList } from "~/components";

export const PlatformAreaList = () => {
  return <ToggleList modes={MODES} resource="platform_areas_views" />;
};

const ListData = () => {
  const createPath = useCreatePath();

  return (
    <Datagrid
      rowClick={(id) =>
        createPath({ resource: "platform_areas", id, type: "edit" })
      }
      bulkActionButtons={false}
    >
      <TextField source="name" />
      <TextField source="subtitle" />
    </Datagrid>
  );
};

const MODES = [
  {
    name: "Grid",
    view: (
      <ImageCard cardData={platformAreaCardData} resource="platform_areas" />
    ),
    resource: "platform_areas",
  },
  {
    name: "Map",
    view: (
      <SimpleMapList
        navigateOnClick
        cardData={platformAreaCardData}
        showPins={false}
        showPolygons={true}
      />
    ),
    resource: "platform_areas",
  },
  { name: "List", view: <ListData />, resource: "platform_areas" },
];
