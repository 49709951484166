import { get, startCase } from "lodash";
import { FunctionField } from "react-admin";

export const FormattedTextField = ({ source, ...props }) => {
  return (
    <FunctionField
      label={startCase(source)}
      render={(record) => startCase(get(record, source)?.toString())}
      {...props}
    />
  );
};
