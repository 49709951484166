import { Plus } from "@phosphor-icons/react";
import { singular } from "pluralize";
import { CreateButton } from "react-admin";

export const CreateButtonWithDefaults = ({
  resource,
  defaultValues,
  label = `New ${singular(resource)}`,
  ...props
}) => {
  return (
    <CreateButton
      label={label}
      resource={resource}
      state={{ record: defaultValues }}
      {...props}
    ></CreateButton>
  );
};
