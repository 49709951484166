import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Dialog, TextField, Typography } from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useState } from "react";
import { useNotify } from "react-admin";
import Logo from "~/images/login_logo.png";
import { ButtonColumn } from "./ButtonColumn";

export const ForgotPassword = () => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const notify = useNotify();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    try {
      await firebase.auth().sendPasswordResetEmail(email);
      setOpen(false);
      notify("Password reset email sent!", { type: "success" });
    } catch (error) {
      notify(error.message, { type: "error" });
    }
  };

  const handleOnChange = (event) => {
    const email = event.target.value;
    setEmail(email);
  };

  return (
    <>
      <Box display="flex" alignContent="center" justifyContent="flex-end">
        <Button
          variant="text"
          onClick={handleClickOpen}
          sx={{
            fontSize: "12px",
            paddingX: 0,
            ":hover": {
              bgcolor: "transparent",
              textDecoration: "underline",
            },
          }}
        >
          Forgot Password?
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{ padding: "32px", minHeight: "587px" }}
          >
            <Box display="flex" justifyContent="center">
              <img src={Logo} width={250} />
            </Box>

            <Box>
              <Box width="100%">
                <Typography variant="h4" margin="24px 0px 8px 0px">
                  Forgot password?
                </Typography>

                <Typography variant="body2">
                  Enter the email address associated with your account, and
                  we’ll email you a link to reset your password.
                </Typography>

                <TextField
                  id="outlined-basic"
                  label={false}
                  type="email"
                  style={{ width: "100%" }}
                  onChange={handleOnChange}
                  sx={{ marginBottom: "24px" }}
                  placeholder={"Email"}
                />
              </Box>
              <ButtonColumn>
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  autoFocus
                  variant="contained"
                >
                  Send Email
                </Button>
                <Button
                  onClick={handleClose}
                  startIcon={<ArrowBack />}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </ButtonColumn>
            </Box>
          </Box>
        </Dialog>
      </Box>
    </>
  );
};
