import { startCase } from "lodash";
import {
  Datagrid,
  ImageField,
  ReferenceManyField,
  TextField,
} from "react-admin";
import { StatsSummaryField } from "~/components/fields";
import { ToggleList } from "~/components/lists";

export const DmoList = () => {
  return (
    <ToggleList
      modes={MODES}
      sx={{ "& .ItinImageCard-image": { objectFit: "contain" } }}
    />
  );
};

const ListData = () => {
  return (
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <ImageField source="logo.url" label="Logo" />

      <TextField source="name" />
      <ReferenceManyField reference="dmos_regions" target="dmo_id">
        <TextField source="region_id" />
      </ReferenceManyField>
      <StatsSummaryField />
    </Datagrid>
  );
};

export const dmoCardData = (record) => ({
  title: startCase(record.name),
  img: record.logo,
  stats_summary: <StatsSummaryField resource="dmos" label="" />,
});

const MODES = [
  { name: "Grid", cardData: dmoCardData },
  { name: "List", view: <ListData /> },
];
