import { startCase } from "lodash";
import { singular } from "pluralize";
import { useCallback } from "react";
import {
  CreateMutationFunction,
  CreateParams,
  useReference,
  useRegisterMutationMiddleware,
} from "react-admin";
import { useFormContext } from "react-hook-form";

export const useAcceptsNestedAttributes = (
  reference: string,
  source: string
) => {
  const { setValue, watch } = useFormContext();
  const reference_id = watch(source);
  const reference_key = getNestedAttributeKey(reference);
  const { refetch } = useReference({
    reference,
    id: reference_id,
    options: {
      onSuccess: (data) => data?.[0] && setValue(reference_key, data[0]),
      enabled: !!reference_id,
    },
  });

  const middleware = useCallback(
    async (
      resource: string | undefined,
      params: Partial<CreateParams<Partial<any>>> | undefined,
      options: any,
      next: CreateMutationFunction
    ) => {
      await next(resource, params, options);
      refetch();
    },
    [refetch]
  );

  useRegisterMutationMiddleware(middleware);

  return useCallback(
    (source: string) => getNestedAttributeInputProps(reference, source),
    [reference]
  );
};

export const getNestedAttributeKey = (reference: string) =>
  `${singular(reference)}_attributes`;

export const getNestedAttributeInputProps = (
  reference: string,
  source: string
) => {
  const key = getNestedAttributeKey(reference);
  return {
    source: `${key}.${source}`,
    label: startCase(source),
  };
};

// Remnants of an attempt to implement a destroy flag for nested attributes
// Was replaced with a reject_if function in the Rails backend

// const attributes = get(params, `data.${reference_key}`);
// const newParams = { ...params };
// const toDestroy = await shouldDestroyRef.current(
//   attributes,
//   params,
//   resource,
//   options
// );
// delete newParams.data?.[reference_key];
// if (newParams.data) {
//   if (toDestroy) {
//     if (!!newParams?.data?.[source]) {
//       newParams.data[reference_key] = {
//         ...attributes,
//         _destroy: true,
//       };
//     }
//   } else {
//     newParams.data[reference_key] = attributes;
//   }
// }
