import { useGetIdentity, useGetManyReference } from "react-admin";

type Props = {
  enabled?: boolean;
};

export const useEditorPlatformUsers = ({ enabled = true }: Props = {}) => {
  const { identity, isLoading } = useGetIdentity();
  const { data = [], isLoading: platformUsersIsLoading } = useGetManyReference(
    "platform_users",
    {
      target: "user_id",
      id: identity?.id,
      filter: { not: { role: 0 } },
    },
    { enabled: !!identity && enabled }
  );
  return { data, isLoading: isLoading || platformUsersIsLoading };
};
