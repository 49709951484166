import { Show, TabbedShowLayout, useEditContext } from "react-admin";
import { Stats } from "~/components";
import { TourismoDateRangePicker } from "~/components/TourismoDateRangePicker";
import { useCurrentPlatform } from "~/context";
import { ReportFilterProvider } from "~/context/ReportFilterContext";
import { ListingOverview } from "./ListingOverView/ListingOverview";

export const ListingShow = () => {
  const { getTerm } = useCurrentPlatform();
  const listingTerm = getTerm("listing");
  const listingsTerm = getTerm("listing", { plural: true });
  const userTerm = getTerm("user");
  const record = useEditContext();

  return (
    <Show actions={false}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Overview">
          <ListingOverview />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Reports">
          <ReportFilterProvider>
            <TourismoDateRangePicker />
            <Stats category="report" />
          </ReportFilterProvider>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
