import {
  Show,
  TextField,
  SimpleShowLayout,
  useGetList,
  useRecordContext,
  useCreatePath,
  useResourceContext,
} from "react-admin";
import { useRefresh } from "react-admin";
import { MarkdownField } from "@react-admin/ra-markdown";
import { ArrowForward } from "@mui/icons-material";
import { Button } from "@mui/material";
import { findIndex, isNumber } from "lodash";
import { Link } from "react-router-dom";

export const HelpTopicShow = () => {
  //const { values } = useResourceAppLocation() || {};
  //console.log(values);
  //children={values.record.description}
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="name" variant="h1" label={false} />
        <TextField source="short_description" variant="h3" label={false} />
        <MarkdownField source="description" label={false} />
        <NextButton />
      </SimpleShowLayout>
    </Show>
  );
};

const NextButton = () => {
  const resource = useResourceContext();
  const { id } = useRecordContext();
  const { data = [] } = useGetList("help_topics", {
    sort: { field: "id", order: "ASC" },
  });

  const createPath = useCreatePath();
  const currentIndex = !!data.length && findIndex(data, { id });
  const nextIndex = currentIndex + 1;
  const nextData = isNumber(currentIndex) && data[nextIndex];
  const refresh = useRefresh();
  const handleClick = () => {
    refresh;
  };
  if (!nextData) return null;
  return (
    <Button
      component={Link}
      to={createPath({ resource, type: "show", id: nextData.id })}
      onClick={handleClick}
      state={{ _scrollToTop: true }}
      endIcon={<ArrowForward />}
      sx={{ padding: "12px 12px 12px 20px", marginTop: "32px" }}
      variant="contained"
    >
      Explore next tip
    </Button>
  );
};
