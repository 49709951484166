import { singular } from "pluralize";
import {
  Datagrid,
  DateField,
  List,
  Pagination,
  TextField,
  useGetOne,
} from "react-admin";
import { useParams } from "react-router-dom";
import { Empty } from "~/components";
import { EnumSelectField, StatsSummaryField } from "~/components/fields";
import { Toolbar } from "~/components/lists/Toolbar";
import { useDefineAppLocation } from "@react-admin/ra-navigation";

export const ContentList = () => {
  const { id: content_type_id } = useParams();
  const { data: content_type, isLoading } = useGetOne("content_types", {
    id: content_type_id,
  });
  useDefineAppLocation(content_type?.name || "contents");
  if (isLoading) return null;
  return (
    <List
      actions={
        <Toolbar
          createButtonProps={{
            to: "create",
            label: `New ${singular(content_type?.name || "Content")}`,
          }}
        />
      }
      empty={<Empty resource={"contents"} />}
      resource="contents"
      filter={{ content_type_id }}
      exporter={false}
      sx={{
        overflow: "hidden",
        flexGrow: 1,
        "& .RaList-main": {
          overflow: "auto",
          flexGrow: 1,
        },
        "& .RaList-content": {
          flexGrow: 1,
          backgroundColor: "background.paper",
        },
        "& .MuiTablePagination-root": {
          overflow: "initial",
          marginTop: 0,
        },
      }}
      pagination={<Pagination rowsPerPageOptions={[12, 24, 36, 120, 240]} />}
    >
      <Datagrid
        rowClick={(id) => `/content_types/${content_type_id}/contents/${id}`}
        bulkActionButtons={false}
      >
        <TextField source="title" />
        <TextField source="slug" />
        <EnumSelectField source="status" />
        <TextField source="description" />
        <DateField source="updated_at" />
        <StatsSummaryField />
      </Datagrid>
    </List>
  );
};
