import { Box } from "@mui/material";
import {
  AutocompleteInput,
  Edit,
  ReferenceInput,
  required,
  TabbedForm,
  TextInput,
  useResourceContext,
} from "react-admin";
import { MapInput, MultiPolygonInput, StyledToolBar } from "~/components";
import {
  PointInput,
  TextInputWithPlaceholderFallback,
} from "~/components/inputs";
import ItinRichTextArea from "~/components/inputs/ItinRichTextArea";
import { NewMarker } from "~/components/maps/MapInput/Marker";
import { useCurrentPlatform } from "~/context";
import { ContentInlineEditList } from "../contents";
import { LogList } from "../logs/LogList";

export const AreaEdit = () => {
  const resource = useResourceContext();
  const { getTerm } = useCurrentPlatform();
  const resourceTerm = getTerm(resource);
  const resourcesTerm = getTerm(resource, { plural: true });
  return (
    <Edit mutationMode="pessimistic" title=" " redirect={false}>
      <TabbedForm mode="onChange" toolbar={<StyledToolBar />}>
        <TabbedForm.Tab label="Basic Info">
          <ReferenceInput source="area_id" reference="areas">
            <AutocompleteInput
              label="Base Area"
              fullWidth
              validate={[required()]}
            />
          </ReferenceInput>
          <TextInputWithPlaceholderFallback
            source="name"
            fallback_reference="areas"
          />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="content">
          <Box
            display="grid"
            sx={{ width: "100%" }}
            gridTemplateColumns="repeat(12,1fr)"
            gap={2}
          >
            <Box gridColumn={"span 12"}>
              <TextInput
                title="Subtitle"
                placeholder="Compose an epic.."
                source="subtitle"
                isRequired={false}
                fullWidth
                helperText={`A short tagline that goes beneath the name of the ${resourceTerm}. Example: 'More than you imagined!'`}
              />
            </Box>
            <Box gridColumn="span 6">
              <ItinRichTextArea
                label="Short Description"
                helperText={`A summary of the full description. Displayed where many ${resourcesTerm} are shown.`}
                placeholder="Compose an epic.."
                source="short_description"
                isRequired={false}
              />
            </Box>
            <Box gridColumn="span 6">
              <ItinRichTextArea
                label="Full Description"
                helperText={`A full description you would like associated with your ${resourceTerm}. This will appear on expanded views of your ${resourceTerm} for the ${getTerm(
                  "user"
                )}.`}
                placeholder="Compose an epic.."
                source="description"
                isRequired={false}
              />
            </Box>
          </Box>
          {/* @ts-expect-error */}
          <ContentInlineEditList />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Area Map">
          <Box height={"60vh"} width="100%">
            <MapInput>
              <NewMarker />
              <MultiPolygonInput
                source="multipolygon_id"
                fallback_reference="areas"
              />
            </MapInput>
          </Box>
          <PointInput />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Activity Log">
          <LogList />
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
};
