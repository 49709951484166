import { Menu, MenuItem } from "@szhsin/react-menu";
import { forwardRef, useRef } from "react";
import { useRecordContext, useResourceContext, useUpdate } from "react-admin";
import { useHover } from "usehooks-ts";
import { useEnumChoices } from "~/hooks";
import { CustomColorChipField } from "./CustomColorChipField";

const COLOR_OPTIONS = {
  draft: "primary",
  active: "success",
  hidden: "error",
  complete: "default",
};
export const EnumSelectField = ({
  color_options = COLOR_OPTIONS,
  button_props,
  ...props
}) => {
  const { source } = props;
  const resource = useResourceContext();
  const record = useRecordContext();
  const { data = [] } = useEnumChoices(props);
  const [update] = useUpdate();
  return (
    <Menu
      menuButton={
        <ChipField
          color_options={color_options}
          source={source}
          onClick={(e) => e.stopPropagation()}
          {...button_props}
        />
      }
    >
      {data.map((choice) => {
        return (
          <MenuItem
            key={choice.id}
            onClick={({ value, syntheticEvent }) => {
              syntheticEvent.stopPropagation();
              update(resource, {
                id: record.id,
                data: { [source]: choice.id, previousData: record },
              });
            }}
          >
            <ChipField
              color_options={color_options}
              value={choice.id}
              sx={{
                width: "100%",
              }}
            />
          </MenuItem>
        );
      })}
    </Menu>
  );
};

const ChipField = forwardRef(({ color_options, ...props }, ref) => {
  const hoverRef = useRef();
  const isHover = useHover(hoverRef);
  return (
    <CustomColorChipField
      ref={(element) => {
        if (ref) ref.current = element;
        hoverRef.current = element;
      }}
      // sx={{ ...sx, "&:hover": { border: "1px solid transparent" } }}
      style={{ cursor: "pointer" }}
      {...props}
      variant={isHover ? "filled" : "outlined"}
      color_options={color_options}
    />
  );
});
