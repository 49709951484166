import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

import { useUpdateManyUnique } from "~/hooks";
import { useListContext, useResourceContext } from "react-admin";

export const SortContext = ({ children, update_source }) => {
  const resource = useResourceContext();
  const { data = [] } = useListContext();
  const sensors = useSensors(useSensor(PointerSensor));
  const [updateManyUnique] = useUpdateManyUnique();
  const handleDragEnd = ({ active, over }) => {
    const { index, items } = active.data.current.sortable;
    const new_index = over.data.current.sortable.index;
    if (index !== new_index) {
      const new_order = arrayMove(items, index, new_index).map((id, index) => ({
        id,
        [update_source]: index + 1,
      }));
      updateManyUnique(resource, new_order);
    }
  };
  if (!update_source) return children;
  const sorted_data = sortData(data, update_source);
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableContext
        items={sorted_data}
        strategy={verticalListSortingStrategy}
      >
        {children}
      </SortableContext>
    </DndContext>
  );
};

export const sortData = (data, update_source) =>
  data.sort((a, b) => a[update_source] - b[update_source]);
