import { Stack } from "@mui/material";
import { Minus, PencilSimple } from "@phosphor-icons/react";
import { SolidIconButton } from "~/components/buttons";
import { Confirm, useTranslate } from "react-admin";
import { useState } from "react";

export const ImagePreviewButtons = ({
  onDelete,
  onEdit,
  disabled,
  ...props
}) => {
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = (e) => {
    onDelete && onDelete();
    setOpen(false);
  };
  return (
    <Stack
      display="flex"
      direction="row"
      spacing={1}
      alignItems="center"
      zIndex={"1"}
      {...props}
    >
      {!!onEdit && (
        <SolidIconButton
          onClick={onEdit}
          aria-label={translate("ra.action.edit")}
          title={translate("ra.action.edit")}
          color="primary"
          disabled={disabled}
        >
          <PencilSimple />
        </SolidIconButton>
      )}
      {!!onDelete && (
        <SolidIconButton
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
          aria-label={translate("ra.action.delete")}
          title={translate("ra.action.delete")}
          disabled={disabled}
          color="error"
        >
          <Minus />
        </SolidIconButton>
      )}
      <Confirm
        isOpen={open}
        title="Delete Image"
        onClose={handleDialogClose}
        content="Are you sure you want to permanently delete this image?"
        onConfirm={handleConfirm}
      />
    </Stack>
  );
};
