import { AutocompleteInput } from "react-admin";
import { useEnumChoices } from "~/hooks";

type Props = {
  label: string;
  source: string;
};

export const EnumFilterInput = ({ label, source = "status" }: Props) => {
  const { data: statuses = [] } = useEnumChoices({ source });
  return (
    <AutocompleteInput choices={statuses} emptyText={label} source="status" />
  );
};
