import { Grid } from "@mui/material";
import { FormTab } from "react-admin";
import { LegList } from "~/resources/legs";
import { ItineraryMap } from "../ItineraryOverview/ItineraryMap";
import { useCurrentPlatform } from "~/context";
const EditStopsTab = (props) => {
  const { getTerm } = useCurrentPlatform();
  const legTerm = getTerm("leg", { capitalize: true, plural: true });
  const stopTerm = getTerm("stop", { capitalize: true, plural: true });
  return (
    <FormTab {...props} label={`${legTerm} & ${stopTerm}`}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={7}
          sx={{
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          <LegList {...props} />
        </Grid>
        <Grid item xs={5}>
          <ItineraryMap />
        </Grid>
      </Grid>
    </FormTab>
  );
};

export default EditStopsTab;
