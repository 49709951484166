import { Envelope } from "@phosphor-icons/react";
import { Create, SimpleForm, TextInput } from "react-admin";
import { ItinResourceProps } from "../types";
import { CommunicationEdit } from "./CommunicationEdit";
import { CommunicationList } from "./CommunicationList";

export const CommunicationCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="subtitle" />
      </SimpleForm>
    </Create>
  );
};

export const CommunicationResource: ItinResourceProps = {
  name: "communications",
  list: CommunicationList,
  edit: CommunicationEdit,
  create: CommunicationCreate,
  icon: <Envelope />,
  recordRepresentation: (r) => r.name,
  options: { menu_group: "communicate" },
};
