import {
  AppBar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Toolbar,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useRef } from "react";
import { Button, InfiniteListBase, InfinitePagination } from "react-admin";
import { Dash } from "../uppy/Dash";
import { AddMediaList } from "./AddMediaList";

type Props = Omit<DialogProps, "onClose"> & {
  onClose: () => void;
  toggleSelect: (id: number) => void;
  selected_ids: number[];
};

export const MediaDialog = ({
  toggleSelect,
  selected_ids,
  ...props
}: Props) => {
  const ref = useRef(null);
  return (
    <Dialog fullScreen {...props} TransitionComponent={Transition}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar sx={{ boxShadow: "none" }}>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Media
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent ref={ref}>
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridTemplateRows="repeat(2, 1fr)"
          gap={2}
        >
          <Box
            gridColumn={{ xs: "span 6", sm: "span 8", lg: "span 6" }}
            gridRow="span 2"
          >
            <Dash
              height="516px"
              onChange={(media) => {
                toggleSelect(media.id);
              }}
            />
          </Box>
          <InfiniteListBase resource="media" perPage={20}>
            <AddMediaList
              toggleSelect={toggleSelect}
              selected_ids={selected_ids}
            />
            <InfinitePagination
              options={{ root: ref.current }}
              sx={{ gridColumn: "span 12" }}
            />
          </InfiniteListBase>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={props.onClose}
          color="primary"
          label="Done"
        />
      </DialogActions>
    </Dialog>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
