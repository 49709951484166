import { Button } from "@mui/material";
import {
  useRecordContext,
  useRefresh,
  useNotify,
  useResourceContext,
} from "react-admin";
import { useMutation } from "react-query";
import AddIcon from "@mui/icons-material/Add";
import { dataProvider } from "~/lib";
import { titleize } from "~/helpers";
import pluralize from "pluralize";

export const CreateCommunicationButton = () => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const refresh = useRefresh();
  const notify = useNotify();

  // Define the mutation using dataProvider's create method
  const mutation = useMutation(
    () =>
      dataProvider.create("communications", {
        data: {
          target_type: pluralize(titleize(resource), 1),
          target_id: record?.id,
          // Add any additional fields if needed
        },
      }),
    {
      onSuccess: () => {
        refresh(); // Refresh the list view
        notify("Communication created successfully", { type: "success" });
      },
      onError: (error) => {
        console.error("Error creating communication:", error);
        notify("Failed to create communication", { type: "error" });
      },
    }
  );

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={() => mutation.mutate()}
      startIcon={<AddIcon />}
      disabled={mutation.isLoading}
    >
      Create Communication
    </Button>
  );
};
