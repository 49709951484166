import ActionCheck from "@mui/icons-material/CheckCircle";
import AlertError from "@mui/icons-material/ErrorOutline";
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { ReactComponentLike } from "prop-types";
import { MouseEventHandler, useCallback } from "react";
import { useTranslate } from "react-admin";

type Props = Omit<DialogProps, "open" | "onClose" | "title"> & {
  cancel?: string;
  className?: string;
  confirm?: string;
  confirmColor?: ButtonProps["color"];
  cancelColor?: ButtonProps["color"];
  confirmVariant?: ButtonProps["variant"];
  cancelVariant?: ButtonProps["variant"];
  ConfirmIcon?: ReactComponentLike;
  CancelIcon?: ReactComponentLike;
  content: React.ReactNode;
  isOpen?: boolean;
  loading?: boolean;
  onClose: (
    e: {},
    reason: "backdropClick" | "escapeKeyDown" | "buttonPress"
  ) => void;
  onConfirm: MouseEventHandler;
  title: React.ReactNode;
  translateOptions?: object;
};

/**
 * Confirmation dialog
 *
 * @example
 * <Confirm
 *     isOpen={true}
 *     title="Delete Item"
 *     content="Are you sure you want to delete this item?"
 *     confirm="Yes"
 *     confirmColor="primary"
 *     ConfirmIcon=ActionCheck
 *     CancelIcon=AlertError
 *     cancel="Cancel"
 *     onConfirm={() => { // do something }}
 *     onClose={() => { // do something }}
 * />
 */
export const Confirm = (props: Props) => {
  const {
    className,
    isOpen = false,
    loading,
    title,
    content,
    cancel = "ra.action.cancel",
    confirm = "ra.action.confirm",
    confirmColor = "error",
    cancelColor = "primary",
    confirmVariant = "contained",
    cancelVariant = "text",
    ConfirmIcon = ActionCheck,
    CancelIcon = AlertError,
    onClose,
    onConfirm,
    translateOptions = {},
    ...rest
  } = props;

  const translate = useTranslate();

  const handleConfirm: MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onConfirm(e);
    },
    [onConfirm]
  );

  const handleClick: MouseEventHandler = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <Dialog
      className={className}
      open={isOpen}
      onClose={onClose}
      onClick={handleClick}
      aria-labelledby="alert-dialog-title"
      {...rest}
    >
      <DialogTitle id="alert-dialog-title">
        {typeof title === "string"
          ? translate(title, { _: title, ...translateOptions })
          : title}
      </DialogTitle>
      <DialogContent>
        {typeof content === "string" ? (
          <DialogContentText variant="body2">
            {translate(content, {
              _: content,
              ...translateOptions,
            })}
          </DialogContentText>
        ) : (
          content
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant={cancelVariant}
          disabled={loading}
          onClick={(e) => onClose(e, "buttonPress")}
          color={cancelColor}
          startIcon={<CancelIcon />}
        >
          {translate(cancel, { _: cancel })}
        </Button>
        <Button
          variant={confirmVariant}
          disabled={loading}
          onClick={handleConfirm}
          color={confirmColor}
          autoFocus
          startIcon={<ConfirmIcon />}
        >
          {translate(confirm, { _: confirm })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
