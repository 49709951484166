import { Box, Typography } from "@mui/material";
import { SingleImageInput } from "./SingleImageInput";
import { MultiImageInput } from "./MultiImageInput";
import { useCurrentPlatform } from "~/context";
import { useMemo } from "react";
import { TextInput, useResourceContext } from "react-admin";
import pluralize from "pluralize";

const useImageTypes = () => {
  const { getTerm } = useCurrentPlatform();
  const resource = useResourceContext();
  const userTerm = getTerm("user");
  const usersTerm = getTerm("user", { plural: true });
  return useMemo(
    () => [
      {
        label: "Hero Image",
        source: "hero",
        description: `Serves as the thumbnail/card image for this ${pluralize(
          resource,
          1
        )}, as well as a large banner image at the top of the detailed page. Images approximately 1600x900 pixels are ideal, it will auto-crop to fit different situations.`,
      },
      {
        label: "Carousel Images",
        source: "carousel_images",
        description: `An image carousel is a slideshow of images that ${usersTerm} can browse on the details page page for this ${pluralize(
          resource,
          1
        )}. A selection of 3 or more images tells the story of this ${pluralize(
          resource,
          1
        )} in a visual way`,
      },
    ],
    [userTerm, usersTerm]
  );
};

export const ImageTabs = () => {
  const image_types = useImageTypes();
  return (
    <Box sx={{ width: "100%" }}>
      {image_types.map(({ source, label, description }) => {
        return (
          <Box
            key={label}
            width={"100%"}
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={2}
          >
            <Box
              gridColumn="span 4"
              display="grid"
              sx={{ alignContent: "start" }}
            >
              <Typography variant="h4">{label}</Typography>
              <Typography variant="body2">{description}</Typography>
              {source === "hero" && (
                <TextInput source="ingest_hero_url" label="Upload Via URL" />
              )}
              {source === "carousel_images" && (
                <TextInput
                  source="ingest_carousel_image_url"
                  label="Upload Via URL"
                />
              )}
            </Box>
            <Box gridColumn="span 8">
              {source === "hero" ? (
                <SingleImageInput source={source} />
              ) : (
                <MultiImageInput source={source} />
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
