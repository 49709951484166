import { Box, Grid } from "@mui/material";
import { get } from "lodash";
import { ListBase, SimpleList, useRecordContext } from "react-admin";
import { SimpleMapList } from "~/components";
import { ClaimRecord } from "~/components/buttons/ClaimRecord";
import { GridCard } from "~/components/GridCard";
import { MapDataProvider } from "~/components/maps/MapDataProvider";
import { useCurrentPlatform } from "~/context";
import { TagSidebar } from "~/resources/taggings";
import { SubscriptionOptions } from "~/stripe/SubscriptionOptions";
import { EventHero } from "./EventHero";

export const EventOverview = (props) => {
  const record = useRecordContext(props);
  const { getTerm } = useCurrentPlatform();
  const EventTerm = getTerm("Event");
  const tagTerm = getTerm("tag", {
    plural: true,
    capitalize: true,
  });
  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} md={8} rowGap={2}>
        <GridCard item xs={12}>
          <EventHero record={record} />
          <SubscriptionOptions
            cta={`Upgrade this ${EventTerm} to take advantage of additional features.`}
          />
        </GridCard>
      </Grid>
      <Grid container item xs={12} md={4} rowGap={2}>
        <GridCard title="Locations">
          <Box height={"350px"}>
            <MapDataProvider>
              <ListBase
                resource="locations"
                filter={{ id: record.location_ids }}
                pagination={[120]}
                perPage={120}
              >
                <SimpleMapList />
              </ListBase>
            </MapDataProvider>
          </Box>
        </GridCard>
        <GridCard title="Event Times">
          <Box>
            <ListBase
              resource="event_times"
              filter={{ event_id: record.id }}
              pagination={[120]}
              perPage={120}
            >
              <SimpleList
                primaryText={(record) => {
                  const start_date = get(record, "start");
                  const end_date = get(record, "end");
                  return (
                    !!start_date &&
                    !!end_date &&
                    `${new Date(start_date).toLocaleDateString()} -  ${new Date(
                      end_date
                    ).toLocaleDateString()}`
                  );
                }}
                secondaryText={(record) => {
                  return record.locations
                    .map((r) => {
                      return r.name;
                    })
                    .join(", ");
                }}
              ></SimpleList>
            </ListBase>
          </Box>
        </GridCard>
        <ClaimRecord />
        <GridCard title={tagTerm}>
          <TagSidebar />
        </GridCard>
      </Grid>
    </Grid>
  );
};
