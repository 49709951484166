import {
  SimpleForm,
  NumberInput,
  required,
  FormTab,
  TextInput,
  TabbedForm,
} from "react-admin";
import { Typography } from "@mui/material";
import { useCurrentPlatform } from "~/context";
import { Create, Edit } from "react-admin";
import { SimpleImageInput, SingleImageInput } from "~/components/images";
import { StyledToolBar } from "~/components";
import { ItinLoading } from "~/components/ItinLoading";
import { PlatformLevelUserInteractions } from "./UserInteractions";

export const PlatformLevelCreate = () => {
  const { currentPlatform, platformLoading, getTerm } = useCurrentPlatform();
  const levelCapTerm = getTerm("platform_level", {
    plural: false,
    capitalize: true,
  });
  const levelTerm = getTerm("platform_level", {
    plural: false,
    capitalize: false,
  });
  const userTerm = getTerm("user", {
    plural: false,
    capitalize: false,
  });
  const pointTerm = getTerm("point", {
    plural: true,
    capitalize: false,
  });

  if (platformLoading) return <ItinLoading />;
  return (
    <Create mutationMode="pessimistic" redirect={"list"}>
      <SimpleForm
        sx={{ width: "100%" }}
        defaultValues={{ platform_id: currentPlatform.id }}
        toolbar={<StyledToolBar />}
      >
        <Typography variant="h4" pb="16px">
          {`New Platform ${levelCapTerm}`}
        </Typography>
        <TextInput source="name" validate={required()} />
        <NumberInput
          source="threshold"
          validate={required()}
          helperText={`Threshold represents the amount of ${pointTerm} a ${userTerm} must earn to achieve this ${levelTerm}.`}
          sx={{
            paddingBottom: "32px",
          }}
        />
        <SimpleImageInput source="hero" />
      </SimpleForm>
    </Create>
  );
};

export const PlatformLevelEdit = () => {
  const { getTerm } = useCurrentPlatform();

  const levelTerm = getTerm("platform_level", {
    plural: false,
    capitalize: false,
  });
  const userTerm = getTerm("user", {
    plural: false,
    capitalize: false,
  });
  const pointTerm = getTerm("point", {
    plural: true,
    capitalize: false,
  });
  const pointCapTerm = getTerm("point", {
    plural: true,
    capitalize: true,
  });
  return (
    <Edit mutationMode="pessimistic" redirect="list">
      <TabbedForm
        variant="standard"
        mode="onChange"
        toolbar={<StyledToolBar />}
      >
        <FormTab label="Basic Info">
          <TextInput fullWidth source="name" validate={required()} />
          <NumberInput
            source="threshold"
            label={`${pointCapTerm} Threshold`}
            validate={required()}
            helperText={`Threshold represents the amount of ${pointTerm} a ${userTerm} must earn to achieve this ${levelTerm}.`}
            sx={{
              paddingBottom: "32px",
            }}
          />
          <SingleImageInput source="hero" />
        </FormTab>
        <FormTab label={`${userTerm} Interactions`}>
          <PlatformLevelUserInteractions />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
