import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  required,
  SimpleForm,
} from "react-admin";
import { StyledToolBar } from "~/components";

export const AreaCreate = () => {
  return (
    <Create>
      <SimpleForm toolbar={<StyledToolBar />}>
        <ReferenceInput source="area_id" reference="areas">
          <AutocompleteInput
            label="Base Area"
            fullWidth
            validate={[required()]}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
