import { Grid, Typography } from "@mui/material";
import { Edit, SimpleForm, TextInput, useEditContext } from "react-admin";
import { StyledToolBar } from "~/components";
import { DoMagicButton } from "~/components/buttons/DoMagicButton";
import { GridCard } from "~/components/GridCard";
import { TagSidebar } from "../taggings";
import { UrlsList } from "../urls/UrlsList";

export const MediaEdit = () => {
  return (
    <Edit mutationMode="pessimistic" redirect={false}>
      <MediaForm />
    </Edit>
  );
};

export const MediaInfoCard = () => {
  const { record, isLoading } = useEditContext();
  if (isLoading) return null;
  const { filename, type, size, width, height } = record;
  return (
    <GridCard title="File Info">
      <Typography variant="body2">File: {filename}</Typography>
      <Typography variant="body2">Type: {type}</Typography>
      <Typography variant="body2">Size: {Math.round(size / 1024)}k</Typography>
      {width && height && (
        <Typography variant="body2">
          Dimensions: {width} x {height}
        </Typography>
      )}
    </GridCard>
  );
};
export const MediaForm = () => {
  const { record } = useEditContext();
  const { type } = record;
  let url;
  switch (type) {
    case "Photo":
      url = record.urls?.large?.url;
      break;
    case "Video":
      url = record.urls?.thumbnails?.large?.url;
      break;
    default:
      break;
  }
  return (
    <SimpleForm toolbar={<StyledToolBar />} sx={{ px: 4 }}>
      <Grid container spacing={5}>
        <Grid item xs={9}>
          <img
            src={url}
            alt={record.filename}
            height="600px"
            width="100%"
            style={{ objectFit: "contain" }}
          />
          <GridCard title="Tags">
            <TagSidebar />
          </GridCard>
          <GridCard title="Related Web Pages">
            <UrlsList />
          </GridCard>
        </Grid>
        <Grid item xs={3}>
          {/* <ImageField
            source="url"
            sx={{
              height: "300px",
              width: "100%",
              "& img": {
                width: "250px",
                height: "250px",
                objectFit: "contain",
              },
            }}
          /> */}
          <Grid container spacing={2}>
            <GridCard title="Caption/Alt">
              <TextInput source="caption" fullWidth multiline />
            </GridCard>
            <MediaInfoCard />
            <GridCard title="EXIF"></GridCard>
            <GridCard title="Attibution"></GridCard>
            <DoMagicButton />
          </Grid>
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
