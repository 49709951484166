import { Newspaper } from "@phosphor-icons/react";
import { Create, required, SimpleForm, TextInput } from "react-admin";
import { useLocation } from "react-router";
import { ItinResourceProps } from "../types";
import { NewsletterList } from "./NewsletterList";

import { NewsletterEdit } from "./NewsletterEdit";

export const NewsletterCreate = () => {
  const location = useLocation();
  const backPath = location.state?.record?.backPath || "create";
  return (
    <Create>
      <SimpleForm redirect={backPath}>
        <TextInput source="name" validate={[required()]} />
        <TextInput source="description" />
        <TextInput
          source="slug"
          helperText="Cannot be changed after creation"
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};

export const NewsletterResource: ItinResourceProps = {
  name: "newsletters",
  list: NewsletterList,
  icon: <Newspaper />,
  edit: NewsletterEdit,
  create: NewsletterCreate,
  recordRepresentation: (r) => `${r.name}`,
  options: { label: "Mailing Lists", menu_group: "communicate", requiredExtension: "Newsletter" },
};
