import { TextInput, SimpleForm, required } from "react-admin";
import { MarkdownInput } from "@react-admin/ra-markdown";
import { Typography } from "@mui/material";
import { IconPickerInput, TextInputWithLengthLimit } from "~/components/inputs";
import { useWatch } from "react-hook-form";
import { StyledToolBar } from "~/components/StyledToolbar";

export const HelpTopicForm = ({ title }) => {
  return (
    <SimpleForm sx={{ width: "100%" }} toolbar={<StyledToolBar />}>
      {title && <Typography variant="h4">{title}...</Typography>}
      <Inputs />
    </SimpleForm>
  );
};

const Inputs = () => {
  const short_description = useWatch({ name: "short_description" }) || "";
  return (
    <>
      <IconPickerInput
        source="fa_icon"
        label="Select an icon"
        validate={required()}
      />
      <TextInput source="name" label="Section title" validate={required()} />
      <TextInputWithLengthLimit
        source="short_description"
        maxLength={60}
        helperText="Appears on the list page and search cards."
        validate={required()}
      />
      <MarkdownInput source="description" label="content" />
    </>
  );
};
