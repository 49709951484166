import { Show, TabbedShowLayout } from "react-admin";
import { StyledToolBar } from "~/components";
import { handleArrayAttributes } from "~/helpers";
import { EventOverview } from "./EventOverview";

const transform = (data, { previousData }) => {
  return handleArrayAttributes(data, previousData, "locations");
};
export const EventShow = () => {
  return (
    <Show transform={transform}>
      <TabbedShowLayout
        redirect={false}
        variant="standard"
        toolbar={<StyledToolBar />}
        defaultValues={{ target_type: "Region" }}
      >
        <TabbedShowLayout.Tab label="overview">
          <EventOverview />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>{" "}
    </Show>
  );
};
