import { useDefineAppLocation } from "@react-admin/ra-navigation";
import { Show, TabbedShowLayout, useGetIdentity } from "react-admin";
import { Stats } from "~/components";
import { TourismoDateRangePicker } from "~/components/TourismoDateRangePicker";
import { useCurrentPlatform } from "~/context";
import { ReportFilterProvider } from "~/context/ReportFilterContext";

export const ContentReport = () => {
  useDefineAppLocation("reports.content");
  const { currentPlatform, isLoading } = useCurrentPlatform();
  const { identity, isLoading: isLoadingIdentity } = useGetIdentity();
  if (isLoading || isLoadingIdentity) return "";

  return (
    <ReportFilterProvider>
      <TourismoDateRangePicker />
      <Show resource="platforms" id={currentPlatform?.id} actions={false}>
        <TabbedShowLayout>
          {identity?.dmo_id ? (
            <TabbedShowLayout.Tab label="Regional Content">
              <Stats category="content" resource="dmos" id={identity?.dmo_id} />
            </TabbedShowLayout.Tab>
          ) : null}
          <TabbedShowLayout.Tab label="Platform Content">
            <Stats category="content" />
          </TabbedShowLayout.Tab>
        </TabbedShowLayout>
      </Show>
    </ReportFilterProvider>
  );
};
