import {
  Datagrid,
  DateField,
  ImageField,
  ReferenceField,
  ReferenceManyField,
  TextField,
} from "react-admin";
import { useCurrentPlatform } from "~/context";
export const UserRewards = () => {
  const { getTerm } = useCurrentPlatform();
  const challengeTerm = getTerm("challenge", {
    plural: false,
    capitalize: true,
  });
  return (
    <ReferenceManyField
      reference="platform_user_rewards"
      target="platform_user_id"
    >
      <Datagrid
        bulkActionButtons={false}
        sx={{
          "& .RaDatagrid-thead ": {
            display: "table-header-group",
            borderTop: "none",
          },
          "& .RaDatagrid-table ": {
            borderTop: "none",
          },
        }}
      >
        <ImageField
          source="url"
          label=""
          sx={{
            "& img": { maxWidth: 50, maxHeight: 50, objectFit: "contain" },
          }}
        />

        <TextField source="name" />
        <ReferenceField
          source="challenge_id"
          reference="challenges"
          label={challengeTerm}
        />
        <DateField source="created_at" label="Date Earned" />
      </Datagrid>
    </ReferenceManyField>
  );
};
