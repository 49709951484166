import { Typography } from "@mui/material";
import { Datagrid, IfCanAccess } from "@react-admin/ra-rbac";
import { useMemo } from "react";
import {
  AutocompleteInput,
  FunctionField,
  ReferenceField,
  ReferenceInput,
  TextField,
  useCreatePath,
  useGetIdentity,
} from "react-admin";
import { SimpleMapList } from "~/components";
import AvatarField from "~/components/AvatarField";
import HasPlatformExtension from "~/components/HasPlatformExtension";
import {
  CircularProgressField,
  FeaturedField,
  OwnerAvatarField,
  StatsSummaryField,
  TaggingsField,
} from "~/components/fields";
import { AiStatusField } from "~/components/fields/AiStatusField";
import { TagFilterInput } from "~/components/filter_inputs";
import { ToggleList } from "~/components/lists";
import { useCurrentPlatform } from "~/context";
import { titleize } from "~/helpers";
import { useListingTypes } from "~/hooks";

const useFilters = () => {
  const { getTerm } = useCurrentPlatform();
  const { data, isLoading } = useListingTypes();
  const region_term = getTerm("region", { capitalize: true });
  return useMemo(
    () => [
      <TagFilterInput source="tags.id" label="By Tag" />,
      <ReferenceInput
        label={`By ${region_term}`}
        source="region_id"
        reference="regions"
        perPage={null}
      >
        <AutocompleteInput
          optionValue="id"
          optionText="name"
          allowEmpty="true"
          emptyText={`By ${region_term}`}
        />
      </ReferenceInput>,
      <AutocompleteInput
        label="By Type"
        source="main_type"
        choices={data}
        emptyText="By Type"
        isLoading={isLoading}
      />,
    ],
    [data, region_term, isLoading]
  );
};
export const ListingList = () => {
  const filters = useFilters();
  return <ToggleList modes={MODES} filters={filters} />;
};

const ListData = () => {
  const { getTerm } = useCurrentPlatform();
  const createPath = useCreatePath();

  return (
    <Datagrid bulkActionButtons={false}>
      <AvatarField source="hero" label={""} />
      <TextField source="location_name" label="Name" />
      <FunctionField
        label="Type"
        render={(record) => titleize(record.main_type)}
      />
      <ReferenceField
        source="region_id"
        reference="regions"
        label={`${getTerm("region", {
          plural: false,
          capitalize: true,
        })} `}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="platform_area_id"
        reference="platform_areas_views"
        label={`${getTerm("area", {
          plural: false,
          capitalize: true,
        })} `}
        link={(record) =>
          createPath({
            resource: "platform_areas",
            type: "edit",
            id: record.id,
          })
        }
      >
        <TextField source="name" />
      </ReferenceField>
      <TaggingsField source="taggings" label="Tags" sortable={false} />
      <FeaturedField source="featured" />
      <OwnerAvatarField label="Owner" />
      <HasPlatformExtension platform_extension="AiExtension">
        <AiStatusField
          source="ingestion_count"
          textAlign="center"
          sortable={false}
        />
      </HasPlatformExtension>
      <CircularProgressField
        source="record_completion_score.score"
        tooltipSource="record_completion_score.deficiencies"
        textAlign="center"
        sortable={false}
        label="Score"
      />
      <HasPlatformExtension
        platform_extension="AnalyticsExtension"
        label="30 Days"
      >
        <StatsSummaryField label="30 Days" />
      </HasPlatformExtension>
    </Datagrid>
  );
};

const listingCta = (record) => {
  return (
    <WithIdentity>
      {(identity) => {
        if (record.owner_id === identity?.id) return null;
        return (
          <IfCanAccess action="claim" record={record} resource="listings">
            <Typography variant="contained">
              You can claim this Listing, click here to get started!
            </Typography>
          </IfCanAccess>
        );
      }}
    </WithIdentity>
  );
};
export const listingCardData = (record) => {
  return {
    title: record.location_name,
    owner_id: record.owner_id,
    subtitles: [<RegionName />],
    tags: false,
    stats_summary: (
      <HasPlatformExtension
        platform_extension="AnalyticsExtension"
        label="30 Days"
      >
        <StatsSummaryField label="30 Days" />
      </HasPlatformExtension>
    ),
    main_type: record.main_type,
    end: listingCta(record),
  };
};

const MODES = [
  { name: "Grid", cardData: listingCardData },
  { name: "List", view: <ListData /> },
  {
    name: "Map",
    view: <SimpleMapList navigateOnClick cardData={listingCardData} />,
  },
];

const WithIdentity = ({ children }) => {
  const { identity } = useGetIdentity();
  return children(identity);
};

export const RegionName = () => (
  <ReferenceField
    source="region_id"
    reference="regions"
    recordRepresentation={(record) => record.name}
    sx={{ color: "grey.600" }}
    link={false}
  >
    <TextField source="name" variant="body" sx={{ color: "grey.600" }} />
  </ReferenceField>
);
