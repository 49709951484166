import { Box } from "@mui/material";
import { useMemo, useState } from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  useCreate,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { CreateContext } from "~/components/drawer/CreateContext";
import { EditContext } from "~/components/drawer/EditContext";
import { useCurrentPlatform } from "~/context";
import { sluggify } from "~/helpers";
import { useTagCategories } from "~/hooks";
//TODO Create Taggings > Create Term
export const TaggingForm = ({
  record,
  target_type,
  target,
  onClose,
  ...props
}) => {
  const { data: categories = [], loading } = useTagCategories();
  const [category, setCategory] = useState(record?.category);
  const { setValue } = useFormContext();
  const [create, { loading: submitting }] = useCreate();
  const { currentPlatform } = useCurrentPlatform();
  const FormContext = useMemo(
    () => (!record?.id ? CreateContext : EditContext),
    [record?.id]
  );
  const { getTerm } = useCurrentPlatform();
  const tagTerm = getTerm("tag", { plural: false, capitalize: true });
  return (
    <FormContext
      id={record?.id}
      resource="taggings"
      mutationMode="optimistic"
      showCloseButton={false}
      onClose={onClose}
    >
      <SimpleForm
        defaultValues={{ target_type, target_id: target.id }}
        {...props}
      >
        <Box gap={3} display="flex" width="100%">
          <AutocompleteInput
            label="Category"
            name="category"
            loading={loading}
            choices={categories}
            onChange={(val) => {
              setCategory(val);
              setValue("tag_id", null);
            }}
            onCreate={(val) => {
              const newCategory = categoryToOption(val);
              categories.push(newCategory);
              return newCategory;
            }}
            fullWidth
          />
          <ReferenceInput
            source="tag_id"
            reference="tags"
            label={tagTerm}
            filter={{ category }}
          >
            <AutocompleteInput
              label={tagTerm}
              disabled={!category || submitting}
              onCreate={async (name) => {
                const res = await create(
                  "tags",
                  {
                    data: {
                      category,
                      name,
                      platform_id: currentPlatform.id,
                    },
                  },
                  { returnPromise: true }
                );
                return res;
              }}
              fullWidth
            />
          </ReferenceInput>
        </Box>
        <TextInput source="note" fullWidth />
      </SimpleForm>
    </FormContext>
  );
};

const categoryToOption = (cat) => {
  const val = sluggify(cat);
  return { id: val, name: val };
};
