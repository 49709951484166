import { forwardRef } from "react";
import { Marker as GmMarker } from "@react-google-maps/api";

export const Marker = forwardRef(({ color, ...props }, ref) => {
  const icon = {
    path: PATH,
    anchor: new google.maps.Point(12, 17),
    fillOpacity: 1,
    fillColor: color,
    strokeWeight: 1,
    strokeColor: "white",
    scale: 1.5,
    labelOrigin: new google.maps.Point(12, 26),
  };
  return <GmMarker icon={icon} {...props} ref={ref} />;
});

const PATH = [
  "M12",
  "11.5A2.5",
  "2.5 0 0",
  "1 9.5",
  "9A2.5",
  "2.5 0 0",
  "1 12",
  "6.5A2.5",
  "2.5 0 0",
  "1 14.5",
  "9A2.5",
  "2.5 0 0",
  "1 12",
  "11.5M12",
  "2A7",
  "7 0 0",
  "0 5",
  "9C5",
  "14.25 12",
  "22 12",
  "22C12",
  "22 19",
  "14.25 19",
  "9A7",
  "7 0 0",
  "0 12",
  "2Z",
].join(",");
