import { DataF } from "@react-google-maps/api";
import { get } from "lodash";
import { useEffect, useRef } from "react";
import { useRecordContext } from "react-admin";
import { useUpdateEffect } from "usehooks-ts";
import { convertMultiPolyToPolyFeature } from "../helpers";
import { useHandleClick } from "./helpers";

export const MultiPolygon = ({ isActive, source, ...props }) => {
  const record = useRecordContext();
  const bounds = get(record, source);
  const dataRef = useRef();
  const handleClick = useHandleClick(props);
  useEffect(() => {
    dataRef.current?.setStyle({
      fillColor: isActive ? "#283593" : "#000000",
    });
  }, [isActive]);

  useUpdateEffect(() => {
    const data = dataRef.current;
    if (data) {
      data.forEach((f) => data.remove(f));
      if (bounds) {
        const feature = convertMultiPolyToPolyFeature(bounds);
        data.addGeoJson(feature);
      }
    }
  }, [bounds]);

  if (!bounds) return null;
  const feature = convertMultiPolyToPolyFeature(bounds);

  return (
    <DataF
      onLoad={(data) => {
        dataRef.current = data;
        data.addGeoJson(feature);
      }}
      options={{ style: { fillColor: "#000000", fillOpacity: 0.35 } }}
      {...props}
      onClick={handleClick}
    />
  );
};
