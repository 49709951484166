import { Stack } from "@mui/material";
import {
  Labeled,
  NumberInput,
  SimpleForm,
  TextInput,
  useListContext,
} from "react-admin";
import { SingleImageInput } from "~/components/images/SingleImageInput";
import ItinMarkdownInput from "~/components/ItinMarkdownInput";
import { useCurrentPlatform } from "~/context";

export const LegForm = ({ itinerary, ...props }) => {
  const { total } = useListContext();
  const { getTerm } = useCurrentPlatform();
  const legTerm = getTerm("leg", { plural: true });
  const itinTerm = getTerm("itinerary", { plural: false });

  return (
    <SimpleForm
      defaultValues={{
        itinerary_id: itinerary?.id,
        order_on_itin: total + 1,
      }}
      {...props}
    >
      <Stack direction="row" width="97%" gap={3}>
        <TextInput source="title" fullWidth required />
        <NumberInput source="order_on_itin" label="Order" fullWidth required />
      </Stack>
      <ItinMarkdownInput
        isRequired={false}
        title="Description"
        height="250px"
        placeholder={`Describe your ${itinTerm} ${legTerm} or days here!`}
        source="introduction"
        description={`This description can be displayed in an ${itinTerm} layout`}
      />
      <LegImageField />
    </SimpleForm>
  );
};

export const LegImageField = ({ target }) => {
  const { getTerm } = useCurrentPlatform();
  return (
    <Stack spacing={2}>
      <Labeled sx={{ width: 1, height: 1 }}>
        <SingleImageInput
          label={`${getTerm("leg", { capitalize: true })} Hero`}
          source="hero"
          height="40vh"
        />
      </Labeled>
    </Stack>
  );
};
