import { memo } from "react";
import { Typography } from "@mui/material";
import { sanitizeFieldRestProps, useRecordContext } from "react-admin";
import { get } from "lodash";

/**
 * Display a time string as a locale string.
 * Based on DateField but only accepts a 24hour time string formatted as hh:mm
 *
 * If data isn't in that format it will just be displayed "as is"
 *
 * By default it formats the string to 12hour format (hh:mm AM/PM) this can be adjusted by passing the timeStyle prop
 * @example
 * <TimeField source="open_time" />
 * // renders the record { id: 1234, open_time: '22:00' } as
 * <span>10:00 PM</span>
 */

export const TimeField = memo((props) => {
  const {
    className,
    emptyText,
    source,
    locales,
    timeStyle = "short",
    ...rest
  } = props;
  const record = useRecordContext(props);
  if (!record) return null;
  const value = get(record, source);
  if (!value && !emptyText) return null;
  let timeString = value;
  if (timeRegex.test(value)) {
    const [hours, minutes] = value.split(":");
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    timeString = toLocaleStringSupportsLocales
      ? date.toLocaleTimeString(locales, { timeStyle })
      : date.toLocaleTimeString();
  }
  return (
    <Typography
      component="span"
      variant="body2"
      className={className}
      {...sanitizeFieldRestProps(rest)}
    >
      {timeString || emptyText}
    </Typography>
  );
});

// hh:mm
const timeRegex = /^\d{2}:\d{2}$/;

const toLocaleStringSupportsLocales = (() => {
  // from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
  try {
    new Date().toLocaleString("i");
  } catch (error) {
    return error instanceof RangeError;
  }
  return false;
})();
