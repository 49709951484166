import Rainbow from "rainbowvis.js";

function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);

  return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
}

export const defineGradient = (num_elements) => {
  const gradient = [hexToRGB("ffffff", 0)];
  const rainbow = new Rainbow();
  rainbow.setSpectrum(
    "#440154",
    "#482475",
    "#414487",
    "#355f8d",
    "#2a788e",
    "#21908d",
    "#22a884",
    "#42be71",
    "#7ad151",
    "#bddf26",
    "#fde725"
  );
  rainbow.setNumberRange(0, num_elements);
  for (let ii = 0; ii < num_elements; ii++) {
    gradient.push(hexToRGB(rainbow.colourAt(ii), 1));
  }
  return gradient;
};
