import { Box } from "@mui/material";

import {
  Article,
  CirclesThreePlus,
  FlagCheckered,
  Image,
  MapTrifold,
  PlusCircle,
  TextAlignJustify,
  TextT,
} from "@phosphor-icons/react";
import { SummaryIcon } from "~/components";
import { useCurrentPlatform } from "~/context";

export const ItineraryIcons = () => {
  const { getTerm } = useCurrentPlatform();
  const legTerm = getTerm("leg", { plural: true });
  const stopTerm = getTerm("stop", { plural: true });
  const regionTerm = getTerm("region", { plural: true });
  const challengeTerm = getTerm("challenge", { plural: true });
  return (
    <Box
      sx={{
        display: "grid",
        gap: 2,
        gridTemplateColumns: "repeat(2, 1fr)",
      }}
    >
      <SummaryIcon source="num_legs" name={legTerm} Icon={CirclesThreePlus} />
      <SummaryIcon source="num_stops" name={stopTerm} Icon={PlusCircle} />
      <SummaryIcon source="region_slug" name={regionTerm} Icon={MapTrifold} />
      <SummaryIcon source="carousel_images" Icon={Image} />
      <SummaryIcon
        source="num_challenges"
        name={challengeTerm}
        Icon={FlagCheckered}
      />
      <SummaryIcon source="subtitle" Icon={TextT} showValue={false} />
      <SummaryIcon
        source="short_description"
        Icon={Article}
        showValue={false}
      />
      <SummaryIcon
        source="description"
        Icon={TextAlignJustify}
        showValue={false}
      />
    </Box>
  );
};
