import { Grid } from "@mui/material";
import { IfCanAccess } from "@react-admin/ra-rbac";
import { isEmpty } from "lodash";
import {
  Edit,
  FormTab,
  RadioButtonGroupInput,
  ReferenceManyField,
  TabbedForm,
  TextField,
  TextInput,
  required,
  useGetOne,
  useRecordContext,
} from "react-admin";
import {
  SingleImageInput,
  SocialsList,
  Stats,
  StyledToolBar,
} from "~/components";
import ItinMarkdownInput from "~/components/ItinMarkdownInput";
import { DrawerDatagrid } from "~/components/drawer";
import { OptionsInputs } from "~/components/inputs";
import { ContentInlineEditList } from "../contents";
import { LogList } from "../logs/LogList";
import { PlatformExtensionForm } from "../platform_extensions";

export const PlatformEdit = () => {
  const { data: currentUser, isLoading: isUserLoading } = useGetOne("users", {
    id: "current",
  });
  const isSuperAdmin = currentUser?.role === "super_admin";

  return (
    <Edit redirect={false} mutationMode="optimistic">
      <TabbedForm
        variant="standard"
        toolbar={<StyledToolBar showDelete={false} />}
      >
        <FormTab label="Overview">
          <Stats />
        </FormTab>
        <FormTab label="basic info">
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <TextInput source="name" validate={required()} fullWidth />
              <TextInput source="subtitle" fullWidth />
              <ItinMarkdownInput
                title="Full Description"
                description="Tell us about this platform. This will appear in the about section of the app and default website"
                height="250px"
                placeholder="Compose an epic.."
                source="description"
                isRequired={false}
              />
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                  <TextInput source="slug" fullWidth />
                  <RadioButtonGroupInput
                    source="collaboration_status"
                    choices={[
                      { id: 0, name: "Not Collaborating" },
                      { id: 1, name: "Open For Requests" },
                      { id: 2, name: "Open For Collaboration" },
                    ]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput source="api_key" fullWidth />
                  {isSuperAdmin && (
                    <RadioButtonGroupInput
                      source="publish_status"
                      choices={[
                        { id: 0, name: "Disabled" },
                        { id: 1, name: "Hidden" },
                        { id: 2, name: "Live" },
                        { id: 3, name: "Promoted" },
                      ]}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextInput source="short_name" fullWidth />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={6} rowSpacing={2}>
              <Grid item xs={6}>
                <SingleImageInput source="logo" label="Logo Image" />
              </Grid>
              <Grid item xs={12}>
                <SingleImageInput source="hero" label="Hero Image" />
              </Grid>
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label="Contact & Socials">
          <TextInput source="contact_email" fullWidth />
          <Grid container columnSpacing={1}>
            <SocialsList />
          </Grid>
        </FormTab>
        <FormTab label="Language & Taxonomy">
          <OptionsInputs source="terms" />
        </FormTab>
        <FormTab label="Additional Content">
          <ContentInlineEditList filter={{ is_single: true }} />
        </FormTab>
        <FormTab label="extensions">
          <ExtensionTab />
        </FormTab>
        <FormTab label="Activity Log">
          <LogList />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const ExtensionTab = () => {
  const record = useRecordContext();
  return (
    <IfCanAccess action="edit" resource="platform_extensions">
      <ReferenceManyField reference="platform_extensions" target="platform_id">
        <DrawerDatagrid
          canEdit={(id, resource, record) => !isEmpty(record.option_keys)}
          Form={<PlatformExtensionForm platform={record} />}
          closeOnSave={false}
        >
          <TextField source="type" />
        </DrawerDatagrid>
      </ReferenceManyField>
    </IfCanAccess>
  );
};
