import { Fade, Typography, useTheme, Box } from "@mui/material";
import { isFunction } from "lodash";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { LinearProgress, useGetOne } from "react-admin";
import {
  SubscriptionCallbacks,
  SubscriptionResponses,
  useSubscription,
} from "~/lib";

type IngestionProcessingChannelMessage =
  SubscriptionResponses["IngestionProcessingChannel"];

type IngestionContextType = {
  status: IngestionProcessingChannelMessage | null;
  ingestion: any;
  refetch(): void;
  setId: Dispatch<SetStateAction<number | string | undefined>>;
  isLoading: boolean;
};

const IngestionContext = createContext<IngestionContextType | undefined>(
  undefined
);
type Callbacks = SubscriptionCallbacks<"IngestionProcessingChannel">;

type Props = Callbacks & {
  completed?: Callbacks["received"];
  errored?: Callbacks["received"];
  onSuccess?: (data: any) => void;
  onRefetch?: (data: any) => void;
  ingestion_id?: number | string;
  children: ReactNode | ((args: IngestionContextType) => ReactNode);
};

/**
 * IngestionProvider automatically queries an `ingestion` once an `ingestion_id` is provided.
 * After the query is successful it automatically sets up the ActionCable subscription.
 *
 * `ingestion_id` can either be passed as a prop or be set from a child component using `setId`
 * available in the `useIngestion` provider.
 *
 * Children can either be standard ReactNodes
 * @example
 * return (
 *  <IngestionProvider ingestion_id={id}>
 *    <ChildComponents>
 *  </IngestionProvider>
 * )
 * Or a function whos arguments are all the same props passed to the context
 * @example
 * return (
 *  <IngestionProvider ingestion_id={id}>
 *    {({status, isLoading, ingestion, setId}) => {
 *      isLoading
 *        ? <Loading/>
 *        : <ChildComponent/>
 *    }}
 *  </IngestionProvider>
 * )
 */

export const IngestionProvider = ({
  ingestion_id,
  children,
  ...all_callbacks
}: Props) => {
  // allows the id to be manually set using setId, but also changed by updating ingestion_id prop
  const [id, setId] = useState(ingestion_id);
  useEffect(() => {
    setId(ingestion_id);
  }, [ingestion_id]);

  const [status, setStatus] =
    useState<IngestionProcessingChannelMessage | null>(null);

  // destructure the custom callbacks, but pass the standard ones on to the subscribe function
  const {
    onRefetch,
    onSuccess,
    completed,
    errored,
    ...subscription_callbacks
  } = all_callbacks;

  // queries the ingestion by id if set
  const {
    data: ingestion,
    refetch,
    isLoading,
  } = useGetOne<any>(
    "ingestions",
    { id: encodeURIComponent(id!) },
    { enabled: !!id, onSuccess }
  );

  // setup subscription once the ingestion query is complete.
  useSubscription("IngestionProcessingChannel", ingestion?.id, {
    ...subscription_callbacks,
    connected: () => {
      const complete = ingestion.status === "Complete";
      setStatus({
        message: complete ? "" : "Rendering...",
        progress: complete ? 100 : 1,
        error: false,
      });
      subscription_callbacks.connected?.();
    },
    received: (res) => {
      subscription_callbacks.received?.(res);
      setStatus(res);
      const complete = res.progress === 100;
      if (complete || res.error) {
        complete && completed?.(res);
        res.error && errored?.(res);
        // if progress is complete or there is an error refetch query to get the latest data.
        refetch().then((data) => {
          onRefetch?.(data);
        });
      }
    },
  });

  return (
    <IngestionContext.Provider
      value={{ status, ingestion, refetch, setId, isLoading }}
    >
      {isFunction(children)
        ? children({ status, ingestion, refetch, setId, isLoading })
        : children}
    </IngestionContext.Provider>
  );
};

export const useIngestion = () => {
  const context = useContext(IngestionContext);
  if (context === undefined)
    throw new Error("useIngestion must be used within a IngestionProvider.");
  return context;
};

export const IngestionStatusMessage = ({
  error_message,
}: {
  error_message?: string;
}) => {
  const { status } = useIngestion();
  const { transitions } = useTheme();
  if (!status) return null;
  const { progress, message: status_message, error } = status;
  const message = (error && error_message) || status_message;
  const inProgress = progress > 0 && progress !== 100;
  return (
    <Fade
      in={(inProgress || error) && !!message}
      timeout={{ enter: transitions.duration.enteringScreen, exit: 3000 }}
    >
      <Box>
        <Typography variant="h3">{`${message}`}</Typography>
        <LinearProgress
          sx={{ width: "100%" }}
          variant={progress ? "determinate" : "indeterminate"}
          value={progress}
        />
      </Box>
    </Fade>
  );
};
