import { SparklineGraphs } from "../graphs/SparklineGraphs";
import { CheckInsGraphType } from "../helpers";
import _ from "lodash";
import dayOfYear from "dayjs/plugin/dayOfYear";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { useState } from "react";
import dayjs from "dayjs";

dayjs.extend(dayOfYear);
dayjs.extend(weekOfYear);

type CheckInsGraphProps = {
  data: CheckInsGraphType[];
};

export const CheckInsGraph = ({ data }: CheckInsGraphProps) => {
  if (data.length < 1) return null;
  const [period, setPeriod] = useState("week");
  let grouped = data;
  switch (period) {
    case "month":
      const monthly = _.groupBy(data, function (item) {
        return item.date.toString().substring(0, 7);
      });
      grouped = Object.entries(monthly).map(([key, value]) => {
        const aggregatedDate = dayjs(key).format("YYYY-MM-DD");
        return {
          date: aggregatedDate,
          check_ins: _.sum(value.map((v) => v.check_ins)),
        };
      });
      break;
    case "week":
      const weekly = _.groupBy(data, function (item) {
        return dayjs(item.date).week();
      });
      grouped = Object.entries(weekly).map(([key, value]) => {
        let date = value[0] ? value[0].date : new Date();
        return {
          date: dayjs(date)
            .dayOfYear(7 * parseInt(key))
            .format("YYYY-MM-DD"),
          check_ins: _.sum(value.map((v) => v.check_ins)),
        };
      });
      break;
    default:
      grouped = data;
  }
  return (
    <SparklineGraphs
      data={grouped}
      header={`Check-Ins Over Time: ${_.sum(grouped.map((d) => d.check_ins))}`}
      text="Check-ins aggregated by date"
      ymax={_.max(grouped.map((d) => d.check_ins))}
      height="300px"
      period={period}
      setPeriod={setPeriod}
      showCsvLink
    />
  );
};
