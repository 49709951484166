import { Avatar, Box, Tooltip, IconButton, Menu } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useGetIdentity, useTranslate } from "react-admin";
import { styled } from "@mui/material/styles";
import { Person } from "@mui/icons-material";
import { UserMenuItems } from "./UserMenuItems";
import { UserMenuProvider } from "./UserMenuContext";

export const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const translate = useTranslate();
  const { isLoading, data: identity } = useGetIdentity();

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = useCallback(() => setAnchorEl(null), []);
  const context = useMemo(() => ({ onClose: handleClose }), [handleClose]);
  const open = Boolean(anchorEl);
  const label = "ra.auth.user_menu";
  return (
    <Root>
      <Tooltip title={label && translate(label, { _: label })}>
        <IconButton
          aria-label={label && translate(label, { _: label })}
          aria-owns={open ? "menu-appbar" : null}
          aria-haspopup={true}
          color="inherit"
          onClick={handleMenu}
          size="large"
        >
          {!isLoading && identity?.avatar ? (
            <Avatar
              className={UserMenuClasses.avatar}
              src={identity.avatar}
              alt={identity.fullName}
            />
          ) : (
            defaultIcon
          )}
        </IconButton>
      </Tooltip>
      <UserMenuProvider value={context}>
        <Menu
          id="menu-appbar"
          className={UserMenuClasses.menu}
          disableScrollLock
          anchorEl={anchorEl}
          anchorOrigin={AnchorOrigin}
          transformOrigin={TransformOrigin}
          open={open}
          onClose={handleClose}
        >
          <UserMenuItems />
        </Menu>
      </UserMenuProvider>
    </Root>
  );
};

const PREFIX = "ItinUserMenu";

export const UserMenuClasses = {
  userButton: `${PREFIX}-userButton`,
  avatar: `${PREFIX}-avatar`,
  menu: `${PREFIX}-menu`,
  menuList: `${PREFIX}-menuList`,
};

const Root = styled("div", {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${UserMenuClasses.userButton}`]: {
    textTransform: "none",
  },

  [`& .${UserMenuClasses.avatar}`]: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  [`& .${UserMenuClasses.menu}`]: {},
}));

const defaultIcon = (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={{
      backgroundColor: "grey.400",
      width: "40px",
      height: "40px",
      borderRadius: "50px",
    }}
  >
    <Person sx={{ zindex: 1 }} />
  </Box>
);

const AnchorOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

const TransformOrigin = {
  vertical: "top",
  horizontal: "right",
};
