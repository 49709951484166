import { Edit, FormTab, TabbedForm } from "react-admin";
import { Stats } from "~/components";
import { ItineraryOverview } from "./ItineraryOverview/ItineraryOverview";

export const ItineraryShow = () => {
  return (
    <Edit actions={false}>
      <TabbedForm>
        <FormTab label="Overview">
          <ItineraryOverview />
        </FormTab>
        <FormTab label="Reports">
          <Stats category="report" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
