import { Chip } from "@mui/material";
import { Building } from "@phosphor-icons/react";
import { useRecordContext } from "react-admin";

export const EventLocationChip = () => {
  const record = useRecordContext(); // Access the record context directly

  return (
    <Chip
      label={record.name}
      size="small"
      icon={
        record.listing_id ? (
          <Building
            weight="fill"
            color="white"
            size={18}
            style={{ paddingLeft: "4px" }}
          />
        ) : null
      } // Adds icon if listing_id is present
    />
  );
};
