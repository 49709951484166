import { Create } from "react-admin";
import { useParams } from "react-router-dom";
import { useCurrentPlatform } from "~/context";
import { ContentForm } from "./ContentForm";

export const ContentCreate = () => {
  const { id: content_type_id } = useParams();

  const { currentPlatform } = useCurrentPlatform();

  const defaultValues = {
    target_type: "Platform",
    target_id: currentPlatform?.id,
    content_type_id,
  };

  return (
    <Create
      resource="contents"
      redirect={(resource, id) =>
        `content_types/${content_type_id}/${resource}/${id}`
      }
      sx={{
        marginTop: 0,
        marginLeft: 0,
        marginRight: 0,
        padding: 0,
        borderRadius: 0,
        backgroundColor: "transparent",
        "& .RaCreate-noActions": {
          marginTop: "0px",
        },
        "& .MuiToolbar-root": {
          margin: "0px",
          marginLeft: "0px",
        },
        "& .RaToolbar-desktopToolbar": {
          marginLeft: "32px",
          marginRight: "32px",
          marginTop: "0px",
          marginBottom: "32px",
          borderRadius: "0px 0px 16px 16px",
          padding: "32px",
        },
        "& .RaCreate-card": {
          backgroundColor: "transparent",
        },
      }}
    >
      <ContentForm defaultValues={defaultValues} />
    </Create>
  );
};
