import { Box } from "@mui/material";

export const ButtonWrapper = ({ position = "end", sx, ...props }) => {
  return (
    <Box
      {...props}
      sx={[
        {
          display: "flex",
          justifyContent: `flex-${position}`,
          alignItems: "center",
          gap: 2,
          whiteSpace: "nowrap",
        },
      ]}
    />
  );
};
