import { get } from "lodash";
import { Datagrid, TextField, ReferenceField, ChipField } from "react-admin";
import { ToggleList } from "~/components/lists";
export const IngestionList = () => {
  return <ToggleList modes={MODES} />;
};
const cardData = (record) => {
  return {
    img: get(record, "images_payload.0"),
    title: record.name,
    main_type: record.base_domain,
    end: record.url,
  };
};

const ListData = () => {
  return (
    <Datagrid rowClick="edit" bulkActionButtons={true}>
      <ReferenceField source="parent_id" reference="ingestions">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <TextField source="url" />
      <TextField source="summary" />
      <TextField source="images_count" />
      <TextField source="content_count" />
      <ChipField source="status" />
    </Datagrid>
  );
};
const MODES = [
  { name: "Grid", cardData: cardData },
  { name: "List", view: <ListData /> },
];
