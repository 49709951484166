import {
  Datagrid,
  NumberField,
  ReferenceField,
  ReferenceManyCount,
  ReferenceManyField,
  SingleFieldList,
  TextField,
} from "react-admin";
import { EnumSelectField } from "~/components/fields";
import { ToggleList } from "~/components/lists";

export const FormList = () => {
  return <ToggleList modes={MODES} />;
};

const ListData = () => {
  return (
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" />
      <EnumSelectField
        source="approval_type"
        color_options={{ manual: "primary", automatic: "success" }}
      />
      <EnumSelectField source="status" />
      <ReferenceManyCount
        label="Fields"
        reference="form_fields"
        target="form_id"
      />
      <ReferenceManyCount
        label="Pending Entries"
        reference="form_entries"
        target="form_id"
        filter={{ status: "awaiting_approval" }}
      />
      <ReferenceManyCount
        label="Approved Entries"
        reference="form_entries"
        target="form_id"
        filter={{ status: "approved" }}
      />
      <ReferenceManyField
        reference="tasks"
        target="form_id"
        label="Linked Challenges"
      >
        <SingleFieldList linkType={false}>
          <ReferenceField reference="challenges" source="challenge_id" />
        </SingleFieldList>
      </ReferenceManyField>
    </Datagrid>
  );
};

const MODES = [{ name: "List", view: <ListData /> }];
