import { Calendar } from "@phosphor-icons/react";
import { ItinResourceProps } from "../types";
import { EventCreate } from "./EventCreate";
import { EventEdit } from "./EventEdit";
import { EventList } from "./EventList";
import { EventShow } from "./EventShow";

export const EventResource: ItinResourceProps = {
  name: "events",
  list: EventList,
  edit: EventEdit,
  create: EventCreate,
  show: EventShow,
  icon: <Calendar />,
  recordRepresentation: (r) => r.name,
  options: { menu_group: "events" },
};
