import { Chip, ChipProps, Stack, Tooltip } from "@mui/material";
import { get, isNil } from "lodash";
import { ChartLine, IconProps } from "@phosphor-icons/react";
import { ForwardRefExoticComponent } from "react";
import { abbreviateNumber } from "~/helpers/abbreviateNumber";
import { useStats } from "~/hooks";
import { DEFAULT_STATS_ICONS } from "../stats_constants";
import { SummaryCard } from "../stats";

type IconRecord = Record<
  string,
  ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>
>;
type Props = {
  icons: IconRecord;
  record?: string;
  resource?: string;
  iconProps?: IconProps;
  hideZeros?: boolean;
};

const hideValue = (value: number | null, hideZeros: boolean) =>
  isNil(value) || (value === 0 && hideZeros);

export const StatsSummaryField = ({
  icons = DEFAULT_STATS_ICONS,
  iconProps,
  hideZeros = true,
  ...props
}: Props) => {
  const { data, isLoading } = useStats(props);
  if (isLoading) return "...";
  if (!data) return null;
  return (
    <Stack direction={"row"} columnGap={1.5}>
      {data.cards.map((card) => {
        if (hideValue(card.value, hideZeros)) return null;
        const Icon = get(icons, card.stat_type, ChartLine);
        return (
          <Tooltip key={card.name} title={card.name}>
            <Stack direction="row" alignItems="center" columnGap={0.0}>
              <Icon size={16} {...iconProps} />
              {abbreviateNumber(card.value)}
            </Stack>
          </Tooltip>
        );
      })}
    </Stack>
  );
};

export const ExpandedStatsSummaryField = ({
  icons = DEFAULT_STATS_ICONS,
  iconProps,
  chipProps,
  hideZeros = true,
  ...props
}: Props & { chipProps?: ChipProps }) => {
  const { data, isLoading } = useStats(props);
  if (isLoading) return "...";
  if (!data) return null;
  return data.cards.map((card) => {
    if (hideValue(card.value, hideZeros)) return null;
    if (card.sparkline) return <SummaryCard card={card} />;
    const Icon = get(icons, card.stat_type, ChartLine);
    return (
      <Chip
        variant="outlined"
        icon={<Icon {...iconProps} />}
        label={`${abbreviateNumber(card.value)} ${card.name}`}
        {...chipProps}
        sx={{ fontSize: "1.25em", margin: "10px", ...chipProps?.sx }}
      />
    );
  });
};
