import { createContext, useContext, useRef, useState } from "react";

const MapDataContext = createContext();

export const MapDataProvider = (props) => {
  const [drawingMode, setDrawingMode] = useState("Grab");
  const dataRef = useRef();
  return (
    <MapDataContext.Provider
      {...props}
      value={{ dataRef, drawingMode, setDrawingMode }}
    />
  );
};
export const useMapData = () => useContext(MapDataContext);
