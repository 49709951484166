import { PartialBlock } from "@blocknote/core";
import { createReactBlockSpec } from "@blocknote/react";
import { FacebookLogo } from "@phosphor-icons/react";
import { LinearProgress, useGetList } from "react-admin";
import { useCurrentPlatform } from "~/context";
import { BlockEditorSchema } from "../BlockInput";

export const BlockNoteSocials = createReactBlockSpec(
  {
    type: "socials",
    content: "none",
    propSchema: {
      size: { default: "medium", values: ["small", "medium", "large"] },
      showTitle: { default: true },
      textColour: { default: "" },
      backgroundColour: { default: "" },
    },
  },
  {
    render: ({ block, editor, contentRef }) => {
      return <BlockNoteSocialsContent {...block.props} />;
    },
  }
);

type Props = {
  size: "small" | "medium" | "large";
  showTitle: boolean;
  textColour: string;
  backgroundColour: string;
};

const getSize = (size: Props["size"]) => {
  switch (size) {
    case "small":
      return 25;
    case "medium":
      return 50;
    case "large":
      return 100;
  }
};

const BlockNoteSocialsContent = ({
  size,
  showTitle,
  textColour,
  backgroundColour,
}: Props) => {
  const { currentPlatform } = useCurrentPlatform() as { currentPlatform: any };
  const cms_extension = currentPlatform.platform_extensions.find(
    (pe: { type: string }) => pe.type === "CmsExtension"
  );
  const { data, isLoading } = useGetList(
    "socials",
    {
      pagination: { page: 1, perPage: 25 },
      filter: { target_type: "Platform", target_id: currentPlatform.id },
      sort: { field: "id", order: "ASC" },
    },
    { keepPreviousData: true }
  );
  const pixel_size = getSize(size);
  if (isLoading) return <LinearProgress />;
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      {data?.map((social) => (
        <div key={social.id} style={{ display: "inline-block", padding: 1 }}>
          <a
            href={social.value}
            target="_blank"
            style={{
              textDecoration: "none",
              color: textColour || "#000",
              display: "block",
            }}
          >
            <div
              style={{
                margin: "0 auto",
                width: `${pixel_size}px`,
                height: `${pixel_size}px`,
                borderRadius: `${pixel_size * 0.15}px`,
                lineHeight: 0,
                padding: `${pixel_size * 0.08}px`,
                backgroundColor:
                  backgroundColour ||
                  cms_extension?.options?.theme?.button_color ||
                  "#aaa",
              }}
            >
              <img
                src={`${document.location.origin}/social_icons/${social.key}.png`}
                alt={social.key}
                width="100%"
                height="100%"
              />
            </div>
            {showTitle && social.key}
          </a>
        </div>
      ))}
    </div>
  );
};

export const insertBlockNoteSocials = (editor: BlockEditorSchema) => ({
  title: "Socials",
  onItemClick: () => {
    const currentBlock = editor.getTextCursorPosition().block;

    const headerBlock: PartialBlock = {
      type: "paragraph",
      content: [
        { type: "text", text: "Follow us on Socials!", styles: { bold: true } },
      ],
    };
    const subHeaderBlock: PartialBlock = {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "Follow us on socials to stay in touch!",
          styles: {},
        },
      ],
    };
    editor.insertBlocks(
      [headerBlock, subHeaderBlock, { type: "socials" }],
      currentBlock,
      "after"
    );
  },
  group: "Itinerator",
  icon: <FacebookLogo />,
});
