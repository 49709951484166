import {
  DateField,
  ReferenceManyField,
  TextField,
  useEditContext,
} from "react-admin";
import { RewardForm } from "./RewardForm";
import { DrawerDatagrid } from "~/components/drawer";
import { useCurrentPlatform } from "~/context";

export const RewardList = () => {
  const { record } = useEditContext();
  const { getTerm } = useCurrentPlatform();
  const rewardCapTerm = getTerm("reward", {
    plural: false,
    capitalize: true,
  });
  return (
    <ReferenceManyField target="challenge_id" label="" reference="rewards">
      <DrawerDatagrid
        buttonLabel={rewardCapTerm}
        Form={<RewardForm challenge={record} />}
      >
        <TextField source="title" label="Name" />,
        <TextField source="type" label="Type" />,
        <TextField source="completion_count" />,
      </DrawerDatagrid>
    </ReferenceManyField>
  );
};
