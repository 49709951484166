import { FormTab } from "react-admin";
import { ImageTabs } from "~/components/images";

export const EditImagesTab = (props) => {
  return (
    <FormTab {...props} label="Images">
      <ImageTabs />
    </FormTab>
  );
};
