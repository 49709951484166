import { PieChart } from "@mui/x-charts";
import { isNumber } from "lodash";
import { titleize } from "~/helpers";
import { StatSummaryCard } from "~/resources/types";

type Props = {
  data: StatSummaryCard["table"];
};
export const ReportPie = ({ data }: Props) => {
  if (!data || !data.length) return null;
  const parsed_data = data.filter(hasCount).map((item) => ({
    label: titleize(item.value as string),
    value: item.count,
  }));
  return (
    <div style={{ width: "100%", maxHeight: "750px" }}>
      <PieChart
        series={[{ data: parsed_data }]}
        height={250}
        slotProps={{
          legend: {
            direction: "column",
            labelStyle: {
              fontSize: 12,
              fontWeight: 500,
            },
            position: { vertical: "top", horizontal: "right" },
            padding: 10,
          },
        }}
      />
    </div>
  );
};

type Item = NonNullable<StatSummaryCard["table"]>[0];
const hasCount = (item: Item): item is Item & { count: number } =>
  isNumber(item.count);
