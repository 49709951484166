import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box, Slider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateView } from "@mui/x-date-pickers";
import dayjs, { Dayjs, ManipulateType, OpUnitType } from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

type SliderComponentProps = {
  period: string;
  earliestDate: Dayjs;
  latestDate: Dayjs;
  startDate: Dayjs;
  setStartDate: Dispatch<SetStateAction<Dayjs>>;
  endDate: Dayjs;
  setEndDate: Dispatch<SetStateAction<Dayjs>>;
};

export const SliderComponent = ({
  period,
  earliestDate,
  latestDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: SliderComponentProps) => {
  let rangeLength: number;
  if (period === "month") {
    rangeLength = latestDate.diff(
      earliestDate.startOf("month"),
      period as OpUnitType
    );
  } else {
    rangeLength = latestDate.diff(earliestDate, period as OpUnitType);
  }

  const periodLookBack = () => {
    switch (period) {
      case "month":
        return 1;
      case "week":
        return 4;
      default:
        return 30;
    }
  };

  const [range, setRange] = useState<number[]>([
    rangeLength - periodLookBack(),
    rangeLength,
  ]);

  useEffect(() => {
    const lookBack = periodLookBack();
    setRange([rangeLength - lookBack, rangeLength]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, rangeLength]);

  const handleSliderChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setRange([newValue[0], range[1]]);
      setStartDate(earliestDate.add(newValue[0], period as ManipulateType));
    } else {
      setRange([range[0], newValue[1]]);
      setEndDate(earliestDate.add(newValue[1], period as ManipulateType));
    }
  };

  const datePickerViews: DateView[] =
    period === "month" ? ["month", "year"] : ["year", "month", "day"];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        width="100%"
        display="flex"
        gap={5}
        alignItems="center"
        pl={1}
        pr={1}
      >
        <Box sx={{ minWidth: "80px" }}>
          <DatePicker
            views={datePickerViews}
            label={undefined}
            minDate={earliestDate}
            maxDate={latestDate}
            value={startDate.utcOffset(8)}
            onChange={(newValue) => {
              setStartDate(newValue!);
            }}
            slotProps={{
              textField: { helperText: null },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "60%",
          }}
        >
          <Slider
            size="medium"
            getAriaLabel={() => "Small"}
            marks={period === "month"}
            min={0}
            max={rangeLength}
            step={1}
            value={range}
            onChange={handleSliderChange}
            valueLabelFormat={(value) =>
              dayjs(earliestDate)
                .add(value, period as ManipulateType)
                .utcOffset(8)
                .format(period === "month" ? "MMM YYYY" : "MMM DD YYYY")
                .toString()
            }
            valueLabelDisplay="auto"
            disableSwap
            dir="rtl"
          />
        </Box>
        <Box sx={{ minWidth: "80px" }}>
          <DatePicker
            views={datePickerViews}
            label={undefined}
            minDate={earliestDate}
            maxDate={latestDate}
            value={endDate.utcOffset(8)}
            onChange={(newValue) => {
              setEndDate(newValue!);
            }}
            slotProps={{ textField: { helperText: null } }}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};
