import { Subscription, createConsumer } from "@rails/actioncable";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useUnmount } from "usehooks-ts";
const { host, protocol } = window.location;
const URL = `${protocol === "http:" ? "ws" : "wss"}://${host}/cable`;
export const consumer = createConsumer(URL);

// This type handles all the allowed channels and defines their responses. When creating a new ActionCable channel add it here.
export type SubscriptionResponses = {
  IngestionProcessingChannel: {
    message: string;
    progress: number;
    error: boolean;
  };
  MediaConversionChannel: { message: string; progress: number };
  WpImportChannel: {
    message: string;
    progress: number;
    error: boolean;
    complete: boolean;
  };
};

type ChannelName = keyof SubscriptionResponses;

type ChannelWithId<Channel extends ChannelName> = {
  channel: Channel;
  id: number;
};

export type SubscriptionCallbacks<Channel extends ChannelName> = {
  appear?(): void;
  away?(): void;
  connected?(): void;
  disconnected?(): void;
  initialized?(): void;
  install?(): void;
  rejected?(): void;
  uninstall?(): void;
  update?(): void;
  received?(data: SubscriptionResponses[Channel]): void;
};

// hook that simplifies the creation of a channel subscription. Also adds typesafety to the responses.
export const useChannel = () => {
  const cable = useRef(consumer);
  const [connected, setConnected] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const subscriptionRef = useRef<Subscription>();

  // allows subscribing by preset channel names and ids.
  const subscribe = useCallback(
    <Channel extends ChannelName>(
      channelParams: ChannelWithId<Channel>,
      callbacks?: SubscriptionCallbacks<Channel>
    ) => {
      const subscription = cable.current.subscriptions.create(channelParams, {
        ...callbacks,
        initialized: () => {
          setSubscribed(true);
          callbacks?.initialized?.();
        },
        connected: () => {
          setConnected(true);
          callbacks?.connected?.();
        },
        disconnected: () => {
          setConnected(false);
          callbacks?.disconnected?.();
        },
        uninstall: () => {
          setSubscribed(false);
          callbacks?.uninstall?.();
        },
      });
      subscriptionRef.current = subscription;
      return subscription;
    },
    []
  );

  const unsubscribe = useCallback(() => {
    setSubscribed(false);
    subscriptionRef.current?.unsubscribe();
  }, []);

  useUnmount(() => {
    // automatically unsubscribes when the component unmounts.
    unsubscribe();
  });
  return useMemo(
    () => ({
      subscribe, //subscribe to a channel
      unsubscribe, // manually unsubscribe from the channel
      subscribed, // is the channel initialized
      connected, // are we able to recieve response data
      consumer: cable.current, // consumer in case manual work needs to be done
    }),
    [subscribe, unsubscribe, subscribed, connected]
  );
};
/**
 * Shortcut hook for subscripions you want to automatically start on component mount
 * simplifies the standard wrapping of the subscribe function in a useEffect if you don't
 * need anything more complicated.
 */
export const useSubscription = <Channel extends ChannelName>(
  channel: Channel,
  channel_id?: number,
  callbacks?: SubscriptionCallbacks<Channel>
) => {
  const channelParams = useMemo<Partial<ChannelWithId<Channel>>>(
    () => ({ channel, id: channel_id }),
    [channel, channel_id]
  );
  const callbackRef = useRef(callbacks);
  callbackRef.current = callbacks;
  const { subscribe, ...rest } = useChannel();
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  useEffect(() => {
    if (isChannelParams(channelParams)) {
      const sub = subscribe(channelParams, callbackRef.current);
      setSubscription(sub);
      return () => {
        sub.unsubscribe();
      };
    }
  }, [channelParams, subscribe]);

  return { subscriber: subscription, ...rest };
};

const isChannelParams = <Channel extends ChannelName>(
  channelParams: Partial<ChannelWithId<Channel>>
): channelParams is ChannelWithId<Channel> => {
  return !!channelParams.id && !!channelParams.channel;
};
