import { Grid, Button, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useDefineAppLocation } from "@react-admin/ra-navigation";
import {
  AppleLogo,
  FlagCheckered,
  Globe,
  Handshake,
  User,
} from "@phosphor-icons/react";
import { ItinLoading } from "~/components";
import { GridCard } from "~/components/GridCard";
import { useCurrentPlatform } from "~/context";

export const Reports = () => {
  const { loading: platformLoading, currentPlatform } = useCurrentPlatform();
  useDefineAppLocation("reports");
  if (platformLoading) return <ItinLoading isLarge />;
  const navigate = useNavigate();

  const platform = currentPlatform;

  const reports = [
    {
      name: "Content",
      description: "View content data",
      icon: <Globe />,
      route: "/reports/content",
    },
    {
      name: "Web Traffic",
      description: "View web traffic data",
      icon: <Globe />,
      route: "/reports/web",
    },
    {
      name: "App Usage",
      description: "View app usage data",
      route: "/reports/app",
      icon: <AppleLogo />,
    },
    {
      name: "User Feedback",
      description: "View user feedback",
      route: "/reports/feedback",
      icon: <User />,
    },
    {
      name: "Collaboration",
      description: "View collaboration data",
      route: "/reports/collaboration",
      icon: <Handshake />,
    },
    {
      name: "Contests",
      description: "View contest data",
      route: "/reports/contests",
      icon: <FlagCheckered />,
    },
  ];
  // .concat(
  //   platform.platform_extensions
  //     .filter((pe) => pe.type === "ReportExtension")
  //     .map((pe) => pe.options)
  // )
  // .flat();

  return (
    <Grid container spacing={2}>
      {reports.map((report) => {
        return (
          <GridCard xs={4} title={report.name}>
            <CardContent>
              <Typography>{report.description}</Typography>
              <Button variant="outlined" onClick={() => navigate(report.route)}>
                View
              </Button>
            </CardContent>
          </GridCard>
        );
      })}
    </Grid>
  );
};
