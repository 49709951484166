import { Box } from "@mui/material";
import { geojsonToWKT } from "@terraformer/wkt";
import {
  Button,
  DateField,
  Labeled,
  ListContextProvider,
  useGetManyReference,
  useListContext,
  useNotify,
  useRecordContext,
} from "react-admin";
import { SimpleMapList } from "~/components";
import { useCustomMutation } from "~/hooks";

export const ManualCheckIn = (props) => {
  const { target_id, target_type, location = {}, user_id } = props;
  const record = useRecordContext(props);

  const { longitude, latitude } = location;
  const lonlat =
    latitude &&
    longitude &&
    geojsonToWKT({
      type: "Point",
      coordinates: [longitude, latitude],
    });
  const { mutate, isLoading: isSubmitting } = useCustomMutation();
  const {
    data: platform_user_check_ins = [],
    isLoading: isCheckinsLoading,
    refetch,
  } = usePlatformUserCheckIns(props);
  const notify = useNotify();
  const listContext = useListContext({
    data: [
      record,
      { name: "Feedback Location", lonlat, id: "checkin" },
      ...platform_user_check_ins.map((checkin) => ({
        ...checkin,
        name: new Date(checkin.updated_at).toLocaleString(),
      })),
    ],
  });
  if (isCheckinsLoading) return null;
  return (
    <Box>
      <Box sx={{ height: 400 }}>
        <ListContextProvider value={listContext}>
          <SimpleMapList />
        </ListContextProvider>
      </Box>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        paddingY={3}
      >
        <Button
          disabled={isSubmitting}
          onClick={() => {
            var data = new FormData();
            data.append("target_id", target_id);
            data.append("target_type", target_type);
            data.append("user_id", user_id);
            data.append("latitude", location.latitude);
            data.append("longitude", location.longitude);
            mutate(
              {
                resource: "platform_user_check_ins",
                custom_url: "manual_check_in",
                data,
              },
              {
                onSuccess: (res) => {
                  const { data } = res;
                  refetch();
                  notify(data.notice, { type: data.type });
                },
              }
            );
          }}
          label="Validate Check In"
        />
        <Labeled label="Existing Check In Dates At This Location">
          <Box display="flex" flexDirection="column">
            {!platform_user_check_ins.length && "No Check Ins Found"}
            {platform_user_check_ins.map((checkin) => (
              <DateField
                key={checkin.id}
                source="updated_at"
                showTime
                record={checkin}
              />
            ))}
          </Box>
        </Labeled>
      </Box>
    </Box>
  );
};

const usePlatformUserCheckIns = ({ user_id, target_type, target_id }) => {
  const { data: platform_users = [], isLoading } = useGetManyReference(
    "platform_users",
    {
      target: "user_id",
      id: user_id,
    },
    { enabled: !!user_id }
  );
  const [platform_user] = platform_users;
  const ref = useGetManyReference(
    "platform_user_check_ins",
    {
      target: "platform_user_id",
      id: platform_user?.id,
      filter: { target_type, target_id },
    },
    { enabled: !!platform_user?.id }
  );
  return { ...ref, isLoading: isLoading || ref.isLoading };
};
