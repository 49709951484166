import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { useGetOne, useRefresh, useUpdate } from "react-admin";
import { useQueryClient } from "react-query";
import { ItinLoading } from "~/components";
import { getTermFunction, Terms } from "~/helpers";

type Media = {
  id: number;
  caption: string;
  url: string;
  content_type: string;
  filename: string;
  filesize: number;
  record_id: number;
  record_type: string;
  rendering: boolean;
  blob_id: number;
};

type Platform = {
  id: number;
  api_key?: string;
  hero?: Media;
  logo?: Media;
  name: string;
  noreply_email: string;
  platform_extensions: any[];
  short_name?: string;
  slug?: string;
  subtitle?: string;
  terms: Terms;
};

type CurrentPlatformContextType = {
  loading: boolean;
  currentPlatform: Platform | null;
  getTerm: ReturnType<typeof getTermFunction>;
  setPlatform: (current_platform_id: number) => void;
};

const CurrentPlatformContext = createContext<CurrentPlatformContextType>({
  loading: true,
  currentPlatform: null,
  getTerm: (t, d) => "",
  setPlatform: () => {},
});

export const CurrentPlatformProvider = (props: PropsWithChildren) => {
  const {
    data: currentPlatform,
    isLoading,
    refetch,
  } = useGetOne("platforms", {
    id: "current",
  });
  const refresh = useRefresh();
  const queryClient = useQueryClient();

  const getTerm = useMemo(
    () => getTermFunction(currentPlatform?.terms),
    [currentPlatform?.terms]
  );

  const [update] = useUpdate();
  const setPlatform = async (current_platform_id: number) =>
    update(
      "users",
      {
        id: "current",
        data: { current_platform_id },
      },
      {
        onSuccess: (d) => {
          console.log("refreshing");
          queryClient.invalidateQueries([
            "platforms",
            "getOne",
            { id: "current" },
          ]);
          queryClient.invalidateQueries(["users", "getOne", { id: "current" }]);
          queryClient.invalidateQueries([
            "platform_users",
            "getOne",
            { id: "current" },
          ]);
          refetch();
          refresh();
        },
      }
    );
  if (isLoading) return <ItinLoading sx={{ height: "100vh" }} />;
  return (
    <CurrentPlatformContext.Provider
      {...props}
      value={{
        loading: isLoading,
        currentPlatform,
        setPlatform,
        getTerm,
      }}
    />
  );
};

export const useCurrentPlatform = () => useContext(CurrentPlatformContext);
