export const PREFIX = "ItinLayout";
export const LayoutClasses = {
  contentWithAppbar: `${PREFIX}-contentWithAppbar`,
  content: `${PREFIX}-content`,
  sidebar: `${PREFIX}-sidebar`,
  appBar: `${PREFIX}-appbar`,
  menu: `${PREFIX}-menu`,
  menuItem: `${PREFIX}-menuItem`,
  logo: `${PREFIX}-logo`,
};
