import { TextInput, TextInputProps } from "react-admin";
import { mergeSx } from "~/helpers";
import {
  useFallbackResourceValue,
  UseFallbackResourceValueProps,
} from "~/hooks";

type Props = TextInputProps & UseFallbackResourceValueProps;

export const TextInputWithPlaceholderFallback = ({
  fallback_reference,
  fallback_source,
  fallback_value_source,
  sx,
  ...props
}: Props) => {
  const [fallback] = useFallbackResourceValue({
    fallback_reference,
    fallback_source,
    fallback_value_source,
    source: props.source,
  });
  return (
    <TextInput
      placeholder={fallback}
      helperText={fallback && `Leave blank to use ${fallback}.`}
      sx={mergeSx({ input: { "&::placeholder": { opacity: 0.7 } } }, sx)}
      fullWidth
      resettable
      {...props}
    />
  );
};
