import { TikTokEmbed } from "react-social-media-embed";

export const TikTokIframe = ({ url: original_url }: { url: string }) => {
  const id = tiktokUrltoId(original_url);
  if (!id) return null;
  const url = `https://www.tiktok.com/embed/v2/${id}?autoplay=false`;
  return (
    <iframe
      src={url}
      style={{
        height: 757,
        display: "inline-block",
        visibility: "unset",
        border: "none",
      }}
    />
  );
};

const tiktokUrltoId = (url: string) => {
  const regExp =
    /^.*https:\/\/(?:m|www|vm)?\.?tiktok\.com\/((?:.*\b(?:(?:usr|v|embed|user|video)\/|\?shareId=|\&item_id=)(\d+))|\w+)/;
  return url.match(regExp)?.filter(Boolean).pop() || false;
};
