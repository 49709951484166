import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

export const useValidateFields = () => {
  const { trigger, formState, setValue, getValues } = useFormContext();
  const validate = useCallback(
    async (fields) => {
      const valid = await trigger(fields);
      if (!valid) {
        // If not valid loop through all the errored field and "touch" them so that the errors show up.
        Object.keys(formState.errors).forEach((field) => {
          setValue(field, getValues(field), { shouldTouch: true });
        });
      }
      return valid;
    },
    [formState.errors, getValues, setValue, trigger]
  );
  return validate;
};
