import { MarkdownField } from "@react-admin/ra-markdown";
import { ReferenceManyField, TextField, useRecordContext } from "react-admin";
import { DrawerDatagrid } from "~/components/drawer";
import { useCurrentPlatform } from "~/context";
import { StopList } from "~/resources/stops";
import { LegForm } from "./LegForm";

export const LegList = (props) => {
  const itinerary = useRecordContext();
  const { getTerm } = useCurrentPlatform();
  const legTerm = getTerm("leg", { capitalize: true, plural: true });
  return (
    <ReferenceManyField reference="legs" target="itinerary_id" perPage={null}>
      <DrawerDatagrid
        buttonLabel={legTerm}
        Form={<LegForm itinerary={itinerary} />}
        datagridProps={{
          expand: (
            <>
              <MarkdownField
                source="introduction"
                sortable={false}
                label={false}
                sx={{ paddingX: 3 }}
              />
              <StopList {...props} itinerary={itinerary} />
            </>
          ),
          expandSingle: true,
        }}
        sortable_update_source="order_on_itin"
        canDelete={false}
        sx={{
          "& .RaDatagrid-tableWrapper": {
            padding: 0,
          },
          "& .MuiTableBody-root": {
            marginTop: "32px",
            marginBottom: "32px",
          },
          "& .RaDatagrid-thead ": { display: "none" },
          "& .RaCreateButton-root": {
            marginLeft: 2,
          },
        }}
      >
        <TextField
          source="title"
          sortable={false}
          sx={{ fontWeight: 600, fontSize: "24px" }}
        />
      </DrawerDatagrid>
    </ReferenceManyField>
  );
};
