import { Chip } from "@mui/material";
import { Chat, DeviceMobile, Envelope } from "@phosphor-icons/react";

import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ListActions,
  ReferenceField,
  ReferenceManyField,
  TextField,
  useRecordContext,
} from "react-admin";
import { Empty } from "~/components";
import {
  EnumField,
  PolymorphicReferenceField,
  StatsSummaryField,
} from "~/components/fields";
import { useTargetType } from "~/hooks";
import { CreateCommunicationButton } from "./CreateCommunicationButton";

export const CommunicationList = () => {
  return (
    <List actions={<ListActions hasCreate={false} />}>
      <CommunicationData />
    </List>
  );
};

export const RecordCommunicationList = () => {
  const target_type = useTargetType();

  return (
    <>
      <CreateCommunicationButton />
      <ReferenceManyField
        reference="communications"
        target="target_id"
        filter={{ target_type }}
      >
        <CommunicationData target_type={target_type} />
      </ReferenceManyField>
    </>
  );
};

export const CommunicationData = ({ target_type, ...props }) => {
  const record = useRecordContext(props);
  return (
    <Datagrid
      bulkActionButtons={false}
      rowClick="edit"
      empty={
        <Empty
          defaultValues={
            record && target_type
              ? { target_id: record.id, target_type }
              : undefined
          }
        />
      }
    >
      <EnumField source="status" />
      <DateField source="scheduled_at" />
      <DateField source="sent_at" />
      {!record && (
        <PolymorphicReferenceField
          source="target_id"
          reference_source="target_type"
        />
      )}
      <TextField source="name" label="Name" />
      <TextField source="subtitle" label="Subtitle" />

      <ReferenceField source="owner_id" reference="users" sortable={false} />
      <FunctionField
        label="Push"
        render={(record) =>
          record.send_push_notification ? (
            <Chip
              variant={"outlined"}
              icon={<DeviceMobile />}
              label={record.communicatable_push_user_count}
            />
          ) : (
            "-"
          )
        }
      />
      <FunctionField
        label="Email"
        render={(record) =>
          record.send_email ? (
            <Chip
              variant={"outlined"}
              icon={<Envelope size={20} />}
              label={record.communicatable_email_user_count}
            />
          ) : (
            "-"
          )
        }
      />
      <FunctionField
        label="SMS"
        render={(record) =>
          record.send_sms ? (
            <Chip
              variant={"outlined"}
              icon={<Chat size={20} />}
              label={record.communicatable_sms_user_count}
            />
          ) : (
            "-"
          )
        }
      />
      <StatsSummaryField />
    </Datagrid>
  );
};
