import { createReactBlockSpec } from "@blocknote/react";
import { Box } from "@mui/material";
import { LinearProgress, useGetList } from "react-admin";
import { Menu, NumberInput } from "@mantine/core";

type Props = { count?: number };

export const BlockNoteUpcomingEventsContent = ({ count = 3 }: Props) => {
  const { data, isLoading } = useGetList(
    "events",
    {
      pagination: { page: 1, perPage: count },
      sort: { field: "id", order: "DESC" },
    },
    { keepPreviousData: true }
  );
  if (isLoading) return <LinearProgress />;
  return data?.map((event) => (
    <Box
      key={event.id}
      width={`${100 / count}%`}
      display="inline-block"
      padding={1}
    >
      <a
        href={event.public_url}
        target="_blank"
        style={{ textDecoration: "none", color: "#000" }}
      >
        <div
          style={{
            width: "100%",
            height: `${450 / count}px`,
            maxWidth: "600px",
            overflow: "hidden",
            borderRadius: "5px",
            lineHeight: 0,
            backgroundColor: "#aaa",
          }}
        >
          <img
            src={event.hero.url}
            alt={event.name}
            style={{
              display: "block",
              width: "100%",
              height: "auto",
            }}
            width="600"
            height="450"
          />
        </div>
        {event.name}
      </a>
    </Box>
  ));
};

export const BlockNoteUpcomingEvents = createReactBlockSpec(
  {
    type: "upcomingEvents",
    content: "none",
    propSchema: { count: { default: 3 } },
  },
  {
    toExternalHTML: ({ block, editor, contentRef }) => {
      const { count } = block.props;
      return <BlockNoteUpcomingEventsContent count={count} />;
    },
    render: ({ block, editor, contentRef }) => {
      const { count } = block.props;
      return (
        <>
          <Menu
            withinPortal={false}
            zIndex={999999}
            trigger="hover"
            openDelay={100}
            closeDelay={400}
            closeOnItemClick={false}
          >
            <Menu.Target>
              <Box>
                <BlockNoteUpcomingEventsContent count={count} />
              </Box>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item component="span">
                <NumberInput
                  value={count}
                  onChange={(val) =>
                    editor.updateBlock(block, {
                      type: "upcomingEvents",
                      props: {
                        count: typeof val === "number" ? val : parseInt(val),
                      },
                    })
                  }
                />
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </>
      );
    },
  }
);
