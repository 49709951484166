/**
 * Abbreviates a number by reducing its precision and appending a suffix (k, m, b, t) to represent large numbers.
 * @param value - The number to be abbreviated.
 * @param total_digits - The total number of digits to be displayed in the abbreviated number. Default is 2.
 * @returns The abbreviated number as a string.
 * @example
 * abbreviateNumber(1000) => "1k"
 * abbreviateNumber(19.9234) => "19"
 * abbreviateNumber(1234567,3) => "1.23m"
 * abbreviateNumber(19.9324,3) => "19.9"
 * abbreviateNumber(2316546542313654) => "2316t"
 */
export const abbreviateNumber = (value: number, total_digits: number = 2) => {
  if (total_digits < 1) total_digits = 1;

  // If the value is less than 1000 return the number with a number of decimals
  // so that the total number of digits is total_digits or the length of the whole number, whichever is bigger.
  if (value < 1000) {
    const v = value.toString();
    const [whole = "", decimal] = v.split(".");
    const return_value = [whole, decimal?.slice(0, total_digits - whole.length)]
      .filter(Boolean)
      .join(".");
    return return_value;
  }

  const suffixes = ["", "k", "m", "b", "t"];
  let suffixNum = Math.floor(Math.trunc(value).toString().length / 3);
  let shortValue = 0;

  // If the number is larger than the suffixes array can support calculate using the last suffix.
  if (suffixes[suffixNum] === undefined) {
    return `${Math.trunc(value / Math.pow(1000, suffixes.length - 1))}${
      suffixes[suffixes.length - 1]
    }`;
  }
  for (let precision = total_digits; precision >= 1; precision--) {
    shortValue = parseFloat(
      (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(
        precision
      )
    );
    const dotLessShortValue = (shortValue + "").replace(/[^a-zA-Z 0-9]+/g, "");
    if (dotLessShortValue.length <= total_digits) {
      break;
    }
  }
  return [shortValue, suffixes[suffixNum]].filter(Boolean).join("");
};
