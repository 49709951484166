import { BookOpen, Envelope } from "@phosphor-icons/react";
import { ItinResourceProps } from "../types";
import { ContentTypeEdit } from "./ContentTypeEdit";
import { ContentTypeList } from "./ContentTypeList";
import { Create, SimpleForm, TextInput } from "react-admin";
import { useLocation } from "react-router";

export * from "./types";

export const ContentTypeCreate = () => {
  const location = useLocation();
  const backPath = location.state?.record?.backPath || "create";
  return (
    <Create redirect={backPath}>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Create>
  );
};

export const ContentTypeResource: ItinResourceProps = {
  name: "content_types",
  list: ContentTypeList,
  edit: ContentTypeEdit,
  create: ContentTypeCreate,
  icon: <BookOpen />,
  recordRepresentation: (r) => r.name,
  options: { menu_group: "admin" },
};
