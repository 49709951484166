import { Box } from "@mui/material";
import { ListBase } from "react-admin";
import { SimpleMapList } from "~/components";
import { MapDataProvider } from "~/components/maps//MapDataProvider";

export const HomePageRegions = (props) => {
  return (
    <MapDataProvider>
      <Box height={"55vh"} width="100%" marginTop={"=0px"} marginBottom={"0px"}>
        <ListBase resource="regions" pagination={[120]} perPage={120}>
          <SimpleMapList toolButtons={false} showPins={false} navigateOnClick />
        </ListBase>
      </Box>
    </MapDataProvider>
  );
};
