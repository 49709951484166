import { Box } from "@mui/material";

type Props = {
  value: number;
  max: number;
};

export const ReportDataGridBar = ({ value, max }: Props) => {
  const valueInPercent = (value / max) * 100;
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        width: "100%",
        height: 20,
        marginTop: 0.5,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          lineHeight: "18px",
          width: "100%",
          display: "flex",
          justifyContent: "left",
          paddingLeft: "6px",
          fontWeight: 700,
          fontSize: "0.9em",
        }}
      >
        {value}
      </Box>
      <Box
        sx={{
          borderRadius: 1,
          height: "100%",
          backgroundColor: "#6d88f4",
          maxWidth: `${valueInPercent}%`,
        }}
      />
    </Box>
  );
};
