import { Show, SimpleShowLayout, useGetIdentity } from "react-admin";
import { ItinLoading } from "~/components";
import { useCurrentPlatform } from "~/context";
import { DmoOverview } from "../dmos/DmoShow";

export const ViewerHome = () => {
  const { currentPlatform } = useCurrentPlatform();
  const { identity } = useGetIdentity();
  if (!currentPlatform || !identity) return <ItinLoading />;
  return (
    <Show id={identity?.dmo_id} resource="dmos">
      <SimpleShowLayout>
        <DmoOverview />
      </SimpleShowLayout>
    </Show>
  );
};
