import { Grid, Divider, FormHelperText } from "@mui/material";
import {
  BooleanInput,
  FunctionField,
  TextField,
  useRecordContext,
} from "react-admin";
import { PolymorphicReferenceField } from "~/components/fields";
import { GridCard } from "~/components/GridCard";
import { useFormContext } from "react-hook-form";

export const CommunicationAudience = () => {
  const record = useRecordContext();
  const {
    formState: { errors },
  } = useFormContext();
  return (
    <GridCard item xs={12} title="Target Audience">
      <PolymorphicReferenceField
        source="target_id"
        reference_source="target_type"
      >
        <FunctionField
          render={(r) => `${record.target_type}: ${r.name}`}
          variant="h4"
        />
      </PolymorphicReferenceField>
      {record.target_type && <Divider sx={{ marginY: 1 }} />}
      <FormHelperText error>
        {errors?.target_audience?.message?.message}
      </FormHelperText>
      <TextField source="communicatable_users_description" />
      <br />
      <Grid container>
        <Grid item xs={6}>
          <BooleanInput source="send_push_notification" />
        </Grid>
        <Grid item xs={6}>
          {record?.communicatable_push_user_count} App Users
        </Grid>
        <Grid item xs={6}>
          <BooleanInput source="send_email" />
        </Grid>
        <Grid item xs={6}>
          {record?.communicatable_email_user_count} Opt-in Email Users
        </Grid>
        <Grid item xs={6}>
          <BooleanInput source="send_sms" />
        </Grid>
        <Grid item xs={6}>
          {record?.communicatable_sms_user_count} Opt-in SMS Users
        </Grid>
      </Grid>
    </GridCard>
  );
};
