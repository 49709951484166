import { FlagCheckered, ListBullets, Signpost } from "@phosphor-icons/react";
import { CardCountIcon } from "~/components";
export const regionCardData = (record) => {
  const { listing_count, itinerary_count, all_challenge_ids } = record;

  return {
    title: record.name,
    subtitles: [
      <CardCountIcon
        key="listing"
        count={listing_count}
        type="listing"
        icon={ListBullets}
      />,
      <CardCountIcon
        key="itinerary"
        count={itinerary_count}
        type={"itinerary"}
        icon={Signpost}
      />,
      <CardCountIcon
        key="challenge"
        count={all_challenge_ids.length}
        type={"challenge"}
        icon={FlagCheckered}
      />,
    ],
  };
};
export default regionCardData;
