import { Create, SimpleForm, TextInput } from "react-admin";

export const PlatformCreate = () => {
  return (
    <Create redirect="edit">
      <SimpleForm width={"50%"}>
        <TextInput source="name" fullWidth />
      </SimpleForm>
    </Create>
  );
};
