import { Box, Card } from "@mui/material";
import {
  GoogleMap,
  HeatmapLayer,
  OverlayView,
  OverlayViewF,
} from "@react-google-maps/api";
import { wktToGeoJSON } from "@terraformer/wkt";
import { get } from "lodash";
import { useCallback, useEffect, useState } from "react";
import {
  RecordContextProvider,
  useListContext,
  useRecordContext,
} from "react-admin";
import { ItinLoading } from "~/components/ItinLoading";
import { ImageCard } from "~/components/lists/ImageCard";
import { defineGradient } from "~/components/maps/heatmapGradient";
import { extractLatLngs } from "../hooks";
import { brightMap } from "../mapStyle";
import { Marker } from "./Marker";
import { MultiPolygon } from "./MultiPolygon";
import { Polyline } from "./Polyline";

export const SimpleMapList = ({
  showPins = true,
  showPolygons = true,
  showPolylines = true,
  navigateOnClick,
  markerSource = "lonlat",
  polySource = "bounds",
  polylineSource = "polyline",
  heatmapData = [],
  onClick,
  cardData,
  children,
  ...props
}) => {
  const { data: listData, isLoading } = useListContext();
  const record = useRecordContext(props);
  const data = listData || (!isLoading && [record]);
  const [loc, setLoc] = useState();
  const [map, setMap] = useState(null);
  const [active, setActive] = useState();
  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  useEffect(() => {
    if (map && data) {
      const bounds = new window.google.maps.LatLngBounds();
      data?.forEach((marker) => {
        const markerBounds = get(marker, polySource);
        if (markerBounds) {
          extractLatLngs(markerBounds).map((latlng) => {
            bounds.extend(latlng);
          });
        }
        const markerLonLat = get(marker, markerSource);
        if (markerLonLat) {
          const [lng, lat] = wktToGeoJSON(markerLonLat).coordinates;
          bounds.extend({ lat, lng });
        }
      });

      // If no markers or bounds, center on heatmap data
      if (heatmapData.length > 0) {
        heatmapData.forEach((d) => {
          bounds.extend(
            new window.google.maps.LatLng(
              Number(d.latitude),
              Number(d.longitude)
            )
          );
        });
      }

      map.fitBounds(bounds);
    }
  }, [data, map, polySource, markerSource, heatmapData]);

  const mouseProps = useCallback(
    (record) => ({
      onMouseOver: (e) => {
        setLoc(e.latLng);
        setActive(record);
      },
      onMouseOut: () => setLoc(),
      onClick: onClick ? () => onClick(record) : undefined,
    }),
    [onClick]
  );
  if (isLoading) return <ItinLoading />;

  return (
    <GoogleMap
      onLoad={onLoad}
      onUnmount={onUnmount}
      mapContainerStyle={{
        height: "100%",
        width: "100%",
      }}
      zoom={8}
      options={{
        styles: brightMap,
        gestureHandling: "cooperative",
        fullscreenControl: false,
        streetViewControl: false,
      }}
      onClick={() => setActive()}
    >
      {data?.map((record) => {
        const { polylines } = record;
        const isActive = active?.id === record.id;
        return (
          <RecordContextProvider key={record.id} value={record}>
            {showPins && (
              <Marker
                navigateOnClick={navigateOnClick}
                isActive={isActive}
                source={markerSource}
                {...mouseProps(record)}
              />
            )}
            {showPolygons && (
              <MultiPolygon
                navigateOnClick={navigateOnClick}
                isActive={isActive}
                source={polySource}
                {...mouseProps(record)}
              />
            )}
            {showPolylines && (
              <>
                <Polyline
                  options={{
                    fillColor: "#FFFFFF",
                    fillOpacity: 0.1,
                  }}
                  isActive={isActive}
                  source={polylineSource}
                  navigateOnClick={navigateOnClick}
                  {...mouseProps(record)}
                />
                {polylines?.map((polyline) => (
                  <Polyline
                    key={polyline}
                    polyline={polyline}
                    options={{
                      strokeOpacity: 100,
                      fillColor: "#FFFFFF",
                      fillOpacity: 0.1,
                    }}
                    navigateOnClick={navigateOnClick}
                    {...mouseProps(record)}
                    isActive={isActive}
                  />
                ))}
              </>
            )}
            {isActive && cardData && (
              <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                <ImageCard cardData={cardData} />
              </Box>
            )}
          </RecordContextProvider>
        );
      })}
      {loc && active && (
        <OverlayViewF
          position={loc}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <Card sx={{ padding: 0.5 }}>{active.name}</Card>
        </OverlayViewF>
      )}
      {map && data && heatmapData.length > 0 && (
        <HeatmapLayer
          data={heatmapData.map(
            (d) =>
              new google.maps.LatLng(Number(d.latitude), Number(d.longitude))
          )}
          options={{
            gradient: defineGradient(
              heatmapData.length > 5 ? heatmapData.length : 10
            ),
          }}
        />
      )}
      {children}
    </GoogleMap>
  );
};
