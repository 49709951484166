import { useMemo } from "react";
import { useReference } from "react-admin";
import {
  useFallbackResourceValue,
  UseFallbackResourceValueProps,
} from "~/hooks";

export const useFallbackPoint = (
  props: Partial<UseFallbackResourceValueProps> & { fallback_reference: string }
) => {
  const [id, isFallbackLoading] = useFallbackResourceValue({
    source: "point_id",
    ...props,
  });
  const { referenceRecord, isLoading } = useReference({
    reference: "points",
    id,
    options: { enabled: !!id },
  });
  return useMemo(
    () => ({
      data: referenceRecord,
      isLoading: isLoading || isFallbackLoading,
    }),
    [referenceRecord, isLoading]
  );
};
