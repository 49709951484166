import { CirclesFour, FlagCheckered, ListBullets } from "@phosphor-icons/react";
import { Datagrid, NumberField, ReferenceField, TextField } from "react-admin";
import { CardCountIcon, SimpleMapList } from "~/components";
import AvatarField from "~/components/AvatarField";
import { EnumSelectField, FeaturedField } from "~/components/fields";
import { ToggleList } from "~/components/lists";
import { useCurrentPlatform } from "~/context";

export const ReelList = () => {
  return <ToggleList modes={MODES} />;
};

const ListData = () => {
  const { getTerm } = useCurrentPlatform();
  const regionCapTerm = getTerm("region", {
    plural: false,
    capitalize: true,
  });
  return (
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <EnumSelectField source="status" />
      <AvatarField source="hero" label={false} />
      <TextField source="name" />
      <ReferenceField
        source="region_id"
        reference="regions"
        label={regionCapTerm}
      >
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="num_legs" label="Sections" />
      <NumberField source="total_num_stops" label="Slides" />
      <FeaturedField source="featured" />
    </Datagrid>
  );
};

const cardData = (record) => {
  return {
    title: record.name,
    subtitles: [
      <CardCountIcon icon={CirclesFour} type={"leg"} count={record.num_legs} />,
      <CardCountIcon
        icon={ListBullets}
        type={"stop"}
        count={record.num_stops}
      />,
      <CardCountIcon
        count={record.num_challenges}
        type={"challenge"}
        icon={FlagCheckered}
      />,
    ],
  };
};

const MODES = [
  { name: "Grid", cardData },
  { name: "List", view: <ListData /> },
  {
    name: "Map",
    view: (
      <SimpleMapList navigateOnClick cardData={cardData} showPolygons={false} />
    ),
  },
];
