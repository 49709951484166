import { Edit } from "react-admin";
import { useParams } from "react-router-dom";
import { ContentForm } from "./ContentForm";

export const ContentEdit = (props) => {
  const { contentId } = useParams();
  return (
    <Edit
      resource="contents"
      id={contentId}
      mutationMode="pessimistic"
      redirect={false}
      {...props}
    >
      <ContentForm />
    </Edit>
  );
};
