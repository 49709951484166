import { useDataProvider } from "react-admin";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateManyUnique = () => {
  const dataProvider = useDataProvider();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ({ resource, data }) =>
      dataProvider
        .updateManyUnique(resource, { data })
        .then(({ data }) => ({ resource, data })),
    {
      onMutate: async (variables) => {
        const updatedAt = Date.now();
        const { resource, data } = variables;

        queryClient.setQueriesData(
          [resource, "getManyReference"],
          (oldData) => {
            return oldData?.data
              ? { ...oldData, data: updateColl(oldData.data, data) }
              : oldData;
          },
          { updatedAt }
        );
        queryClient.setQueriesData(
          [resource, "getList"],
          (oldData) => {
            return oldData?.data
              ? { ...oldData, data: updateColl(oldData.data, data) }
              : oldData;
          },
          { updatedAt }
        );
        data.forEach((record) => {
          queryClient.setQueryData(
            [resource, "getOne", { id: record.id, meta: {} }],
            (oldRecord) => ({ ...oldRecord, ...record }),
            { updatedAt }
          );
        });
        return variables;
      },
    }
  );
  const updateManyUnique = async (resource, data) => {
    return mutation.mutate({ resource, data });
  };
  return [updateManyUnique, mutation];
};

const updateColl = (oldData, newData) => {
  const newCollection = oldData.map((oldRecord) => {
    const newRecord = newData.find(({ id }) => id === oldRecord.id);
    if (!newRecord) return oldRecord;
    return { ...oldRecord, ...newRecord };
  });
  return newCollection;
};
