import { Tooltip } from "@mui/material";
import { ReferenceField, WithRecord } from "react-admin";
import AvatarField from "../AvatarField";

export const OwnerAvatarField = (props) => {
  return (
    <ReferenceField reference="users" source="owner_id">
      <WithRecord
        render={(record) => (
          <Tooltip title={`Owned By: ${record.name}`}>
            <AvatarField source="avatar" {...props} />
          </Tooltip>
        )}
      />
    </ReferenceField>
  );
};
