import {
  useResourceContext,
  useRecordContext,
  useCreatePath,
  ReferenceField,
} from "react-admin";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AvatarField from "~/components/AvatarField";
import { startCase } from "lodash";

export const UserCard = () => {
  const resource = useResourceContext();
  const navigate = useNavigate();
  const record = useRecordContext();
  const { id, name } = record;
  const createPath = useCreatePath();
  const path = createPath({ resource, id, type: "edit" });

  return (
    <Grid gridColumn="span 3">
      <Card
        variant="outlined"
        sx={{
          borderRadius: "12px",
          backgroundColor: "primary.contrastText",
          cursor: "pointer",
          minWidth: 200,
        }}
      >
        <CardContent
          variant={"bordered"}
          sx={{
            gap: 2,
            display: "flex",
            flexDirection: "column",
            padding: "0px",
            alignItems: "center",
          }}
        >
          <AvatarField sx={{ width: 80, height: 80 }} source="avatar" />
          <Typography variant={"h6"} noWrap>
            {name}
          </Typography>

          <Stack direction="column" alignItems="center" gap={1}>
            <ReferenceField source="dmo_id" reference="dmos" />
            <Typography noWrap variant="body1">
              {startCase(record.role)}
            </Typography>
            <Typography noWrap variant="body1">
              {record.email}
            </Typography>
          </Stack>
          <Button
            variant="outlined"
            onClick={() => navigate(path)}
            sx={{
              width: "100%",
              textTransform: "none",
            }}
          >
            View Profile
          </Button>
        </CardContent>
      </Card>
    </Grid>
  );
};
