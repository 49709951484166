import { Typography } from "@mui/material";
import { createContext, useContext } from "react";
import { List, Pagination, useResourceContext, useStore } from "react-admin";
import { Empty } from "~/components/Empty";
import { mergeSx } from "~/helpers";
import { ImageGrid } from "./ImageGrid";
import { Toolbar } from "./Toolbar";
import { ListModeButton } from "./Toolbar/ListModeButton";

export const ToggleList = ({
  sx = [],
  modes = [],
  filters,
  actions,
  gridProps,
  toolbar_props,
  ...props
}) => {
  const resource = useResourceContext(props);
  const mode_names = modes.map((m) => m.name);
  const [currentModeName, setCurrentModeName] = useStore(
    `${resource}.list.mode`,
    mode_names[0]
  );
  if (!modes.length)
    return <Typography variant="h1">No list provided</Typography>;
  const current_mode_index = mode_names.findIndex((m) => m === currentModeName);
  const currentMode = modes[current_mode_index];
  const nextMode = modes[(current_mode_index + 1) % mode_names.length];

  return (
    <>
      <List
        empty={<Empty resource={currentMode.resource || resource} />}
        exporter={false}
        pagination={<Pagination rowsPerPageOptions={[12, 24, 36, 120, 240]} />}
        perPage={currentMode?.name === "Map" ? 120 : 12}
        sx={mergeSx(
          {
            overflow: "hidden",
            flexGrow: 1,
            "& .RaList-main": {
              overflow: "auto",
              flexGrow: 1,
            },
            "& .RaList-content": {
              flexGrow: 1,
              backgroundColor: "background.paper",
            },
            "& .MuiTablePagination-root": {
              overflow: "initial",
              marginTop: 0,
            },
          },
          sx
        )}
        actions={null}
        resource={currentMode.resource || undefined}
        {...props}
      >
        <ToggleListModeContext.Provider
          value={{ modes, currentMode, nextMode, setCurrentModeName }}
        >
          <Toolbar
            filters={filters}
            resource={currentMode.resource || undefined}
            actions={
              <>
                <ListModeButton />
                {actions}
              </>
            }
            {...toolbar_props}
          />
          {currentMode.name === "Grid" ? (
            <ImageGrid cardData={currentMode.cardData} {...gridProps}>
              {currentMode.view}
            </ImageGrid>
          ) : (
            currentMode.view
          )}
        </ToggleListModeContext.Provider>
      </List>
    </>
  );
};

const ToggleListModeContext = createContext();
export const useToggleListMode = () => useContext(ToggleListModeContext);
