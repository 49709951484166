import { Box } from "@mui/material";
import {
  DateRangePicker,
  LocalizationProvider,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { Calendar } from "@phosphor-icons/react";
import dayjs from "dayjs";
import { useState } from "react";
import { useReportFilter } from "~/context/ReportFilterContext";

const shortcutsItems = [
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Last Month",
    getValue: () => {
      const today = dayjs();
      const startOfLastMonth = today.startOf("month").add(-1, "month");
      return [startOfLastMonth, startOfLastMonth.endOf("month")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

export const TourismoDateRangePicker = ({
  defaultStartDate,
  defaultEndDate,
}) => {
  const { startDate, setStartDate, endDate, setEndDate } = useReportFilter();
  if (!startDate || !endDate) return null;

  //this holds the partially selected dates in state until the picker is closed
  const [tempStartDate, setTempStartDate] = useState(startDate);
  const [tempEndDate, setTempEndDate] = useState(endDate);

  // const handleRefresh = () => {
  //   // Customize this function to perform the action you need
  //   console.log("Refresh button clicked");
  //   console.log("Start Date:", startDate);
  //   console.log("End Date:", endDate);
  // };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        marginTop={2}
        marginX={4}
      >
        <DateRangePicker
          name="allowedRange"
          slots={{ field: SingleInputDateRangeField }}
          slotProps={{
            shortcuts: { items: shortcutsItems },
            textField: {
              InputProps: { endAdornment: <Calendar />, fullWidth: true },
            },
          }}
          localeText={{ start: "Start Date", end: "End Date" }}
          onChange={(newValue) => {
            setStartDate(newValue[0]);
            setEndDate(newValue[1]);
          }}
          defaultValue={[dayjs(startDate), dayjs(endDate)]}
          sx={{ minWidth: "250px" }}
        />
      </Box>
    </LocalizationProvider>
  );
};
