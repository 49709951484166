import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
} from "@mui/x-data-grid-pro";
import { ArrowSquareOut } from "@phosphor-icons/react";
import dayjs from "dayjs";
import { isNumber } from "lodash";
import { Link } from "react-admin";
import { titleize } from "~/helpers";
import { StatSummaryCard } from "~/resources/types";
import { ProgressBar } from "../ProgressBar";
import { ReportDataGridBar } from "./ReportDataGridBar";
import { useMemo } from "react";

type Props = {
  data: StatSummaryCard["table"];
  name: StatSummaryCard["name"];
  gridProps: Partial<DataGridProProps>;
};

export const ReportDataGrid = ({ data, name, gridProps }: Props) => {
  if (!data?.length) return null;

  const maxCount = data.reduce((max, obj) => {
    return isNumber(obj.count) && obj.count > max ? obj.count : max;
  }, -Infinity);
  const linkable = Object.keys(data[0]!).includes("resource");
  const columns = useMemo(
    () =>
      Object.keys(data[0]!)
        .filter((key) => !["resource", "resource_id"].includes(key))
        .map(
          (key): GridColDef => ({
            field: key,
            headerName: titleize(key),
            width: key === "count" ? 180 : undefined,
            flex: key !== "count" ? 1 : undefined,
            renderCell: (params) => {
              const value = params.value;
              if (key === "date" || key === "sent_at") {
                return dayjs(value, "YYYY-MM-DD").format("LL");
              }
              if (key === "percentage") {
                return <ProgressBar value={Number(value / maxCount)} />;
              }
              if (key === "count") {
                return (
                  <ReportDataGridBar value={Number(value)} max={maxCount} />
                );
              }
              if (key === "target_url") {
                return (
                  <Link to={value}>
                    <ArrowSquareOut />
                  </Link>
                );
              }
              return linkable ? (
                <Link
                  to={`/${params.row["resource"]}/${params.row["resource_id"]}`}
                >
                  {value}
                </Link>
              ) : (
                value
              );
            },
          })
        ),
    [data]
  );

  const rows = useMemo(
    () =>
      data.map((row, index) => ({
        ...row,
        id: `${name}-${index}`,
      })),
    [data]
  );
  //, maxHeight: "750px"
  return (
    <div style={{ width: "100%" }}>
      <DataGridPro
        pagination
        rows={rows}
        columns={columns}
        initialState={{
          // ...data.initialState, Data is an array so it cant have an 'initialState' property
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[5, 10, 25]}
        rowHeight={30}
        sx={{ paddingBottom: 2 }}
        {...gridProps}
      />
    </div>
  );
};
