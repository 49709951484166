import { AddCircleOutline } from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";
import { IfCanAccess } from "@react-admin/ra-rbac";
import { get } from "lodash";
import { useMemo } from "react";
import { AutocompleteInput, required, useCreatePath } from "react-admin";
import { Link } from "react-router-dom";
import { useCurrentPlatform } from "~/context";
import { useCustomQuery } from "~/hooks";

export const RegionInput = ({
  source = "region_id",
  isRequired = true,
  ...props
}) => {
  const { data = [], isLoading } = useCustomQuery("regions/select_input");

  return (
    <AutocompleteInput
      fullWidth
      label={<RegionLabel isRequired={isRequired} />}
      isLoading={isLoading}
      source={source}
      choices={data}
      inputText={(option) => option.name}
      optionText={(option) => <OptionWithDepth option={option} />}
      validate={isRequired ? required() : undefined}
      isRequired={isRequired}
      filterSelectedOptions={false}
      {...props}
    />
  );
};

const OptionWithDepth = ({ option }) => {
  const depth = get(option, "depth", 0);
  const dividers = useMemo(
    () =>
      [...Array(depth)].map((v, i) => (
        <Divider
          key={i}
          sx={{ mr: 2, my: "-6px" }}
          color="secondary"
          orientation="vertical"
          flexItem
        />
      )),
    [depth]
  );
  return (
    <Box display="flex">
      {dividers}
      {get(option, "name", "")}
    </Box>
  );
};

const RegionLabel = ({ isRequired }) => {
  const createPath = useCreatePath();
  const { getTerm } = useCurrentPlatform();
  const regionTerm = getTerm("region", { capitalize: true });
  const label = [regionTerm, isRequired && "*"].filter(Boolean).join(" ");
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="caption">{label}</Typography>
      <IfCanAccess action="create" resource="regions">
        <Box
          as={Link}
          to={createPath({ resource: "regions", type: "create" })}
          sx={{
            textDecoration: "none",
            "&:hover": { textDecoration: "underline" },
          }}
        >
          <Box display="flex" alignItems={"center"} height="20px" gap={0.5}>
            <AddCircleOutline fontSize="8px" className="addRegionIcon" />
            <Typography>{`Create new ${regionTerm}`}</Typography>
          </Box>
        </Box>
      </IfCanAccess>
    </Box>
  );
};
