import { ArrowBack } from "@mui/icons-material";
import { Box } from "@mui/material";
import { email, required, TextInput, useTranslate } from "react-admin";
import { ButtonColumn } from "./ButtonColumn";
import { EmailVerification } from "./EmailVerification";
import { ForgotPassword } from "./ForgotPassword";
import { useLoginContext } from "./Login";
import { LoginButton } from "./LoginButton";
import { useState, useEffect } from "react"; // Import useState and useEffect

export const EmailForm = ({ defaultEmail = "" }) => {
  // Accept defaultEmail prop
  const { loading, setEmailAuth, sign_up, awaiting_verification } =
    useLoginContext();
  const translate = useTranslate();

  const [emailValue, setEmailValue] = useState(defaultEmail); // State to hold the email value

  useEffect(() => {
    setEmailValue(defaultEmail); // Update email state when defaultEmail changes
  }, [defaultEmail]);

  if (awaiting_verification) return <EmailVerification />;

  return (
    <>
      <Box>
        <TextInput
          autoFocus
          source="email"
          label="Email"
          readOnly={loading}
          fullWidth
          validate={[required(), email()]}
          helperText={false}
          defaultValue={emailValue} // Set the value of the TextInput
          onChange={(e) => setEmailValue(e.target.value)} // Update the email state on change
        />
        <TextInput
          source="password"
          label={translate("ra.auth.password")}
          type="password"
          readOnly={loading}
          autoComplete={sign_up ? "new-password" : "current-password"}
          fullWidth
          validate={required()}
          helperText={!sign_up ? <ForgotPassword /> : undefined}
        />
      </Box>
      <ButtonColumn>
        <LoginButton type="submit" loading={loading}>
          {sign_up ? "Sign up" : "Sign in"}
        </LoginButton>
        <LoginButton
          onClick={() => setEmailAuth(false)}
          startIcon={<ArrowBack />}
          variant="outlined"
        >
          Back
        </LoginButton>
      </ButtonColumn>
    </>
  );
};
