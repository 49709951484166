import { Grid } from "@mui/material";
import { get } from "lodash";
import { NumberInput, regex, required, Validator } from "react-admin";
import { useAcceptsNestedAttributes } from "~/hooks/useAcceptsNestedAttributes";
import { useFallbackPoint } from "~/resources/points/useFallbackPoint";
const LAT_PATTERN = /^-?(([0-8]?[0-9])(\.[0-9]+)|90(\.0+))$/;
const LON_PATTERN = /^-?(([0-9]{1,2}|1[0-7][0-9])(\.[0-9]+)|180(\.0+))$/;

const otherValueSet: Validator = (value, values, field) => {
  const other_field = field.source.includes("latitude")
    ? "longitude"
    : "latitude";
  if (!value && !!get(values, `point_attributes.${other_field}`))
    return "Both latitude & longitude must be set.";
  return undefined;
};

const validate = (pattern: RegExp, message: string, isRequired?: boolean) => [
  !!isRequired ? required() : () => undefined,
  regex(pattern, message),
  otherValueSet,
];

type Props = {
  isRequired?: boolean;
};
export const PointInput = ({ isRequired }: Props) => {
  const { data: fallbackPoint } = useFallbackPoint({
    fallback_reference: "areas",
  });
  const getProps = useAcceptsNestedAttributes("points", "point_id");
  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <NumberInput
          {...getProps("latitude")}
          placeholder={get(fallbackPoint, "latitude")?.toString()}
          sx={{ input: { "&::placeholder": { opacity: 0.7 } } }}
          helperText="Leave blank to use the default latitude."
          fullWidth
          validate={validate(
            LAT_PATTERN,
            "Latitude must be between -90.0 and 90.0 and include decimal precision",
            isRequired
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          {...getProps("longitude")}
          placeholder={get(fallbackPoint, "longitude")?.toString()}
          sx={{ input: { "&::placeholder": { opacity: 0.7 } } }}
          helperText="Leave blank to use the default longitude."
          fullWidth
          validate={validate(
            LON_PATTERN,
            "Longitude must be between -180.0 and 180.0 and include decimal precision",
            isRequired
          )}
        />
      </Grid>
    </Grid>
  );
};
