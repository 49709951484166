import { Collapse, Grid } from "@mui/material";
import { Check } from "@phosphor-icons/react";
import { get } from "lodash";
import { useCallback, useState } from "react";
import {
  Button,
  Create,
  SaveButton,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { useLocation } from "react-router";
import { CreateIngestionButton, Sparkles } from "~/components";
import { GooglePlaceAutocomplete } from "~/components/inputs";
import { RegionInput } from "~/components/inputs/RegionInput";
import { useCurrentPlatform } from "~/context";
import { validateUrl } from "~/hooks";
import {
  IngestionImageSelect,
  IngestionProvider,
  IngestionStatusMessage,
} from "../ingestions";
import { BasicFields } from "./BasicFields";
import { ListingHero } from "./ListingOverView/ListingHero";

export const ListingCreate = () => {
  const { state } = useLocation();
  const { region_id } = state?.record || {};
  return (
    <Create>
      <SimpleForm
        defaultValues={{ region_id, short_description: "", subtitle: "" }}
        toolbar={false}
      >
        <ListingCreateForm />
      </SimpleForm>
    </Create>
  );
};

const ListingCreateForm = () => {
  const { getTerm } = useCurrentPlatform();
  const listingTerm = getTerm("listing", { capitalize: true });
  const [manualEntryMode, setManualEntryMode] = useState(false);
  const { ingestion_id, ...record } = useWatch();
  const ingestionCreated = !!ingestion_id;
  const showAutoFields = !manualEntryMode && !ingestionCreated;
  const { setValue } = useFormContext();
  const handleRefetch = useCallback(
    ({ data }) => {
      // after the ingestion has completed processing get the finished json_data
      setValue("location_name", data?.name);
      const { socials = {}, ...values } = get(data, "json_data", {});
      // Listings accepts_nested_attributes_for :socials so we
      // loop through the socials and create an array in the correct format
      const socials_attributes = Object.entries(socials).flatMap(
        ([key, value]) => {
          return value ? { key, value } : [];
        }
      );
      // and assign that to "socials_attributes"
      socials_attributes?.length &&
        setValue("socials_attributes", socials_attributes);
      // For all other values we directly set the form value for the attribute
      Object.entries(values).forEach(
        ([attribute, value]) => value && setValue(attribute, value)
      );
    },
    [setValue]
  );

  return (
    <IngestionProvider ingestion_id={ingestion_id} onRefetch={handleRefetch}>
      {({ status: renderingStatus, isLoading, ingestion }) => {
        const finishedRendering =
          renderingStatus && renderingStatus.progress === 100;
        const isRendering = renderingStatus && !finishedRendering;
        return (
          <Grid container spacing={4}>
            {!manualEntryMode && ingestionCreated && (
              <Collapse
                component={Grid}
                in
                item
                xs={12}
                sx={{ position: "relative" }}
              >
                <Sparkles show={isRendering} sx={{ width: "100%" }}>
                  <ListingHero record={record} sx={{ height: "260px;" }} />
                </Sparkles>
                <IngestionStatusMessage />
              </Collapse>
            )}

            <Grid item xs={12} position={"relative"}>
              {manualEntryMode && <BasicFields show={manualEntryMode} />}
              {showAutoFields && (
                <>
                  <GooglePlaceAutocomplete />
                  <RegionInput />
                  <TextInput
                    source="website"
                    label="Or enter a website url:"
                    validate={[validateUrl, required()]}
                  />
                </>
              )}
            </Grid>
            <IngestionImageSelect
              images={ingestion?.all_images}
              isLoading={isLoading}
            />
            <Grid item xs={6}>
              {!ingestion_id && (
                <CreateIngestionButton
                  website={record.website}
                  onSuccess={(ingestion) =>
                    setValue("ingestion_id", ingestion?.id)
                  }
                />
              )}
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "end" }}>
              {!manualEntryMode && (
                <Button
                  label={`Create ${getTerm("listing")} manually...`}
                  onClick={() => setManualEntryMode(true)}
                />
              )}
              {(manualEntryMode || finishedRendering) && (
                <SaveButton
                  sx={{ ml: 2 }}
                  type="button"
                  label={`Create ${listingTerm}`}
                  endIcon={<Check />}
                  icon={null}
                />
              )}
            </Grid>
          </Grid>
        );
      }}
    </IngestionProvider>
  );
};
