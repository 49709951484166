import { useRecordContext } from "react-admin";
import { OptionsInputs } from "~/components/inputs";
import { useWatch } from "react-hook-form";

export const ContestingExtension = () => {
  const record = useRecordContext();
  const allow_manual_point_redemption = useWatch({
    name: "options.allow_manual_point_redemption",
  });
  return (
    <OptionsInputs
      record={record}
      source={"options"}
      type_source={"type"}
      container
      filter={([field]) => {
        return (
          field === "allow_manual_point_redemption" ||
          allow_manual_point_redemption
        );
      }}
      gridItemProps={(field) => ({
        item: true,
        xs: 12,
        md: ["json", "object", "boolean"].includes(field.type) ? 12 : 4,
      })}
    />
  );
};
