import { Create, Show } from "react-admin";
import {
  SimpleShowLayout,
  TextField,
  TextInput,
  NumberField,
  RichTextField,
  SimpleForm,
  AutocompleteInput,
  useNotify,
} from "react-admin";
import { useCurrentPlatform } from "~/context";
import { sluggify } from "~/helpers";
import { useLocation } from "react-router";
import { useTagCategories } from "~/hooks";
import { StyledToolBar } from "~/components";
import { Typography } from "@mui/material";
import { ItinLoading } from "~/components/ItinLoading";

export const TagCreate = () => {
  const notify = useNotify();
  const location = useLocation();
  const record = location.state?.record || null;
  const { loading, currentPlatform, getTerm } = useCurrentPlatform();
  const tagTerm = getTerm("tag", { plural: false, capitalize: true });
  const { data: categories, loading: categoriesLoading } = useTagCategories();
  if ((loading, categoriesLoading)) return <ItinLoading />;
  return (
    <Create redirect={record?.backPath || "list"}>
      <Typography variant="h4" paragraph mt={1}>
        {`New ${tagTerm}`}
      </Typography>
      <SimpleForm
        variant="standard"
        defaultValues={{
          platform_id: currentPlatform?.id,
        }}
        toolbar={<StyledToolBar />}
        width="50%"
      >
        <AutocompleteInput
          isRequired
          label="Category"
          source="category"
          optionValue="name"
          helperText={'Only a-z, 0-9, and "-" allowed'}
          choices={categories}
          onCreate={(val) => {
            const category = prompt("Enter a new category", sluggify(val));
            const newCategory = {
              id: categories.length + 1,
              name: sluggify(category),
            };
            notify("Select the new category!");
            categories.push(newCategory);
            return newCategory;
          }}
          fullWidth
        />
        <TextInput source="name" fullWidth isRequired />
      </SimpleForm>
    </Create>
  );
};

export const TagShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <NumberField source="platform_id" />
      <TextField source="category" />
      <RichTextField source="name" />
    </SimpleShowLayout>
  </Show>
);
