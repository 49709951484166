import { Box, ClickAwayListener, Popover, useTheme } from "@mui/material";
import { forwardRef, useRef, useState } from "react";
import { TextInput } from "react-admin";
import { ChromePicker } from "react-color";
import { useFormContext } from "react-hook-form";

export const ColorInput = (props) => {
  return (
    <>
      <TextInput
        InputProps={{
          components: { Input: ColorPicker },
          sx: { overflow: "hidden" },
        }}
        {...props}
      />
    </>
  );
};

const ColorPicker = forwardRef((props, forwardedRef) => {
  const theme = useTheme();
  const { setValue, watch } = useFormContext();
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const assigned_color = watch(props.name);
  const [color, setColor] = useState(assigned_color);

  return (
    <>
      <Box
        ref={ref}
        onClick={() => setShow(true)}
        sx={{
          height: 40,
          backgroundColor: assigned_color,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          color: theme.palette.getContrastText(assigned_color || "#fff"),
        }}
      >
        {assigned_color}
      </Box>
      <Popover
        open={show}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <ClickAwayListener onClickAway={() => setShow(false)}>
          <Box border="1px solid black">
            <ChromePicker
              color={color}
              disableAlpha
              onChange={({ hex }) => setColor(hex)}
              onChangeComplete={({ hex }) =>
                setValue(props.name, hex, {
                  shouldDirty: true,
                  shouldTouch: true,
                })
              }
            />
          </Box>
        </ClickAwayListener>
      </Popover>
    </>
  );
});
