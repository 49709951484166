import dayjs from "dayjs";
import weekYear from "dayjs/plugin/weekYear";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isLeapYear from "dayjs/plugin/isLeapYear";

dayjs.extend(weekYear);
dayjs.extend(weekOfYear);
dayjs.extend(isLeapYear);

export const aggregateWeekYear = (date: string) => {
  const year = parseInt(date.substring(0, 4));
  const isLeapYear = dayjs(date).isLeapYear();
  const week = dayjs(date).week();
  const prependZero = week < 10 ? "0" : "";
  const fixLeapYear = week === 53 && !isLeapYear ? year - 1 : year;
  const fixWeekNum = week === 53 ? 52 : week;
  return `${fixLeapYear}-${prependZero}${fixWeekNum}`;
};


export const unitJump = (period: string) => {
  switch (period) {
    case "month":
      return 2;
    case "week":
      return 4;
    default:
      return 30;
  }
};