import { Stack, Typography } from "@mui/material";
import {
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  useGetIdentity,
} from "react-admin";
import { StyledToolBar } from "~/components";
import { ItinLoading } from "~/components/ItinLoading";
import ItinMarkdownInput from "~/components/ItinMarkdownInput";
import { RegionInput } from "~/components/inputs/RegionInput";
import { useCurrentPlatform } from "~/context";

export const ItineraryCreate = () => {
  const { identity, isLoading } = useGetIdentity();
  const { loading, currentPlatform, getTerm } = useCurrentPlatform();
  const Itinerary = getTerm("itinerary", { capitalize: true });
  const itinerary = getTerm("itinerary");
  const user = getTerm("user");
  if (isLoading || loading) return <ItinLoading />;
  return (
    <Create>
      <SimpleForm
        variant="standard"
        redirect="edit"
        defaultValues={{
          user_id: identity.id,
          platform_id: currentPlatform.id,
        }}
        toolbar={<StyledToolBar />}
      >
        <Typography variant="h4" pb="16px">
          {`New ${Itinerary} `}
        </Typography>
        <Stack direction="row" gap={3} width="98%">
          <TextInput source="name" />
          <RegionInput />
        </Stack>
        <BooleanInput
          label={`Make this a featured ${itinerary}?`}
          source="featured"
        />
        <ItinMarkdownInput
          title="Description"
          description={`A full description you would like associated with your ${itinerary}. This will appear on expanded views of your ${itinerary} for the ${user}.`}
          height="200px"
          placeholder="Compose an epic.."
          source="description"
          isRequired
        />
      </SimpleForm>
    </Create>
  );
};
