import { Box } from "@mui/material";
import { Edit, TabbedForm, TextInput, email } from "react-admin";
import { SocialsList, Stats, StyledToolBar } from "~/components";
import HasPlatformExtension from "~/components/HasPlatformExtension";
import { ImageTabs } from "~/components/images";
import { AdminUserInput, RecordExtensionInputs } from "~/components/inputs";
import ItinRichTextArea from "~/components/inputs/ItinRichTextArea";
import { TourismoDateRangePicker } from "~/components/TourismoDateRangePicker";
import { useCurrentPlatform } from "~/context";
import { ReportFilterProvider } from "~/context/ReportFilterContext";
import { validateEditorRedirect } from "~/helpers/validateEditorRedirect";
import { validateUrl } from "~/hooks";
import { ContentInlineEditList } from "~/resources/contents";
import { OperatingHoursList } from "~/resources/operating_hours";
import { RecordCommunicationList } from "../communications/CommunicationList";
import { LogList } from "../logs/LogList";
import { UrlsList } from "../urls/UrlsList";
import { BasicFields } from "./BasicFields";
import { ListingFoundContent } from "./ListingFoundContent";
import { ListingOverview } from "./ListingOverView/ListingOverview";

export const ListingEdit = () => {
  return (
    <Edit mutationMode="pessimistic" title=" " redirect={false} actions={false}>
      <ListingForm />
    </Edit>
  );
};

export const ListingForm = () => {
  const { getTerm } = useCurrentPlatform();
  const listingTerm = getTerm("listing");
  const listingsTerm = getTerm("listing", { plural: true });
  const userTerm = getTerm("user");
  validateEditorRedirect();
  return (
    <TabbedForm
      variant="standard"
      mode="onChange"
      toolbar={<StyledToolBar saveButtonProps={{ alwaysEnable: true }} />}
    >
      <TabbedForm.Tab label="Overview">
        <ListingOverview />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="Location & Map">
        <BasicFields show />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="content">
        <Box
          display="grid"
          sx={{ width: "100%" }}
          gridTemplateColumns="repeat(12,1fr)"
          gap={2}
        >
          <Box gridColumn={"span 12"}>
            <TextInput
              title="Subtitle"
              placeholder="Keep it punchy.."
              source="subtitle"
              isRequired={false}
              fullWidth
              helperText={`A short tagline that goes beneath the name of the ${listingTerm}. Example: 'More than you imagined!'`}
            />
          </Box>
          <Box gridColumn="span 6">
            <ItinRichTextArea
              label="Short Description"
              helperText={`A summary of the full description. Displayed where multiple ${listingsTerm} are shown. Never shown with the full description`}
              height="300px"
              placeholder="Compose an epic.."
              source="short_description"
              isRequired={false}
            />
          </Box>
          <Box gridColumn="span 6">
            <ItinRichTextArea
              label="Full Description"
              helperText={`A full description you would like associated with your ${listingTerm}. This will appear on the ${listingTerm} page for the ${userTerm}.`}
              height="300px"
              placeholder="Compose an epic.."
              source="description"
              isRequired={false}
            />
          </Box>
        </Box>
        <ContentInlineEditList />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="Contact & Links">
        <Box
          display="grid"
          sx={{ width: "100%" }}
          gridTemplateColumns="repeat(12,1fr)"
          gap={2}
        >
          <Box gridColumn={"span 6"}>
            <AdminUserInput fullWidth />
            <TextInput source="email" validate={email()} fullWidth />
            <TextInput source="phone_number" fullWidth />
          </Box>
          <Box gridColumn={"span 6"}>
            <TextInput source="website" fullWidth validate={validateUrl} />
            <TextInput
              source="website2"
              label="Booking Website"
              fullWidth
              validate={validateUrl}
            />
          </Box>
          <Box
            gridColumn={"span 12"}
            style={{ paddingLeft: "40px", paddingRight: "40px" }}
          >
            <SocialsList />
            <UrlsList />
          </Box>
        </Box>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="hours">
        <OperatingHoursList />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="images">
        <ImageTabs />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="Magic Content">
        <HasPlatformExtension
          platform_extension="AiExtension"
          fallback={"Upgrade to gain AI superpowers!"}
        >
          <ListingFoundContent />
        </HasPlatformExtension>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="Reports">
        <ReportFilterProvider>
          <TourismoDateRangePicker />
          <Stats category="report" />
        </ReportFilterProvider>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="Communications">
        <RecordExtensionInputs
          extension_name="AppExtension"
          section_source="automated_notifications"
          activate_extension_source="allow_automated_notifications"
        />
        <RecordCommunicationList />
      </TabbedForm.Tab>

      <TabbedForm.Tab label="Activity Log">
        <LogList />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="Contesting">
        <RecordExtensionInputs
          extension_name="ContestingExtension"
          activate_extension_source="redeemable_location"
        />
      </TabbedForm.Tab>
    </TabbedForm>
  );
};
