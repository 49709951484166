import {
  BlockFromConfigNoChildren,
  InlineContentSchema,
  StyleSchema,
} from "@blocknote/core";
import { useBlockNoteEditor } from "@blocknote/react";
import { Flex, Modal } from "@mantine/core";
import { get } from "lodash";
import { useRecordContext } from "react-admin";
import { block_note_schema } from "../BlockInput";
import { IMAGE_CONFIG } from "./config";
import { Dropzone } from "./Dropzone";
import { Media } from "./types";

export const ImageSelectModal = <
  T extends BlockFromConfigNoChildren<
    typeof IMAGE_CONFIG,
    InlineContentSchema,
    StyleSchema
  >
>({
  block,
  opened,
  onClose,
}: {
  block: T;
  opened: boolean;
  onClose: () => void;
}) => {
  const editor = useBlockNoteEditor(block_note_schema);
  const record = useRecordContext<{ media: Media[] }>();

  const media = get(record, "media", []);
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Select Image"
      centered
      size={"xl"}
    >
      <Flex gap={15} wrap="wrap" justify={"space-around"}>
        {media.map((m) => (
          <img
            onClick={() =>
              editor.updateBlock(block, {
                type: "image",
                props: {
                  url: m.url,
                },
              })
            }
            width="150px"
            style={{ objectFit: "contain" }}
            src={m.url}
          />
        ))}
      </Flex>
      <Dropzone block={block} />
    </Modal>
  );
};
