import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  FunctionField,
  TextField,
} from "react-admin";
import { ItinLoading } from "~/components/ItinLoading";
import { ToggleList } from "~/components/lists";
import { useTagCategories } from "~/hooks";
import { DynamicIconRenderer, loadTagIcon } from "./tag_icons";
const filters = (categories) => [
  <AutocompleteInput
    label="By Category"
    source="category"
    optionText="name"
    optionValue="name"
    choices={categories}
    emptyText="All Categories"
  />,
];

export const TagList = () => {
  const { data: categories, loading: categoriesLoading } = useTagCategories();
  if (categoriesLoading) return <ItinLoading />;
  return <ToggleList filters={filters(categories)} modes={MODES} />;
};

const TagData = () => (
  <Datagrid rowClick="edit" bulkActionButtons={false}>
    <TextField source="category" />
    <TextField source="name" />
    <BooleanField source="show" />
    <FunctionField
      source="icon"
      render={(record) => <DynamicIconRenderer iconName={record.icon} />}
    />
  </Datagrid>
);

const MODES = [{ name: "List", view: <TagData /> }];
