import { useDataProvider } from "react-admin";
import { useMutation } from "react-query";

export type CustomMutationVariables = {
  resource: string;
  id?: string | number;
  custom_url?: string;
  data?: any;
  params?: any;
};
export const useCustomMutation = () => {
  const dataProvider = useDataProvider();
  return useMutation<any, Error, CustomMutationVariables>({
    mutationFn: ({ resource, id, custom_url, data, params }) => {
      const url = [resource, id, custom_url].filter(Boolean).join("/");
      return dataProvider.putCustom(url, params, data);
    },
  });
};
