import clsx from "clsx";
import { Chip } from "@mui/material";
import { get, startCase } from "lodash";
import { sanitizeFieldRestProps, useRecordContext } from "react-admin";
import { forwardRef } from "react";

export const CustomColorChipField = forwardRef((props, ref) => {
  const {
    className,
    source,
    emptyText,
    color_options = {},
    value: customValue,
    ...rest
  } = props;
  const record = useRecordContext(props);
  const value = customValue || get(record, source) || emptyText;
  const color = color_options[value] || color_options.fallback;
  if (!value) return null;
  return (
    <Chip
      ref={ref}
      color={color}
      className={clsx(className)}
      label={startCase(value)}
      {...sanitizeFieldRestProps(rest)}
    />
  );
});
