import { get } from "lodash";
import {
  ListContextProvider,
  ResourceContextProvider,
  useGetManyReference,
  useRecordContext,
} from "react-admin";
import { Typography } from "@mui/material";

const UsingRegexp = /^([A-Za-z0-9_-]+),([A-Za-z0-9_-]+)$/;

export const useReferenceManyThroughManyFieldController = (props) => {
  const {
    filter,
    reference,
    sort,
    source = "id",
    through,
    using,
    perPage = 25,
    unique = true,
  } = props;
  const record = useRecordContext();
  const [, sourceField, targetField] = using.match(UsingRegexp);
  const page = 1;

  const throughManyReferences = useGetManyReference(through, {
    target: sourceField,
    id: get(record, source),
    pagination: { page, perPage },
    sort,
    filter,
  });
  const allReferencesIds = throughManyReferences.data
    ? throughManyReferences.data
        .map((record) => {
          return record.id;
        })
        .filter((id) => !!id)
    : [];

  const references = useGetManyReference(reference, {
    target: targetField,
    id: allReferencesIds,
    // pagination: { page, perPage },
    sort,
    filter,
  });
  return {
    resource: reference,
    data: references.data,
    sort,
    error: throughManyReferences.error || references.error,
    isLoading: throughManyReferences.isLoading || references.isLoading,
    isFetching: throughManyReferences.isFetching || references.isFetching,
    total: references.total,
    hasNextPage:
      throughManyReferences.total !== null
        ? page * perPage < throughManyReferences.total
        : undefined,
    hasPreviousPage: page > 1,
    page,
    perPage,
    refetch: () => {
      throughManyReferences.refetch();
      references.refetch();
    },
  };
};

export const ReferenceManyThroughMany = ({
  reference,
  through,
  using,
  children,
  perPage,
  sort = { field: "id", order: "DESC" },
  sortBy,
  source = "id",
  ...props
}) => {
  const { error, ...referenceManyThroughManyProps } =
    useReferenceManyThroughManyFieldController({
      filter: props.filter,
      perPage,
      record: props.record,
      resource: props.resource,
      reference,
      through,
      source,
      sort,
      using,
    });

  if (error) {
    return <Typography color="error">{error.message}</Typography>;
  }
  return (
    <ResourceContextProvider value={referenceManyThroughManyProps.resource}>
      <ListContextProvider
        value={{
          // Define default mandatory ListContextProps
          // These are not provided by useReferenceManyToManyFieldController
          displayedFilters: {},
          filterValues: {},
          onSelect: null,
          onToggleItem: null,
          onUnselectItems: null,
          hideFilter: null,
          selectedIds: [],
          setFilters: null,
          setPage: null,
          setPerPage: null,
          setSort: null,
          showFilter: null,
          ...referenceManyThroughManyProps,
        }}
      >
        {children}
      </ListContextProvider>
    </ResourceContextProvider>
  );
};
