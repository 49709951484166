import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const formatLabels = (labels: string[], period: string) => {
  let formattedLabels = labels;
  switch (period) {
    case "month":
      formattedLabels = labels.map((l) =>
        dayjs(l).utcOffset(8).format("MMM YYYY")
      );
      break;
    // DONT NEED CASE == WEEKLY
    default:
      formattedLabels = labels.map((l) =>
        dayjs(l).utcOffset(8).format("MMM DD, YYYY")
      );
  }

  return formattedLabels;
};
