import { Edit } from "react-admin";
import { FormForm } from "./FormForm";

export const FormEdit = () => {
  return (
    <Edit mutationMode="pessimistic" redirect={false}>
      <FormForm />
    </Edit>
  );
};
