import {
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const Accordion = ({
  id,
  title,
  children,
  onChange,
  current_id,
  ...props
}) => {
  const handleChange = () => {
    if (current_id === id) {
      onChange && onChange();
    } else {
      onChange && onChange(id);
    }
  };
  return (
    <MuiAccordion
      expanded={current_id === id}
      onChange={handleChange}
      defaultExpanded
      disableGutters
      sx={{
        width: 1,
        "&:before": {
          display: "none",
        },
      }}
      {...props}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          "&:hover": { backgroundColor: "blue.50", borderRadius: "10px" },
        }}
      >
        <Typography variant="h4">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
};
