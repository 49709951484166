import { Block } from "@blocknote/core";
import {
  useBlockNoteEditor,
  useComponentsContext,
  useSelectedBlocks,
} from "@blocknote/react";
import { RiExpandWidthFill } from "react-icons/ri";
import { block_note_schema, BlockSchema } from "../BlockInput";

export const FullWidthButton = () => {
  const editor = useBlockNoteEditor(block_note_schema);
  const selectedBlocks = useSelectedBlocks(editor);
  const Components = useComponentsContext()!;

  const block = selectedBlocks.length === 1 ? selectedBlocks[0] : undefined;

  if (block === undefined || !editor.isEditable || !hasFullWidth(block)) {
    return null;
  }
  return (
    <Components.FormattingToolbar.Button
      icon={<RiExpandWidthFill />}
      label={"Full Width"}
      mainTooltip="Full Width"
      isSelected={block.props.fullWidth}
      onClick={() =>
        editor.updateBlock(block, {
          props: { fullWidth: !block.props.fullWidth },
        })
      }
    />
  );
};

type FullWidthBlock<B = Block<BlockSchema>> = B extends {
  props: { fullWidth?: boolean };
}
  ? B
  : never;

const hasFullWidth = (
  block: Block<BlockSchema>
): block is FullWidthBlock & {
  props: {
    fullWidth?: boolean;
  };
} => {
  return "fullWidth" in block.props;
};
