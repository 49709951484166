import {
  AutocompleteInput,
  BooleanInput,
  Labeled,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  required,
  SimpleForm,
  useRecordContext,
} from "react-admin";
import { SingleImageInput } from "~/components";
import { EnumChoiceInput } from "~/components/inputs";

export const PlatformUserForm = ({ user }) => {
  return (
    <SimpleForm defaultValues={{ user_id: user.id }}>
      <PlatformField user={user} />
      <EnumChoiceInput source="role" defaultValue={"user"} />
      <NumberInput label="Points" source="set_points" defaultValue={0} />{" "}
      <NumberInput
        label="Points Redeemed"
        source="set_points_redeemed"
        defaultValue={0}
      />
      <BooleanInput source="allow_emails" />
      <BooleanInput source="allow_push" />
      <BooleanInput source="allow_sms" />
      <SingleImageInput source="avatar" show_caption={false} />
    </SimpleForm>
  );
};

const PlatformField = ({ user }) => {
  const record = useRecordContext();
  if (!record?.id)
    return (
      <ReferenceInput
        reference="platforms"
        source="platform_id"
        filter={{ id_neq: user.platform_ids }}
      >
        <AutocompleteInput
          validate={required()}
          sx={{ minWidth: 160, width: "50%" }}
        />
      </ReferenceInput>
    );
  return (
    <Labeled label="Platform">
      <ReferenceField reference="platforms" source="platform_id" link={false} />
    </Labeled>
  );
};
