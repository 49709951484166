export const InstagramIframe = ({ url: original_url }: { url: string }) => {
  const url = new URL(original_url);
  url.pathname = url.pathname.replace(/\/?$/, "/embed/");
  return (
    <iframe
      src={url.toString()}
      height={"613px"}
      scrolling="no"
      style={{
        backgroundColor: "white",
        borderRadius: "3px",
        border: "1px solid rgb(219, 219, 219)",
        display: "inline-block",
        minWidth: "326px",
      }}
      allowTransparency
      allowFullScreen
    />
  );
};
