import { useState } from "react";
import {
  ImageInput,
  useRecordContext,
  useInput,
  SimpleFormIterator,
} from "react-admin";
import { ReferenceManyInput } from "@react-admin/ra-relationships";
import Image from "mui-image";
import { MinusCircle, UploadSimple } from "@phosphor-icons/react";
import { Box, Typography } from "@mui/material";
import { useTargetType } from "~/hooks";
import { useTheme } from "@mui/styles";

export const SimpleImageInput = (props) => {
  const theme = useTheme();
  const record = useRecordContext();
  const image = record?.avatar || record?.hero;
  const record_type = useTargetType();
  const [hasImage, setHasImage] = useState(image ?? false);
  return (
    <>
      {!hasImage && (
        <ImageInput
          source={props.source}
          multiple={false}
          variant="standalone"
          placeholder={
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                borderWidth: 1,
                borderColor: "black",
                borderStyle: "dashed",
                borderRadius: "25px",
                height: "350px",
              }}
            >
              <UploadSimple size={33} color={theme.palette.grey[800]} />
              <Typography
                width="100%"
                variant="h5"
                color={theme.palette.grey[800]}
                mt={2}
              >
                Drop a picture to upload, or click to select one
              </Typography>
            </Box>
          }
        />
      )}
      <Box display={hasImage ? "block" : "none"} style={{ marginTop: "16px" }}>
        <ReferenceManyInput
          reference="images"
          target="record_id"
          label={false}
          filter={{ record_type, name: props.source }}
        >
          <SimpleFormIterator
            variant="hero"
            removeButton={
              <MinusCircle
                {...props}
                size={40}
                onClick={(e) => {
                  setHasImage(false);
                  if (props.onClick) props.onClick(e);
                }}
              />
            }
            fullWidth
            disableAdd
            sx={{
              "& .RaSimpleFormIterator-list": {
                display: "block",
                flexDirection: null,
                overflow: "auto",
              },
            }}
          >
            <ImageField source="url" theme={theme} setHasImage={setHasImage} />
          </SimpleFormIterator>
        </ReferenceManyInput>
      </Box>
    </>
  );
};

const ImageField = ({ setHasImage, theme, ...props }) => {
  const {
    field: { value },
  } = useInput(props);
  setHasImage(true);
  return (
    <Image
      src={value}
      fit="contain"
      width="100%"
      showLoading
      style={{
        borderRadius: 12,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.secondary.dark,
      }}
    />
  );
};
