import {
  TextInput,
  useNotify,
  useDataProvider,
  useGetIdentity,
  Button,
  EditBase,
  Form,
  SaveButton,
  required,
} from "react-admin";
import AvatarField from "~/components/AvatarField";
import { useProfileActions } from "~/context/ProfileContext";
import { ArrowBack } from "@mui/icons-material";
import { Box, Divider, Stack } from "@mui/material";
import { Check } from "@phosphor-icons/react";

// A different form and actions that are used for editing the current user's profile.
export const ProfileUserEdit = () => {
  const { identity } = useGetIdentity();
  const { toggleDrawer, refreshProfile } = useProfileActions();
  const notify = useNotify();
  const { updateUserProfile } = useDataProvider();
  const onSuccess = () => {
    updateUserProfile().then(() => {
      notify("Changes saved", { type: "success" });
      refreshProfile();
      toggleDrawer();
    });
  };
  if (!identity) return null;
  return (
    <EditBase
      resource="users"
      id={identity.id}
      mutationOptions={{ onSuccess }}
      mutationMode="pessimistic"
    >
      <Form>
        <Box sx={{ px: 4, py: 2 }}>
          <Button
            variant="outlined"
            label="Go Back"
            startIcon={<ArrowBack />}
            onClick={toggleDrawer}
          />
        </Box>
        <Divider />
        <Stack alignItems="stretch" sx={{ px: 4, py: 2, alignSelf: "stretch" }}>
          <AvatarField source="avatar" label={false} size={80} sx={{ mb: 2 }} />
          <TextInput source="name" fullWidth validate={required()} />
          <TextInput source="email" fullWidth validate={required()} />
          <SaveButton endIcon={<Check />} icon={null} />
        </Stack>
      </Form>
    </EditBase>
  );
};
