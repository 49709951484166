import React, { createContext, useState, useContext } from "react";

// Create the context
const ReportFilterContext = createContext({
  startDate: null,
  setStartDate: null,
  endDate: null,
  setEndDate: null,
});

// Create a provider component
export const ReportFilterProvider = ({ children }) => {
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  const [startDate, setStartDate] = useState(thirtyDaysAgo);
  const [endDate, setEndDate] = useState(new Date());

  return (
    <ReportFilterContext.Provider
      value={{ startDate, setStartDate, endDate, setEndDate }}
    >
      {children}
    </ReportFilterContext.Provider>
  );
};

// Custom hook to use the ReportFilterContext
export const useReportFilter = () => {
  return useContext(ReportFilterContext);
};
