import { isEqual } from "lodash";
import { useDelete, useRecordContext, useResourceContext } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { useQueryClient } from "react-query";

export const useDeleteImage = (source) => {
  const [deleteOne] = useDelete();
  const { setValue } = useFormContext();
  const record = useRecordContext();
  const value = useWatch({ name: source });
  const resource = useResourceContext();
  const multiple = Array.isArray(value);
  const queryClient = useQueryClient();

  const deleteImage = (file) => {
    deleteOne(
      "images",
      {
        id: file.id,
      },
      {
        onSuccess: () => {
          if (multiple) {
            const filteredFiles = value.filter(
              (stateFile) => !isEqual(stateFile, file)
            );
            setValue(source, filteredFiles);
          } else {
            setValue(source, null);
          }
          if (record?.id) {
            queryClient.invalidateQueries([
              resource,
              "getOne",
              { id: record.id.toString() },
            ]);
          }
        },
      }
    );
  };
  return deleteImage;
};
