import { geojsonToWKT } from "@terraformer/wkt";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { Point } from "terraformer";
import { useCustomQuery } from "~/hooks";

const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

type PlaceResult = google.maps.places.PlaceResult;

const formatTime = (time?: google.maps.places.PlaceOpeningHoursTime) => {
  const intToString = (int: number) =>
    int.toLocaleString(undefined, { minimumIntegerDigits: 2 });
  if (!time) return;
  return [intToString(time.hours), intToString(time.minutes)].join(":");
};

export const useAutoCompletePlace = () => {
  const { setValue, watch, getValues } = useFormContext();
  const lonlat = watch("lonlat");
  useCustomQuery(
    "regions/closest",
    { lonlat },
    {
      options: {
        enabled: !!lonlat,
        onSuccess: (data: any) => {
          if (data?.id && !getValues("region_id"))
            setValue("region_id", data?.id);
        },
      },
    }
  );

  const setListingData = useCallback(
    (place: PlaceResult) => {
      const getAddressValue = (key: string) => {
        const value = place.address_components?.find((component) =>
          component.types.includes(key)
        );
        return value?.short_name ?? "";
      };

      const values = {
        city: getAddressValue("locality"),
        postal_code: getAddressValue("postal_code"),
        street_address:
          getAddressValue("street_number") + " " + getAddressValue("route"),
        province: getAddressValue("administrative_area_level_1"),
        country: getAddressValue("country"),
        location_name: place.name,
        website: place.website,
      };
      setValue("location_name", values.location_name, { shouldDirty: true });
      setValue("website", values.website);
      setValue("postal_code", values.postal_code);
      setValue("country", values.country);
      setValue("city", values.city);
      setValue("province", values.province);
      setValue("street_address", values.street_address);

      const { lng, lat } = place.geometry?.location ?? {};

      if (lat)
        setValue("latitude", Number(lat()), {
          shouldValidate: true,
          shouldTouch: true,
          shouldDirty: true,
        });
      if (lng)
        setValue("longitude", Number(lng()), {
          shouldValidate: true,
          shouldTouch: true,
          shouldDirty: true,
        });

      if (lng && lat) {
        const point = new Point([lng(), lat()]);
        const lonlat = geojsonToWKT(point);
        setValue("lonlat", lonlat);
      }
    },
    [setValue]
  );
  const setHours = useCallback(
    (place: PlaceResult) => {
      if (place.opening_hours?.periods) {
        const operating_hours = place.opening_hours.periods.map(
          (opening_hour) => {
            return {
              day_of_week: DAYS[opening_hour.open.day],
              open_time: formatTime(opening_hour.open),
              close_time: formatTime(opening_hour.close),
              subtitle: "",
              description: "",
            };
          }
        );
        setValue("operating_hours_attributes", operating_hours);
      }
    },
    [setValue]
  );
  return { setHours, setListingData };
};
