import { ListBullets } from "@phosphor-icons/react";
import { ItinResourceProps } from "../types";
import { ListingCreate } from "./ListingCreate";
import { ListingEdit } from "./ListingEdit";
import { ListingList } from "./ListingList";
import { ListingShow } from "./ListingShow";

export const ListingResource: ItinResourceProps = {
  name: "listings",
  list: ListingList,
  edit: ListingEdit,
  show: ListingShow,
  create: ListingCreate,
  icon: <ListBullets />,
  recordRepresentation: (r) => r.location_name,
  options: { menu_group: "places" },
};  

