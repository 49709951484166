import { ReferenceManyField, TextField, useRecordContext } from "react-admin";
import { DrawerDatagrid } from "~/components/drawer";
import { useTargetType } from "~/hooks";
import { OperatingHoursForm } from "./OperatingHoursForm";
import { TimeField } from "~/components/fields";

export const OperatingHoursList = () => {
  const target = useRecordContext();
  const target_type = useTargetType();

  return (
    <ReferenceManyField
      label="Operating Hours"
      reference="operating_hours"
      target="target_id"
      filter={{ target_type }}
    >
      <DrawerDatagrid
        buttonLabel={"hours"}
        bulkActionButtons
        Form={<OperatingHoursForm target={target} target_type={target_type} />}
      >
        <TextField source="day_of_week" label="Week Day" />
        <TimeField source="open_time" label="Open" />
        <TimeField source="close_time" label="Close" />
        <TextField source="subtitle" />
        <TextField source="description" />
      </DrawerDatagrid>
    </ReferenceManyField>
  );
};
