import { Box, Button, Collapse, Grid, Stack, Typography } from "@mui/material";
import { chain, defaults, get, isEmpty, startCase } from "lodash";
import { ArrowLeft, CheckCircle, MagicWand } from "@phosphor-icons/react";
import { useMemo, useState } from "react";
import { useGetManyReference, useRecordContext } from "react-admin";
import ConfettiExplosion from "react-confetti-explosion";
import { useFormContext } from "react-hook-form";
import { CreateIngestionButton, Sparkles, SummaryCards } from "~/components";
import { timeAgo, timeAgoMinutes } from "~/helpers/timeAgo";
import { Logo } from "~/images/Logo";
import {
  IngestionImageSelect,
  IngestionProvider,
  IngestionStatusMessage,
  useIngestion,
} from "../ingestions";

export const FoundContentHeader = ({ ingestion, record, setId }) => {
  const { status } = useIngestion();
  const inProgress = status && status.progress > 0 && status.progress !== 100;
  return (
    <>
      {!ingestion && <IngestionCallToAction record={record} setId={setId} />}
      <Grid item xs={4}>
        {inProgress && <IngestionStatusMessage />}
        {ingestion && !inProgress && (
          <>
            <CreateIngestionButton
              website={record.website}
              onSuccess={(ingestion) => setId(ingestion.id)}
              fields="website"
              disabled={timeAgoMinutes(ingestion.updated_at) < 5}
              label={
                timeAgoMinutes(ingestion.updated_at) < 5
                  ? "Please wait a few minutes to try again"
                  : "Refresh the Magic"
              }
            />
            <Typography>
              Last Updated {timeAgo(ingestion.updated_at)} Ago
            </Typography>
          </>
        )}
      </Grid>
      <Grid container item xs={8} spacing={2}>
        <IngestionCards />
      </Grid>
      <Grid item xs={6}>
        {!record.website && !ingestion && (
          <Typography variant="h5">
            Enter a website to get some data!
          </Typography>
        )}
      </Grid>
    </>
  );
};

export const ListingFoundContent = () => {
  const record = useRecordContext();

  return (
    <IngestionProvider
      ingestion_id={
        record.root_ingestion_id ? record.root_ingestion_id : record.website
      }
    >
      {({ ingestion, setId, status }) => {
        return (
          <Grid container columnSpacing={1} rowSpacing={2}>
            <FoundContentHeader
              ingestion={ingestion}
              record={record}
              setId={setId}
            />
            <Grid item xs={12}>
              <Collapse
                component={Grid}
                in={status && status.progress === 100}
                item
                xs={12}
                sx={{ position: "relative" }}
              >
                <AttributesSummary data={get(ingestion, "json_data")} />
              </Collapse>
            </Grid>
            <IngestionImageSelect
              images={chain(ingestion).get("all_images").uniqBy("url").value()}
            />
          </Grid>
        );
      }}
    </IngestionProvider>
  );
};
const IngestionCards = () => {
  const { ingestion } = useIngestion();
  const page_count = get(ingestion, "page_count", 0);
  const content_count = get(ingestion, "content_count", 0);
  const links_count = get(ingestion, "links_count", 0);
  const images_count = get(ingestion, "all_images", 0).length;

  const cards = useMemo(
    () => [
      { name: "Pages", value: page_count },
      { name: "Characters", value: content_count },
      { name: "Images", value: images_count },
      { name: "Links", value: links_count },
    ],
    [content_count, images_count, page_count, links_count]
  );
  if (!ingestion) return null;
  return (
    <SummaryCards item cards={cards} sx={{ justifyContent: "flex-end" }} />
  );
};

const AttributesSummary = ({ data }) => {
  const { id } = useRecordContext();
  const { getValues, setValue, watch } = useFormContext();
  const { data: current_socials } = useGetManyReference(
    "socials",
    {
      target: "target_id",
      id,
      pagination: { page: 1, perPage: null },
      filter: { target_type: "Listing" },
    },
    { enabled: !isEmpty(data) }
  );

  if (isEmpty(data)) return null;

  const { socials = {}, ...rest } = defaults(data, {});

  const findSocial = (socials, key) =>
    socials?.find((s) => s.key.toLowerCase() === key.toLowerCase());

  const createSocial = (key, value, id) => {
    const current = getValues("socials_attributes");
    setValue(
      `socials_attributes`,
      [
        ...(isEmpty(current) ? [] : current),
        {
          id,
          key,
          value,
        },
      ],
      { shouldDirty: true, shouldTouch: true }
    );
  };

  const mapAttributes = (attributes, setParams) =>
    Object.entries(attributes)
      .filter(([attribute, value]) => !!value)
      .map(([attribute, value]) => {
        const parsed_value =
          typeof value === "string" ? value : JSON.stringify(value);
        return {
          attribute: startCase(attribute),
          value: parsed_value,
          ...setParams(attribute, parsed_value),
        };
      });

  const all_attributes = [
    ...mapAttributes(rest, (attribute, value) => ({
      used: watch(attribute) === value,
      current: watch(attribute),
      onClick: () =>
        setValue(attribute, value, { shouldDirty: true, shouldTouch: true }),
    })),
    ...mapAttributes(socials, (attribute, value) => {
      const existing_social = findSocial(current_socials, attribute);
      const created_social = findSocial(watch("socials_attributes"), attribute);
      const used =
        existing_social?.value === value || created_social?.value === value;
      return {
        used,
        current: created_social?.value || existing_social?.value,
        onClick: () => createSocial(attribute, value, existing_social?.id),
      };
    }),
  ];

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <Typography variant="h5">Listing Content</Typography>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <Typography variant="h5">AI Suggestion</Typography>
        </Grid>
        {all_attributes.map((row, i) => {
          return (
            <AiRecommendationRow
              key={i}
              row={row}
              label="AI Suggests (click to use)"
            />
          );
        })}
      </Grid>
    </Box>
  );
};

const AiRecommendationRow = ({ row }) => {
  const [isExploding, explode] = useState(false);
  return (
    <>
      <Grid item xs={1}>
        <Typography variant="h6"> {row.attribute}</Typography>
      </Grid>
      <Grid item xs={5}>
        {isExploding && (
          <ConfettiExplosion
            colors={["#F7A7EA", "#F7DF96", "#B3B3FD"]}
            zIndex={10}
            duration={2000}
            particleCount={80}
            width={1200}
            style={{ position: "absolute", left: "25%" }}
            onComplete={() => explode(false)}
          />
        )}
        {row.current}
      </Grid>
      <Grid item xs={1}>
        {row.used ? (
          <Button
            startIcon={<CheckCircle size={32} color={"#55aa55"} />}
            size={"small"}
            disabled={true}
          />
        ) : (
          <Button
            startIcon={<ArrowLeft size={18} />}
            endIcon={<MagicWand size={18} />}
            size={"small"}
            variant={"contained"}
            onClick={() => {
              explode(true);
              row.onClick();
            }}
          ></Button>
        )}
      </Grid>
      <Grid item xs={5}>
        <Sparkles show={!row.used} rate={500}>
          <Typography
            sx={{
              color: row.used ? "grey.300" : "primary",
            }}
            onClick={() => {
              explode(true);
              row.onClick();
            }}
          >
            {row.value}
          </Typography>
        </Sparkles>
      </Grid>
    </>
  );
};

const IngestionCallToAction = ({ record, setId }) => {
  return (
    <Stack
      width="100%"
      alignItems="center"
      justifyContent="space-around"
      sx={{ minHeight: "60vh" }}
    >
      <Typography variant="h3">
        Let's make some magic together
        <Logo sx={{ ml: 1, height: 32, display: "inline-block" }} />
      </Typography>
      <CreateIngestionButton
        website={record.website}
        onSuccess={(ingestion) => setId(ingestion.id)}
        fields="website"
        label={"Sprinkle Magic Fairy Dust"}
      />
    </Stack>
  );
};
