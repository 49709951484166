import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Box, Slider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateView } from "@mui/x-date-pickers";
import dayjs, { Dayjs, ManipulateType, OpUnitType } from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

type VerticalSliderProps = {
  period: string;
  earliestDate: Dayjs;
  latestDate: Dayjs;
  startDate: Dayjs;
  setStartDate: Dispatch<SetStateAction<Dayjs>>;
  endDate: Dayjs;
  setEndDate: Dispatch<SetStateAction<Dayjs>>;
  height: number;
  rowsPerPage: number;
};

export const VerticalSlider = ({
  period,
  earliestDate,
  latestDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  height,
  rowsPerPage,
}: VerticalSliderProps) => {
  const [pickerHeight, setPickerHeight] = useState<number>(0);
  const datePickerRef = useRef<HTMLDivElement>(null);

  let rangeLength: number;
  if (period === "month") {
    rangeLength = latestDate.diff(
      earliestDate.startOf("month"),
      period as OpUnitType
    );
  } else {
    rangeLength = latestDate.diff(earliestDate, period as OpUnitType);
  }

  const periodLookBack = () => {
    switch (period) {
      case "month":
        return rangeLength - 1;
      case "week":
        return 4;
      default:
        return 30;
    }
  };

  const [range, setRange] = useState<number[]>(
    [rangeLength - periodLookBack(), rangeLength].map((x) => -x).reverse()
  );

  useEffect(() => {
    const lookBack = periodLookBack();
    setRange([rangeLength - lookBack, rangeLength].map((x) => -x).reverse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, rangeLength]);

  useEffect(() => {
    datePickerRef.current &&
      setPickerHeight(datePickerRef.current.offsetHeight);
  }, [period, rangeLength, startDate, endDate, rowsPerPage]);

  const handleSliderChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setRange([newValue[0], range[1]]);
    } else {
      setRange([range[0], newValue[1]]);
    }
  };

  const handleSliderCommit = (
    event: Event | SyntheticEvent<Element, Event>,
    value: number | number[]
  ) => {
    if (!Array.isArray(value)) {
      return;
    }
    setEndDate(earliestDate.add(-value[0], period as ManipulateType));
    setStartDate(earliestDate.add(-value[1], period as ManipulateType));
  };

  const datePickerViews: DateView[] =
    period === "month" ? ["year", "month"] : ["year", "month", "day"];

  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="column"
      // gap={5}
      alignItems="center"
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ width: "152px", marginBottom: pickerHeight / 8 }}>
          <DatePicker
            ref={datePickerRef}
            views={datePickerViews}
            label={undefined}
            minDate={earliestDate}
            maxDate={latestDate}
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue!);
              // TODO: update slider on date select click
              // need numeric, find the index of the value in the date str/obj array
              // also TODO: in Slider.tsx (horizontal)
            }}
            slotProps={{ textField: { helperText: null } }}
            sx={{ padding: 0, margin: 0 }}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height={height - 4.15 * pickerHeight}
          sx={{ minHeight: 200 }}
        >
          <Slider
            orientation="vertical"
            size="medium"
            getAriaLabel={() => "Small"}
            marks={period === "month"}
            min={-rangeLength}
            max={0}
            step={1}
            value={range}
            onChange={handleSliderChange}
            onChangeCommitted={(event, value) =>
              handleSliderCommit(event, value)
            }
            valueLabelFormat={(value) =>
              dayjs(earliestDate)
                .add(-value, period as ManipulateType)
                .format(period === "month" ? "MMM YYYY" : "MMM DD YYYY")
                .toString()
            }
            valueLabelDisplay="auto"
            disableSwap
            sx={{ minHeight: 100 }}
          />
        </Box>
        <Box sx={{ width: "152px", marginTop: pickerHeight / 8 }}>
          <DatePicker
            views={datePickerViews}
            label={undefined}
            minDate={earliestDate}
            maxDate={latestDate}
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue!);
            }}
            slotProps={{ textField: { helperText: null } }}
          />
        </Box>
      </LocalizationProvider>
    </Box>
  );
};
