import { useDefineAppLocation } from "@react-admin/ra-navigation";
import { Show, TabbedShowLayout, useGetIdentity } from "react-admin";
import { Stats } from "~/components";
import { TourismoDateRangePicker } from "~/components/TourismoDateRangePicker";
import { useCurrentPlatform } from "~/context";
import { ReportFilterProvider } from "~/context/ReportFilterContext";

export const ContestReport = () => {
  useDefineAppLocation("reports.contest");
  const { currentPlatform, isLoading } = useCurrentPlatform();
  const { identity, isLoading: isLoadingIdentity } = useGetIdentity();
  if (isLoading || isLoadingIdentity) return "";
  return (
    <ReportFilterProvider>
      <TourismoDateRangePicker />
      <Show resource="platforms" id={currentPlatform?.id} actions={false}>
        <TabbedShowLayout>
          {identity?.dmo_id && (
            <TabbedShowLayout.Tab label="Regional Contests">
              <Stats category="contest" resource="dmos" id={identity?.dmo_id} />
            </TabbedShowLayout.Tab>
          )}
          <TabbedShowLayout.Tab label="Platform Contests">
            <Stats category="contest" />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="Leaderboard">
            <Stats category="leaderboard" />
          </TabbedShowLayout.Tab>
        </TabbedShowLayout>
      </Show>
    </ReportFilterProvider>
  );
};
