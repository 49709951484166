import { Box, Tooltip, Typography } from "@mui/material";
import { Menu } from "@react-admin/ra-rbac";
import { filter, isObject } from "lodash";
import { useEffect } from "react";
import { useCreatePath, useStore } from "react-admin";
import { matchPath, useLocation } from "react-router-dom";
import { useMenuResources } from "~/context";
import { Logo } from "~/images/Logo";
import { LayoutClasses } from "../layoutClasses";
import PlatformMenu from "../PlatformMenu";
import { MENU_GROUPS } from "./constants";
import { MenuSection } from "./MenuSection";

export const Sidebar = () => {
  const location = useLocation();
  const [expanded, setExpanded] = useStore(`menu.accordion`);
  const createPath = useCreatePath();

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  const { menu_resources } = useMenuResources();

  useEffect(() => {
    // Determine which accordion group should be expanded based on the current path
    MENU_GROUPS.every((mg) => {
      const resources = filter(
        menu_resources,
        (v) => v.options?.menu_group === mg.key
      );
      const shouldExpand = resources.some(({ to, name }) => {
        const path =
          (isObject(to) ? to.pathname : to) ||
          createPath({ resource: name, type: "list" });
        const matches = matchPath(
          { path: path, end: false },
          location.pathname
        );
        return matches;
      });
      if (!shouldExpand) return true;

      setExpanded(mg.key);
      return false;
    });
  }, [location.pathname, menu_resources]);

  //I'm not convinced that setting resource labels in the admin is a good thing...
  //useSetResourceLabels();

  return (
    <div className={LayoutClasses.sidebar}>
      <PlatformMenu />

      <Tooltip
        title={"Home Page"}
        className={LayoutClasses.tooltip}
        enterDelay={500}
      >
        {/* Box is needed because DashboardItem doesn't forwardRef which is needed for tooltip*/}
        <Box>
          <Menu.DashboardItem
            variant="main"
            primaryText={
              <Typography
                color="white"
                variant="body2"
                sx={{ fontWeight: 500 }}
                whiteSpace="normal"
              >
                Dashboard
              </Typography>
            }
            // leftIcon={<House />}
            sx={{
              mt: "auto",
              fontSize: 24,
              paddingLeft: "1em",
            }}
            className={LayoutClasses.menuItem}
          />
        </Box>
      </Tooltip>

      <Menu className={LayoutClasses.menu}>
        {MENU_GROUPS.map((mg) => (
          <MenuSection
            key={mg.key}
            menu_group={mg}
            menu_resources={filter(
              menu_resources,
              (v) => v.options?.menu_group === mg.key
            )}
            expanded={expanded === mg.key}
            onChange={handleAccordionChange(mg.key)}
          />
        ))}
        {/* <MenuItem resource={menu_resources["help_topics"]} key="help_topics" />; */}
      </Menu>
      <a
        href="https://tourismo.co"
        target="_blank"
        style={{ color: "white", textDecoration: "none" }}
      >
        <Typography
          sx={{
            display: "flex", // Use flexbox layout
            alignItems: "center", // Vertically center items
            justifyContent: "center", // Horizontally center items
            color: "white",
            textAlign: "center",
            padding: "5px",
            textDecoration: "none",
          }}
        >
          powered by
          <Logo
            color={"white"}
            sx={{ display: "inline-block", width: "90px", marginTop: "-3px" }}
          />
        </Typography>
      </a>
    </div>
  );
};
