import { forwardRef, useState } from "react";
import { IconButton, Box } from "@mui/material";
import { DatagridRow } from "react-admin";
import { useSortable } from "@dnd-kit/sortable";
import { DotsSixVertical } from "@phosphor-icons/react";
import { CSS } from "@dnd-kit/utilities";

export const SortableRow = forwardRef(({ children, ...props }, ref) => {
  const [cursor, setCursor] = useState("grab");
  const { id } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  if (transform) transform.scaleY = 1;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    align: "center",
  };

  return (
    <DatagridRow
      ref={setNodeRef}
      {...props}
      style={style}
      sx={{
        "& td:first-of-type": { width: "1%", whiteSpace: "nowrap" },
      }}
      {...attributes}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <IconButton
          size="large"
          {...listeners}
          sx={{ cursor }}
          onMouseDown={() => setCursor("grabbing")}
          onMouseUp={() => setCursor("grab")}
        >
          <DotsSixVertical size={32} />
        </IconButton>
      </Box>
      {children}
    </DatagridRow>
  );
});
