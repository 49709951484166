import {
  useDataProvider,
  useNotify,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { useMutation, UseMutationOptions } from "react-query";

type RecordandResource = {
  record?: any;
  resource?: string;
};
export type RecordCommunicateAction = "invite_collaborator" | "claim";

export type UseRecordCommunicateOptions<
  T extends RecordandResource = RecordandResource
> = Omit<UseMutationOptions, "mutationFn"> & T;

export const useRecordCommunicate = (
  action: RecordCommunicateAction = "invite_collaborator",
  options: UseRecordCommunicateOptions = {}
) => {
  const { record, resource, ...mutation_options } = options;
  const dataProvider = useDataProvider();
  const mutation_resource = useResourceContext(options);
  const mutation_record = useRecordContext(options);
  const notify = useNotify();
  return useMutation({
    mutationFn: () =>
      dataProvider
        .recordCommunicate(mutation_resource, mutation_record, action)
        .then(({ data }: { data: any }) => data),
    ...mutation_options,
    onSuccess: (data: any, variables, context) => {
      mutation_options.onSuccess?.(data, variables, context);
      if (data?.notice) notify(data.notice, { type: data.type });
    },
  });
};
