import { Grid } from "@mui/material";
import {
  Edit,
  FormTab,
  required,
  TabbedForm,
  TextInput,
  WithRecord,
} from "react-admin";
import { Stats, StyledToolBar } from "~/components";
import { GridCard } from "~/components/GridCard";
import { AdminUserInput, EnumChoiceInput } from "~/components/inputs";
import { ContentInlineEditList } from "../contents";
import { LogList } from "../logs/LogList";
import { CommunicationAudience, SendControls, SendTestButtons } from "./form";

const validateAudiencePresence = (values) => {
  const errors = {};
  if (
    !values.send_push_notification &&
    !values.send_email &&
    !values.send_sms
  ) {
    errors.target_audience = "At least one notification type must be selected";
  }
  if (!values.name) errors.name = "ra.validation.required";
  return errors;
};

export const CommunicationEdit = () => {
  return (
    <Edit mutationMode="pessimistic" redirect={false}>
      <WithRecord
        render={(record) => {
          return (
            <TabbedForm
              variant="standard"
              toolbar={
                <StyledToolBar showDelete={record?.status === "draft"} />
              }
              mode="onChange"
              validate={validateAudiencePresence}
            >
              <FormTab label="Basic Setup">
                <Grid container spacing={2}>
                  <Grid container item spacing={2} xs={9}>
                    <Grid item xs={6}>
                      <TextInput
                        source="name"
                        label="Name/Subject"
                        fullWidth
                        helperText="E-mail subject & Notification title"
                        validate={[required()]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextInput
                        source="subtitle"
                        fullWidth
                        helperText="E-mail & Notification pre-amble"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ContentInlineEditList disableAdd disableRemove />
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <Grid container rowGap={2}>
                      <CommunicationAudience />
                      <GridCard>
                        <AdminUserInput fullWidth />
                        <EnumChoiceInput source="from_email_source" fullWidth />
                        <SendTestButtons />
                      </GridCard>
                      <SendControls />
                    </Grid>
                  </Grid>
                </Grid>
              </FormTab>
              <FormTab label="Activity Log">
                <LogList />
              </FormTab>
              <FormTab label="Reports">
                <Stats />
              </FormTab>
            </TabbedForm>
          );
        }}
      ></WithRecord>
    </Edit>
  );
};
