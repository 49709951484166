import { MouseEvent, useCallback, useMemo, useState } from "react";

export const useMenuAnchor = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  return useMemo(
    () => ({ anchorEl, open, handleClick, handleClose }),
    [anchorEl, handleClick, handleClose, open]
  );
};
