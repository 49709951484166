import {
  CreateContextProvider,
  useCreateController,
  useNotify,
} from "react-admin";
import { useQueryClient } from "react-query";
import { useDrawerForm } from "./DrawerForm";
import { DrawerFormHeader } from "./DrawerFormHeader";

export const CreateContext = ({
  children,
  onCreate,
  mutationOptions = {},
  closeOnSave = true,
  closeOnCreate = true,
  showCloseButton,
  ...props
}) => {
  const { setOpenId, onClose } = useDrawerForm();
  const { onSuccess, ...newMutationOptions } = mutationOptions;
  const notify = useNotify();
  const { resource } = props;
  const queryClient = useQueryClient();
  const controllerProps = useCreateController({
    mutationOptions: {
      ...newMutationOptions,
      onSuccess: async (data) => {
        notify("ra.notification.created", {
          type: "info",
          messageArgs: { smart_count: 1 },
        });
        if (onCreate) await onCreate(data);
        if (onSuccess) await onSuccess(data);
        queryClient.invalidateQueries([resource, "getManyReference"]);
        if (closeOnSave && closeOnCreate) {
          onClose();
        } else {
          setOpenId(data.id);
        }
      },
    },
    ...props,
  });
  return (
    <CreateContextProvider value={controllerProps}>
      <DrawerFormHeader
        title={controllerProps.defaultTitle}
        showCloseButton={showCloseButton}
      />
      {children}
    </CreateContextProvider>
  );
};
