import { Box, Typography } from "@mui/material";
import { Empty, useListContext } from "react-admin";

export const SimpleListView = ({ children, title, ...props }) => {
  const { defaultTitle, data, error, isLoading, filterValues, resource } =
    useListContext(props);
  const content =
    !data?.length && !isLoading ? (
      props.empty ? (
        props.empty
      ) : (
        <Empty />
      )
    ) : (
      children
    );
  const final_title = title !== undefined ? title : defaultTitle;
  return (
    <Box
      sx={{ "& .RaEmpty-message .MuiTypography-root": { textAlign: "center" } }}
    >
      {final_title && (
        <Typography variant="h5" mb={2} textTransform="capitalize">
          {final_title}
        </Typography>
      )}
      {content}
    </Box>
  );
};
