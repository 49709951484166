import { useNotify, useRecordContext } from "react-admin";
import { useCustomMutation } from "./useCustomMutation";

export const useSendTestMessage = (props?: { record?: any }) => {
  const record = useRecordContext(props);
  const notify = useNotify();
  const { mutate, ...rest } = useCustomMutation();
  const sendTestMessage = (
    message_type: "email" | "sms" | "push_notification"
  ) => {
    const data = new FormData();
    data.append("message_type", message_type);
    return mutate(
      {
        resource: "communications",
        id: record.id,
        custom_url: "send_single_message",
        data,
      },
      {
        onSuccess: ({ data }) => {
          notify(data.notice, { type: data.type });
        },
      }
    );
  };
  return [sendTestMessage, rest];
};
