import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  Datagrid,
  Empty,
  List,
  SearchInput,
  TextField,
  useCreatePath,
  useRecordContext,
} from "react-admin";
import { useCurrentPlatform } from "~/context";

export const PlatformLevelUserInteractions = () => {
  const [expanded, setExpanded] = useState("panel1");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const { getTerm } = useCurrentPlatform();
  const userTerm = getTerm("user", {
    plural: true,
    capitalize: true,
  });
  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
      disableGutters
      elevation={0}
      sx={{
        width: "100%",
        marginBottom: "32px",
      }}
    >
      <AccordionSummary sx={{ "&:hover": { backgroundColor: "blue.50" } }}>
        <Typography variant="h4">{`${userTerm} Completed`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <UserTable />
      </AccordionDetails>
    </Accordion>
  );
};

const UserTable = () => {
  const [search, setSearch] = useState("");
  const record = useRecordContext();
  console.log("❗️ - UserTable - record:", record);
  const createPath = useCreatePath();
  const { getTerm } = useCurrentPlatform();
  const pointTerm = getTerm("point", {
    plural: true,
    capitalize: true,
  });
  const usersTerm = getTerm("user", {
    plural: true,
    capitalize: true,
  });
  const userTerm = getTerm("user");
  return (
    <>
      <SearchInput
        source="q"
        onChange={({ target }) => setSearch(target?.value || "")}
      />
      <List
        empty={
          <Typography>{`Currently no ${userTerm} interactions.`}</Typography>
        }
        resource="platform_users"
        filter={{
          points: record.threshold,
          q: search,
          platform_id: record.platform_id,
        }}
        sort={{ field: "points", order: "DESC" }}
        exporter={false}
        actions={null}
        storeKey="platform_levels.platform_users.listParams"
      >
        <Datagrid
          buttonLabel={usersTerm}
          bulkActionButtons={false}
          rowClick={(_id, _resource, record) => {
            console.log("❗️ - UserTable - record:", record);
            return createPath({
              resource: "users",
              id: record.user_id,
              type: "edit",
            });
          }}
        >
          <TextField source="name" />
          <TextField source="points" label={pointTerm} />
        </Datagrid>
      </List>
    </>
  );
};
