import { Image } from "@phosphor-icons/react";
import { ItinResourceProps } from "../types";
import { MediaEdit } from "./MediaEdit";
import { MediaList } from "./MediaList";

export const MediaResource: ItinResourceProps = {
  name: "media",
  list: <MediaList />,
  icon: <Image />,
  // edit: <MediaEdit />,
  recordRepresentation: "filename",
  options: { menu_group: "content" },
};
