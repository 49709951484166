import { SimpleForm, CreateBase, useNotify } from "react-admin";
import { Box, Drawer } from "@mui/material";
import { LocationForm } from "./LocationForm";

export const LocationDrawer = ({ defaultValue, onCancel, onCreate }) => {
  const notify = useNotify();

  const onSuccess = (data) => {
    notify("ra.notification.created", {
      type: "info",
      messageArgs: { smart_count: 1 },
    });
    onCreate && onCreate({ id: data.id, name: data.name });
  };
  return (
    <Drawer
      anchor="right"
      open={true}
      onClose={onCancel}
      PaperProps={{
        sx: {
          backgroundColor: "white",
          "& .MuiToolbar-root": {
            paddingX: 0,
          },
        },
      }}
    >
      <Box
        sx={{
          width: "45vw",
          padding: 4,
          backgroundColor: "white",
        }}
      >
        <CreateBase
          resource="locations"
          redirect={false}
          mutationOptions={{ onSuccess }}
        >
          <SimpleForm>
            <LocationForm defaultValue={defaultValue} />
          </SimpleForm>
        </CreateBase>
      </Box>
    </Drawer>
  );
};
