import { GoogleMap } from "@react-google-maps/api";
import { createContext, PropsWithChildren, useContext } from "react";
import { brightMap } from "../mapStyle";
type MapInputProps = PropsWithChildren<{}>;

type MapInputContextType = { mapBounds: google.maps.LatLngBounds };
const MapInputContext = createContext<MapInputContextType | null>(null);
export const useMapInput = () => {
  const context = useContext(MapInputContext);
  if (!context) {
    throw new Error("useMapInput must be used within a MapInput");
  }
  return context;
};
export const MapInput = ({ children }: MapInputProps) => {
  return (
    <MapInputContext.Provider
      value={{ mapBounds: new google.maps.LatLngBounds() }}
    >
      <GoogleMap
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}
        options={{
          styles: brightMap,
          gestureHandling: "cooperative",
          fullscreenControl: false,
          streetViewControl: false,
        }}
      >
        {children}
      </GoogleMap>
    </MapInputContext.Provider>
  );
};
