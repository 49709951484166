import { Box, CardMedia, Grid, Stack, Typography } from "@mui/material";
import { get } from "lodash";
import { Envelope, Globe, Phone } from "@phosphor-icons/react";
import { useRecordContext } from "react-admin";
import ReactHtmlParser from "react-html-parser";
import { useRandomIllustration } from "~/images/illustrations";
import { EventTimeSnippet } from "./EventTimeSnippet";

export const EventHero = (props) => {
  const record = useRecordContext(props);
  const Illustration = useRandomIllustration();
  const url = get(record, "hero.url", record?.ingest_hero_url);

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <CardMedia
          component={!url ? Illustration : "img"}
          alt=""
          image={url}
          sx={{
            borderRadius: 3,
            width: "100%",
            height: !url ? "260px" : "100%",
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Stack gap={2}>
          <Box>
            <Typography sx={{ fontWeight: "bold", fontSize: "1.2em", mb: 1 }}>
              {record.name}
            </Typography>
            <Typography sx={{ fontWeight: "500" }}>
              {record.subtitle}
            </Typography>

            <EventTimeSnippet />
            <Typography variant="h6">${record.cost}</Typography>
            <>{ReactHtmlParser(record.description)}</>
          </Box>
          <Box>
            <Typography>
              <Envelope />
              {record.email}
            </Typography>
            <Typography>
              <Phone />
              {record.phone_number}
            </Typography>
            <Typography>
              <Globe />
              {record.website}
            </Typography>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};
