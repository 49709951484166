import { Box, Typography } from "@mui/material";
import { useRecordContext } from "react-admin";
import { MapDataProvider } from "~/components/maps/MapDataProvider";
import { ReferenceManyThroughMany } from "~/components/fields/ReferenceManyThroughMany";
import { SimpleMapList } from "~/components/maps/SimpleMapList";
import { useStopDrawerContext } from "../ItineraryEdit";
export const ItineraryMap = () => {
  const record = useRecordContext();
  const { handleOpen } = useStopDrawerContext();
  return (
    <Box height={"680px"}>
      <MapDataProvider>
        {!record && <Empty />}
        <ReferenceManyThroughMany
          reference="stops"
          through="legs"
          using="itinerary_id,leg_id"
        >
          <SimpleMapList
            navigateOnClick={false}
            polySource="fullBounds"
            markerSource="fullLonLat"
            onClick={(record) => {
              handleOpen && handleOpen(record.id);
            }}
          />
        </ReferenceManyThroughMany>
      </MapDataProvider>
    </Box>
  );
};

const Empty = () => <Typography>No itinerary stops set.</Typography>;
