import { ReferenceManyField, WithListContext } from "react-admin";
import { SummaryIcon } from "./SummaryIcon";

export const ReferenceManySummaryIcon = ({
  Icon,
  reference,
  summary_props,
  ...props
}) => {
  return (
    <ReferenceManyField reference={reference} {...props}>
      <WithListContext
        render={({ total }) => (
          <SummaryIcon
            Icon={Icon}
            source={reference}
            value={total}
            {...summary_props}
          />
        )}
      />
    </ReferenceManyField>
  );
};
