import AwsS3, { type AwsBody } from "@uppy/aws-s3";
import Uppy, { Meta } from "@uppy/core";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import { Dashboard } from "@uppy/react";
import { useRef } from "react";
import { useCreate } from "react-admin";

type Props = {
  onChange: (media: any) => void;
  multiple?: boolean;
  height?: string | number;
};

export const Dash = ({ onChange, multiple, height }: Props) => {
  const [create] = useCreate();
  const onChangeRef = useRef(onChange);
  const ref = useRef(null);
  const uppy = useRef(
    new Uppy<Meta, AwsBody>({
      restrictions: { maxNumberOfFiles: multiple ? undefined : 1 },
    })
      .use(AwsS3, { endpoint: "/api/admin", shouldUseMultipart: true })
      .on("upload-success", async (file, res) => {
        const id = res.uploadURL?.match(/\/cache\/([^\?]+)/)?.[1];
        if (!file || !id) return;
        const { size, name: filename, type: mime_type } = file;
        const fileData = {
          id,
          storage: "cache",
          metadata: { size, filename, mime_type },
        };
        await create(
          "media",
          { data: { file: fileData } },
          {
            onSuccess: (res) => {
              onChangeRef.current(res);
            },
          }
        );
      })
  ).current;
  return <Dashboard ref={ref} uppy={uppy} height={height} />;
};
