import { chain, chunk } from "lodash";

export const getImageIsSolidColor = (image: HTMLImageElement) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  let step = 0;
  let w = (canvas.width = image.naturalWidth);
  let h = (canvas.height = image.naturalHeight);
  if (h <= 128 || w <= 128 || !ctx) return true;

  ctx.globalCompositeOperation = "source-over";
  ctx.fillStyle = "#FFF";
  ctx.fillRect(0, 0, w, h);
  ctx.drawImage(image, 0, 0);
  ctx.globalCompositeOperation = "copy";
  while (step++ < 4) {
    ctx.drawImage(canvas, 0, 0, w, h, 0, 0, (w /= 2), (h /= 2));
  }
  const imgData = ctx.getImageData(0, 0, w | 0, h | 0);
  const pixels = chunk(new Uint8Array(imgData.data.buffer), 4);
  let firstPixel;
  let solidColor = true;
  let idx = pixels.length;
  while (idx-- > 0) {
    const pixel =
      "#" +
      chain(pixels)
        .get(idx, [] as number[])
        .map((c) => c.toString(16))
        .join("")
        .value();
    if (!firstPixel) {
      firstPixel = pixel;
    } else if (firstPixel !== pixel) {
      solidColor = false;
      break;
    }
  }
  return solidColor;
};
