import jsonExport from "jsonexport/dist";
import {
  AutocompleteInput,
  BulkExportButton,
  Datagrid,
  DateField,
  downloadCSV,
  ReferenceField,
  ReferenceInput,
} from "react-admin";
import { EditDeleteButtons } from "~/components";
import { EnumSelectField, PlatformUserField } from "~/components/fields";
import { EnumChoiceInput } from "~/components/inputs";
import { ToggleList } from "~/components/lists";
import { useCurrentPlatform } from "~/context";

const useFilters = () => {
  return [
    <EnumChoiceInput source="status" fullWidth />,
    <ReferenceInput source="form_id" reference="forms" label="By Form">
      <AutocompleteInput emptyText="By Form" fullWidth />
    </ReferenceInput>,
  ];
};

const findAndFlattenByFormEntry = (data, targetEntryId) => {
  return data
    .filter((item) => item.form_entry_id === targetEntryId) // Find objects with the matching form_entry_id
    .reduce((acc, item) => {
      acc[item.label] = item.value; // Add the key-value pair to the accumulator object
      return acc;
    }, {}); // Start with an empty object
};

const formExporter = async (
  form_entries,
  fetchRelatedRecords,
  dataProvider
) => {
  // will call dataProvider.getMany('posts', { ids: records.map(record => record.post_id) }),
  // ignoring duplicate and empty post_id

  const { data: form_entries_fields } = await dataProvider.getMany(
    "form_entry_fields",
    {
      form_id: form_entries.map((fe) => fe.id),
    }
  );
  const formEntriesWithFields = form_entries.map((form_entry) =>
    findAndFlattenByFormEntry(form_entries_fields, form_entry.id)
  );
  return jsonExport(
    formEntriesWithFields,

    (err, csv) => {
      downloadCSV(csv, "form entries");
    }
  );
};

export const FormEntryList = () => {
  const filters = useFilters();
  return (
    <ToggleList
      modes={MODES}
      filters={filters}
      filterDefaultValues={{ status: "awaiting_approval" }}
      exporter={formExporter}
    />
  );
};

const ListData = () => {
  const { getTerm } = useCurrentPlatform();
  const userTerm = getTerm("user", { capitalize: true });
  const formTerm = getTerm("form", { capitalize: true });
  return (
    <Datagrid
      rowClick="show"
      bulkActionButtons={
        <>
          <BulkExportButton />
        </>
      }
    >
      <PlatformUserField source="platform_user_id" label={userTerm} />
      <ReferenceField source="form_id" reference="forms" label={formTerm} />
      <EnumSelectField
        source="status"
        color_options={{
          awaiting_approval: "primary",
          approved: "success",
          denied: "error",
          spam: "default",
        }}
      />
      <DateField showTime source="created_at" />
      <EditDeleteButtons canEdit={false} canDelete />
    </Datagrid>
  );
};

const MODES = [{ name: "List", view: <ListData /> }];
