import { Grid } from "@mui/material";
import { chain, get } from "lodash";
import {
  Edit,
  Labeled,
  SimpleForm,
  TextInput,
  useRecordContext,
} from "react-admin";
import { JsonField } from "react-admin-json-view";
import { ImageButton } from "./IngestionImageSelect";

export const IngestionEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source="name" fullWidth />
        <TextInput source="url" fullWidth />
        <Labeled label="Json Data" sx={{ mb: 3 }}>
          <JsonField source="json_data" />
        </Labeled>
        <Images source="images_payload" />
        <Content source="content_payload" />
      </SimpleForm>
    </Edit>
  );
};

const Images = ({ source }) => {
  const record = useRecordContext();
  const images = get(record, source, []);
  return (
    <Labeled label="Images">
      <Grid container spacing={2}>
        {chain(images)
          .uniqBy("url")
          .filter("url")
          .value()
          .map((image) => (
            <ImageButton source={image.url} />
          ))}
      </Grid>
    </Labeled>
  );
};

const Content = ({ source }) => {
  const record = useRecordContext();
  const content_payload = get(record, source, []);
  return (
    <Labeled label="Content">
      <Grid container spacing={2}>
        {content_payload.map((cp, i) => {
          return (
            <Grid key={i} container variant="outlined" xs={12}>
              <Grid item xs={2}>
                {cp["tag"]}
              </Grid>
              <Grid item xs={2}>
                {cp["classes"]}
              </Grid>
              <Grid item xs={2}>
                {cp["href"]}
              </Grid>
              <Grid item xs={6}>
                {cp["text"]}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Labeled>
  );
};
