import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  FormControl,
} from "@mui/material";
import { CursorIcon } from "./CursorIcon";
import { useMapData } from "./MapDataProvider";
import { MapPin, Hand, BoundingBox } from "@phosphor-icons/react";
import { forwardRef } from "react";

export const ToolButtons = ({ mapMouseOver }) => {
  const { dataRef, drawingMode, setDrawingMode } = useMapData();

  const handleChange = (_, value) => {
    if (!value) return;
    setDrawingMode(value);
    dataRef.current?.setDrawingMode(value === "Polygon" ? value : null);
  };

  return (
    <>
      <CursorIcon
        color="error"
        Icon={MapPin}
        show={drawingMode === "Marker" && mapMouseOver}
      />
      <FormControl sx={{ margin: 0 }}>
        <ToggleButtonGroup
          value={drawingMode}
          exclusive
          onChange={handleChange}
          size="small"
        >
          <TooltipToggleButton title="Move Map or Items" value="Grab">
            <Hand />
          </TooltipToggleButton>
          <TooltipToggleButton title="Add Boundary Area" value="Polygon">
            <BoundingBox />
          </TooltipToggleButton>
          <TooltipToggleButton title="Add Marker" value="Marker">
            <MapPin />
          </TooltipToggleButton>
        </ToggleButtonGroup>
      </FormControl>
    </>
  );
};

const TooltipToggleButton = forwardRef(({ title, ...props }, ref) => {
  return (
    <Tooltip title={title}>
      <Box>
        <ToggleButton
          ref={ref}
          sx={{
            marginLeft: 2,
            color: "primary.contrastText",
            backgroundColor: "primary.main",
            borderRadius: "50%",
            borderWidth: 0,
            fontSize: 28,
            "&:hover, &.Mui-selected:hover": {
              backgroundColor: "primary.light",
              color: "primary.dark",
            },
            "&.Mui-selected": {
              backgroundColor: "primary.dark",
              color: "primary.contrastText",
            },
          }}
          {...props}
        />
      </Box>
    </Tooltip>
  );
});
