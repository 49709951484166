import { Collapse, Grid } from "@mui/material";
import { get } from "lodash";
import { useCallback, useState } from "react";
import { Button, Create, SimpleForm, TextInput } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { CreateIngestionButton, Sparkles, StyledToolBar } from "~/components";
import { RegionInput } from "~/components/inputs/RegionInput";
import { useCurrentPlatform } from "~/context";
import {
  IngestionImageSelect,
  IngestionProvider,
  IngestionStatusMessage,
} from "../ingestions";
import { EditEventBasicInfo } from "./edit";
import { EventHero } from "./EventHero";

export const EventCreate = () => {
  const { getTerm } = useCurrentPlatform();
  const [manual, setManual] = useState(true);
  const [ingestionId, setIngestionId] = useState(null);
  const eventTerm = getTerm("event");
  return (
    <Create redirect="edit">
      <SimpleForm
        variant="standard"
        toolbar={
          <StyledToolBar
            showDelete={false}
            showSave={manual || !!ingestionId}
            saveButtonProps={{ label: `Create ${eventTerm}` }}
          >
            {!manual && !ingestionId && (
              <IngestionButton
                onSuccess={(ingestion) =>
                  // setValue("ingestion_id", ingestion?.id)
                  setIngestionId(ingestion?.id)
                }
              />
            )}
            <Button
              label={
                manual
                  ? `Try some Magic (beta)`
                  : `Create ${eventTerm} manually...`
              }
              onClick={() => setManual((m) => !m)}
            />
          </StyledToolBar>
        }
        defaultValues={{
          target_type: "Region",
        }}
      >
        <EventCreateForm manualEntryMode={manual} ingestion_id={ingestionId} />
      </SimpleForm>
    </Create>
  );
};

const IngestionButton = (props) => {
  const website = useWatch({ name: "website" });

  return <CreateIngestionButton website={website} {...props} />;
};

const EventCreateForm = ({ manualEntryMode = true, ingestion_id }) => {
  const record = useWatch();
  console.log("❗️ - EventCreateForm - record:", record);
  const ingestionCreated = !!ingestion_id;
  const showAutoFields = !manualEntryMode && !ingestionCreated;
  const { setValue } = useFormContext();
  const handleRefetch = useCallback(
    ({ data }) => {
      // after the ingestion has completed processing get the finished json_data
      const {
        socials = {},
        event_times = {},
        locations = {},
        ...values
      } = get(data, "json_data", {});
      // Listings accepts_nested_attributes_for :socials so we
      // loop through the socials and create an array in the correct format
      const socials_attributes = Object.entries(socials).flatMap(
        ([key, value]) => {
          return value ? { key, value } : [];
        }
      );
      // and assign that to "socials_attributes"
      socials_attributes?.length &&
        setValue("socials_attributes", socials_attributes);

      // Listings accepts_nested_attributes_for :event_times so we
      // loop through the socials and create an array in the correct format

      event_times?.forEach((et, index) => {
        const key = `@@ra-many/events/event_times/event_id.0.event_times.${index}`;
        setValue(
          key,
          {
            start: new Date(et.start).toISOString(),
            end: new Date(et.end).toISOString(),
            all_day: et.all_day,
            locations_attributes: locations,
            rrule: et.rrule || null,
          },
          { shouldDirty: true, shouldTouch: true }
        );
      });

      // For all other values we directly set the form value for the attribute
      Object.entries(values).forEach(
        ([attribute, value]) => value && setValue(attribute, value)
      );
    },
    [setValue]
  );

  return (
    <IngestionProvider ingestion_id={ingestion_id} onRefetch={handleRefetch}>
      {({ status: renderingStatus, isLoading, ingestion }) => {
        const finishedRendering = renderingStatus?.progress === 100;
        const isRendering = renderingStatus && !finishedRendering;
        return (
          <>
            {!manualEntryMode && ingestionCreated && (
              <Collapse
                component={Grid}
                in
                item
                xs={12}
                sx={{ position: "relative" }}
              >
                <Sparkles show={isRendering} sx={{ width: "100%" }}>
                  <EventHero record={record} sx={{ height: "260px;" }} />
                </Sparkles>
                <IngestionStatusMessage />
              </Collapse>
            )}

            {!manualEntryMode && (
              <RegionInput source="target_id" isRequired={false} />
            )}
            {showAutoFields && <TextInput label="Event URL" source="website" />}
            {manualEntryMode && <EditEventBasicInfo />}
            <IngestionImageSelect
              images={ingestion?.all_images}
              isLoading={isLoading}
            />
          </>
        );
      }}
    </IngestionProvider>
  );
};
