import { Datagrid, TextField } from "react-admin";
import AvatarField from "~/components/AvatarField";
import { CustomColorChipField, OwnerAvatarField } from "~/components/fields";
import { ToggleList } from "~/components/lists";
import { EditDialog } from "@react-admin/ra-form-layout";
import { MediaForm } from "./MediaEdit";

export const MediaList = () => {
  return (
    <>
      <ToggleList
        modes={MODES}
        sx={{ "& .ItinImageCard-image": { objectFit: "contain" } }}
        rowClick="edit"
      />
      <EditDialog fullScreen>
        <MediaForm />
      </EditDialog>
    </>
  );
};

const cardData = (record) => {
  return {
    title: `${record.filename}`,
    subtitles: [
      <CustomColorChipField
        source="type"
        color_options={COLOR_OPTIONS}
        variant="outlined"
      />,
    ],
    status: "type",
    img: record.urls?.medium,
  };
};

const COLOR_OPTIONS = {
  Photo: "primary",
  Video: "success",
  Audio: "default",
  Pdf: "primary",
  Other: "error",
};

const ListData = () => {
  return (
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <AvatarField source="urls.thumb_square" label={false} hideEmpty />
      <CustomColorChipField
        source="type"
        color_options={COLOR_OPTIONS}
        variant="outlined"
      />
      <TextField source="filename" />
      <OwnerAvatarField source="owner_id" />
    </Datagrid>
  );
};

const MODES = [
  { name: "Grid", cardData: cardData },
  { name: "List", view: <ListData /> },
];
