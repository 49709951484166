import { useCallback } from "react";
import { useNavigateToRecord } from "~/hooks";

export const useHandleClick = ({ onClick, navigateOnClick }) => {
  const navigateToRecord = useNavigateToRecord();
  return useCallback(
    (e) => {
      if (onClick) onClick(e);
      if (navigateOnClick) navigateToRecord();
    },
    [onClick, navigateOnClick]
  );
};
