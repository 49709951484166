import { ImageField, TextField } from "react-admin";
import Typography from "@mui/material/Typography";
import { startCase } from "lodash";

export const FormTextField = (props) => (
  <div>
    <Typography component="span" variant="body2">
      {`${startCase(props.source)}: `}
    </Typography>
    <TextField {...props} />
  </div>
);

FormTextField.defaultProps = TextField.defaultProps;

export const FormImageField = ({ variant, ...props }) => (
  <ImageField {...props} />
);

FormImageField.defaultProps = ImageField.defaultProps;
