import { Box, Grid } from "@mui/material";
import { get } from "lodash";
import { SimpleForm, useRecordContext } from "react-admin";
import { useWatch } from "react-hook-form";
import { SummaryCards } from "~/components";
import { EnumChoiceInput, OptionsInputs } from "~/components/inputs";
import { WpExtension } from "./WpExtension";
import { ContestingExtension } from "./ContestingExtension";

export const PlatformExtensionForm = ({ platform, ...props }) => {
  const record = useRecordContext();
  return (
    <SimpleForm
      sx={{ width: "90vw" }}
      defaultValues={{ platform_id: platform.id }}
      {...props}
    >
      <Box width="100%" my={2}>
        <Grid container>
          <Grid item xs={6}>
            {!record?.id && <EnumChoiceInput source={"type"} />}
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <SummaryCards
              cards={record?.summary_cards}
              sx={{ justifyContent: "flex-end" }}
            />
          </Grid>
        </Grid>
        <DefaultComponent />
      </Box>
    </SimpleForm>
  );
};

const DefaultComponent = () => {
  const type = useWatch({ name: "type" });
  const record = useRecordContext();
  const Component = get(platformExtensionComponents, type);
  if (Component) return <Component />;
  return (
    <OptionsInputs
      record={record}
      source={"options"}
      type_source={"type"}
      container
      gridItemProps={(field) => ({
        item: true,
        xs: 12,
        md: ["json", "object"].includes(field.type) ? 12 : 4,
      })}
    />
  );
};

export const platformExtensionComponents = {
  WpExtension,
  ContestingExtension,
};
