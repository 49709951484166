import { Box, Typography } from "@mui/material";
import { CirclesFour, FlagCheckered, ListBullets } from "@phosphor-icons/react";
import {
  RecordContextProvider,
  ResourceContextProvider,
  useGetMany,
} from "react-admin";
import { CardCountIcon, Empty } from "~/components";
import { CreateButtonWithDefaults } from "~/components/buttons/CreateButtonWithDefaults";
import { ImageCard } from "~/components/lists/ImageCard";
import { useCurrentPlatform } from "~/context";

export const RegionItineraries = (props) => {
  const record = props.record;
  const { data: itineraries } = useGetMany("itineraries", {
    ids: record.itinerary_ids,
  });
  const { getTerm } = useCurrentPlatform();
  return (
    <>
      <Typography variant="h5">
        {`${record.itinerary_ids.length} ${getTerm("itinerary", {
          plural: true,
          capitalize: true,
        })}`}
        {itineraries?.length > 0 && (
          <CreateButtonWithDefaults
            defaultValues={{ region_id: record.id }}
            resource="itineraries"
            sx={{ marginLeft: "10px" }}
          />
        )}
      </Typography>
      {itineraries?.length == 0 && (
        <Empty
          resource="itineraries"
          title={`What's happening in ${record.name}?`}
          subtitle="Let's create our first itinerary in this region..."
          buttonLabel="Create Itinerary"
          showImage={false}
          defaultValues={{ region_id: record.id }}
        />
      )}
      {itineraries && (
        <Box
          display="grid"
          gridTemplateColumns="repeat(12,1fr)"
          gap={2}
          sx={{ padding: "20px 0px" }}
        >
          {itineraries.map((itin) => {
            const cardData = (record) => {
              return {
                title: itin.name,
                subtitles: [
                  <CardCountIcon
                    icon={CirclesFour}
                    type={"leg"}
                    count={itin.num_legs}
                  />,
                  <CardCountIcon
                    icon={ListBullets}
                    type={"stop"}
                    count={itin.num_stops}
                  />,
                  <CardCountIcon
                    count={itin.num_challenges}
                    type={"challenge"}
                    icon={FlagCheckered}
                  />,
                ],
              };
            };

            return (
              <RecordContextProvider value={itin} key={"rcp-i-" + itin.id}>
                <ResourceContextProvider value="itineraries">
                  <ImageCard cardData={cardData} />
                </ResourceContextProvider>
              </RecordContextProvider>
            );
          })}
        </Box>
      )}
    </>
  );
};
