import { Globe } from "@phosphor-icons/react";
import { ItinResourceProps } from "../types";
import { PlatformEdit } from "./PlatformEdit";
import { PlatformList } from "./PlatformList";
import { PlatformCreate } from "./PlatformCreate";

export const PlatformResource: ItinResourceProps = {
  name: "platforms",
  list: PlatformList,
  edit: PlatformEdit,
  create: PlatformCreate,
  icon: <Globe />,
  recordRepresentation: "name",
  options: {menu_group: "admin"},
};
