import { Check, Pencil, Rocket, Trash } from "@phosphor-icons/react";
import { useCanAccess } from "@react-admin/ra-rbac";
import {
  Button,
  DeleteButtonProps,
  DeleteWithConfirmButton,
  SaveButton,
  SaveButtonProps,
  Toolbar,
  ToolbarProps,
  useEditContext,
  useGetResourceLabel,
  useResourceContext,
  useUpdate, // Import useUpdate
} from "react-admin";
import { useCurrentPlatform } from "~/context";

type Props = ToolbarProps & {
  showSave?: boolean;
  showDelete?: boolean;
  deleteButtonProps?: DeleteButtonProps;
  saveButtonProps?: SaveButtonProps;
  submitButtonProps?: SubmitButtonProps;
};

export const StyledToolBar = ({
  showSave = true,
  showSubmit = false,
  showDelete = true,
  deleteButtonProps,
  saveButtonProps,
  submitButtonProps,
  children,
  ...props
}: Props) => {
  const resource = useResourceContext(props);
  const getResourceLabel = useGetResourceLabel();
  const resource_name = getResourceLabel(resource);
  const { getTerm } = useCurrentPlatform();

  const { record } = useEditContext();

  const { canAccess: canSubmit } = useCanAccess({
    record,
    resource: resource,
    action: "edit",
  });

  const [update, { isLoading, error }] = useUpdate(); // useUpdate hook

  const handleSubmit = () => {
    const newStatus = record.status === "draft" ? "pending" : "draft"; // Toggle status
    update(
      resource, // resource name
      { id: record.id, data: { status: newStatus }, previousData: record }, // update payload
      {
        onSuccess: () => {
          console.log(`Status updated to '${newStatus}'`);
        },
        onFailure: (error) => {
          console.error("Error updating status:", error);
        },
      }
    );
  };

  const label = getTerm(resource_name, { capitalize: true });
  return (
    <Toolbar {...props}>
      {showSave && (
        <SaveButton
          type="button"
          endIcon={<Check />}
          // @ts-expect-error
          icon={null}
          {...saveButtonProps}
        />
      )}
      {children}
      {showSubmit &&
        record &&
        (record.status === "draft" || record.status === "pending") && (
          <Button
            type="button"
            label={
              record.status === "draft"
                ? "Submit for approval"
                : "Mark as Draft"
            } // Toggle button label
            endIcon={record.status === "draft" ? <Rocket /> : <Pencil />} // Toggle icon
            icon={null}
            onClick={handleSubmit} // Attach onClick handler to toggle status
            {...submitButtonProps}
            disabled={isLoading} // Optionally disable the button while updating
          />
        )}
      {showDelete && (
        <DeleteWithConfirmButton
          label={`Delete ${label}`}
          endIcon={<Trash />}
          startIcon={null}
          {...deleteButtonProps}
          confirmTitle={`Delete ${label}`}
          confirmContent={`Are you sure you want to permanently delete this ${getTerm(
            resource
          )}?`}
        />
      )}
      {error && <p style={{ color: "red" }}>Error: {error.message}</p>}
    </Toolbar>
  );
};
