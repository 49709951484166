import {
  ReferenceArrayField,
  ReferenceManyField,
  SimpleList,
} from "react-admin";
import { Avatar, Grid, Typography } from "@mui/material";

export const ListingItineraries = () => {
  return (
    <ReferenceArrayField source="itinerary_ids" reference="itineraries">
      <SimpleList
        leftAvatar={(record) => {
          return record.hero && <Avatar src={record.hero.url} />;
        }}
        // tertiaryText={(record) => {
        //   const completed = record.user_completion_counts.filter(
        //     (c) => c.completed
        //   ).length;
        //   return `${completed} completed`;
        // }}
        // secondaryText={
        //   <ReferenceManyField reference="rewards" target="challenge_id">
        //     <SimpleList
        //       empty={null}
        //       linkType={false}
        //       leftAvatar={(r) => r.url}
        //       tertiaryText={(r) => `${r.earned_count} earned`}
        //       sx={{ padding: 1 }}
        //       rowSx={() => ({
        //         "& .MuiListItemText-primary": {
        //           display: "flex",
        //           alignItems: "center",
        //           "& .MuiListItemText-primary": {
        //             display: "block",
        //           },
        //         },
        //       })}
        //     />
        //   </ReferenceManyField>
        // }
      />
    </ReferenceArrayField>
  );
};
