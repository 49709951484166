import { SimpleForm } from "react-admin";
import { StopFields } from "./StopFields";
export const StopForm = ({ leg_id, itinerary, ...props }) => {
  return (
    <SimpleForm
      defaultValues={{
        leg_id: leg_id,
        status: "active",
        checkinable: true,
      }}
      {...props}
    >
      <StopFields itinerary={itinerary} />
    </SimpleForm>
  );
};
