import { Chip, Stack } from "@mui/material";
import { get } from "lodash";
import {
  BooleanField,
  FunctionField,
  NumberInput,
  TextField,
  maxValue,
  minValue,
  number,
  useListContext,
  useRecordContext,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { DrawerDatagrid } from "~/components/drawer";
import { integer } from "~/components/inputs/validations";
import { useCurrentPlatform } from "~/context";
import { FormFieldForm } from "./FormFieldForm";

export const FormFieldList = () => {
  const record = useRecordContext();
  const approval_type = useWatch({ name: "approval_type" });
  const { total } = useListContext();
  const { getTerm } = useCurrentPlatform();
  const label = getTerm("form", { capitalize: true });
  if (!record) return null;
  return (
    <>
      {approval_type === "automatic" && (
        <NumberInput
          source="correct_count"
          label={`Correct count (out of ${total})`}
          max={total}
          min={0}
          validate={[number(), maxValue(total), minValue(0), integer()]}
          sx={{ minWidth: "20%" }}
        />
      )}
      <DrawerDatagrid
        buttonLabel={`${label} Field`}
        Form={<FormFieldForm form_id={record.id} />}
        sortable_update_source={"position"}
      >
        <TextField source="position" sortable={false} />
        <TextField source="label" sortable={false} />
        <CorrectAnswersField
          source="options.correct_answers"
          sortable={false}
          label="Correct Answers"
        />
        <FormFieldTypeField source="type" sortable={false} />
        <BooleanField source="required" sortable={false} />
      </DrawerDatagrid>
    </>
  );
};
const CorrectAnswersField = (props) => {
  const record = useRecordContext();
  const isArray = Array.isArray(record.options.correct_answers);
  return (
    <Stack direction="row" spacing={1}>
      {isArray ? (
        record.options?.correct_answers?.map((item) => (
          <Chip key={item} label={condenseLabel(item)} />
        ))
      ) : (
        <BooleanField {...props} />
      )}
    </Stack>
  );
};

const condenseLabel = (string) => {
  if (string.length <= 25) return string;
  return `${string.slice(0, 25)}...`;
};

export const FormFieldTypeField = (props) => {
  const { source } = props;
  return (
    <FunctionField
      {...props}
      render={(record) => get(record, source).replace("Field", "")}
    />
  );
};
