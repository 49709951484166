import { get, isArray } from "lodash";
import { useRecordContext } from "react-admin";
import { Box, Tooltip, Typography } from "@mui/material";
import { CircularProgressWithLabel } from "../CircularProgressWithLabel";

export const CircularProgressField = ({
  source,
  tooltipSource,
  textAlign,
  sortable,
  ...props
}) => {
  const record = useRecordContext(props);
  const value = get(record, source);
  const tooltip = get(record, tooltipSource);
  const title = isArray(tooltip)
    ? tooltip.map((t) => <Typography key={t}>{t}</Typography>)
    : tooltip;
  return (
    <Tooltip title={title}>
      <Box>
        <CircularProgressWithLabel
          value={value}
          color="gradient"
          size={32}
          {...props}
        />
      </Box>
    </Tooltip>
  );
};
