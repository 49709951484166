import { useMemo } from "react";
import { TextInput } from "react-admin";
import { MultiImageInput } from "~/components";
import { BlockInput } from "~/components/inputs/block_editor/BlockInput";
import ItinRichTextArea from "~/components/inputs/ItinRichTextArea";
import { ContentTypeType } from "../content_types";

type Props = {
  getSource?: (field: string) => string;
  type: ContentTypeType;
};
export const useContentField = ({ type, getSource = (f) => f }: Props) => {
  let field: JSX.Element;
  switch (type) {
    case "ContentType::PlainText":
      field = <TextInput source={getSource("description")} label={false} />;
      break;
    case "ContentType::Media":
      field = <MultiImageInput source={getSource("media")} />;
      break;
    case "ContentType::Markdown":
      field = (
        <ItinRichTextArea
          placeholder="Additional Content.."
          source={getSource("description")}
          isRequired={false}
        />
      );
      break;
    case "ContentType::MediaAndMarkdown":
      field = (
        <>
          <MultiImageInput source={getSource("media")} />
          <ItinRichTextArea
            placeholder="Additional Content.."
            source={getSource("description")}
            isRequired={false}
          />
        </>
      );
      break;
    case "ContentType::Url":
      field = (
        <TextInput
          source={getSource("url")}
          label={false}
          helperText="Complete Url, ie. https://tourismo.co"
        />
      );
      break;
    case "ContentType::BlockEditor":
      field = (
        <BlockInput
          source={getSource("json_data")}
          html_source={getSource("html")}
        />
      );
      break;
    default:
      field = <p>Content {type} Type not found</p>;
  }
  return useMemo(() => field, [type]);
};
