import { TextInput, TextInputProps } from "react-admin";
import { InputAdornment } from "@mui/material";
import { useWatch } from "react-hook-form";
import { useCallback, useMemo } from "react";

type Props = Omit<TextInputProps, "helperText"> & {
  maxLength?: number;
  minLength?: number;
  helperText?: string;
};

export const TextInputWithLengthLimit = ({
  maxLength,
  minLength,
  helperText,
  validate = [],
  ...props
}: Props) => {
  const { source } = props;
  const value = useWatch({ name: source });

  const validateLength = useCallback(
    (value: string | null) => {
      if (maxLength && value && value.length > maxLength)
        return `Must be ${maxLength} characters or less.`;
      if (minLength && (!value || value.length < minLength))
        return `Must be greater than ${minLength} characters.`;
    },
    [minLength, maxLength]
  );
  const helper_text = useMemo(() => {
    let text = helperText ? `${helperText} ` : "";
    if (maxLength || minLength) {
      text += "(";
      if (minLength) text += `Min ${minLength} `;
      if (maxLength && minLength) text += " & ";
      if (maxLength) text += `Max ${maxLength} `;
      text += "characters)";
    }
    return text;
  }, [minLength, maxLength, helperText]);

  const valid = !validateLength(value);
  return (
    <TextInput
      {...props}
      source="short_description"
      helperText={helper_text}
      validate={[validate, validateLength].flat()}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{
              color: valid ? undefined : "error.main",
              fontWeight: valid ? undefined : "bold",
            }}
          >
            <div>{value?.length || 0}</div>
          </InputAdornment>
        ),
      }}
    />
  );
};
