import {
  useBlockNoteEditor,
  useComponentsContext,
  useDictionary,
  useSelectedBlocks,
} from "@blocknote/react";
import { useEffect, useState } from "react";
import { RiImageEditFill } from "react-icons/ri";
import { block_note_schema } from "../BlockInput";

export const EmbedToolbarbuttons = () => {
  const dict = useDictionary();
  const Components = useComponentsContext()!;

  const editor = useBlockNoteEditor(block_note_schema);

  const selectedBlocks = useSelectedBlocks(editor);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(false);
  }, [selectedBlocks]);

  const block = selectedBlocks.length === 1 ? selectedBlocks[0] : undefined;
  if (
    block === undefined ||
    block?.type !== "embed" ||
    !editor.isEditable ||
    !block.props.url
  ) {
    return null;
  }
  const label =
    dict.formatting_toolbar.file_replace.tooltip[block.type] || "Replace Url";
  return (
    <>
      <Components.FormattingToolbar.Button
        className={"bn-button"}
        onClick={() => editor.updateBlock(block, { props: { url: undefined } })}
        isSelected={isOpen}
        mainTooltip={label}
        label={label}
        icon={<RiImageEditFill />}
      />
    </>
  );
};
