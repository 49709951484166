import { GlobeHemisphereWest } from "@phosphor-icons/react";
import { ItinResourceProps } from "../types";
import { PlatformAreaList } from "./PlatformAreaList";
import { PlatformAreaEdit } from "./PlatformAreaEdit";
import { PlatformAreaCreate } from "./PlatformAreaCreate";

export const PlatformAreaResource: ItinResourceProps = {
  name: "platform_areas",
  list: PlatformAreaList,
  edit: PlatformAreaEdit,
  create: PlatformAreaCreate,
  icon: <GlobeHemisphereWest />,
  recordRepresentation: ({ name, area_name }) => name || area_name,
  options: {
    // menu_group: "places", //Temporarily removed from menu
    label: "Areas",
  },
};
