import { Edit } from "react-admin";
import { HelpTopicForm } from "./HelpTopicForm";

export const HelpTopicEdit = () => {
  return (
    <Edit sx={{ "& .MuiCardContent-root": { width: "auto" } }} actions={null}>
      <HelpTopicForm />
    </Edit>
  );
};
