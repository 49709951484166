import { Box, BoxProps, styled } from "@mui/material";
import { ImageThumbnails, Media } from "~/resources/media/types";
import { MediaForm } from "~/resources/media/MediaEdit";
import { EditDialog } from "@react-admin/ra-form-layout";
import { useState } from "react";
import { SolidIconButton } from "../buttons";
import { ResourceContextProvider, useTranslate } from "react-admin";
import { Minus, PencilSimple } from "@phosphor-icons/react";

type Props = Omit<BoxProps, "onClick"> & {
  media: Media;
  onClick: (id: number) => void;
  onRemove?: () => void;
};
export const MediaCard = ({
  media,
  onClick,
  onRemove,
  children,
  ...props
}: Props) => {
  const thumbnail_url = getThumbnailUrl(media);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const translate = useTranslate();

  return (
    <>
      <StyledMediaCard
        {...props}
        loaded={(!loading).toString()}
        error={error.toString()}
        onClick={() => onClick(media.id)}
      >
        <Box className={MediaCardClasses.buttons}>
          {onRemove && (
            <SolidIconButton
              onClick={(e) => {
                e.stopPropagation();
                onRemove();
              }}
              aria-label={translate("ra.action.remove")}
              title={translate("ra.action.remove")}
              color="error"
            >
              <Minus />
            </SolidIconButton>
          )}
          <SolidIconButton
            onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
            }}
            aria-label={translate("ra.action.edit")}
            title={translate("ra.action.edit")}
            color="primary"
          >
            <PencilSimple />
          </SolidIconButton>
        </Box>
        <img
          className={MediaCardClasses.image}
          src={thumbnail_url}
          alt={media.caption}
          height={"100%"}
          width={"100%"}
          onLoad={() => {
            setLoading(false);
          }}
          onError={() => {
            setLoading(false);
            setError(true);
          }}
        />
        {children}
      </StyledMediaCard>
      <ResourceContextProvider value="media">
        <EditDialog
          fullScreen
          isOpen={open}
          close={() => setOpen(false)}
          open={() => setOpen(true)}
          resource="media"
          // @ts-expect-error record is an accepted prop
          record={media}
        >
          <MediaForm />
        </EditDialog>
      </ResourceContextProvider>
    </>
  );
};

const getThumbnailUrl = <T extends Media>(
  media: T,
  size: keyof ImageThumbnails = "small"
) => {
  switch (media.type) {
    case "Photo":
      return media.urls?.[size]?.url;
    case "Video":
      return media.urls?.thumbnails?.[size]?.url;
    default:
      return "";
  }
};

const PREFIX = "MediaCard";
const MediaCardClasses = {
  imageContainer: `${PREFIX}-imageContainer`,
  imageWrapper: `${PREFIX}-imageWrapper`,
  image: `${PREFIX}-image`,
  buttons: `${PREFIX}-buttons`,
  audio: `${PREFIX}-audio`,
};

const StyledMediaCard = styled(Box, {
  name: PREFIX,
  slot: "Root",
})<BoxProps & { loaded: string; error: string }>(({ theme, loaded, error }) => {
  const hasError = error === "true";
  const isLoaded = loaded === "true";
  return {
    cursor: "pointer",
    position: "relative",
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // width: "100%",
    [`&:hover .${MediaCardClasses.buttons}`]: {
      opacity: 1,
    },

    [`& .${MediaCardClasses.image}`]: {
      display: hasError ? "none" : "block",
      opacity: isLoaded ? 1 : 0,
      borderRadius: 4,
      objectFit: "cover",
      // width: "100%",
      // maxHeight: height ? `${height}px` : "100%",
      // maxWidth: width ? `${width}px` : "100%",
    },
    [`& .${MediaCardClasses.audio}`]: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
    },
    [`& .${MediaCardClasses.buttons}`]: {
      position: "absolute",
      display: "flex",
      gap: 10,
      right: 10,
      top: 10,
      opacity: 0,
      transition: theme.transitions.create(["opacity"], {
        duration: theme.transitions.duration.short,
      }),
    },
  };
});
