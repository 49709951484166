import { get, snakeCase } from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useHasPlatformExtension } from "../HasPlatformExtension";
import { OptionsInputs } from "./OptionsInputs";

//expects extension_name to confirm usage, optional activate_extension_source for a header, and fields to show, will show all extension fields if none passed
export const RecordExtensionInputs = ({
  extension_name,
  section_source,
  activate_extension_source,
  visible_fields,
  ...props
}) => {
  const { watch } = useFormContext();
  const hasExtension = useHasPlatformExtension();
  const extension_name_source = snakeCase(extension_name);
  const extension_data_source = `extension_data.${extension_name_source}`;
  const full_section_source = [extension_data_source, section_source]
    .filter(Boolean)
    .join(".");
  const full_activate_extension_source = `${full_section_source}.${activate_extension_source}`;

  const activate_feature_field_value =
    activate_extension_source && watch(full_activate_extension_source);

  const filterFields = useCallback(
    ([field], source) => {
      // if it's not the extension object, or a child of the extension object
      if (
        field !== extension_name_source &&
        !source.startsWith(extension_data_source)
      )
        return false;

      //show if it's activate field, or the extension object
      if (full_activate_extension_source.split(".").includes(field))
        return true;
      // Field is included if there is no filter fields array of the array includes the field name
      const isFieldIncluded = !visible_fields || visible_fields.includes(field);

      // Extension is activated if there is no activate_extension_source or if the activate_extension_source is true
      const isExtensionActivated =
        !activate_extension_source || !!activate_feature_field_value;

      if (isExtensionActivated && isFieldIncluded) return true;

      //no activate field
      if (!activate_extension_source && isFieldIncluded) return true;
      //otherwise don't show
      return false;
    },
    [activate_feature_field_value]
  );

  const schemaRef = useToggleActivatedField(
    activate_feature_field_value,
    activate_extension_source,
    extension_name_source,
    section_source
  );
  //Confirm we have the extension
  if (!hasExtension(extension_name)) return false;

  return (
    <OptionsInputs
      source="extension_data"
      gridItemProps={(config, source) => {
        if (source === full_activate_extension_source) return { xs: 12 };
      }}
      filter={filterFields}
      // label={(field) => {
      //   if (field === extension_name_source && section_source) return false;
      // }}
      schemaRef={schemaRef}
      {...props}
    />
  );
};

// Clears the values of all subfields when the active_extension_source is toggled off
const useToggleActivatedField = (
  show,
  activate_extension_source,
  extension_name_source,
  section_source
) => {
  const isInitial = useRef(true);
  const schemaRef = useRef();
  const { setValue } = useFormContext();

  useEffect(() => {
    // Clears a field or loops through subfields to clear them.
    const clearField = (key) => {
      // Get the type and properties of the field
      const { type, properties } = get(schemaRef.current, key) || {};
      // If the field is an object, loop through the properties and clear them
      if (type === "object") {
        Object.keys(properties).forEach((field) => {
          clearField(`${key}.properties.${field}`);
        });
        // else clear the field if its not the activate field
      } else if (key.split(".").pop() !== activate_extension_source) {
        // Remove the .properties from the key to match the form data structure.
        const formKey = `extension_data.${key.replaceAll(".properties", "")}`;
        setValue(formKey, null);
      }
    };
    // skip the initial render and if there is no activate_extension_source
    if (!isInitial.current && activate_extension_source && show === false) {
      //schema keys are separated by a .properties key for objects
      const key = [
        extension_name_source,
        section_source && "properties",
        section_source,
      ]
        .filter(Boolean)
        .join(".");
      clearField(key);
    }
    isInitial.current = false;
  }, [show, activate_extension_source, extension_name_source, section_source]);
  return schemaRef;
};
