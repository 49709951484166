import { PauseCircleFilled, PlayCircleFilled } from "@mui/icons-material";
import { Box, LinearProgress, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";

export const AudioThumbnail = ({ src, onLoad, ...props }) => {
  const [playingAudio, setPlayingAudio] = useState(false);
  const durationRef = useRef(0);
  const [progress, setProgress] = useState(0);
  const audioRef = useRef(new Audio(src));
  const intervalRef = useRef();
  useEffect(() => {
    const audio = audioRef.current;
    const handleLoad = () => {
      onLoad?.();
      durationRef.current = audio.duration;
    };
    audio.addEventListener("loadeddata", handleLoad);

    const watchProgress = () => {
      intervalRef.current = setInterval(() => {
        console.log("intervaling");
        setProgress((audio.currentTime / durationRef.current) * 100);
      }, 500);
    };
    const stopWatchProgress = () => clearInterval(intervalRef.current);
    audio.addEventListener("play", watchProgress);
    audio.addEventListener("pause", stopWatchProgress);
    return () => {
      audio.removeEventListener("play", watchProgress);
      audio.removeEventListener("pause", stopWatchProgress);
      audio.removeEventListener("loadeddata", handleLoad);
      clearInterval(intervalRef.current);
    };
  }, [onLoad]);
  const Icon = playingAudio ? PauseCircleFilled : PlayCircleFilled;
  return (
    <Tooltip title={`Click To ${playingAudio ? "Pause" : "Play"}`}>
      <Box
        onClick={(e) => {
          e.stopPropagation();
          playingAudio ? audioRef.current.pause() : audioRef.current.play();
          setPlayingAudio((s) => !s);
        }}
        border="solid"
        borderColor="primary.main"
        padding={1.5}
        borderRadius={2}
        color="primary.main"
        height="100%"
        boxSizing="border-box"
        // {...props}
      >
        <Icon sx={{ height: "100%", width: "100%" }} />
        <LinearProgress value={progress} variant="determinate" />
      </Box>
    </Tooltip>
  );
};
