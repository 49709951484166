import { ReferenceArrayField, SimpleList, useRecordContext } from "react-admin";
import { Box, Tooltip, Typography } from "@mui/material";
import {
  ListBullets,
  MapTrifold,
  PlusCircle,
  Signpost,
  Star,
  TreeStructure,
} from "@phosphor-icons/react";
import { useCurrentPlatform } from "~/context";

export const RegionChallenges = () => {
  const { getTerm } = useCurrentPlatform();
  const challengeTerm = getTerm("challenge", {
    plural: true,
    capitalize: false,
  });

  // TODO EmptyText not working, says "Not results found."
  return (
    <ReferenceArrayField
      reference="challenges"
      source="all_challenge_ids"
      emptyText={`No associated ${challengeTerm}.`}
    >
      <SimpleList sx={{ padding: 0 }} primaryText={(r) => <ChallengeField />} />
    </ReferenceArrayField>
  );
};
const Empty = () => {
  const { getTerm } = useCurrentPlatform();
  const challengeTerm = getTerm("challenge", {
    plural: true,
    capitalize: false,
  });
  return <Typography>{`No associated ${challengeTerm}.`}</Typography>;
};

const ChallengeField = () => {
  const record = useRecordContext();
  const { getTerm } = useCurrentPlatform();
  const targetTypeTerm = getTerm(record.target_type.toLowerCase(), {
    plural: false,
    capitalize: true,
  });
  const challengeTerm = getTerm("challenge", {
    plural: false,
    capitalize: false,
  });

  let Icon = Star;
  switch (record.target_type) {
    case "Itinerary":
      Icon = Signpost;
      break;
    case "Region":
      Icon = MapTrifold;
      break;
    case "Platform":
      Icon = TreeStructure;
      break;
    case "Stop":
      Icon = PlusCircle;
      break;
    case "Listing":
      Icon = ListBullets;
      break;
  }

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      {`${record.name}`}
      <Tooltip title={`${targetTypeTerm} ${challengeTerm}`}>
        <Icon />
      </Tooltip>
    </Box>
  );
};
