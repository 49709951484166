type Props = {
  url: string | false;
  fullWidth: boolean;
  width: number;
};

export const YoutubeIframe = ({ url, fullWidth, width }: Props) => {
  if (!url)
    return (
      <div
        style={{
          width: fullWidth ? "100%" : width,
          aspectRatio: "16/9",
          backgroundColor: "#f4f4f4",
          border: "1px solid red",
        }}
      />
    );
  const videoId = youtubeUrlToId(url);

  return (
    <iframe
      src={videoId ? `https://www.youtube.com/embed/${videoId}` : undefined}
      style={{
        width: fullWidth ? "100%" : width,
        aspectRatio: "16/9",
      }}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    />
  );
};

/** From https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
    Matches various link formats
    http://www.youtube.com/watch?v=0zM3nApSvMg&feature=feedrec_grec_index
    http://www.youtube.com/user/IngridMichaelsonVEVO#p/a/u/1/QdK8U-VIH_o
    http://www.youtube.com/v/0zM3nApSvMg?fs=1&amp;hl=en_US&amp;rel=0
    http://www.youtube.com/watch?v=0zM3nApSvMg#t=0m10s
    http://www.youtube.com/embed/0zM3nApSvMg?rel=0
    http://www.youtube.com/watch?v=0zM3nApSvMg
    http://youtu.be/0zM3nApSvMg
*/

const youtubeUrlToId = (url: string) => {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7]?.length == 11 ? match[7] : false;
};
