import {
  BlockFromConfigNoChildren,
  InlineContentSchema,
  StyleSchema,
} from "@blocknote/core";
import { useBlockNoteEditor, useComponentsContext } from "@blocknote/react";
import { Flex, Modal, TextInput as MTextInput, Text } from "@mantine/core";
import { useCallback, useState } from "react";
import { block_note_schema } from "../BlockInput";
import { EMBED_CONFIG } from "./config";

export const EmbedModal = <
  T extends BlockFromConfigNoChildren<
    typeof EMBED_CONFIG,
    InlineContentSchema,
    StyleSchema
  >
>({
  block,
  opened,
  onClose,
}: {
  block: T;
  opened: boolean;
  onClose: () => void;
}) => {
  const editor = useBlockNoteEditor(block_note_schema);
  const Components = useComponentsContext()!;
  const [currentUrl, setCurrentUrl] = useState("");
  const [error, setError] = useState("");
  const handleUpdateBlock = useCallback(() => {
    editor.updateBlock(block, {
      props: { url: currentUrl },
    });
    onClose();
  }, [editor, block, currentUrl]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Set Youtube Url"
      centered
      size={"md"}
    >
      <Flex align="center" direction="column" gap="8px" w="100%">
        <MTextInput
          w="100%"
          className="bn-text-input"
          placeholder="https://youtu.be/jNQXAC9IVRw?si=HcSQ18gHCO-z7UYc"
          value={currentUrl}
          onChange={(e) => {
            setError("");
            setCurrentUrl(e.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              handleUpdateBlock();
            }
          }}
          error={error}
        />
        {error && <Text>{error}</Text>}
        <Components.FilePanel.Button
          className={"bn-button"}
          onClick={() => handleUpdateBlock()}
        >
          Add Url
        </Components.FilePanel.Button>
      </Flex>
    </Modal>
  );
};
