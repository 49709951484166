import { Grid, Typography } from "@mui/material";
import { ReferenceManyToManyField } from "@react-admin/ra-relationships";
import { chain, map } from "lodash";
import pluralize from "pluralize";
import { useMemo } from "react";
import {
  LinearProgress,
  RecordContextProvider,
  useListContext,
} from "react-admin";
import { useTargetType } from "~/hooks";
import { ChipField } from "./ChipField";

export const TagsField = ({ children, ...props }) => {
  const { data, total, isLoading } = useListContext(props);
  const records = useMemo(
    () =>
      chain(data)
        .groupBy("category")
        .map((tags, category) => ({
          category,
          names: map(tags, "name").join(", "),
        }))
        .orderBy(["category", "desc"])
        .value(),
    [data]
  );
  if (isLoading) return <LinearProgress />;
  if (records == null || records.length === 0 || total === 0) {
    return null;
  }
  if (records.length >= 5) {
    return (
      <RecordContextProvider
        value={{
          category: pluralize("tag", total, true),
          names: records.map(({ category, names }) => (
            <Typography key={category}>{`${category}: ${names}`}</Typography>
          )),
        }}
      >
        {children}
      </RecordContextProvider>
    );
  }

  return (
    <Grid container spacing={0.5} maxWidth="250px">
      {records.map((record) => (
        <Grid key={record.category} item xs={"auto"}>
          <RecordContextProvider value={record}>
            {children}
          </RecordContextProvider>
        </Grid>
      ))}
    </Grid>
  );
};

export const TaggingsField = (props) => {
  const target_type = useTargetType(props.resource);
  return (
    <ReferenceManyToManyField
      reference="tags"
      through="taggings"
      using="target_id,tag_id"
      filter={{ target_type }}
    >
      <TagsField>
        <ChipField source="category" tooltipSource="names" size="small" />
      </TagsField>
    </ReferenceManyToManyField>
  );
};
