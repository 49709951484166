import {
  CircularProgress,
  CircularProgressProps,
  Box,
  Typography,
  TypographyProps,
} from "@mui/material";
import { SystemCssProperties } from "@mui/system";
import { ReactNode } from "react";
type Props = Omit<CircularProgressProps, "color"> & {
  minValue?: number;
  maxValue?: number;
  hideBackground?: boolean;
  color?: SystemCssProperties["color"] | "gradient";
  backgroundColor?: SystemCssProperties["color"];
  rotate?: number;
  children?: ReactNode | false;
  textProps?: TypographyProps;
};

export const CircularProgressWithLabel = ({
  value,
  minValue = 0,
  maxValue = 1,
  hideBackground,
  color,
  backgroundColor = "grey.300",
  rotate = 0.5,
  children,
  textProps,
  ...props
}: Props) => {
  const progress = value && ((value - minValue) * 100) / maxValue - minValue;
  const child = children || (progress !== undefined && Math.round(progress));
  const hue = typeof progress === "number" ? progress * 1.2 : 0;
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        fontSize: props.size || 40,
      }}
    >
      {!hideBackground && (
        <CircularProgress
          thickness={6}
          {...props}
          variant="determinate"
          value={100}
          sx={{
            ...props.sx,
            position: "absolute",
            color: backgroundColor,
          }}
        />
      )}
      <CircularProgress
        variant="determinate"
        thickness={6}
        {...props}
        value={progress}
        sx={{
          ...props.sx,
          color: color === "gradient" ? `hsl(${hue}, 100%, 50%)` : color,
          "& svg": { transform: `rotate(${rotate}turn)` },
        }}
      />
      {children !== false && typeof child !== "boolean" && (
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            fontSize="0.35em"
            {...textProps}
          >
            {child}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
