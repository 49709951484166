import { Box, Stack, CardMedia, Grid, Typography } from "@mui/material";
import { useRecordContext } from "react-admin";
import ReactHtmlParser from "react-html-parser";
import { useRandomIllustration } from "~/images/illustrations";
import { ListingIcons } from "./Icons";
import { get } from "lodash";

export const ListingHero = (props) => {
  const record = useRecordContext(props);
  const Illustration = useRandomIllustration();
  const url = get(record, "hero.url", record?.ingest_hero_url);

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <CardMedia
          component={!url ? Illustration : "img"}
          alt=""
          image={url}
          sx={{
            borderRadius: 3,
            width: "100%",
            height: !url ? "260px" : "100%",
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Stack gap={2}>
          <Box>
            <Typography sx={{ fontWeight: "bold", fontSize: "1.2em", mb: 1 }}>
              {record.location_name}
            </Typography>
            <Typography sx={{ fontWeight: "500" }}>
              {record.subtitle}
            </Typography>
          </Box>
          <Box>
            <Typography>{record.street_address}</Typography>
            <Typography>{`${record.city}, ${record.province}`}</Typography>
            <Typography>
              {record.postal_code}, {record.country}
            </Typography>
          </Box>
          <>{ReactHtmlParser(record.short_description)}</>
          <ListingIcons record={record} />
        </Stack>
      </Grid>
    </Grid>
  );
};
