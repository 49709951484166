import { useCanAccess } from "@react-admin/ra-rbac";
import { useEffect } from "react";
import { useEditContext, useRedirect, useResourceContext } from "react-admin";

export const validateEditorRedirect = (code) => {
  const { record } = useEditContext();
  const resource = useResourceContext();
  //this is in place to redirect users to show if they don't have access to edit
  //it covers a bug in RBAC Datagrid that doesn't handle the permissions with multiple conditions very well.

  const redirect = useRedirect();
  const { canAccess: canEdit } = useCanAccess({
    record,
    resource: resource,
    action: "edit",
  });

  const { canAccess: canShow } = useCanAccess({
    record,
    resource: resource,
    action: "show",
  });
  useEffect(() => {
    if (!canEdit && record) {
      redirect("show", resource, record.id);
    }
  }, [canEdit, record, redirect]);
  return canEdit;
};
