import { createReactBlockSpec } from "@blocknote/react";
import { Box } from "@mui/material";
import { Menu, TextInput } from "@mantine/core";
import { useCurrentPlatform } from "~/context";

type Props = { label?: string; url?: string };

export const BlockNoteButtonContent = ({ label, url }: Props) => {
  const { currentPlatform } = useCurrentPlatform() as { currentPlatform: any };

  const cms_extension = currentPlatform.platform_extensions.find(
    (pe: { type: string }) => pe.type === "CmsExtension"
  );
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <a
        href={url}
        target="_blank"
        style={{
          backgroundColor:
            cms_extension?.options?.theme?.button_color || "#707070",
          borderRadius: "5px",
          padding: "10px 30px",
          margin: "0 auto",
          display: "inline-block",
          color: "#ffffff",
          textDecoration: "none",
        }}
      >
        {label}
      </a>
    </div>
  );
};

export const BlockNoteButton = createReactBlockSpec(
  {
    type: "button",
    content: "none",
    propSchema: {
      label: { default: "Button" },
      url: { default: "https://tourismo.co" },
    },
  },
  {
    toExternalHTML: ({ block, editor, contentRef }) => {
      const { label, url } = block.props;
      return <BlockNoteButtonContent label={label} url={url} />;
    },
    render: ({ block, editor, contentRef }) => {
      const { label, url } = block.props;
      return (
        <>
          <Menu
            withinPortal={false}
            zIndex={999999}
            trigger="hover"
            openDelay={100}
            closeDelay={400}
            closeOnItemClick={false}
          >
            <Menu.Target>
              <Box>
                <BlockNoteButtonContent label={label} url={url} />
              </Box>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item component="span">
                <TextInput
                  value={label}
                  onChange={(event) =>
                    editor.updateBlock(block, {
                      type: "button",
                      props: {
                        label: event.target.value,
                      },
                    })
                  }
                />
                <TextInput
                  value={url}
                  onChange={(event) =>
                    editor.updateBlock(block, {
                      type: "button",
                      props: {
                        url: event.target.value,
                      },
                    })
                  }
                />
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </>
      );
    },
  }
);
