import { useState } from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  ReferenceManyCount,
  ReferenceManyField,
  TextField,
} from "react-admin";
import {
  EnumSelectField,
  FeaturedField,
  FormattedTextField,
  PolymorphicReferenceField,
  StatsSummaryField,
} from "~/components/fields";
import { ToggleList } from "~/components/lists";
import { useCurrentPlatform } from "~/context";
import { useChallengeTargets } from "~/hooks";
import { TARGET_TYPES } from "./constants";

const useFilters = () => {
  const [targetType, setTargetType] = useState("");
  const { data: targets = [] } = useChallengeTargets(targetType);
  const { getTerm } = useCurrentPlatform();
  const termTargetType = TARGET_TYPES.map((tt) => {
    return {
      id: tt.id,
      name: getTerm(tt.name, { capitalize: true }),
    };
  });
  return [
    <AutocompleteInput
      source="target_type"
      choices={termTargetType}
      onChange={setTargetType}
      emptyText="By Target Type"
      label="By Target Type"
      width="50%"
    />,
    <AutocompleteInput
      source="target_id"
      choices={targets}
      emptyText="By Target"
      label="By Target"
      noOptionsText={
        targetType ? "No Targets Found" : "Select Target Type First"
      }
    />,
  ];
};

export const ChallengeList = () => {
  const filters = useFilters();
  return <ToggleList filters={filters} modes={MODES} />;
};

const ChallengeData = () => {
  const { getTerm } = useCurrentPlatform();
  const rewardTerm = getTerm("reward", { plural: true, capitalize: true });
  return (
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <FeaturedField source="featured" />
      <TextField source="name" />
      <FormattedTextField source="type" />
      <PolymorphicReferenceField
        source="target_id"
        reference_source="target_type"
      />
      <EnumSelectField source="status" />
      <DateField source="start_date" />
      <DateField source="end_date" />
      <ReferenceManyCount
        reference="tasks"
        target="challenge_id"
        label="Tasks"
      />
      <ReferenceManyCount
        reference="rewards"
        target="challenge_id"
        label="Rewards"
      />
      <StatsSummaryField label="30 Days" />
    </Datagrid>
  );
};

const MODES = [{ name: "List", view: <ChallengeData /> }];
