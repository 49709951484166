import { Stack } from "@mui/material";
import {
  Labeled,
  NumberInput,
  SimpleForm,
  TextInput,
  useListContext,
} from "react-admin";
import ItinMarkdownInput from "~/components/ItinMarkdownInput";
import { SingleImageInput } from "~/components/images/SingleImageInput";

export const ReelLegForm = ({ itinerary, ...props }) => {
  const { total } = useListContext();
  return (
    <SimpleForm
      defaultValues={{
        itinerary_id: itinerary?.id,
        order_on_itin: total + 1,
      }}
      {...props}
    >
      <Stack direction="row" width="97%" gap={3}>
        <TextInput source="title" fullWidth required />
        <NumberInput
          source="order_on_itin"
          label="Order"
          display={"none"}
          fullWidth
        />
      </Stack>
    </SimpleForm>
  );
};
