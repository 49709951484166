import { insertOrUpdateBlock } from "@blocknote/core";
import { createReactBlockSpec } from "@blocknote/react";
import { Button } from "@mantine/core";
import { YoutubeLogo } from "@phosphor-icons/react";
import { useBoolean } from "usehooks-ts";
import { BlockEditorSchema } from "../BlockInput";
import { EMBED_CONFIG } from "./config";
import { EmbedModal } from "./EmbedModal";
import { EmbedPreview } from "./EmbedPreview";
import { InstagramIframe, TikTokIframe, YoutubeIframe } from "./iframes";

export const BlockNoteEmbed = createReactBlockSpec(EMBED_CONFIG, {
  toExternalHTML: ({ block }) => {
    const { url, previewWidth, textAlignment, fullWidth } = block.props;
    let component;
    if (url.includes("instagram")) {
      component = <InstagramIframe url={url} />;
    } else if (url.includes("youtube")) {
      component = (
        <YoutubeIframe url={url} width={previewWidth} fullWidth={fullWidth} />
      );
    } else if (url.includes("tiktok")) {
      component = <TikTokIframe url={url} />;
    }
    return (
      <div className="embed-block" style={{ textAlign: textAlignment }}>
        {component}
      </div>
    );
  },
  render: ({ block, editor }) => {
    const {
      props: { url },
    } = block;
    const { value, setTrue, setFalse } = useBoolean(!url);

    if (url) return <EmbedPreview block={block} editor={editor} />;
    return (
      <>
        <Button
          variant="filled"
          onMouseDown={(e) => e.preventDefault()}
          onClick={setTrue}
        >
          Add Social Media Url
        </Button>
        <EmbedModal block={block} opened={value} onClose={setFalse} />
      </>
    );
  },
});

export const insertBlockNoteEmbed = (editor: BlockEditorSchema) => ({
  title: "Social Media Embed",
  onItemClick: () => {
    insertOrUpdateBlock(editor, { type: "embed" });
  },
  group: "Media",
  icon: <YoutubeLogo />,
  subtext: "Embed a social media video from Youtube, TikTok, or Instagram.",
});
