import { DateTime } from "luxon";

export const timeAgo = (datetime) => {
  const { days, hours, months, minutes } = DateTime.now().diff(
    DateTime.fromISO(datetime),
    ["days", "months", "hours", "minutes"]
  );
  if (months > 0) return `${months} Months, ${days} Days`;
  if (days > 0) return `${days} Days, ${hours} Hours`;
  if (hours > 0) return `${hours} Hours, ${minutes} Mins`;
  if (minutes > 0) return `${Math.round(minutes)} Mins`;
};

export const timeAgoMinutes = (datetime) => {
  const { days, hours, months, minutes } = DateTime.now().diff(
    DateTime.fromISO(datetime),
    ["days", "months", "hours", "minutes"]
  );
  return Math.round(minutes);
};
