import { Chip, Tooltip, Typography, styled } from "@mui/material";
import { get } from "lodash";
import {
  sanitizeFieldRestProps,
  useGetRecordRepresentation,
  useRecordContext,
  useResourceContext,
  useTranslate,
} from "react-admin";
import clsx from "clsx";
import { useEffect } from "react";
import { XCircle } from "@phosphor-icons/react";

export const ChipField = ({ onValue, ...props }) => {
  const { className, source, emptyText, tooltipSource, ...rest } = props;
  const record = useRecordContext(props);
  const title = props.title || get(record, tooltipSource);
  const resource = useResourceContext();
  const getRecordRepresentation = useGetRecordRepresentation(resource);
  const defaultValue = getRecordRepresentation(record);
  const value = props.value || (source ? get(record, source) : defaultValue);
  useEffect(() => {
    onValue?.(value);
  }, [value, onValue]);
  const translate = useTranslate();

  if (value == null && emptyText) {
    return (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {emptyText && translate(emptyText, { _: emptyText })}
      </Typography>
    );
  }
  if (!value) return null;
  return (
    <Tooltip title={title}>
      <StyledChip
        deleteIcon={<XCircle size={18} />}
        className={clsx(ChipFieldClasses.chip, className)}
        label={value}
        {...sanitizeFieldRestProps(rest)}
      />
    </Tooltip>
  );
};

const PREFIX = "RaChipField";

const ChipFieldClasses = {
  chip: `${PREFIX}-chip`,
};

const StyledChip = styled(Chip, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})({
  [`&.${ChipFieldClasses.chip}`]: { cursor: "inherit" },
});
