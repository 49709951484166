import { Button, Grid, Typography } from "@mui/material";
import {
  List,
  Loading,
  RecordContextProvider,
  ResourceContextProvider,
  useGetList,
  useStore,
  WithRecord,
} from "react-admin";
import { Empty } from "~/components";
import { HasPlatformExtension } from "~/components/HasPlatformExtension";
import { ImageCard } from "~/components/lists/ImageCard";
import { ImageGrid } from "~/components/lists/ImageGrid";
import { useEditorPlatformUsers } from "~/hooks";
import { UpcomingEvents } from "../events/UpcomingEvents";
import { listingCardData } from "../listings/ListingList";
import { platformCardData } from "../platforms/PlatformList";

export const CollaboratorHome = ({ platform_user }) => {
  const { data: platform_users = [], isLoading: platformUsersIsLoading } =
    useEditorPlatformUsers();

  //Get Platforms that are open for collab / request, and I'm not already a member of.
  const { data: open_platforms = [], isLoading: isLoadingPlatforms } =
    useGetList(
      "platforms",
      {
        filter: {
          collaboration_status: [1, 2],
          not: { id: platform_users.map((p) => p.platform_id) },
        },
        sort: { field: "id", order: "random" },
        pagination: { page: 1, perPage: 3 },
      },
      { enabled: !platformUsersIsLoading }
    );

  const [hideListings, setHideListings] = useStore(
    `collaborator.dashboard.hide_listings.${platform_user.id}`,
    false
  );

  if (isLoadingPlatforms) return <Loading />;

  return (
    <Grid container spacing={2} padding="32px">
      <Grid item xs={12}>
        {/* <Typography variant="h3">
          Welcome to the collaboration club...
        </Typography>
        <Typography>
          This is exciting.... now that you're here, let's get started!
        </Typography> */}
      </Grid>
      {!hideListings && (
        <Grid item xs={12}>
          <List
            resource="listings"
            exporter={false}
            pagination={false}
            perPage={120}
            actions={null}
            sx={{
              "& .RaList-content": {
                backgroundColor: "initial",
                "& > .MuiBox-root": {
                  padding: 0,
                },
              },
            }}
            empty={
              <Empty
                resource="listings"
                title="Hey there, it seems you don't have any listings..."
                subtitle="Let's make some magic! Click below to get started"
                buttonLabel="Create your first listing"
              >
                <Button
                  variant="outlined"
                  onClick={() => setHideListings(true)}
                >
                  I'm not going to create listings, please hide this.
                </Button>
              </Empty>
            }
          >
            <ImageGrid cardData={listingCardData}>
              <WithRecord
                render={(item) => (
                  <ImageCard
                    cardData={listingCardData}
                    sparkles={item.owner_id != platform_user.user_id}
                  />
                )}
              />
            </ImageGrid>
          </List>
        </Grid>
      )}
      <Grid item xs={12}>
        <HasPlatformExtension platform_extension="Events">
          <UpcomingEvents />
        </HasPlatformExtension>
      </Grid>
      {/* <Grid item xs={12}>
        <Typography sx={{ marginTop: "20px" }} variant="h3">
          Join new networks!
        </Typography>
        Reach new audiences and get your offers out there, select from a wide
        range of destination marketing networks to find partnerships and
        collaboration opportunities with industry and sectors in your area
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          sx={{ "& .ItinImageCard-image": { objectFit: "contain" } }}
          gap={2}
        >
          <ResourceContextProvider value="platforms">
            {open_platforms.map((item) => (
              <RecordContextProvider key={item.id} value={item}>
                <ImageCard xs={3} item cardData={platformCardData} />
              </RecordContextProvider>
            ))}
          </ResourceContextProvider>
        </Grid>
      </Grid> */}
    </Grid>
  );
};

const EmptyListings = ({ onClick }) => {
  return <></>;
};
