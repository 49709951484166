import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import dayjs, { Dayjs, OpUnitType } from "dayjs";
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNotify } from "react-admin";
import { sum, mean, round, groupBy, orderBy, set } from "lodash";
import { MediumSourceType } from "../helpers";
import { VerticalSlider } from "../shared_components";
import { blue } from "~/theme/colors";
import SortableHeaderCell from "./SortableHeaderCell";
import { ItinLoading } from "~/components";
import StarredTableCell from "./StarredTableCell";

type MediumSourceTableProps = {
  rows: MediumSourceType[];
  period: string;
  earliestDate: Dayjs;
  latestDate: Dayjs;
  activeRows: string[];
  setActiveRows: Dispatch<SetStateAction<string[]>>;
  startDate: Dayjs;
  setStartDate: Dispatch<SetStateAction<Dayjs>>;
  endDate: Dayjs;
  setEndDate: Dispatch<SetStateAction<Dayjs>>;
  setCsv: Dispatch<SetStateAction<any[]>>;
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

type dateFilterType = (
  data: MediumSourceType[],
  startDate: Dayjs,
  endDate: Dayjs,
  period: string
) => MediumSourceType[];
const dateFilter: dateFilterType = (
  data: MediumSourceType[],
  startDate: Dayjs,
  endDate: Dayjs,
  period: string
) => {
  return data.filter(
    (e: any) =>
      dayjs(e.date).isSameOrAfter(startDate, period as OpUnitType) &&
      dayjs(e.date).isSameOrBefore(endDate, period as OpUnitType)
  );
};

export const MediumSourceTable = ({
  rows,
  period,
  earliestDate,
  latestDate,
  activeRows,
  setActiveRows,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setCsv,
}: MediumSourceTableProps) => {
  const classes = useStyles();
  const notify = useNotify();

  const heightRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const [sortedColumn, setSortedColumn] = useState<string>("sessions");
  const [sortOrder, setSortOrder] = useState<"desc" | "asc">("desc");

  const [page, setPage] = useState<number>(0);
  const [dataReadied, setDataReadied] = useState<boolean>(false);

  const [filteredData, setFilteredData] = useState<MediumSourceType[]>(
    dateFilter(rows, startDate, endDate, period)
  );
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);

  const [totalSessions, setTotalSessions] = useState<number>(
    sum(filteredData.map((r) => r.sessions))
  );
  const [totalPageViews, setTotalPageViews] = useState<number>(
    sum(filteredData.map((r) => r.pageviews))
  );
  const [meanAvgSessionDuration, setMeanAvgSessionDuration] = useState<number>(
    mean(filteredData.map((r) => r.avgSessionDuration))
  );
  const [meanPageviewsPerSessions, setmeanPageviewsPerSessions] =
    useState<number>(mean(filteredData.map((r) => r.pageviewsPerSession)));

  useEffect(() => {
    const dateFilteredData = dateFilter(rows, startDate, endDate, period);
    const groupedData = Object.entries(
      groupBy(dateFilteredData, (item) => `"${item.medium}<>${item.source}"`)
    ).map(([key, value]) => {
      return {
        date: dayjs().toString(), // just a placeholder to stay ByPageType[]
        medium: key.split("<>")[0].replace('"', ""),
        source: key.split("<>")[1].replace('"', ""),
        sessions: sum(value.map((v) => v.sessions)),
        pageviews: sum(value.map((v) => v.pageviews)),
        avgSessionDuration: round(
          mean(value.map((v) => v.avgSessionDuration)),
          3
        ),
        pageviewsPerSession: round(
          mean(value.map((v) => v.pageviewsPerSession)),
          3
        ),
      };
    }) as MediumSourceType[];
    setFilteredData(orderBy(groupedData, [sortedColumn], [sortOrder]));
    setDataReadied(true);
  }, [rows.length, startDate, endDate, period, sortedColumn, sortOrder]);

  useEffect(() => {
    setCsv(filteredData);
  }, [filteredData]);

  const handleSort = (column: string) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("desc");
    }
  };
  useEffect(() => {
    setTotalSessions(sum(filteredData.map((r) => r.sessions)));
    setTotalPageViews(sum(filteredData.map((r) => r.pageviews)));
    setMeanAvgSessionDuration(
      mean(filteredData.map((r) => r.avgSessionDuration))
    );
    setmeanPageviewsPerSessions(
      mean(filteredData.map((r) => r.pageviewsPerSession))
    );
  }, [filteredData]);

  useEffect(() => {
    const handleResize = () => {
      heightRef.current && setHeight(heightRef.current.clientHeight || 0);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [filteredData, startDate, endDate, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rowClickHandler = (row_id: string) => {
    const rowAlreadyActive = activeRows.includes(row_id);
    if (activeRows.length == 0) {
      notify("Selected rows appear in charts below", { type: "success" });
    }
    if (rowAlreadyActive) {
      const validRows = activeRows.filter((r) => r != row_id);
      setActiveRows([...validRows]); // remove row
      return;
    }
    if (activeRows.length == 5) {
      notify(
        "Maximium 5 rows already selected, remove others to keep visualizing",
        { type: "error" }
      ); // limit for visualization
      return;
    }
    setActiveRows([...activeRows, row_id]); // add path
  };

  if (!rows || !dataReadied) return <ItinLoading isLarge />;
  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <Grid item xs={1.4}>
          <VerticalSlider
            height={height}
            period={period}
            earliestDate={earliestDate}
            latestDate={latestDate}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            rowsPerPage={rowsPerPage}
          />
        </Grid>
        <Grid item xs={10.6} sx={{ paddingLeft: 4 }}>
          <Card
            ref={heightRef}
            variant={"outlined"}
            sx={{
              display: "flex",
              borderRadius: "12px",
              backgroundColor: "primary.contrastText",
              cursor: "pointer",
              width: "100%",
              ":hover": {
                backgroundColor: "blue.50",
              },
            }}
          >
            <CardContent
              sx={{ width: "100%", display: "flex", flexDirection: "column" }}
            >
              <TableContainer sx={{ maxHeight: "60vh" }}>
                <Table
                  className={classes.table}
                  aria-label="simple table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} />
                      <TableCell align="right" colSpan={1}>
                        Total: {totalSessions}
                      </TableCell>
                      <TableCell align="right" colSpan={1}>
                        Total: {totalPageViews}
                      </TableCell>
                      <TableCell align="right" colSpan={1}>
                        Avg: {round(meanAvgSessionDuration || 0, 2)}
                      </TableCell>
                      <TableCell align="right" colSpan={1}>
                        Avg: {round(meanPageviewsPerSessions || 0, 2)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <SortableHeaderCell
                        label="Medium"
                        column="medium"
                        align="left"
                        handleSort={handleSort}
                        sortOrder={sortOrder}
                        sortedColumn={sortedColumn}
                      />
                      <SortableHeaderCell
                        label="Source"
                        column="source"
                        align="left"
                        colSpan={1}
                        handleSort={handleSort}
                        sortOrder={sortOrder}
                        sortedColumn={sortedColumn}
                      />
                      <SortableHeaderCell
                        label="Sessions"
                        column="sessions"
                        align="right"
                        handleSort={handleSort}
                        sortOrder={sortOrder}
                        sortedColumn={sortedColumn}
                      />
                      <SortableHeaderCell
                        label="Pageviews"
                        column="pageviews"
                        align="right"
                        handleSort={handleSort}
                        sortOrder={sortOrder}
                        sortedColumn={sortedColumn}
                      />
                      <SortableHeaderCell
                        label="Avg. Session (s)"
                        column="avgSessionDuration"
                        align="right"
                        handleSort={handleSort}
                        sortOrder={sortOrder}
                        sortedColumn={sortedColumn}
                      />
                      <SortableHeaderCell
                        label="Views Per Session"
                        column="pageviewsPerSession"
                        align="right"
                        handleSort={handleSort}
                        sortOrder={sortOrder}
                        sortedColumn={sortedColumn}
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, ii) => {
                        const row_id = row.medium + " " + row.source;
                        const isActive = activeRows.includes(row_id);
                        const totalSize = filteredData.length;
                        const indexInData = page * rowsPerPage + ii;
                        const isLastPage =
                          page * rowsPerPage + rowsPerPage >= totalSize;
                        const location =
                          page === 0
                            ? "first"
                            : isLastPage
                            ? "last"
                            : undefined;
                        return (
                          <TableRow
                            key={row_id}
                            sx={{
                              backgroundColor: isActive ? blue[200] : undefined,
                              "&:hover": { backgroundColor: blue[100] },
                              "&:active": { backgroundColor: blue[300] },
                            }}
                            onClick={() => {
                              rowClickHandler(row_id);
                            }}
                          >
                            <StarredTableCell
                              value={row.medium}
                              column="medium"
                              align="left"
                              colSpan={1}
                              sortOrder={sortOrder}
                              sortedColumn={sortedColumn}
                              index={indexInData}
                              tableSize={totalSize}
                            />
                            <StarredTableCell
                              value={row.source}
                              column="source"
                              align="left"
                              colSpan={1}
                              sortOrder={sortOrder}
                              sortedColumn={sortedColumn}
                              index={indexInData}
                              tableSize={totalSize}
                            />
                            <StarredTableCell
                              value={row.sessions}
                              column="sessions"
                              align="right"
                              colSpan={1}
                              sortOrder={sortOrder}
                              sortedColumn={sortedColumn}
                              index={indexInData}
                              tableSize={totalSize}
                              isStarrable
                              location={location}
                            />
                            <StarredTableCell
                              value={row.pageviews}
                              column="pageviews"
                              align="right"
                              colSpan={1}
                              sortOrder={sortOrder}
                              sortedColumn={sortedColumn}
                              index={indexInData}
                              tableSize={totalSize}
                              isStarrable
                              location={location}
                            />
                            <StarredTableCell
                              value={round(row.avgSessionDuration)}
                              column="avgSessionDuration"
                              align="right"
                              colSpan={1}
                              sortOrder={sortOrder}
                              sortedColumn={sortedColumn}
                              index={indexInData}
                              tableSize={totalSize}
                              isStarrable
                              location={location}
                            />
                            <StarredTableCell
                              value={round(row.pageviewsPerSession)}
                              column="pageviewsPerSession"
                              align="right"
                              colSpan={1}
                              sortOrder={sortOrder}
                              sortedColumn={sortedColumn}
                              index={ii}
                              tableSize={totalSize}
                              isStarrable
                              location={location}
                            />
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                showFirstButton
                showLastButton
                rowsPerPageOptions={[50, 100, 150, 200]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ paddingTop: 0, marginTop: 0 }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
