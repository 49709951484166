import { ItinResourceProps } from "../types";
import { Question } from "@phosphor-icons/react";
import { HelpTopicList } from "./HelpTopicList";
import { HelpTopicCreate } from "./HelpTopicCreate";
import { HelpTopicShow } from "./HelpTopicShow";
import { HelpTopicEdit } from "./HelpTopicEdit";

export const HelpTopicResource: ItinResourceProps = {
  name: "help_topics",
  list: HelpTopicList,
  show: HelpTopicShow,
  edit:  HelpTopicEdit,
  create:  HelpTopicCreate,
  icon: <Question />,
  recordRepresentation: (r) => r.name,
}
