import { Box, Grid } from "@mui/material";
import { RecentCheckIns, SimpleMapList } from "~/components";
import { ReferenceManyThroughMany } from "~/components/fields/ReferenceManyThroughMany";
import { GridCard } from "~/components/GridCard";
import HasPlatformExtension from "~/components/HasPlatformExtension";
import { MapDataProvider } from "~/components/maps/MapDataProvider";
import { useCurrentPlatform } from "~/context";
import { TagSidebar } from "~/resources/taggings";
import { SubscriptionOptions } from "~/stripe/SubscriptionOptions";
import { ItineraryStats } from "../ItineraryStats";
import { ItineraryHero } from "./ItineraryHero";

export const ItineraryOverview = () => {
  const { getTerm } = useCurrentPlatform();
  const itineraryTerm = getTerm("itinerary");
  const tagTerm = getTerm("tag", {
    plural: true,
    capitalize: true,
  });
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <GridCard sx={[{ height: "175px" }]}>
          <ItineraryHero />
          <SubscriptionOptions
            cta={`Upgrade this ${itineraryTerm} to take advantage of additional features.`}
          />
        </GridCard>
        <ItineraryStats />
        <HasPlatformExtension platform_extension="Contesting">
          <GridCard>
            <RecentCheckIns limit={10} />
          </GridCard>
        </HasPlatformExtension>
      </Grid>
      <Grid item xs={6}>
        <GridCard mb={2} sx={[{ height: "700px" }]}>
          <Box height={"680px"}>
            <MapDataProvider>
              <ReferenceManyThroughMany
                reference="stops"
                through="legs"
                using="itinerary_id,leg_id"
              >
                <SimpleMapList
                  navigateOnClick={false}
                  polySource="fullBounds"
                  markerSource="fullLonLat"
                />
              </ReferenceManyThroughMany>
            </MapDataProvider>
          </Box>
        </GridCard>
        <GridCard title={tagTerm}>
          <TagSidebar />
        </GridCard>
      </Grid>
    </Grid>
  );
};
