import { Divider, Typography } from "@mui/material";
import { NumberField, TextInput } from "react-admin";
import { useWatch } from "react-hook-form";

export const QRCodeScansConversion = () => {
  const [audience, scans] = useWatch({
    name: ["audience_reach", "qr_code_scans_count"],
  });
  const conversionPercent = Math.floor((scans / audience) * 100);
  return (
    <>
      <TextInput
        source="audience_reach"
        helperText="Enter audience reach to help calculate the conversion rate for qr codes"
      />
      <NumberField source="qr_code_scans_count" display="none" />
      {conversionPercent < 101 && (
        <>
          <Divider style={{ marginTop: 15, marginBottom: 5 }} />
          <Typography variant="body2" sx={{ marginBottom: 1 }}>
            Conversion
          </Typography>
          <Typography
            variant="body"
            sx={{ marginBottom: 1 }}
          >{`Your QR code has been scanned ${scans} times, giving you a  ${conversionPercent}% conversion rate`}</Typography>
          <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        </>
      )}
    </>
  );
};
