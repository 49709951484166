import { Create } from "react-admin";
import { HelpTopicForm } from "./HelpTopicForm";

export const HelpTopicCreate = () => {
  return (
    <Create redirect="list">
      <HelpTopicForm title="New Help Category" />
    </Create>
  );
};
