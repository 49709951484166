import { useCallback, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

/**
 *
 * Returns the size of the referenced element with 4 different methods for catching changes in size.
 * 1. On first render via useEffect hook
 * 2. Resize Event Listener.
 *    Whenever the window is resized the size of the element is re-calulated
 * 3. [Intersection Observer](https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API) (via react-intersection-observer)
 *    This does a resize the first time the element becomes visible in the view port.
 *    This is because elements that are hidden via display:none or similar have a height/width of 0
 *    and need to be re-calculated once they become visible.
 * 4. useEffect Dependency Array.
 *    You can manually trigger a resize by changing a value in the dependency array you pass to the hook.
 *
 * @example
 * ```js
 * import { useGetElementSize } from './useGetElementSize';
 *
 * const Component = () => {
 *   const { ref, size } = useGetElementSize();
 *
 *   return (
 *     <div ref={ref}>
 *       <h2>{`Div size is height:${size.height}, width:${size.width}`}</h2>
 *     </div>
 *   );
 * };
 * ```
 */

export const useGetElementSize = (dependencies: [any?] = []) => {
  const [size, setSize] = useState({});
  const ref = useRef<HTMLElement>();
  // Gets size of referenced element and sets to state.
  const setSizeState = () => {
    if (!!ref.current) {
      const { clientHeight: height, clientWidth: width } = ref.current;
      setSize({ height, width });
    }
  };

  // We only need the intersection-observer to trigger the first time the element is visible.
  const [inViewSet, setInviewSet] = useState(false);

  // Handle setting the size state when the inViewRef is inView.
  const { ref: inViewRef } = useInView({
    onChange: (inView) => {
      if (inView) {
        setInviewSet(true);
        setSizeState();
      }
    },
    // After the first time the element is in view we skip further triggers.
    skip: inViewSet,
  });

  /**
   * We need to set 2 refs for the single element. One for the setSizeState and one for useInView.
   * This callback combines the 2 into a single ref function we can pass onto the element ref.
   */
  const setRefs = useCallback(
    (node: HTMLElement) => {
      ref.current = node;
      inViewRef(node);
    },
    [inViewRef]
  );

  // Sets the window resize event listenter and removes it when the component is unmounted.
  useEffect(() => {
    window.addEventListener("resize", setSizeState);
    return ref.current?.removeEventListener("resize", setSizeState);
  }, [ref]);

  /**
   * Handles the initial resize event on first render
   * Also allows you to manually trigger a resize event by changing the value of any passed dependencies.
   */
  useEffect(setSizeState, dependencies);

  return { ref: setRefs, size };
};
