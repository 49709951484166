import {
  Datagrid,
  DateField,
  ListContextProvider,
  ReferenceField,
  TextField,
  useList,
  useRecordContext,
} from "react-admin";
import { useCurrentPlatform } from "~/context";
export const UserCheckIns = () => {
  const record = useRecordContext();
  const listContext = useList({
    data: record?.checkin_counts,
    isLoading: false,
  });
  const { getTerm } = useCurrentPlatform();
  const listingTerm = getTerm("listing", { plural: true, capitalize: true });
  const itineraryTerm = getTerm("itinerary", {
    plural: true,
    capitalize: true,
  });
  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={false}>
        <TextField />
        <ReferenceField
          source="listing_id"
          reference="listings"
          label={listingTerm}
        />
        <ReferenceField
          source="itinerary_id"
          reference="itineraries"
          label={itineraryTerm}
        />
        <DateField source="last_check_in_date" showTime />
        <TextField source="count" label="Check ins" />
      </Datagrid>
    </ListContextProvider>
  );
};
