import { get } from "lodash";
import { singular } from "pluralize";
import { useMemo } from "react";
import { useReference } from "react-admin";
import { useWatch } from "react-hook-form";

export type UseFallbackResourceValueProps = {
  fallback_reference?: string;
  fallback_source?: string;
  fallback_value_source?: string;
  source: string;
};

export const useFallbackResourceValue = ({
  fallback_reference = "",
  fallback_source,
  fallback_value_source,
  source,
}: UseFallbackResourceValueProps) => {
  const fallback_source_value = useWatch({
    name: fallback_source || `${singular(fallback_reference)}_id`,
  });
  const { referenceRecord, isLoading } = useReference({
    reference: fallback_reference,
    id: fallback_source_value,
    options: { enabled: !!fallback_source_value && !!fallback_reference },
  });
  const reference_source = fallback_value_source || source;
  return useMemo(
    () => [get(referenceRecord, reference_source), isLoading] as const,
    [referenceRecord, reference_source, isLoading]
  );
};
