import { Star } from "@phosphor-icons/react";
import { Box, Typography } from "@mui/material";
import { mergeSx } from "~/helpers";
export const FeaturedChip = ({ sx, ...props }) => {
  return (
    <Box
      label="Featured"
      icon={<Star />}
      sx={mergeSx(
        {
          display: "flex",
          padding: "5px 10px 7px",
          gap: 0.5,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "green.300",
          color: "green.800",
          fontWeight: 500,
          borderRadius: 100,
          fontSize: 12,
        },
        sx
      )}
      {...props}
    >
      <Star />
      <Typography sx={{ fontSize: "inherit", fontWeight: "inherit" }}>
        Featured
      </Typography>
    </Box>
  );
};
