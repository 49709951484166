import { createReactBlockSpec } from "@blocknote/react";
import { Box } from "@mui/material";
import { LinearProgress, useGetList } from "react-admin";
import { Menu, NumberInput } from "@mantine/core";

type Props = { count?: number };

export const ListingGridBlockContent = ({ count = 3 }: Props) => {
  const { data, isLoading } = useGetList(
    "listings",
    {
      pagination: { page: 1, perPage: count },
      sort: { field: "id", order: "DESC" },
    },
    { keepPreviousData: true }
  );
  if (isLoading) return <LinearProgress />;
return  data?.map((listing) => (
  <Box key={listing.id} width={`${100/count}%`} display='inline-block' padding={1}>
   <a href={listing.public_url} target="_blank" style={{textDecoration:"none",color:'#000'}}> 
   <div style={{ 
  width: '100%', 
  height: `${450/count}px`,
  maxWidth: '600px', 
  overflow: 'hidden', 
  borderRadius: '5px', 
  lineHeight: 0 ,
  backgroundColor:"#aaa"
}}>
  <img 
    src={listing.hero.url} 
    alt={listing.name} 
    style={{ 
      display: 'block', 
      width: '100%', 
      height: 'auto' 
    }} 
    width="600" 
    height="450" 
  />
</div>
    {listing.name}
    </a>
  </Box>
  ))
}

export const ListingGridBlock = createReactBlockSpec(
  {
    type: "listingGrid",
    content: "none",
    propSchema: { count: { default: 3 } },
  },
  {
      toExternalHTML: ({ block, editor, contentRef }) => {
        const { count } = block.props;
        return <ListingGridBlockContent  count={count} />;

      },
      render: ({ block, editor, contentRef }) => {
      const { count } = block.props;
      return (
        <>
          <Menu
            withinPortal={false}
            zIndex={999999}
            trigger="hover"
            openDelay={100}
            closeDelay={400}
            closeOnItemClick={false}
          >
            <Menu.Target>
              <Box>
              <ListingGridBlockContent  count={count} />
              </Box>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item component="span">
                <NumberInput
                  value={count}
                  onChange={(val) =>
                    editor.updateBlock(block, {
                      type: "listingGrid",
                      props: {
                        count: typeof val === "number" ? val : parseInt(val),
                      },
                    })
                  }
                />
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </>
      );
    },
  }
);
