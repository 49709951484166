import { DirectUpload } from "@rails/activestorage";
import { useDataProvider } from "react-admin";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

const uploadImage = async (file) => {
  const upload = new DirectUpload(file, "/api/admin/direct_uploads");
  return new Promise((resolve, reject) => {
    upload.create((error, blob) => {
      error ? reject(error) : resolve(blob);
    });
  });
};

export const useCreateImage = (source, multiple) => {
  const { setValue } = useFormContext();
  // const trimmed_source = source.split('.').pop()
  const dataProvider = useDataProvider();

  return useCallback(
    async (files) => {
      const newBlobs = await Promise.all(files.map(uploadImage));
      if (!!newBlobs.length) {
        setValue(
          `${source}_signed_id`,
          multiple ? newBlobs.map((b) => b.signed_id) : newBlobs[0].signed_id,
          { shouldDirty: true, shouldTouch: true }
        );
        const data = await dataProvider
          .getMany("blobs", { ids: newBlobs.map((b) => b.id) })
          .then(({ data }) => data);
        return data;
      }
    },
    [multiple, source]
  );
};
