import { isFunction } from "lodash";
import {
  RadioButtonGroupInput,
  RadioButtonGroupInputProps,
  SelectInput,
  SelectInputProps,
  required,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { useEnumChoices } from "~/hooks";

type BaseProps = {
  onChoices?: (data: any[]) => any[];
  isRequired?: boolean;
  fullWidth?: boolean;
  // descriptions?: Record<string, string>[]
  source: string;
  resource?: string;
  helperText?:
    | SelectInputProps["helperText"]
    | ((value: string) => SelectInputProps["helperText"]);
};

type SelectProps = Omit<SelectInputProps, "helperText"> &
  BaseProps & {
    isRadio?: false;
  };
type RadioProps = Omit<RadioButtonGroupInputProps, "helperText"> &
  BaseProps & {
    isRadio: true;
  };
type Props = SelectProps | RadioProps;

export const EnumChoiceInput = (props: Props) => {
  const { data = [], isLoading } = useEnumChoices(props);
  const choices = props.onChoices?.(data) || data;

  const value = useWatch({ name: props.source });

  const getHelperText = (value: string) => {
    if (isFunction(props.helperText)) {
      return props.helperText(value);
    }
    return props.helperText;
  };

  if (props.isRadio) {
    const { isRadio, onChoices, helperText, isRequired, ...rest } = props;
    return (
      <>
        <RadioButtonGroupInput
          choices={choices}
          dir="row"
          validate={isRequired ? required() : undefined}
          {...rest}
          helperText={getHelperText(value)}
        />
      </>
    );
  }
  if (!props.isRadio) {
    const { isRadio, onChoices, helperText, isRequired, ...rest } = props;
    return (
      <>
        <SelectInput
          choices={choices}
          validate={isRequired ? required() : undefined}
          isLoading={isLoading}
          {...rest}
          helperText={getHelperText(value)}
        />
      </>
    );
  }
};
