import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";

//remove missing translation warnings for useNotify https://github.com/marmelab/react-admin/issues/758
//https://marmelab.com/react-admin/Translation.html

export const i18nProvider = polyglotI18nProvider(
  (locale) => englishMessages,
  "en",
  {
    allowMissing: true,
  }
);
