import { Box, Tooltip, Typography } from "@mui/material";
import { useCurrentPlatform } from "~/context";
import { grey } from "~/theme/colors";
export const CardCountIcon = (props) => {
  const { icon, count, type } = props;
  const activeColor = !!count ? "grey.500" : "grey.300";
  const { getTerm } = useCurrentPlatform();
  return (
    <Tooltip
      title={getTerm(type, {
        plural: count,
        capitalize: true,
        inclusive: true,
      })}
    >
      <Box display="flex" alignItems="center">
        <Field Icon={icon} color={activeColor} />
        <Typography fontStyle={{ color: activeColor }} mb="1px">
          {!!count && count}
        </Typography>
      </Box>
    </Tooltip>
  );
};
const Field = ({ Icon, color }) => {
  const iconColor = color ? color : "grey.500";
  return (
    <Box
      display="flex"
      sx={{
        "&:hover": { cursor: "pointer" },
        color: iconColor,
      }}
    >
      <Icon size={12} weight="fill" />
    </Box>
  );
};
export const CardItemIcon = (props) => {
  const { icon, item, available, empty } = props;

  return (
    <Tooltip title={item ? item : empty}>
      <Box display="flex" alignItems="center" padding="8px">
        <Field Icon={icon} color={available ? grey[500] : grey[300]} />
      </Box>
    </Tooltip>
  );
};
