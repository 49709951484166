import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { styled } from "@mui/material";
import { UploadSimple } from "@phosphor-icons/react";
import { InputHTMLAttributes, forwardRef } from "react";

type InputProps = InputHTMLAttributes<HTMLInputElement>;
type Props = Omit<LoadingButtonProps<"label">, "onChange"> & {
  onChange: InputProps["onChange"];
  inputProps: InputProps;
};

export const UploadButton = forwardRef<HTMLInputElement, Props>(
  ({ children, inputProps, onChange, ...props }, ref) => {
    return (
      <LoadingButton
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<UploadSimple />}
        {...props}
      >
        {children}
        <VisuallyHiddenInput
          {...inputProps}
          ref={ref}
          type="file"
          onChange={onChange}
        />
      </LoadingButton>
    );
  }
);

UploadButton.displayName = "UploadButton";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
