import { ItinResourceProps } from "~/resources/types";
import { Barcode } from "@phosphor-icons/react";
import { QRCodeList } from "./QRCodeList";
import { QRCodeCreate } from "./QRCodeCreate";
import { QRCodeEdit } from "./QRCodeEdit";

export const QRCodeResource: ItinResourceProps = {
  name: "qr_codes",
  list: QRCodeList,
  edit: QRCodeEdit,
  create: QRCodeCreate,
  icon: <Barcode />,
  recordRepresentation: (r) => r.description,
  options: { menu_group: "contesting" },
};
