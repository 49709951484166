import { Box, Button } from "@mui/material";
import { IfCanAccess } from "@react-admin/ra-rbac";
import { useRecordContext, useResourceContext } from "react-admin";
import { useCurrentPlatform } from "~/context";
import { useDoMagic } from "~/hooks";
import { GridCard } from "../GridCard";
import { Sparkles } from "../Sparkles";

export const DoMagicButton = (props) => {
  const resource = useResourceContext(props);
  const record = useRecordContext(props);
  const { getTerm } = useCurrentPlatform();
  const resourceTerm = getTerm(resource);
  const [doMagic] = useDoMagic(props);

  if (!record) return null;

  return (
    <IfCanAccess action="do_magic" fallback={"Get Magic!"}>
      <GridCard variant="outlined">
        <Box>
          <Sparkles show={true}>
            <Button variant="contained" onClick={() => doMagic()}>
              {`Make Magic`}
            </Button>
          </Sparkles>
        </Box>
        <p>{`Let's make this ${resourceTerm} a bit more magic`}</p>
      </GridCard>
    </IfCanAccess>
  );
};
