import { useBlockNoteEditor, useComponentsContext } from "@blocknote/react";
import CloseIcon from "@mui/icons-material/Close";
import ComputerIcon from "@mui/icons-material/Computer";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import { Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import { TransitionProps } from "@mui/material/transitions";
import Typography from "@mui/material/Typography";
import { forwardRef, useEffect, useState } from "react";
import { useGetList } from "react-admin";
import { MdPreview } from "react-icons/md";
import { useCurrentPlatform } from "~/context";
import { block_note_schema } from "../BlockInput";

export const BlockPreview = () => {
  const editor = useBlockNoteEditor(block_note_schema);

  const { currentPlatform } = useCurrentPlatform() as {
    currentPlatform: any;
  };
  const { data = [] } = useGetList(
    "contents",
    { filter: { target_type: "Platform", target_id: currentPlatform?.id } },
    { enabled: !!currentPlatform?.id }
  );
  const Components = useComponentsContext()!;

  const fine_print = data.find((d: any) => d.name === "email_fine_print");
  const [open, setOpen] = useState(false);
  const [html, setHtml] = useState("");
  const [desktopSize, setDesktopSize] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      editor.blocksToHTMLLossy(editor.document).then(setHtml);
    }, 100);
    editor.onChange(() => {
      editor.blocksToHTMLLossy(editor.document).then(setHtml);
    });
    return () => clearTimeout(timeout);
  }, [editor]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Components.FormattingToolbar.Button
        className="bn-button"
        isSelected={open}
        mainTooltip="Preview"
        label="Preview"
        onClick={handleClickOpen}
        icon={<MdPreview />}
      />
      <Dialog
        fullScreen
        open={open}
        maxWidth={false}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{ zIndex: 2147483002, backgroundColor: "#f1f1f1" }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Preview
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setDesktopSize((s) => !s)}
            >
              {desktopSize ? <ComputerIcon /> : <SmartphoneIcon />}
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box padding={2}>
          <Box
            sx={{
              maxWidth: 600,
              backgroundColor: "#fff",
              padding: "50px 0",
              borderRadius: 10,
              margin: "0 auto",
            }}
          >
            <Box
              sx={{
                margin: "0 auto",
                padding: "20px",
                textAlign: "left",
              }}
            >
              <img
                height={100}
                style={{ display: "block", margin: "0 auto 30px" }}
                src={currentPlatform?.logo?.url}
              />
              {html && (
                <div className="bn-container">
                  <div
                    className=" bn-default-styles"
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                </div>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              textAlign: "center",
              margin: "0 auto 30px",
              width: "500px",
              marginTop: "20px",
              color: "#666",
              fontSize: "12px",
            }}
          >
            {`If you wish to unsubscribe or update your preferences for ${currentPlatform?.name} `}
            <a href="#">click here</a>.
          </Box>
          <Box
            className="finer-print"
            sx={{
              textAlign: "center",
              margin: "0 auto 30px",
              width: "400px",
              marginTop: "30px",
              color: "#666",
              fontSize: "9px",
            }}
          >
            {fine_print?.description ? (
              <div
                dangerouslySetInnerHTML={{ __html: fine_print.description }}
              />
            ) : (
              <>
                Your privacy is important to us. We use your personal
                information to provide you with the best possible user
                experience. We do not share your information with any third
                party without your consent.
                <br />
                {`©${new Date().getFullYear()} ${
                  currentPlatform?.name || "Platform"
                } `}
                except where attributed. All rights reserved.
              </>
            )}
          </Box>
          <a href="https://tourismo.co/" style={{ paddingBottom: "30px" }}>
            <img
              src="/assets/ismo-no-sparkles.png"
              className="ismo"
              title="Powered By Tourismo, click here to learn more"
              style={{
                height: "36px",
                display: "block",
                margin: "0 auto 30px",
              }}
            />
          </a>
        </Box>
      </Dialog>
    </>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
