import { Grid } from "@mui/material";
import { useDefineAppLocation } from "@react-admin/ra-navigation";
import {
  required,
  TabbedForm,
  TextInput,
  useGetOne,
  useRecordContext,
} from "react-admin";
import { useParams } from "react-router-dom";
import { MultiImageInput, SingleImageInput, StyledToolBar } from "~/components";
import { EnumChoiceInput } from "~/components/inputs";
import { LogList } from "../logs/LogList";
import { useContentField } from "./hooks";

export const ContentForm = ({ defaultValues }) => {
  const { id: content_type_id } = useParams();
  const record = useRecordContext();
  const isCreate = !record?.id;
  useDefineAppLocation(`contents.${isCreate ? "create" : "edit"}`, { record });

  const { data: content_type } = useGetOne("content_types", {
    id: content_type_id,
  });
  const field = useContentField({ type: content_type?.type });

  return (
    <TabbedForm
      toolbar={<StyledToolBar />}
      syncWithLocation={false}
      defaultValues={defaultValues}
    >
      <TabbedForm.Tab label="Content">
        <Grid container spacing={2}>
          <Grid item md={6}>
            {content_type?.use_title && (
              <TextInput source={"title"} fullWidth validate={required()} />
            )}
          </Grid>
          <Grid item md={6}>
            <TextInput
              source="slug"
              fullWidth
              validate={[!isCreate && required()].filter(Boolean)}
              disabled={isCreate}
            />
          </Grid>
          <Grid item md={6}>
            <TextInput source="description" fullWidth />
          </Grid>
          <Grid item md={6}>
            <EnumChoiceInput source="status" fullWidth defaultValue={"draft"} />
          </Grid>
          {!isCreate && (
            <>
              <Grid item md={12}>
                <SingleImageInput source="hero" />
              </Grid>
              <Grid item md={12}>
                {field}
              </Grid>
            </>
          )}
        </Grid>
      </TabbedForm.Tab>
      {!isCreate && (
        <TabbedForm.Tab label="Activity Log">
          <LogList />
        </TabbedForm.Tab>
      )}
    </TabbedForm>
  );
};
