import { Box } from "@mui/material";
import { get } from "lodash";
import { Star } from "@phosphor-icons/react";
import { useRecordContext } from "react-admin";

export const FeaturedField = (props) => {
  const { source, icon } = props;
  const record = useRecordContext(props);
  const value = get(record, source);
  if (!value) return null;

  return (
    <Box
      sx={{
        height: 22,
        width: 22,
        backgroundColor: "green.200",
        borderRadius: 11,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0 auto",
        fontSize: 10,
        color: "green.800",
      }}
    >
      {icon || <Star />}
    </Box>
  );
};
