import { Header } from "./Header";
import { Box, IconButton } from "@mui/material";
import { X } from "@phosphor-icons/react";
import { useDrawerForm } from "./DrawerForm";

export const DrawerFormHeader = ({ title, showCloseButton = TextTrackCue }) => {
  const { onClose } = useDrawerForm();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Header title={title} />
      {showCloseButton && (
        <IconButton onClick={(e) => onClose(e)}>
          <X />
        </IconButton>
      )}
    </Box>
  );
};
