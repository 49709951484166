import { ItinResourceProps } from "../types";
// import { Gift } from "@phosphor-icons/react";

export const RewardResource: ItinResourceProps = {
  name: "rewards",
  recordRepresentation: "title",
  // icon: <Gift />,
};

export * from "./RewardList";
