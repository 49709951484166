import { Tooltip, Typography } from "@mui/material";
import { Menu, useCanAccess } from "@react-admin/ra-rbac";
import { get } from "lodash";
import { useCreatePath, useGetResourceLabel } from "react-admin";
import { useWindowSize } from "usehooks-ts";
import { useHasPlatformExtension } from "~/components/HasPlatformExtension";
import { LayoutClasses } from "../layoutClasses";

export const MenuItem = ({ resource, indent = false }) => {
  const getResourceLabel = useGetResourceLabel();
  const createPath = useCreatePath();
  const { name, icon, options, type, to = false } = resource;
  const hasExtension = useHasPlatformExtension(options.requiredExtension);
  const id = get(options, "resource_id");
  const { canAccess: canUseResource } = useCanAccess({
    resource: name,
    id,
    action: type ? type : id ? "edit" : "navigate",
  });
  const label = get(options, "label", getResourceLabel(name, 2));
  if (!canUseResource || (options.requiredExtension && !hasExtension))
    return null;
  //if options.requiredExtension

  return (
    <Tooltip title={label} className={LayoutClasses.tooltip} enterDelay={500}>
      <Menu.Item
        variant="main"
        to={
          to ||
          createPath({
            resource: name,
            type: type ? type : id ? "edit" : "list",
            id,
          })
        }
        primaryText={<Text label={label} />}
        leftIcon={icon}
        sx={{
          mt: name === "help_topics" ? "auto" : undefined,
          fontSize: 24,
          paddingLeft: indent ? "2em" : "1em",
        }}
        className={LayoutClasses.menuItem}
      />
    </Tooltip>
  );
};

const Text = ({ label }) => {
  const { width: windowSize } = useWindowSize();
  if (windowSize < 900) return null;
  return (
    <Typography
      color="white"
      variant="body2"
      sx={{ fontWeight: 500 }}
      whiteSpace="normal"
    >
      {label}
    </Typography>
  );
};
