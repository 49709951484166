import { FormHelperText, Grid } from "@mui/material";
import { TextInput, required } from "react-admin";
import { useWatch } from "react-hook-form";
import { ItinLoading } from "~/components/ItinLoading";
import { ImageField } from "~/components/images";
import { SingleImageInput } from "~/components/images/SingleImageInput";
import {
  EnumChoiceInput,
  PolymorphicInputs,
  usePolymorphic,
} from "~/components/inputs";
import { useCurrentPlatform } from "~/context";

export const ReelStopFields = () => {
  const { getTerm } = useCurrentPlatform();
  const { data: target, type, isLoading } = usePolymorphic();
  const target_name = target?.name;
  const target_subtitle = target?.subtitle;
  const termTarget = type ? type.toString().toLowerCase() : "";
  const target_singular =
    getTerm(termTarget, {
      capitalize: false,
      plural: false,
    }) || "null";

  const target_type = useWatch({ name: "target_type" });

  return (
    <Grid container width="75vw" columnSpacing={0}>
      <Grid item md={12}>
        <PolymorphicInputs target_types={["Region", "Listing"]} />
      </Grid>
      <Grid container item md={12}>
        <Grid item md={6}>
          <EnumChoiceInput
            source="status"
            label={false}
            isRadio={true}
            sx={{ padding: 0, margin: 0, "& legend": { display: "none" } }}
          />
        </Grid>
      </Grid>

      <Grid item container md={12} columnSpacing={2}>
        <Grid item md={6}>
          <Grid container>
            <TextInput
              source="title_override"
              label="Name"
              fullWidth
              helperText={
                !!target_name
                  ? `Leave blank to use the ${target_singular} name.`
                  : undefined
              }
              placeholder={target_name || undefined}
              validate={!target ? required() : undefined}
            />
            <TextInput
              source="subtitle"
              label="Subtitle"
              fullWidth
              helperText={
                !!target_name
                  ? `Leave blank to use the ${target_singular} subtitle.`
                  : undefined
              }
              placeholder={target_subtitle || undefined}
            />
            {/* <Grid item md={5}>
              <AutocompleteInput
                label="Type"
                source="main_type"
                choices={main_types}
                onCreate={(name) => {
                  const newType = { id: name, name };
                  main_types.push(newType);
                  return newType;
                }}
              />
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={5}>
              <AutocompleteInput
                label="Sub Type"
                source="subtype"
                choices={subtypes}
                optionValue="name"
                onCreate={(name) => {
                  const newType = { id: name, name };
                  subtypes.push(newType);
                  return newType;
                }}
              />
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item md={6}>
          {target && (
            <ImageField
              sx={{ height: "200px" }}
              source="hero.url"
              label={`${target_singular} Hero Image`}
              record={target}
            />
          )}
          <SingleImageInput label="Hero Image" source="hero" />
          <FormHelperText>
            {target
              ? `Using ${target_singular} image, upload to override.`
              : ""}
          </FormHelperText>
        </Grid>
      </Grid>
    </Grid>
  );
};
