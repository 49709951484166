/*global google*/

import { LocationOn } from "@mui/icons-material";
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import { debounce } from "@mui/material/utils";
import parse from "autosuggest-highlight/parse";
import { useEffect, useMemo, useRef, useState } from "react";
import { FieldTitle } from "react-admin";
import { useGoogleMapsLoader } from "~/context";
import { useAutoCompletePlace } from "./hooks";

export const GooglePlaceAutocomplete = ({
  helperText,
  defaultValue = "",
  handleSelectPlace,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue);
  const [inputValue, setInputValue] = useState(defaultValue);
  const [options, setOptions] = useState([]);
  const autocompleteRef = useRef();
  const placesRef = useRef();
  const { loaded } = useGoogleMapsLoader();
  const { setListingData, setHours } = useAutoCompletePlace();
  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteRef.current.getPlacePredictions(
          {
            ...request,
          },
          callback
        );
      }, 400),
    []
  );

  useEffect(() => {
    let active = true;
    if (loaded && !autocompleteRef.current && !placesRef.current) {
      const fakeEl = document.createElement("div");
      autocompleteRef.current = new google.maps.places.AutocompleteService();
      placesRef.current = new google.maps.places.PlacesService(fakeEl);
    }
    if (inputValue === "") {
      setOptions(value ? [value] : []);
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });
    return () => {
      active = false;
    };
  }, [loaded, value, inputValue, fetch]);

  const onSelect = (_event, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    if (!!newValue && typeof newValue !== "string") {
      placesRef?.current.getDetails(
        {
          placeId: newValue?.place_id,
          fields: [
            "address_components",
            "opening_hours",
            "geometry.location",
            "name",
            "website",
            "photos",
            "types",
            "international_phone_number",
            "editorial_summary",
          ],
        },
        (place) => {
          if (!!handleSelectPlace) {
            handleSelectPlace(place, newValue);
          } else {
            setListingData(place);
            setHours(place);
          }
        }
      );
    }
  };

  return (
    <Autocomplete
      openOnFocus
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={onSelect}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      fullWidth
      renderInput={(params) => (
        <TextField
          autoFocus
          {...params}
          sx={{ fontSize: "2em" }}
          label={<FieldTitle label="Enter name and/or the address:" />}
        />
      )}
      renderOption={(props, option) => <Option option={option} {...props} />}
      {...props}
    />
  );
};

const Option = ({ option, ...props }) => {
  const matches =
    option.structured_formatting.main_text_matched_substrings || [];

  const parts = parse(
    option.structured_formatting.main_text,
    matches.map((match) => [match.offset, match.offset + match.length])
  );
  return (
    <li {...props}>
      <Grid container alignItems="center">
        <Grid item sx={{ display: "flex", width: 44 }}>
          <LocationOn sx={{ color: "text.secondary" }} />
        </Grid>
        <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
          {parts.map((part, index) => (
            <Box
              key={index}
              component="span"
              sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
            >
              {part.text}
            </Box>
          ))}
          <Typography variant="body2" color="text.secondary">
            {option.structured_formatting.secondary_text}
          </Typography>
        </Grid>
      </Grid>
    </li>
  );
};
