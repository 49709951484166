import { Grid, Typography } from "@mui/material";
import { BooleanInput, FormTab, TextInput, required } from "react-admin";
import { AdminUserInput, EnumChoiceInput } from "~/components/inputs";
import { RegionInput } from "~/components/inputs/RegionInput";
import { useCurrentPlatform } from "~/context";
import { validateUrl } from "~/hooks";
import { EditEventTimes } from "./EditEventTimes";
import { GridCard } from "~/components/GridCard";
import ItinRichTextArea from "~/components/inputs/ItinRichTextArea";

export const EditEventBasicInfo = (props) => {
  const { getTerm } = useCurrentPlatform();
  const eventsTerm = getTerm("event", { plural: true });
  const eventTerm = getTerm("event");
  return (
    <Grid container columnSpacing={3}>
      <Grid item xs={9}>
        <Grid container columnSpacing={2}>
          <Grid item xs={6}>
            <TextInput source="name" validate={required()} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <RegionInput source="target_id" />
          </Grid>

          <Grid item xs={6} md={4}></Grid>
          <Grid item xs={12}>
            <ItinRichTextArea
              label="Description"
              description={`Give a short description of this ${eventTerm}.`}
              height="250px"
              width="100%"
              placeholder="Compose an epic.."
              source="description"
              isRequired={false}
            />
          </Grid>
          <Grid item xs={12}>
            <EditEventTimes />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid container rowGap={2}>
          <GridCard>
            <EnumChoiceInput source="status" isRadio />
            <BooleanInput
              source="featured"
              label={`Make this a featured ${eventTerm}`}
            />
          </GridCard>
          <GridCard title="Pricing & Tickets">
            <TextInput
              source="cost"
              validate={required()}
              fullWidth
              helperText={`Enter 0 for free ${eventsTerm}.`}
            />
            <TextInput label="Ticket URL" source="ticket_url" fullWidth />
          </GridCard>
          <GridCard title="Contact Details">
            <AdminUserInput fullWidth />
            <TextInput source="email" fullWidth />
            <TextInput source="phone_number" fullWidth />
            <TextInput source="website" validate={validateUrl} fullWidth />
          </GridCard>
        </Grid>
      </Grid>
    </Grid>
  );
};
