import { BookOpen } from "@phosphor-icons/react";
import { ItinResourceProps } from "../types";
import { ContentCreate } from "./ContentCreate";
import { ContentEdit } from "./ContentEdit";
import { ContentList } from "./ContentList";

export * from "./ContentInlineEditList";

export const ContentResource: ItinResourceProps = {
  name: "contents",
  list: ContentList,
  edit: ContentEdit,
  create: ContentCreate,
  icon: <BookOpen />,
  recordRepresentation: "title",
};
