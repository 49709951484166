import { Card, Typography, Grid } from "@mui/material";
import { Children } from "react";

export const GridCard = ({ title, children, hasCreate, ...props }) => {
  return (
    <Grid
      item
      xs={12}
      as={Card}
      variant="outlined"
      flexDirection="column"
      {...props}
    >
      {title && (
        <Typography variant="h6" sx={{ marginBottom: "16px" }}>
          {title}
        </Typography>
      )}
      {!!Children.count(children) ? children : <Empty title={title} />}
    </Grid>
  );
};

const Empty = ({ title }) => {
  return (
    <Typography variant="body1" marginTop="4px" marginLeft="24px">
      {`No associated ${title}.`}
    </Typography>
  );
};
