import { Box, Grid, Typography } from "@mui/material";
import { get } from "lodash";
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  useEditContext,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { EnumField, PolymorphicReferenceField } from "~/components/fields";
import { EnumChoiceInput } from "~/components/inputs";
import { classifyResource, titleize } from "~/helpers";
import { ManualCheckIn } from "../platform_user_check_ins/ManualCheckIn";

const filters = [
  <EnumChoiceInput
    label="Category"
    source="category"
    emptyText="Category"
    isRequired={false}
    SelectProps={{ displayEmpty: true, notched: false }}
  />,
];

const LogPanel = () => {
  const record = useRecordContext();
  const json_data = get(record, "json_data");
  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="h5">{titleize(record.target_type)}</Typography>
        <Box
          as="pre"
          sx={{
            "& .show-more-less-clickable": { cursor: "pointer" },
            textWrap: "wrap",
          }}
        >
          {json_data
            ? Object.entries(json_data).map(([key, value]) => (
                <Grid container key={key}>
                  <Grid item xs={6} fontWeight="bold">
                    {titleize(key)}
                  </Grid>
                  <Grid item xs={6}>
                    {record.category == "RecordChange" ? (
                      <>
                        <Typography
                          sx={{
                            textDecoration: "line-through",
                            backgroundColor: "#ffcdd2",
                          }}
                        >
                          {typeof value[0] === "object" && value[0] !== null
                            ? JSON.stringify(value[0])
                            : value[0]}
                        </Typography>
                        <Typography sx={{ backgroundColor: "#d0f0c0" }}>
                          {typeof value[1] === "object" && value[1] !== null
                            ? JSON.stringify(value[1])
                            : value[1]}
                        </Typography>
                      </>
                    ) : (
                      JSON.stringify(value, null, 2)
                    )}
                  </Grid>
                </Grid>
              ))
            : "Nothing to report"}
        </Box>
      </Grid>
      <Grid item xs={6}>
        {json_data?.location && record.target_type == "Listing" && (
          <PolymorphicReferenceField
            source="target_id"
            reference_source="target_type"
            link={false}
          >
            <ManualCheckIn
              location={json_data?.location}
              target_type={record.target_type}
              target_id={record.target_id}
              user_id={record.user_id}
            />
          </PolymorphicReferenceField>
        )}
      </Grid>
    </Grid>
  );
};

export const LogList = (props) => {
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  return (
    <List
      sx={{
        "& .MuiToolbar-root": { padding: "0" },
        width: "100%",
      }}
      sort={{ field: "created_at", order: "DESC" }}
      resource="logs"
      filter={
        record && {
          target_id: record.id,
          target_type: classifyResource(resource),
          include_nested: true,
        }
      }
      filters={filters}
    >
      <Datagrid bulkActionButtons={false} expand={<LogPanel />}>
        <DateField source="created_at" showTime label="Time" />
        <EnumField source="category" />
        <TextField source="name" label="Note" />
        <ReferenceField
          source="user_id"
          reference="users"
          // link={false}
          sortable={false}
        />
        <ReferenceField source="owner_id" reference="users" sortable={false} />
        {!record && (
          <PolymorphicReferenceField
            source="target_id"
            reference_source="target_type"
          />
        )}
      </Datagrid>
    </List>
  );
};
