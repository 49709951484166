import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { DateTimeInput } from "@react-admin/ra-form-layout";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { useState } from "react";
import { Button, SaveButton, useRecordContext } from "react-admin";
import { useFormContext } from "react-hook-form";
import { GridCard } from "~/components/GridCard";
dayjs.extend(LocalizedFormat);

export const SendControls = () => {
  const record = useRecordContext();
  return (
    <GridCard>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6">{statusMessage(record)}</Typography>
        </Grid>
        {record.status === "draft" && <SendButtons />}
      </Grid>
    </GridCard>
  );
};

const SendButtons = () => {
  const [open, setOpen] = useState(false);
  const { setValue } = useFormContext();

  const handleOpenDialog = (scheduled) => {
    setValue("scheduled", scheduled, {
      shouldDirty: true,
      shouldTouch: true,
    });
    setOpen(true);
  };
  return (
    <>
      <Grid item xs={6}>
        <SaveButton
          type="button"
          icon={null}
          label="Send Later"
          fullWidth
          alwaysEnable
          sx={{ fontSize: "0.8125rem" }}
          mutationOptions={{ onSuccess: () => handleOpenDialog(true) }}
        />
      </Grid>
      <Grid item xs={6}>
        <SaveButton
          type="button"
          icon={null}
          label="Send Now"
          fullWidth
          alwaysEnable
          sx={{ fontSize: "0.8125rem" }}
          mutationOptions={{ onSuccess: () => handleOpenDialog(false) }}
        />
      </Grid>
      <SendDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};

const SendDialog = ({ open, onClose }) => {
  const { setValue, watch } = useFormContext();
  const [scheduled_at, scheduled] = watch(["scheduled_at", "scheduled"]);
  return (
    <Dialog
      open={open}
      onClose={() => {}} // We don't want the dialog to close on backdrop click
    >
      <DialogTitle>
        {`Are you sure you're ready to ${
          scheduled ? "schedule" : "send"
        } this message?`}
      </DialogTitle>
      <DialogContent>
        {scheduled ? <ScheduleText /> : <SendText />}
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setValue("scheduled_at", null);
              setValue("status", 0);
              onClose();
            }}
            label="Cancel"
          />
          <SaveButton
            type="button"
            label={scheduled ? "Schedule" : "Send Now"}
            icon={null}
            disabled={scheduled && !scheduled_at}
            transform={(data) => ({ ...data, status: 1 })}
            mutationOptions={{ onSuccess: () => onClose() }}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const SendText = () => {
  return (
    <DialogContentText variant="body2">
      Once you click send this message will be sent to all recipients.
    </DialogContentText>
  );
};

const ScheduleText = () => {
  return (
    <>
      <DateTimeInput
        label="Scheduled Date and Time"
        fullWidth
        variant="outlined"
        source="scheduled_at"
        disablePast
        closeOnSelect={false}
        slotProps={{
          actionBar: {
            actions: ["clear", "cancel", "accept"],
          },
          popper: {
            modifiers: [
              {
                name: "preventOverflow",
                enabled: true,
                options: {
                  altAxis: true,
                  altBoundary: true,
                  tether: true,
                },
              },
            ],
            sx: {
              "& .MuiMultiSectionDigitalClockSection-root": {
                scrollbarWidth: "none",
              },
            },
          },
        }}
      />
      <DialogContentText variant="body2">
        Once you schedule this message, it will no longer be editable and it
        will be sent at the specified time.
      </DialogContentText>
    </>
  );
};

const statusMessage = (record) => {
  const { status, scheduled_at, sent_at } = record;
  if (status === "draft") return "All done?";
  if (status === "sent") return `Sent on ${dayjs(sent_at).format("LLL")}`;
  if (scheduled_at) return `Scheduled for ${dayjs(scheduled_at).format("LLL")}`;
  return "Sending in progress. Refresh to check status.";
};
