import { Box, BoxProps } from "@mui/material";
import { Icon } from "@phosphor-icons/react";
import { mergeSx } from "~/helpers";
import { useMousePosition } from "~/hooks";
const SIZE = 40;

type Props = BoxProps & {
  Icon: Icon;
  show?: boolean;
};
export const CursorIcon = ({ Icon, show, sx, ...props }: Props) => {
  const { x, y } = useMousePosition(show);
  if (!show) return null;
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      zIndex={100}
      fontSize={SIZE}
      sx={mergeSx(
        {
          transform:
            x && y ? `translate(${x - SIZE / 2}px, ${y - SIZE}px)` : undefined,
          pointerEvents: "none",
          color: "#EA4335",
        },
        sx
      )}
      {...props}
    >
      <Icon weight="fill" />
    </Box>
  );
};
