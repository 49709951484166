import { useProfileActions, useProfileState } from "~/context";
import { useGetIdentity } from "react-admin";
import { Box, Drawer } from "@mui/material";
import { ProfileUserEdit } from "~/resources/users";

export const ProfileDrawer = () => {
  const { drawerOpen } = useProfileState();
  const { toggleDrawer } = useProfileActions();
  const { identity } = useGetIdentity();
  if (!identity) return null;
  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={toggleDrawer}
      PaperProps={{ sx: { backgroundColor: "common.white" } }}
    >
      <Box sx={{ minWidth: "25vw", maxWidth: "85vw" }}>
        <ProfileUserEdit
          id={identity?.id}
          resource={"users"}
          basePath="/users"
        />
      </Box>
    </Drawer>
  );
};
