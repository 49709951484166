import { sortData } from "./SortContext";
import { DatagridBody } from "react-admin";
import { SortableRow } from "./SortableRow";

export const SortableBody = ({ update_source, data, children, ...props }) => {
  const sorted_data = sortData(data, update_source);
  return (
    <DatagridBody {...props} data={sorted_data} row={<SortableRow />}>
      {children}
    </DatagridBody>
  );
};
