import { useBlockNoteEditor } from "@blocknote/react";
import { Stack, Typography } from "@mui/material";
import { UploadSimple } from "@phosphor-icons/react";
import { DirectUpload } from "@rails/activestorage";
import { useState } from "react";
import { LinearProgress, useRecordContext, useUpdate } from "react-admin";
import { useDropzone } from "react-dropzone";
import { block_note_schema } from "../BlockInput";

const uploadImage = async (file) => {
  const upload = new DirectUpload(file, "/api/admin/direct_uploads");
  return new Promise((resolve, reject) => {
    upload.create((error, blob) => {
      error ? reject(error) : resolve(blob);
    });
  });
};

export const Dropzone = ({ block }) => {
  const editor = useBlockNoteEditor(block_note_schema);
  const record = useRecordContext();
  const [update] = useUpdate();
  const [loading, setLoading] = useState(false);

  const onDrop = async (newFiles, rejectedFiles, event) => {
    setLoading(true);
    const blob = await uploadImage(newFiles[0]);
    const res = await update(
      "contents",
      {
        id: record.id,
        data: { media_signed_id: [blob.signed_id] },
      },
      { returnPromise: true }
    );
    const media = res.media.find((m) => m.blob_id === blob.id);
    editor.updateBlock(block, {
      type: "image",
      props: {
        media_id: media.id,
      },
    });
    setLoading(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
    disabled: loading,
    multiple: false,
  });
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      borderRadius={1.5}
      border="1px dashed"
      borderColor="grey.900"
      textAlign="center"
      maxWidth={400}
      boxSizing="border-box"
      height="100%"
      flexGrow={1}
      sx={{ cursor: "pointer", marginY: 2 }}
      {...getRootProps()}
    >
      <input id={`upload_${record.id}`} {...getInputProps()} />

      <UploadSimple size={33} />
      <Typography width="65%" variant="h6" mt={2} textAlign="center">
        {loading
          ? "Uploading..."
          : "Or drop files to upload, or click to select"}
        {loading && <LinearProgress sx={{ width: 1 }} />}
      </Typography>
    </Stack>
  );
};
