import { useDropzone } from "react-dropzone";
import { useInput, LinearProgress } from "react-admin";
import { useCreateImage } from "~/hooks";
import { Stack, Typography } from "@mui/material";
import { UploadSimple } from "@phosphor-icons/react";
import { useState } from "react";
import { mergeSx } from "~/helpers";

export const ImageDropzone = ({
  options,
  source,
  sx,
  show = true,
  ...props
}) => {
  const { multiple } = options;
  const [loading, setLoading] = useState(false);
  const createImage = useCreateImage(source, multiple);
  const {
    id,
    field: { onChange, value, ...inputProps },
  } = useInput({
    source,
    type: "file",
  });

  const onDrop = async (newFiles, rejectedFiles, event) => {
    setLoading(true);
    const newRecords = await createImage(newFiles);
    const updatedFiles = multiple ? [...value, ...newRecords] : newRecords[0];
    onChange(updatedFiles);
    if (options.onDrop) {
      await options.onDrop(newFiles, rejectedFiles, event);
    }
    setLoading(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    // accept: "image/*",
    onDrop,
    disabled: loading,
    ...options,
  });
  return (
    <Stack
      display={show ? undefined : "none"}
      justifyContent="center"
      alignItems="center"
      borderRadius={1.5}
      border="1px dashed"
      borderColor="grey.900"
      textAlign="center"
      minWidth={300}
      boxSizing="border-box"
      height="100%"
      flexGrow={1}
      sx={mergeSx({ cursor: "pointer" }, sx)}
      {...props}
      {...getRootProps()}
    >
      <input
        id={id}
        {...getInputProps({
          ...inputProps,
        })}
      />

      <UploadSimple size={33} />
      <Typography width="65%" variant="h6" mt={2} textAlign="center">
        {loading ? "Uploading..." : "Drop files to upload, or click to select"}
        {loading && <LinearProgress sx={{ width: 1 }} />}
      </Typography>
    </Stack>
  );
};
