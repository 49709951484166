import { capitalize } from "lodash";
import pluralize from "pluralize";
import { useResourceContext } from "react-admin";

export const useTargetType = (type) => {
  const resource = useResourceContext({ resource: type });
  if (["media", "images"].includes(resource)) {
    return "ActiveStorage::Attachment";
  }
  return capitalize(pluralize(resource, 1));
};
