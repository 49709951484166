import { Typography } from "@mui/material";
import { RESULT_LENGTH } from "./helpers";

export const TextMatchResult = ({ match }) => {
  return (
    <Typography>
      {match[0].length === RESULT_LENGTH && "..."}
      {match.map((part, i) => {
        return (
          <Typography
            key={i}
            component="span"
            display="inline"
            sx={
              i === 1
                ? {
                    backgroundColor: "yellow.300",
                    borderRadius: 1,
                    padding: 0.5,
                    textTransform: "uppercase",
                  }
                : undefined
            }
          >
            {part}
          </Typography>
        );
      })}
      {RESULT_LENGTH === match[2].length && "..."}
    </Typography>
  );
};
