import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { capitalize } from "lodash";
import { useState } from "react";
import { useRecordContext, useResourceContext } from "react-admin";
import { FeaturedChip } from "~/components/FeaturedChip";
import { ImageError } from "~/components/images";
import { replaceMediaSizeInUrl } from "~/helpers";
import { useNavigateToRecord } from "~/hooks";
import { useRandomIllustration } from "~/images/illustrations";
import { Sparkles } from "../Sparkles";
import { EnumField, OwnerAvatarField, TaggingsField } from "../fields";

export const ImageCard = ({ cardData, sparkles = false, ...props }) => {
  const record = useRecordContext();
  const { hero, featured, status, owner_id } = record;
  const [error, setError] = useState(false);
  const resource = useResourceContext();
  const {
    title,
    subtitles,
    end,
    main_type,
    img,
    buttons,
    stats_summary,
    tags,
    path,
  } = cardData(record);
  const navigate = useNavigateToRecord({ ...props, path });
  const image = img || hero;
  const Illustration = useRandomIllustration();
  let imageComponent = "img";
  if (!image?.url && !error) {
    imageComponent = Illustration;
  }
  if (error) imageComponent = SmallImageError;
  return (
    <StyledImageCard gridColumn="span 3" {...props}>
      <Sparkles show={sparkles}>
        <CardActionArea
          sx={{ borderRadius: "12px" }}
          onClick={!buttons ? navigate : undefined}
        >
          <Card className={ImageCardClasses.card} variant={"outlined"}>
            <Box className={ImageCardClasses.content}>
              <Box sx={{ position: "relative" }}>
                <CardMedia
                  className={ImageCardClasses.image}
                  component={imageComponent}
                  image={replaceMediaSizeInUrl(
                    image?.url,
                    image?.content_type?.startsWith("video")
                      ? "large_image"
                      : "large"
                  )}
                  title={title}
                  onError={() => setError(true)}
                  sx={
                    !image?.url && !error
                      ? { background: "#f7f7f7", padding: "15px" }
                      : {}
                  }
                />
                {/* above xs shows gray background when using default images */}
                {featured && (
                  <FeaturedChip className={ImageCardClasses.featured} />
                )}
                {status && (
                  <Box position="absolute" top={16} right={16}>
                    <EnumField
                      className={ImageCardClasses.status}
                      variant="filled"
                      source="status"
                    />
                  </Box>
                )}
              </Box>
              {title && (
                <Box textOverflow="ellipsis" overflow="hidden" width="100%">
                  <Typography
                    variant="h5"
                    noWrap
                    className={ImageCardClasses.title}
                  >
                    {title}
                  </Typography>
                </Box>
              )}

              <Stack direction="row" className={ImageCardClasses.subtitles}>
                {main_type && (
                  <Typography noWrap key="00" fontWeight={700} variant="body">
                    {capitalize(main_type)}
                  </Typography>
                )}

                {!!subtitles?.length &&
                  subtitles.map((text, i) => (
                    <Typography
                      className={ImageCardClasses.subtitle}
                      noWrap
                      key={i}
                      fontWeight={300}
                      variant="body"
                    >
                      {text}
                    </Typography>
                  ))}
              </Stack>
              {!!tags && (
                <TaggingsField
                  source="taggings"
                  label="Tags"
                  resource={resource}
                  sortable={false}
                />
              )}
              <Grid container>
                {!!buttons && <Box>{buttons}</Box>}
                {!!stats_summary && (
                  <Grid item xs={6}>
                    {stats_summary}
                  </Grid>
                )}
                {!!owner_id && (
                  <Grid
                    item
                    xs={stats_summary ? 6 : 12}
                    alignContent={"right"}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <OwnerAvatarField size={28} />
                  </Grid>
                )}
                {!!end && <Box className={ImageCardClasses.end}>{end}</Box>}
              </Grid>
            </Box>
          </Card>
        </CardActionArea>
      </Sparkles>
    </StyledImageCard>
  );
};

const SmallImageError = (props) => {
  return (
    <Box {...props}>
      <ImageError size="sm" />
    </Box>
  );
};

const PREFIX = "ItinImageCard";
const ImageCardClasses = {
  card: `${PREFIX}-card`,
  content: `${PREFIX}-content`,
  image: `${PREFIX}-image`,
  featured: `${PREFIX}-featured`,
  status: `${PREFIX}-status`,
  title: `${PREFIX}-title`,
  main_type: `${PREFIX}-main_type`,
  subtitles: `${PREFIX}-subtitles`,
  subtitle: `${PREFIX}-subtitle`,
  end: `${PREFIX}-end`,
};
const StyledImageCard = styled(Grid, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) =>
  theme.unstable_sx({
    [`& .${ImageCardClasses.card}`]: {
      borderRadius: 3,
    },
    [`& .${ImageCardClasses.content}`]: {
      gap: 2,
      display: "flex",
      flexDirection: "column",
      padding: "0px",
      margin: "0px",
      width: "100%",
    },
    [`& .${ImageCardClasses.image}`]: {
      width: "100%",
      height: 110,
      borderRadius: 2,
      textAlign: "center",
    },
    [`& .${ImageCardClasses.featured}`]: {
      position: "absolute",
      top: 16,
      left: 16,
    },
    [`& .${ImageCardClasses.status}`]: {
      display: "flex",
      padding: "2px",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      fontWeight: 500,
      borderRadius: 100,
      fontSize: 8,
    },
    [`& .${ImageCardClasses.title}`]: {},
    [`& .${ImageCardClasses.main_type}`]: {},
    [`& .${ImageCardClasses.subtitles}`]: { gap: 2 },
    [`& .${ImageCardClasses.subtitle}`]: { mr: 1 },
    [`& .${ImageCardClasses.end}`]: { height: 20 },
  })
);
