import { Box, Modal } from "@mui/material";

export const MediaModal = ({ src, contentType, ...props }) => {
  const isAudio = contentType.startsWith("audio");
  const isVideo = contentType.startsWith("video");
  return (
    <Modal {...props}>
      <Box>
        {isVideo && (
          <video
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "90vw",
              maxHeight: "90vh",
              objectFit: "contain",
            }}
            controls
            autoPlay
            muted
          >
            <source src={src} />
          </video>
        )}
        {isAudio && (
          <audio
            controls
            src={src}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "90vw",
              maxHeight: "90vh",
              objectFit: "contain",
            }}
          ></audio>
        )}
        {!isVideo && !isAudio && (
          <img
            src={src}
            alt=""
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "90vw",
              maxHeight: "90vh",
              objectFit: "contain",
            }}
          />
        )}
      </Box>
    </Modal>
  );
};
