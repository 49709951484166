import { Grid } from "@mui/material";
import { ReferenceManyInput } from "@react-admin/ra-relationships";
import dayjs from "dayjs";
import { isDate } from "lodash";
import { useEffect, useRef, useState } from "react";
import {
  AutocompleteArrayInput,
  BooleanInput,
  DateTimeInput,
  FormDataConsumer,
  FormTab,
  NumberInput,
  RecordContextProvider,
  ReferenceArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  required,
  useCreateSuggestionContext,
  useRecordContext,
} from "react-admin";

import { useFormContext, useWatch } from "react-hook-form";
import { GridCard } from "~/components/GridCard";
import { useCurrentPlatform } from "~/context";
import { LocationDrawer } from "~/resources/locations";

export const EditEventTimes = (props) => {
  const { getTerm } = useCurrentPlatform();
  const firstEventTime = useWatch({
    name: "@@ra-many/events/event_times/event_id.0.event_times.0",
  });
  return (
    <ReferenceManyInput
      label=""
      reference="event_times"
      target="event_id"
      defaultValue={
        !firstEventTime
          ? [
              {
                all_day: false,
                location_ids: [],
                recurring: false,
                sold_out: false,
              },
            ]
          : undefined
      }
    >
      <SimpleFormIterator disableClear>
        <FormDataConsumer>
          {(props) => <EventTimeFormFields {...props} />}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ReferenceManyInput>
  );
};

const EventTimeFormFields = ({ getSource: baseGetSource, scopedFormData }) => {
  const getSource = useRef(baseGetSource).current;
  const [record] = useState(() => ({ ...scopedFormData }));
  const { watch, setValue } = useFormContext();
  const rrule = watch(getSource("rrule"));
  const all_day = watch(getSource("all_day"));

  const handleRecurring = ({ target }) => {
    if (!target.checked) {
      setValue(getSource("rrule.freq"), "");
      setValue(getSource("rrule.interval"), null);
      setValue(getSource("rrule.count"), null);
      setValue(getSource("rrule.until"), null);
    }
    setValue(getSource("rrule"), target.checked ? {} : null, {
      shouldDirty: true,
    });
  };
  const { getTerm } = useCurrentPlatform();
  const eventTerm = getTerm("event", {
    plural: false,
    capitalize: true,
  });
  return (
    <RecordContextProvider value={record}>
      <GridCard>
        <Grid container columnSpacing={3} mt={3}>
          <Grid item xs={12} md={3}>
            <ToggleableDateTimeInput
              show_time={!all_day}
              source={getSource("start")}
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ToggleableDateTimeInput
              show_time={!all_day}
              end
              source={getSource("end")}
              validate={required()}
            />
          </Grid>
          <Grid item xs={6}>
            <ReferenceArrayInput
              source={getSource("location_ids")}
              reference="locations"
            >
              <AutocompleteArrayInput
                label="Locations"
                variant="outlined"
                optionText="name"
                fullWidth
                disableClearable
                sx={{ width: "100%" }}
                create={<EventTimeLocationForm />}
              />
            </ReferenceArrayInput>
          </Grid>
          <Grid container item xs={12}>
            <BooleanInput source={getSource("all_day")} label="All Day?" />
            <BooleanInput
              source={getSource("sold_out")}
              label={`${eventTerm} sold out?`}
            />
            <BooleanInput
              defaultValue={!!rrule}
              source={getSource("recurring")}
              onChange={handleRecurring}
              label="Recurring?"
            />
          </Grid>
          {!!rrule && (
            <>
              <Grid item xs={3}>
                <SelectInput
                  InputLabelProps={{ shrink: false }}
                  label="Frequency"
                  source={getSource("rrule.freq")}
                  choices={[
                    { id: "DAILY", name: "Daily" },
                    { id: "WEEKLY", name: "Weekly" },
                    { id: "MONTHLY", name: "Monthly" },
                    { id: "YEARLY", name: "Annually" },
                  ]}
                  validate={required()}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <NumberInput
                  label="Repeat Interval"
                  source={getSource("rrule.interval")}
                  fullWidth
                  helperText={`eg. every 2 weeks`}
                />
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  label="Repeat (x) Times..."
                  source={getSource("rrule.count")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <ToggleableDateTimeInput
                  show_time={false}
                  label="... or Repeat Until"
                  source={getSource("rrule.until")}
                  defaultValue={null}
                  helperText="Leave blank for no end date"
                />
              </Grid>
            </>
          )}
        </Grid>
      </GridCard>
    </RecordContextProvider>
  );
};

const EventTimeLocationForm = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  return (
    <LocationDrawer
      defaultValue={filter}
      onCancel={onCancel}
      onCreate={onCreate}
    />
  );
};

const parseTime = (val, end) => {
  if (!val) return null;
  const time_stamp = end ? "T23:59:59.999Z" : "T00:00:00Z";
  return new Date(val + time_stamp).toISOString();
};

const formatDate = (val) => {
  if (!val) return "";
  return dayjs(isDate(val) ? val : val.replace("Z", "")).format("YYYY-MM-DD");
};

const ToggleableDateTimeInput = ({ source, show_time, end, ...props }) => {
  const { setValue, getValues } = useFormContext();
  const initial = useRef(true);
  useEffect(() => {
    if (!initial.current) {
      if (show_time) {
        const val = dayjs(getValues(source)).format("YYYY-MM-DD");
        setValue(source, parseTime(val, source));
      }
    } else {
      initial.current = false;
    }
  }, [show_time, source, end, getValues, setValue]);

  return (
    <DateTimeInput
      InputLabelProps={{ shrink: false }}
      type={!show_time ? "date" : "datetime-local"}
      format={!show_time ? formatDate : undefined}
      parse={!show_time ? (val) => parseTime(val, end) : undefined}
      fullWidth
      source={source}
      {...props}
    />
  );
};
