import { Show, TabbedShowLayout, useResourceContext } from "react-admin";
import { StyledToolBar } from "~/components";
import { useCurrentPlatform } from "~/context";
import { RegionOverview } from "./RegionOverView/RegionOverview";

export const RegionShow = () => {
  const resource = useResourceContext();
  const { getTerm } = useCurrentPlatform();
  const resourceTerm = getTerm(resource);
  const resourcesTerm = getTerm(resource, { plural: true });

  return (
    <Show mutationMode="pessimistic" title=" " redirect={false}>
      <TabbedShowLayout variant="standard" toolbar={<StyledToolBar />}>
        <TabbedShowLayout.Tab label="overview">
          <RegionOverview />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default RegionShow;
