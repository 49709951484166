import {
  Clock,
  FlagCheckered,
  FolderOpen,
  Gift,
  IconProps,
  Image,
  ListBullets,
  MapTrifold,
  Question,
  ShareNetwork,
  Signpost,
  Star,
  Tag,
  UserCircle,
} from "@phosphor-icons/react";
import { RefAttributes } from "react";

type IconType = typeof UserCircle;
type IconPropsType = IconProps & RefAttributes<SVGSVGElement>;

export const HELP_TOPIC_ICONS: {
  [key: string]: { Icon: IconType; props?: IconPropsType };
} = {
  UserCircle: { Icon: UserCircle },
  Clock: { Icon: Clock },
  ShareNetwork: { Icon: ShareNetwork },
  Image: { Icon: Image },
  MapTrifold: { Icon: MapTrifold },
  ListBullets: { Icon: ListBullets },
  Signpost: { Icon: Signpost },
  Tag: { Icon: Tag },
  Gift: { Icon: Gift },
  FlagCheckered: { Icon: FlagCheckered, props: { weight: "fill" } },
  Question: { Icon: Question },
  Star: { Icon: Star },
  FolderOpen: { Icon: FolderOpen },
} as const;
