import { Box, Typography, CircularProgress, Card } from "@mui/material";
import { useRandomIllustration } from "~/images/illustrations";
import { useCallback, useEffect, useState } from "react";
import { random } from "lodash";

const wisecracks = [
  "Summoning digital pixies to paint the pixels...",
  "Tickling the servers to wake them up...",
  "Racing snails to bring you the content...",
  "Convincing the hamsters to run faster in their wheels...",
  "Counting back from infinity and crossing our fingers...",
  "Just figuring out when the Narwhal Bacons...",
  "Gathering cosmic stardust for your viewing pleasure...",
  "Assembling puzzle pieces in the digital universe...",
  "Encouraging electrons to do the hustle...",
  "Waking up the code gremlins to do their magic...",
  "Inventing new dance moves for progress bars...",
  "Coaxing unicorns to sprinkle loading speed dust...",
  "Training our hamster squadron for turbo mode...",
  "Convincing the servers to break a sweat...",
  "Dialing up the caffeine levels for our digital elves...",
  "Unleashing a team of hyperactive squirrels on the data highway...",
  "Teaching binary birds the art of synchronized loading...",
  "Channeling inner zen while bytes find their path...",
  "Tick-tock goes the loading clock...",
  "Firing up the engines of digital delight...",
  "Cooking up a loading storm in our virtual kitchen...",
  "Because patience is a virtue, right?",
  "Stay calm and let the loading magic happen...",
  "Embracing the loading chaos and turning it fabulous...",
  "Chasing rainbows while the data computes...",
  "Staring into the digital abyss while the abyss stares back...",
];

export const ItinLoading = (props) => {
  const { isLarge } = props;

  const Illustration = useRandomIllustration();
  const randomIndex = useCallback(() => random(0, wisecracks.length - 1), []);
  const [index, setIndex] = useState(randomIndex());

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(randomIndex());
    }, 6000);
    return () => clearInterval(interval);
  }, [randomIndex]);

  return (
    <Card sx={{ padding: isLarge ? "30px" : "150px" }}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Box sx={{ marginBottom: "16px" }}>
          <Illustration
            width="350px"
            height="300px"
            sx={{ resizeMode: "contain" }}
          />
        </Box>
        <Box width="380px">
          <Box
            sx={{
              display: "flex",
              marginTop: "12px",
              marginBottom: "8px",
              alignItems: "center",
            }}
          >
            <Typography variant="h2">Loading</Typography>
            <CircularProgress
              size={28}
              sx={{
                marginTop: "4px",
                marginLeft: "16px",
              }}
            />
          </Box>
          <Typography variant="body2">{wisecracks[index]}</Typography>
        </Box>
      </Box>
    </Card>
  );
};
