import { Box, FormControl } from "@mui/material";
import pluralize from "pluralize";
import { Labeled, NumberInput } from "react-admin";
import { useFormContext } from "react-hook-form";
import { EnumChoiceInput } from "~/components/inputs";

export const TaskIntervalInputs = () => {
  const { watch, getValues } = useFormContext();
  const [type] = watch(["type", "task_interval_count"]);
  if (type !== "RepeatTask") return null;
  return (
    <FormControl>
      <Labeled
        label="Users Can Repeat Task Every"
        sx={{ "& .RaLabeled-label": { lineHeight: "1.4375em" } }}
      >
        <Box display="flex" columnGap={2} alignItems={"center"}>
          <NumberInput
            source="task_interval_count"
            defaultValue={1}
            label={false}
            sx={{ width: 75, minWidth: 75, mt: 0 }}
          />
          <EnumChoiceInput
            sx={{
              mt: 0,
              flexGrow: 1,
              "& .MuiFormLabel-root": { display: "none" },
            }}
            source="task_interval_type"
            defaultValue="days"
            label={false}
            onChoices={(choices) =>
              choices.map((choice) => ({
                ...choice,
                name: pluralize(choice.name, getValues("task_interval_count")),
              }))
            }
          />
        </Box>
      </Labeled>
    </FormControl>
  );
};
