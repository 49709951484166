import { Scan } from "@phosphor-icons/react";
import { Datagrid, TextField } from "react-admin";
import { CardCountIcon } from "~/components";
import { ToggleList } from "~/components/lists";

export const QRCodeList = () => {
  return <ToggleList modes={MODES} />;
};

const cardData = (record) => {
  return {
    title: record.url,
    subtitles: [
      <CardCountIcon
        count={record.qr_code_scans_count}
        type={"Scan"}
        icon={Scan}
      />,
    ],
  };
};

const ListData = () => (
  <Datagrid rowClick="edit" bulkActionButtons={false}>
    <TextField source="url" />
  </Datagrid>
);

const MODES = [
  { name: "Grid", cardData },
  { name: "List", view: <ListData /> },
];
