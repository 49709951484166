import { Fragment, useMemo } from "react";
import {
  ImageField,
  ReferenceManyField,
  TextField,
  useRecordContext,
} from "react-admin";
import { DrawerDatagrid } from "~/components/drawer";
import { TargetReferenceField } from "~/components/fields";
import { useCurrentPlatform } from "~/context";
import { useStopDrawerContext } from "./ReelEdit";
import { ReelStopForm } from "./ReelStopForm";

export const ReelStops = (props) => {
  const { setStopRef } = useStopDrawerContext();
  const { getTerm } = useCurrentPlatform();
  const listingCapTerm = getTerm("listing", {
    plural: false,
    capitalize: true,
  });

  return (
    <>
      <ReferenceManyField
        reference="stops"
        target="leg_id"
        perPage={null}
        sortable={true}
      >
        <DrawerDatagrid
          ref={setStopRef}
          canCreate={true}
          buttonLabel={"Slide"}
          Form={<ReelStopForm leg_id={props.record?.id} />}
          sortable_update_source="order_on_leg"
          sx={{
            "& .RaDatagrid-thead ": {
              display: "table-header-group",
              marginTop: "0px",
              borderTop: "none",
            },
            "& .RaDatagrid-table ": {
              marginTop: "20px",
              borderTop: "none",
            },
          }}
        >
          <StopImageField label={false} />
          <TextField source="name" label={listingCapTerm} sortable={false} />
          <TextField source="subtitle" label="Headline" sortable={false} />
        </DrawerDatagrid>
      </ReferenceManyField>
    </>
  );
};

const StopImageField = () => {
  const record = useRecordContext();
  const Wrapper = useMemo(() => {
    return record.hero ? Fragment : TargetReferenceField;
  }, [record]);

  return (
    <Wrapper>
      <ImageField
        source="hero.url"
        sx={{
          "& .RaImageField-image": {
            width: 90,
            height: 180,
            objectFit: "cover",
            borderRadius: "10px",
          },
        }}
      />
    </Wrapper>
  );
};
