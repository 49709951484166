import { useNotify } from "react-admin";
import { Dispatch, SetStateAction, useEffect, useState, useRef } from "react";
import dayjs, { Dayjs, OpUnitType } from "dayjs";
import { sum, mean, round, groupBy, orderBy } from "lodash";
import { Card, CardContent, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import { blue, grey } from "~/theme/colors";
import { ByPageType } from "../helpers/types";
import { VerticalSlider } from "../shared_components";
import { ItinLoading } from "~/components";
import { SortableHeaderCell } from "./SortableHeaderCell";
import StarredTableCell from "./StarredTableCell";

type PagePathTableProps = {
  rows: ByPageType[];
  period: string;
  earliestDate: Dayjs;
  latestDate: Dayjs;
  activeRows: string[];
  setActiveRows: Dispatch<SetStateAction<string[]>>;
  startDate: Dayjs;
  setStartDate: Dispatch<SetStateAction<Dayjs>>;
  endDate: Dayjs;
  setEndDate: Dispatch<SetStateAction<Dayjs>>;
  setCsv: Dispatch<SetStateAction<any[]>>;
  smallTable?: boolean;
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    padding: 3,
  },
});

const dateFilter = (
  data: ByPageType[],
  startDate: Dayjs,
  endDate: Dayjs,
  period: string
) => {
  return data.filter(
    (e: any) =>
      dayjs(e.date).isSameOrAfter(startDate, period as OpUnitType) &&
      dayjs(e.date).isSameOrBefore(endDate, period as OpUnitType)
  );
};

export const PagePathTable = ({
  rows,
  period,
  earliestDate,
  latestDate,
  activeRows,
  setActiveRows,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  smallTable = false,
  setCsv,
}: PagePathTableProps) => {
  const classes = useStyles();
  const notify = useNotify();

  const heightRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const [sortedColumn, setSortedColumn] = useState<string>("pageviews");
  const [sortOrder, setSortOrder] = useState<"desc" | "asc">("desc");

  const [page, setPage] = useState<number>(0);
  const [dataReadied, setDataReadied] = useState<boolean>(false);

  const [filteredData, setFilteredData] = useState<ByPageType[]>(
    dateFilter(rows, startDate, endDate, period)
  );
  const [rowsPerPage, setRowsPerPage] = useState<number>(smallTable ? 5 : 50);

  const [totalPageViews, setTotalPageViews] = useState<number>(
    sum(filteredData.map((r) => r.pageviews))
  );
  const [meanAvgTimeOnPage, setMeanAvgTimeOnPage] = useState<number>(
    mean(filteredData.map((r) => r.avgTimeOnPage))
  );
  const [meanBounceRate, setMeanBounceRate] = useState<number>(
    mean(filteredData.map((r) => r.bounceRate))
  );

  useEffect(() => {
    const dateFilteredData = dateFilter(rows, startDate, endDate, period);
    const groupedData = Object.entries(
      groupBy(dateFilteredData, "pagePath")
    ).map(([key, value]) => {
      return {
        date: dayjs().toString(), // just a placeholder to stay ByPageType[]
        pagePath: key,
        avgTimeOnPage: round(mean(value.map((v) => v.avgTimeOnPage)), 2),
        bounceRate: round(mean(value.map((v) => v.bounceRate)), 2),
        pageviews: sum(value.map((v) => v.pageviews)),
      };
    });
    setFilteredData(orderBy(groupedData, [sortedColumn], [sortOrder]));
    setDataReadied(true);
  }, [
    rows.length,
    startDate,
    endDate,
    period,
    rowsPerPage,
    sortedColumn,
    sortOrder,
  ]);

  useEffect(() => {
    setCsv(filteredData);
  }, [filteredData]);

  const handleSort = (column: string) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("desc");
    }
  };

  useEffect(() => {
    setTotalPageViews(sum(filteredData.map((r) => r.pageviews)));
    setMeanAvgTimeOnPage(mean(filteredData.map((r) => r.avgTimeOnPage)));
    setMeanBounceRate(mean(filteredData.map((r) => r.bounceRate)));
  }, [filteredData]);

  useEffect(() => {
    const handleResize = () => {
      heightRef.current && setHeight(heightRef.current.clientHeight || 0);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [filteredData, startDate, endDate, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rowClickHandler = (row_id: string) => {
    const rowAlreadyActive = activeRows.includes(row_id);
    if (activeRows.length == 0) {
      notify("Selected rows appear in charts below", { type: "success" });
    }
    if (rowAlreadyActive) {
      const validRows = activeRows.filter((r) => r != row_id);
      setActiveRows([...validRows]); // remove row
      return;
    }
    if (activeRows.length == 5) {
      notify(
        "Maximium 5 rows already selected, remove others to keep visualizing",
        { type: "error" }
      ); // limit for visualization
      return;
    }
    setActiveRows([...activeRows, row_id]); // add path
  };
  if (!rows || !dataReadied) return <ItinLoading isLarge />;
  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <Grid item xs={1.4}>
          <VerticalSlider
            period={period}
            earliestDate={earliestDate}
            latestDate={latestDate}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            height={height}
            rowsPerPage={rowsPerPage}
          />
        </Grid>
        <Grid
          item
          xs={10.6}
          sx={{
            paddingLeft: 4,
            "& .MuiCardContent-root": {
              bottomBorderLeftRadius: "12px",
              bottomBorderRightRadius: "12px",
              padding: "12px",
            },
          }}
        >
          <Card
            ref={heightRef}
            variant={"outlined"}
            sx={{
              display: "flex",
              borderRadius: "12px",
              backgroundColor: "primary.contrastText",
              cursor: "pointer",
              width: "100%",
              padding: smallTable ? 0 : "12px",
              margin: 0,
              paddingBottom: smallTable ? "32px" : undefined,
              ":hover": {
                backgroundColor: "blue.50",
              },
            }}
          >
            <CardContent
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                borderRadius: "12px",
                paddingBottom: smallTable ? "16px" : undefined,
                margin: "20px",
              }}
            >
              <TableContainer sx={{ maxHeight: "60vh" }}>
                <Table
                  className={classes.table}
                  aria-label="simple table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow key="totals">
                      <TableCell
                        colSpan={1}
                        sx={{ paddingTop: smallTable ? 0 : undefined }}
                      />
                      <TableCell
                        align="right"
                        colSpan={1}
                        sx={{ paddingTop: smallTable ? 0 : undefined }}
                      >
                        Total: {totalPageViews}
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={1}
                        sx={{ paddingTop: smallTable ? 0 : undefined }}
                      >
                        Avg: {round(meanAvgTimeOnPage || 0, 2)}
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={1}
                        sx={{ paddingTop: smallTable ? 0 : undefined }}
                      >
                        Avg: {round(meanBounceRate || 0, 2)}
                      </TableCell>
                    </TableRow>
                    <TableRow key="column-headers">
                      <SortableHeaderCell
                        label="Page Path"
                        column="pagePath"
                        align="left"
                        colSpan={1}
                        handleSort={handleSort}
                        sortOrder={sortOrder}
                        sortedColumn={sortedColumn}
                      />
                      <SortableHeaderCell
                        label="Pageviews"
                        column="pageviews"
                        align="right"
                        colSpan={1}
                        handleSort={handleSort}
                        sortOrder={sortOrder}
                        sortedColumn={sortedColumn}
                      />

                      <SortableHeaderCell
                        label="Avg. Time (s)"
                        column="avgTimeOnPage"
                        align="right"
                        colSpan={1}
                        handleSort={handleSort}
                        sortOrder={sortOrder}
                        sortedColumn={sortedColumn}
                      />
                      <SortableHeaderCell
                        label="Bounce Rate {%}"
                        column="bounceRate"
                        align="right"
                        colSpan={1}
                        handleSort={handleSort}
                        sortOrder={sortOrder}
                        sortedColumn={sortedColumn}
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, ii) => {
                        const row_id = row.date + "<>" + row.pagePath;
                        const indexInData = page * rowsPerPage + ii;
                        const isActive = activeRows.includes(row.pagePath);
                        const totalSize = filteredData.length;
                        const isLastPage =
                          page * rowsPerPage + rowsPerPage >= totalSize;
                        const location =
                          page === 0
                            ? "first"
                            : isLastPage
                            ? "last"
                            : undefined;
                        return (
                          <TableRow
                            key={row_id}
                            sx={{
                              backgroundColor: isActive ? blue[200] : undefined,
                              "&:hover": { backgroundColor: blue[100] },
                              "&:active": { backgroundColor: blue[300] },
                            }}
                            onClick={() => {
                              rowClickHandler(row.pagePath);
                            }}
                          >
                            <StarredTableCell
                              value={row.pagePath}
                              column="pagePath"
                              align="left"
                              style={{
                                maxWidth: "400px",
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                              }}
                              colSpan={1}
                              sortOrder={sortOrder}
                              sortedColumn={sortedColumn}
                              index={indexInData}
                              tableSize={totalSize}
                            />
                            <StarredTableCell
                              value={row.pageviews}
                              column="pageviews"
                              align="right"
                              colSpan={1}
                              sortOrder={sortOrder}
                              sortedColumn={sortedColumn}
                              index={indexInData}
                              tableSize={totalSize}
                              isStarrable
                              location={location}
                            />
                            <StarredTableCell
                              value={round(row.avgTimeOnPage)}
                              column="avgTimeOnPage"
                              align="right"
                              colSpan={1}
                              sortOrder={sortOrder}
                              sortedColumn={sortedColumn}
                              index={indexInData}
                              tableSize={totalSize}
                              isStarrable
                              location={location}
                            />
                            <StarredTableCell
                              value={round(row.bounceRate)}
                              column="bounceRate"
                              align="right"
                              colSpan={1}
                              sortOrder={sortOrder}
                              sortedColumn={sortedColumn}
                              index={indexInData}
                              tableSize={totalSize}
                              isStarrable
                              location={location}
                              reverseStarring
                            />
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                showFirstButton
                showLastButton
                rowsPerPageOptions={
                  smallTable ? [5, 10, 15, 20] : [50, 100, 150, 200]
                }
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  borderRadius: smallTable ? "0px" : undefined,
                  paddingTop: 0,
                  marginTop: 0,
                  borderTop: "1px solid",
                  borderBottom: "0px",
                  borderColor: grey[200],
                  "& .MuiToolbar-root": {
                    padding: smallTable ? "8px" : undefined,
                  },
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
