import {
  useResourceContext,
  useRecordContext,
  useCreatePath,
  useListContext,
} from "react-admin";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  CardActionArea,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import removeMd from "remove-markdown";
import { getTextMatches } from "./helpers";
import { TextMatchResult } from "./TextMatchResult";
import { HelpTopicTitle } from "./HelpTopicTitle";

const useNavigateShow = () => {
  const resource = useResourceContext();
  const navigate = useNavigate();
  const { id } = useRecordContext();
  const createPath = useCreatePath();
  const path = createPath({ resource, id, type: "show" });
  return () => navigate(path);
};

export const HelpTopicCard = () => {
  const {
    filterValues: { q: search },
  } = useListContext();
  const { short_description, description } = useRecordContext();
  const matches = getTextMatches(search, removeMd(description));
  const hasSearchResults = !!matches.length;
  const navigate = useNavigateShow();
  return (
    <Grid item xs={hasSearchResults ? 12 : 3} sx={{ padding: 0 }}>
      <Card
        sx={{
          borderRadius: "8px",
          border: "1px solid",
          borderColor: "grey.200",
        }}
      >
        <CardActionArea disableRipple onClick={navigate}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 4,
              minHeight: "220px",
              padding: "24px",
            }}
          >
            <HelpTopicTitle />
            {hasSearchResults ? (
              matches.map((match, i) => (
                <TextMatchResult key={i} match={match} />
              ))
            ) : (
              <Typography
                variant="body1"
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  whiteSpace: "",
                  flexGrow: 1,
                }}
              >
                {short_description}
              </Typography>
            )}
            <Typography variant="body1" color="blue.500">
              Learn More
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
