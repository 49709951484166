import { Typography } from "@mui/material";
import { Loading } from "react-admin";
import { SummaryCards } from "~/components";
import { useStats } from "~/hooks";

export const RegionStats = () => {
  const { data, isLoading } = useStats();
  if (isLoading) return <Loading />;
  return (
    <>
      <Typography variant="h4">{data.title}</Typography>
      <SummaryCards cards={data.cards} />
    </>
  );
};
