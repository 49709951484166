import { Button, Typography } from "@mui/material";
import { IfCanAccess } from "@react-admin/ra-rbac";
import { identity, startCase } from "lodash";
import {
  Datagrid,
  FunctionField,
  ImageField,
  ReferenceManyField,
  SingleFieldList,
  useRecordContext,
} from "react-admin";
import {
  ChipField,
  ExpandedStatsSummaryField,
  StatsSummaryField,
} from "~/components/fields";
import { ToggleList } from "~/components/lists";
import { dataProvider } from "~/lib";

const ListData = () => {
  return (
    <Datagrid rowClick="edit" sx={{}} bulkActionButtons={false}>
      <ImageField source="logo.url" label={""} />
      <FunctionField
        source="name"
        label=""
        render={(record) => {
          return (
            <>
              <Typography variant="h4">{record.name}</Typography>
              <Typography sx={{ textWrap: "wrap", maxWidth: "500px" }}>
                {record.description}
              </Typography>
            </>
          );
        }}
      />

      <ExpandedStatsSummaryField label="" />
      {identity.role == "super_admin" && (
        <ReferenceManyField
          reference="platform_extensions"
          target="platform_id"
        >
          <SingleFieldList linkType={false}>
            <FunctionField
              label=""
              render={(record) => {
                return (
                  <ChipField
                    title={[record.alerts, record.status].join(",")}
                    color={record.alerts ? "error" : "primary"}
                    value={record.type.replaceAll("Extension", "")}
                  />
                );
              }}
            />
          </SingleFieldList>
        </ReferenceManyField>
      )}
      <JoinPlatformButton />
    </Datagrid>
  );
};

const JoinPlatformButton = (props) => {
  const record = useRecordContext(props);

  return (
    <IfCanAccess action="join">
      <Button
        variant={"contained"}
        onClick={() => dataProvider.joinPlatform(record)}
      >
        Join Platform
      </Button>
    </IfCanAccess>
  );
};

export const platformCardData = (record) => ({
  title: startCase(record.name),
  img: record.logo,
  // buttons: <JoinPlatformButton />,
  stats_summary: <StatsSummaryField resource="platforms" label="" />,
});

const MODES = [
  { name: "Grid", cardData: platformCardData },
  { name: "List", view: <ListData /> },
];

export const PlatformList = () => {
  return (
    <ToggleList
      modes={MODES}
      sx={{ "& .ItinImageCard-image": { objectFit: "contain" } }}
    />
  );
};
