import { Button, Stack, Typography, StackProps , Link} from "@mui/material";
import { Record } from "@phosphor-icons/react";
import { TextInput } from "ra-ui-materialui";
import { useRandomIllustration } from "~/images/illustrations";

type ImageErrorProps = StackProps & {
  onDelete?: () => void;
  size?: "sm" | "lg";
};
export const ImageError = ({
  onDelete,
  size = "lg",
  file = {},
  ...props
}: ImageErrorProps) => {
  const Illustration = useRandomIllustration();
  const isSmall = size === "sm";
  const textVariant = isSmall ? "h6" : "h4";
  return (
    <Stack
      className="ItinImageError"
      height={1}
      width={1}
      spacing={isSmall ? 1 : 3}
      border="1px solid"
      borderColor="error.main"
      padding={3}
      bgcolor="error.light"
      color="error.main"
      borderRadius={4}
      boxSizing="border-box"
      {...props}
    >
      {/* <Typography variant={textVariant} textAlign="center">
        with your image!
      </Typography> */}
      <Illustration overflow="hidden" />
      <Typography variant={textVariant} textAlign="center">
        Please delete and re-upload this image 

      </Typography>
      <Link
                  href={`https://drive.google.com/drive/search?q=${file.filename}`}
                  target="_blank"
                >
                  Search Drive for {file.filename}
                </Link>
      {!!onDelete && (
        <Button variant="contained" color="error" onClick={onDelete}>
          Delete Image
        </Button>
      )}
    </Stack>
  );
};
