import { Box, Grid } from "@mui/material";
import { MarkdownField } from "@react-admin/ra-markdown";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import {
  Edit,
  FormTab,
  Loading,
  ReferenceManyField,
  TabbedForm,
  TextField,
  TextInput,
  required,
  useRecordContext,
  useRefresh,
  useResourceContext,
} from "react-admin";
import { useQueryClient } from "react-query";
import {
  useCounter,
  useDebounceCallback,
  useResizeObserver,
} from "usehooks-ts";
import { DrawerDatagrid } from "~/components/drawer";
import { AdminUserInput, EnumChoiceInput } from "~/components/inputs";
import { useCurrentPlatform } from "~/context";
import { itineratorNetUrl } from "~/helpers";
import { ReelLegForm } from "./ReelLegForm";
import { ReelStops } from "./ReelStops";

const StopDrawerContext = createContext(undefined);

export const useStopDrawerContext = () => {
  const context = useContext(StopDrawerContext);
  if (context === undefined)
    throw new Error(
      "useStopDrawerContext cannot be used outside a StopDrawerProvider"
    );
  return context;
};

export const ReelEdit = (props) => {
  const [stopRef, setStopRef] = useState();
  const refresh = useRefresh();
  const onSuccess = () => {
    refresh();
  };
  const { getTerm } = useCurrentPlatform();
  const reelCapTerm = getTerm("reel", {
    plural: false,
    capitalize: true,
  });

  return (
    <StopDrawerContext.Provider value={{ ...stopRef, setStopRef }}>
      <Edit
        mutationMode="pessimistic"
        title=" "
        redirect={false}
        mutationOptions={{
          onSuccess,
        }}
      >
        <TabbedForm>
          <FormTab label={reelCapTerm}>
            <ReelLegsStops />
          </FormTab>
          <FormTab label="Basic info">
            <Grid container width={"50%"}>
              <Grid item md={12}>
                <TextInput
                  source="name"
                  validate={required()}
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingBottom: 0,
                    justifyContent: "space-between",
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <AdminUserInput fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <EnumChoiceInput source="status" isRadio={true} />
              </Grid>
            </Grid>
          </FormTab>
        </TabbedForm>
      </Edit>
    </StopDrawerContext.Provider>
  );
};

const useOnDebouncedMutationCacheUpdate = (callback, timeout = 750) => {
  const callbackRef = useRef(callback);
  const debounced = useDebounceCallback(callbackRef.current, timeout);
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.getMutationCache().subscribe(debounced);
  }, [debounced, queryClient]);
};

export const ReelLegsStops = () => {
  const reel = useRecordContext();
  const resource = useResourceContext();
  const { currentPlatform } = useCurrentPlatform();
  const { count: iframeKey, increment } = useCounter(0);
  useOnDebouncedMutationCacheUpdate(increment);
  const url = itineratorNetUrl(
    currentPlatform?.slug,
    resource,
    `embed/${reel?.slug}?single=true`
  );

  return (
    <Grid container cs={12} columnSpacing={2}>
      <Grid item xs={6}>
        <ReferenceManyField
          reference="legs"
          target="itinerary_id"
          perPage={null}
        >
          <DrawerDatagrid
            Form={<ReelLegForm itinerary={reel} />}
            buttonLabel={"Section"}
            label={"Create your first Section"}
            datagridProps={{
              expand: (
                <>
                  <MarkdownField source="introduction" sx={{ paddingX: 3 }} />
                  <ReelStops />
                </>
              ),
              expandSingle: true,
            }}
            sortable_update_source="order_on_itin"
            canDelete={false}
            sx={{
              "& .RaDatagrid-tableWrapper": {
                padding: 0,
              },
              "& .MuiTableBody-root": {
                // margin: "-32px",
                marginTop: "32px",
                marginBottom: "32px",
              },
              "& .RaDatagrid-thead ": { display: "none" },
              "& .RaCreateButton-root": {
                marginLeft: 2,
              },
            }}
          >
            <TextField
              source="title"
              sortable={false}
              sx={{ fontWeight: 600, fontSize: "24px" }}
            />
          </DrawerDatagrid>
        </ReferenceManyField>
      </Grid>
      <Grid item xs={6}>
        {reel?.legs[0]?.stops?.length > 0 && (
          <IFrameWithLoading key={iframeKey} src={url} height={"500px"} />
        )}
      </Grid>
    </Grid>
  );
};

const IFrameWithLoading = (props) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
  }, []);
  const ref = useRef();
  const { width, height } = useResizeObserver({ ref, box: "border-box" });
  return (
    <Box sx={{ position: "relative" }}>
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width,
            height,
          }}
        >
          <Loading />
        </Box>
      )}
      <iframe
        title="iframe"
        ref={ref}
        style={{ border: "none" }}
        {...props}
        onLoad={() => setLoading(false)}
      />
    </Box>
  );
};
