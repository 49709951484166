import { Box } from "@mui/material";
import {
  AutocompleteInput,
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
} from "react-admin";
import { useLocation } from "react-router";
import { StyledToolBar } from "~/components";
import { ItinLoading } from "~/components/ItinLoading";
import { sluggify } from "~/helpers";
import { useTagCategories } from "~/hooks";
import { loadTagIcon, TAG_ICONS } from "./tag_icons";
import React, { Suspense, useState } from "react";

export const TagEdit = () => {
  const location = useLocation();
  const record = location.state?.record || null;
  const backPath = record?.backPath || "list";
  const { data: categories, loading: categoriesLoading } = useTagCategories();
  if (categoriesLoading) return <ItinLoading />;

  const [IconComponent, setIconComponent] = useState(null);

  const handleChange = async (event, newValue) => {
    if (newValue?.name) {
      // Load the icon dynamically based on the selected category name
      const LoadedIcon = loadTagIcon(newValue.name);
      setIconComponent(() => LoadedIcon);
    } else {
      setIconComponent(null);
    }
  };

  return (
    <Edit>
      <Box
        sx={{
          margin: 4,
          backgroundColor: "white",
          borderRadius: "16px",
        }}
      >
        <SimpleForm redirect={backPath} width="50%" toolbar={<StyledToolBar />}>
          <AutocompleteInput
            source="category"
            helperText={'Only a-z, 0-9, and "-" allowed'}
            choices={categories}
            onCreate={(val) => {
              const category = sluggify(val);
              const newCategory = {
                id: categories.length + 1,
                name: category,
              };
              categories.push(newCategory);
              return newCategory;
            }}
            fullWidth
          />
          <AutocompleteInput
            source="icon"
            choices={TAG_ICONS}
            onChange={handleChange} // Track the selected value
          />
          {/* Render the dynamically loaded icon beside the AutocompleteInput */}
          {IconComponent && (
            <Suspense fallback={<div>Loading icon...</div>}>
              <div style={{ marginLeft: "16px" }}>
                <IconComponent size={32} weight="bold" />
              </div>
            </Suspense>
          )}
          <TextInput source="name" fullWidth />
          <BooleanInput source="show" />
        </SimpleForm>
      </Box>
    </Edit>
  );
};
