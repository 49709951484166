import { Grid } from "@mui/material";
import { useRef, useState } from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { useUpdateEffect } from "usehooks-ts";
import { StyledToolBar } from "~/components";
import { EnumChoiceInput } from "~/components/inputs";
import { useCurrentPlatform } from "~/context";
import { useChallengeTaskTargets } from "~/hooks";
const TARGET_TYPES = [
  "Platform",
  "Region",
  "Itinerary",
  "Stop",
  "Listing",
  "Reward",
  "Event",
];

const filterTargetTypes = (record_type) => {
  const record_type_index = TARGET_TYPES.indexOf(record_type);
  return TARGET_TYPES.filter((_, index) => index >= record_type_index)
    .map((type) => ({ id: type, name: type }))
    .reverse();
};

export const TaskForm = ({ challenge }) => {
  const { getTerm } = useCurrentPlatform();

  const task = useRecordContext();
  const { id, target_type: challenge_target_type } = challenge;
  const termTargetType = filterTargetTypes(challenge_target_type).map((tt) => {
    return {
      id: tt.id,
      name: getTerm(tt.name.toLowerCase(), { plural: true, capitalize: true }),
    };
  });
  const targetTypesRef = useRef(termTargetType);

  const [taskTargetType, setTaskTargetType] = useState(task?.target_type);
  const { data: targets = [] } = useChallengeTaskTargets(id, taskTargetType);
  const input_props = {
    validate: required(),
    fullWidth: true,
  };
  const pointCapTerm = getTerm("point", {
    plural: true,
    capitalize: true,
  });
  return (
    <SimpleForm
      defaultValues={{ challenge_id: id }}
      toolbar={
        <StyledToolBar showDelete={task?.platform_user_task_count === 0} />
      }
    >
      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          <SelectInput
            source="target_type"
            defaultValue={taskTargetType}
            choices={targetTypesRef.current}
            onChange={({ target }) => {
              setTaskTargetType(target.value);
            }}
            {...input_props}
          />
          <AutocompleteInput
            source="target_id"
            emptyText={
              !targets.length
                ? `No ${taskTargetType}s Available`
                : `Select a ${taskTargetType}`
            }
            choices={targets}
            validate={required()}
            disabled={!taskTargetType}
            label={taskTargetType}
            {...input_props}
          />
          <TextInput
            source="weight"
            label={`Completion ${pointCapTerm}`}
            defaultValue={100}
            {...input_props}
          />
        </Grid>
        <Grid item xs={6}>
          <EnumChoiceInput source="type" {...input_props} />
          <FormSelect />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

const FormSelect = () => {
  const { setValue } = useFormContext();
  const { getTerm } = useCurrentPlatform();
  const formCapTerm = getTerm("form", {
    plural: false,
    capitalize: true,
  });
  const type = useWatch({ name: "type" });
  const isForm = type === "FormTask";
  useUpdateEffect(() => {
    setValue("form_id", null);
  }, [isForm]);
  if (!isForm) return null;
  return (
    <>
      <ReferenceInput source="form_id" reference="forms">
        <AutocompleteInput
          validate={required()}
          fullWidth
          label={formCapTerm}
        />
      </ReferenceInput>
    </>
  );
};
