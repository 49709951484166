import {
  Box,
  Button,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { CaretDown } from "@phosphor-icons/react";
import { sortBy } from "lodash";
import { useState } from "react";
import { useGetList, useGetMany, useGetOne, useRefresh } from "react-admin";
import { useQueryClient } from "react-query";
import { useCurrentPlatform } from "~/context";
import { useEditorPlatformUsers } from "~/hooks";
import { Logo } from "~/images/Logo";

const PlatformMenu = () => {
  const queryClient = useQueryClient();
  const { currentPlatform, loading, setPlatform } = useCurrentPlatform();
  const { data: platforms, isLoading } = usePlatformList();
  const refresh = useRefresh();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterText, setFilterText] = useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setFilterText(""); // Reset filter when popover closes
  };

  const open = Boolean(anchorEl);
  if (isLoading || loading) return null;
  if (platforms.length <= 1)
    return (
      <Typography
        variant="h4"
        sx={{ color: "grey.800", textTransform: "capitalize" }}
      >
        {currentPlatform?.name}
      </Typography>
    );

  return (
    <>
      <Button
        onClick={handleClick}
        variant="dropdown"
        sx={{
          height: "fit-content",
          backgroundColor: "black",
          borderRadius: 0,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          alignItems: "flex-start",
          padding: 2,
          "&:hover": {
            backgroundColor: "black",
          },
        }}
        endIcon={
          <CaretDown
            size={16}
            weight="bold"
            style={{
              color: "white",
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.2s",
            }}
          />
        }
      >
        <Box
          height={64}
          minWidth={64}
          width={64}
          // width={currentPlatform.logo ? 64 : "auto"}
          display="flex"
          alignItems="center"
          sx={{ borderRadius: 3, backgroundColor: "white" }}
        >
          {currentPlatform.logo?.url ? (
            <img
              src={currentPlatform.logo.url}
              alt={currentPlatform.name}
              style={{
                width: "95%",
                height: "95%",
                display: "block",
                objectFit: "contain",
                margin: "auto",
              }}
            />
          ) : (
            <>
              <Typography
                variant="body"
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  textAlign: "center",
                  overflow: "hidden", // Ensures overflow is hidden if text exceeds container
                  textOverflow: "ellipsis", // Adds "..." when the text is too long
                  fontSize: "clamp(6px, 2vw, 10px)", // Dynamically adjust font size between 12px and 16px based on viewport width
                  width: "100%", // Ensures it takes up the full width of the parent container
                }}
              >
                {/* <Logo sx={{ width: 24, height: 24, display: "inline" }} /> */}
                {currentPlatform.name}
              </Typography>
            </>
          )}
        </Box>
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        autoFocus={false}
        sx={{ zIndex: 2147483004 }}
      >
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            placeholder="Filter platforms"
            value={filterText}
            onChange={(e) => {
              setFilterText(e.target.value);
            }}
            variant="outlined"
            fullWidth
          />
        </MenuItem>
        {sortBy(platforms, ["name"]).map(({ id, name, logo }) => (
          <MenuItem
            key={id}
            selected={id === currentPlatform?.id}
            onClick={() => {
              setPlatform(id).then(() => {
                handleClose();
                refresh();
              });
              queryClient.invalidateQueries({ queryKey: ["analytics"] });
            }}
            sx={{
              display: name.toLowerCase().includes(filterText.toLowerCase())
                ? "flex"
                : "none",
            }}
          >
            <ListItemAvatar sx={{ height: 48, width: 48, padding: "6px" }}>
              {logo?.url && (
                <img
                  src={logo.url}
                  alt={name}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              )}
            </ListItemAvatar>
            <ListItemText
              primary={name}
              sx={{ textTransform: "capitalize", marginLeft: 1 }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const usePlatformList = () => {
  const { data: currentUser, isLoading: isUserLoading } = useGetOne("users", {
    id: "current",
  });
  const isSuperAdmin = currentUser?.role === "super_admin";
  const { data: platform_users = [], isLoading: platformUsersIsLoading } =
    useEditorPlatformUsers({
      enabled: !isUserLoading && !isSuperAdmin,
    });

  const { data: editor_platforms = [], isLoading: isPlatformsLoading } =
    useGetMany(
      "platforms",
      {
        ids: platform_users.map((p) => p.platform_id),
      },
      { enabled: !platformUsersIsLoading && !isSuperAdmin }
    );

  const { data: all_platforms = [], isLoading: allPlatformsIsLoading } =
    useGetList(
      "platforms",
      { pagination: { page: 1, perPage: 100 } },
      { enabled: !platformUsersIsLoading && isSuperAdmin }
    );

  return {
    data: isSuperAdmin ? all_platforms : editor_platforms,
    isLoading:
      isUserLoading ||
      platformUsersIsLoading ||
      isPlatformsLoading ||
      allPlatformsIsLoading,
  };
};

export default PlatformMenu;
