import { Dispatch, ReactNode, SetStateAction, useRef } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ToolTipJS,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Box, Card, CardContent, Typography, Tooltip } from "@mui/material";
import { formatData } from "../helpers";
import { DatePeriodSelect } from "../shared_components";
import { CSVLink } from "react-csv";
import { CloudArrowDown } from "@phosphor-icons/react";
import { blue } from "~/theme/colors";
import { Loading } from "react-admin";

ChartJS.register(ArcElement, ToolTipJS, Legend);
ChartJS.defaults.scale.grid.display = false;
ChartJS.defaults.scale.ticks.display = false;
ChartJS.defaults.maintainAspectRatio = false;
ChartJS.defaults.borderColor = "transparent";

const options = (ymax: number) => {
  return {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "",
      },
      interaction: {
        intersect: false,
        mode: "index" as const,
      },
      tooltip: {
        intersect: false,
        mode: "nearest" as const,
        axis: "x" as const,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: ymax + 1,
      },
    },
  };
};

type SparklineGraphsProps = {
  data: any[];
  ignore?: string[];
  header?: string | ReactNode;
  headerType?: string;
  text?: string;
  ymax?: number;
  height?: string;
  period?: string;
  setPeriod?: Dispatch<SetStateAction<string>>;
  showCsvLink?: boolean | undefined;
};

export const SparklineGraphs = ({
  data,
  ignore = [],
  header,
  headerType = "string",
  text,
  ymax = 5.5,
  height = "350px",
  period,
  setPeriod,
  showCsvLink = false,
}: SparklineGraphsProps) => {
  const chartRef = useRef();
  if (!data) return <Loading />;
  const formattedData = formatData(data, ignore);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        borderRadius: "10px",
        width: "100%",
        height: height,

      }}
      className="graph"
    >
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            {headerType === "string" ? (
              <Typography variant="h5" mb="8px">
                {header}
              </Typography>
            ) : (
              header
            )}
            <Box display="flex" flexDirection="row" alignItems="center">
              {showCsvLink && (
                <CSVLink data={data} filename={"cumulative-users.csv"}>
                  <Tooltip title="Download Data">
                    <CloudArrowDown
                      size={22}
                      style={{
                        marginTop: "3px",
                        marginRight: "5px",
                        color: blue[300],
                      }}
                    />
                  </Tooltip>
                </CSVLink>
              )}
              {period && setPeriod && (
                <DatePeriodSelect period={period} setPeriod={setPeriod} />
              )}
            </Box>
          </Box>
          <Typography variant="body2" mb="8px">
            {text}
          </Typography>
        </Box>
        <Box sx={{ height: "75%", width: "100%" }}>
          <Line options={options(ymax)} data={formattedData} ref={chartRef} />
        </Box>
    </Box>
  );
};
