import { Box, Card, Grid } from "@mui/material";
import { ItinLoading, RecentCheckIns, Stats } from "~/components";
import { HasPlatformExtension } from "~/components/HasPlatformExtension";
import { RecentlyUpdatedContent } from "~/components/RecentlyUpdatedContent";
import { useCurrentPlatform } from "~/context";
import { SubscriptionOptions } from "~/stripe/SubscriptionOptions";
import { UpcomingEvents } from "../events/UpcomingEvents";
import { HomePageRegions } from "./HomePageRegions";
import { useCheckInsGraph } from "~/hooks";
import { CheckInsGraph } from "../analytics/mobile_analytics_components";
import { Show, ShowBase } from "react-admin";

export const AdminHome = () => {
  const { currentPlatform } = useCurrentPlatform();
  if (!currentPlatform) return <ItinLoading />;

  return (
    <Box
      width={"100%"}
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
      padding="32px"
    >
      <Box gridColumn="span 12" sx={{ cursor: "pointer" }}>
        <SubscriptionOptions
          target_type="Platform"
          record={currentPlatform.currentPlatform}
          cta="Upgrade this platform to publish it live"
        />
      </Box>

      <Box gridColumn="span 8">
        <Card variant="outlined">
          <HomePageRegions />
        </Card>
      </Box>
      <Box gridColumn="span 4">
        <HasPlatformExtension
          platform_extension="Contesting"
          fallback={
            <Card variant="outlined">
              <RecentlyUpdatedContent filter={{ limit: "10" }} />
            </Card>
          }
        >
          <Card variant="outlined">
            <Grid container>
              <Grid item xs={12}>
                <ShowBase resource="platforms" id={currentPlatform.id}>
                  <Stats
                    category="home_app_activity"
                    // start_date={new Date().toLocaleDateString("en-GB")}
                    // end_date={new Date().toLocaleDateString("en-GB")}
                  />
                </ShowBase>
                <RecentCheckIns />
              </Grid>
            </Grid>
          </Card>
        </HasPlatformExtension>
      </Box>
      <Box gridColumn="span 12">
        <HasPlatformExtension platform_extension="Events">
          <UpcomingEvents />
        </HasPlatformExtension>
      </Box>
    </Box>
  );
};
