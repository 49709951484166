import { Box, Button, Card, Typography } from "@mui/material";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useRef } from "react";
import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
  required,
  useRecordContext,
} from "react-admin";
import QRCode from "react-qr-code";
import { StyledToolBar } from "~/components";
import { QRCodeScansConversion } from "./QRCodeScansConversion";
import { useCurrentPlatform } from "~/context";

// TODO use validate URL once merged
export const QRCodeEdit = () => {
  return (
    <Edit mutationMode="pessimistic" redirect={false}>
      <TabbedForm
        redirect={false}
        variant="standard"
        toolbar={<StyledToolBar />}
      >
        <FormTab label="QR Code">
          <QRCard />
        </FormTab>
        <FormTab label="Basic info" width="100%">
          <TextInput source="url" validate={required()} />
          <Box
            sx={{
              display: "flex",
              gap: 1,
              width: "100%",
            }}
          >
            <TextInput
              source="tactic_code"
              helperText="Enter a tactic code to track your campaign."
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              width: "100%",
            }}
          >
            <TextInput source="notes" multiline minRows={10} fullWidth />
          </Box>
        </FormTab>
        <FormTab label="QR code Scans">
          <QRCodeScansConversion />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const QRCard = () => {
  const record = useRecordContext();
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "in",
    format: [12, 12],
  });

  const printRef = useRef();

  const handleDownloadImage = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/pdf");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      doc.addImage(link.href, 1, 1, 10, 10);
      doc.save("QR-code.pdf");
    } else {
      window.open(data);
    }
  };
  const { getTerm } = useCurrentPlatform();
  const stopTerm = getTerm("stop", {
    plural: true,
    capitalize: false,
  });
  const eventTerm = getTerm("event", {
    plural: true,
    capitalize: false,
  });
  const challengeTerm = getTerm("challenge", {
    plural: true,
    capitalize: false,
  });
  return (
    <Card
      variant={"outlined"}
      sx={{
        borderRadius: "12px",
        backgroundColor: "primary.contrastText",
        cursor: "pointer",
        margin: "12px",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignContent="center"
        width="100%"
      >
        <Box sx={{ mb: "12px", mt: "12px" }}>
          <Typography variant={"h5"} sx={{ mb: "8px" }} noWrap>
            Download QR Code
          </Typography>

          <Typography
            variant="body1"
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              whiteSpace: "",
              flexGrow: 1,
              width: "80%",
            }}
          >
            {` Download your QR code for checking into ${stopTerm}, ${eventTerm} or ${challengeTerm}!`}
          </Typography>
        </Box>

        <Box
          width="100%"
          sx={{ padding: "10px" }}
          display="flex"
          flexDirection="column"
        >
          <Box ref={printRef}>
            <QRCode
              value={`https://admin.itinerator.ca/qr/${record.id}`}
              size={250}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            />
          </Box>
        </Box>
        <Button variant="outlined" fullWidth onClick={handleDownloadImage}>
          Download
        </Button>
      </Box>
    </Card>
  );
};
