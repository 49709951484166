import { Button } from "react-admin";
import { useCurrentPlatform } from "~/context";

export const SubscribeButton = (props) => {
  /**
   * props = {
      target_type, // "Listing"
      target_id, // "853"
      platform_extension_id, // "6"
    }
  */
  const params = new URLSearchParams(props);
  const { getTerm } = useCurrentPlatform();
  const term = getTerm(props.target_type.toString().toLowerCase(), {
    plural: false,
    capitalize: true,
  });
  return (
    <Button
      label={props.label ? props.label : `Upgrade this ${term}`}
      href={`/api/admin/user_subscriptions/checkout_session?${params}`}
      target="_blank"
    />
  );
};
