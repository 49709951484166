import { omit } from "lodash";

type Record = {
  id: number | undefined;
  [key: string]: any;
};

const formatDestroyedRecords = (
  records?: Record[],
  previous_records?: Record[]
) => {
  if (!records || !previous_records) return null;
  const record_ids = records.map((loc) => loc.id).filter(Boolean);
  return previous_records
    .filter((loc) => !record_ids.includes(loc.id))
    .map((loc) => ({ ...loc, _destroy: true }));
};

export const handleArrayAttributes = (
  data: any,
  previousData: any,
  source: string
) => {
  const records = data[source];
  const previous_records = previousData[source];
  const deleted_records = formatDestroyedRecords(records, previous_records);
  return {
    ...omit(data, source),
    [`${source}_attributes`]: { ...records, ...deleted_records },
  };
};
