type MenuGroup = {
  key: string;
  name: string;
  defaultExpanded: boolean;
  requiredRole?: Array<"admin" | "super_admin" | "viewer">;
  primary_resource?: string;
  requiredExtension?:
    | "Cms"
    | "Contesting"
    | "App"
    | "Analytics"
    | "Events"
    | "PremiumContent"
    | "Ai"
    | "Wp"
    | "Newsletter";
};

export const MENU_GROUPS: MenuGroup[] = [
  {
    key: "places",
    name: "Places",
    primary_resource: "regions",
    defaultExpanded: true,
  },
  {
    key: "events",
    name: "Events",
    primary_resource: "events",
    defaultExpanded: true,
    requiredExtension: "Events",
  },
  {
    key: "content",
    name: "Content",
    requiredRole: ["admin", "super_admin", "viewer"],
    primary_resource: "contents",
    defaultExpanded: true,
  },
  {
    key: "collaborate",
    name: "Collaborate",
    primary_resource: "platforms",
    defaultExpanded: true,
    requiredRole: ["admin", "super_admin"],
  },
  {
    key: "communicate",
    name: "Communicate",
    primary_resource: "newsletters",
    defaultExpanded: true,
    requiredRole: ["admin", "super_admin"],
  },
  {
    key: "contesting",
    name: "Contesting",
    primary_resource: "challenges",
    defaultExpanded: false,
    requiredRole: ["admin", "super_admin"],
    requiredExtension: "Contesting",
  },
  {
    key: "reports",
    name: "Reports",
    primary_resource: "analytics",
    defaultExpanded: false,
    requiredRole: ["admin", "super_admin", "viewer"],
  },
  {
    key: "admin",
    name: "Admin",
    defaultExpanded: false,
    requiredRole: ["admin", "super_admin"],
  },
];
